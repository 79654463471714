import React, { useState, useEffect } from "react";
import { useHotkeys } from "../../tools/react-hotkeys-hook";
import Labeler from "./component";
import Spinner from "../common/Spinner";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import withContext from "../../tools/withContext";

/** Labeler container manage datas fetching & saving for image labelization */
const MosaicContainerF = (props: any) => {
  const {
    match: {
      params: { noBatch, noPage },
    },
    ctx: { appFetch, user },
  } = props;
  const [motifs, setMotifs] = useState<string[]>([]);
  const [nomBatch, setNomBatch] = useState("");
  const [typeLabellisation, setTypeLabellisation] = useState("");
  const [tags, setTags] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [nbrePages, setNbrePages] = useState<number>(0);
  const [dimensions, setDimensions] = useState({ height: 576, width: 720 });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [keyEvent, setKeyEvent] = useState<any>(null);

  useHotkeys("*", (e) => {
    const { key, type, shiftKey, altKey, ctrlKey } = e;
    setKeyEvent({ key, type, shiftKey, altKey, ctrlKey });
  });

  const clearKeypressedState = () => setKeyEvent(null);

  /** Fetch image list for batch noBatch */
  const fetchImages = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const images = await appFetch(
        `/api/images/batches/${noBatch}/all?offset=0&size=1000`
      );

      const image: any = new Image();
      image.src = images[0].url;

      // we define the labeler's canvas size by loading the first image
      image.onload = (img: any) => {
        const { height, width } = img.target;
        const nbrPages =
          Math.floor(images.length / 6) + (images.length % 6 > 0 ? 1 : 0);
        setDimensions({ height, width });
        setImages(images);
        setNbrePages(nbrPages);
        setIsLoading(false);
      };

      image.onerror = (e: any) => {
        throw new Error("Erreur lors du chargement de la première image");
      };
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
    }
  };

  /** Load batch tags*/
  const fetchBatch = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const batch = await appFetch(
        `${constants.paths.BATCHES_PATH}/${noBatch}`
      );
      const labelingTypes = batch.project.labelingTypes[0];
      const tagsWithColors = labelingTypes.tags.map(
        (t: any, index: number) => ({
          ...constants.colors[index],
          legend: t,
          visible: true,
        })
      );
      setTypeLabellisation(labelingTypes.name);
      setTags(tagsWithColors);
      setMotifs(labelingTypes.reasons);
      setNomBatch(batch.name);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
    }
  };

  /** Close snackbar */
  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchImages();
    fetchBatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noBatch]);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      {isLoading && <Spinner message="Chargement des images" />}
      {!isLoading &&
        images.length > 0 &&
        (tags.length > 0 || typeLabellisation === "PLATE") &&
        motifs.length > 0 && (
          <Labeler
            loading={isLoading}
            images={images}
            labels={tags}
            motifs={motifs}
            noBatch={noBatch}
            typeLabellisation={typeLabellisation}
            noPage={noPage}
            nbrePages={nbrePages}
            nomBatch={nomBatch}
            height={dimensions.height}
            width={dimensions.width}
            userRole={user ? user.authorities[0] : null}
            keyEvent={keyEvent}
            clearKeyevent={clearKeypressedState}
          />
        )}
    </React.Fragment>
  );
};

export default withContext(MosaicContainerF);
