import BatchType from "../../../types/Batch";

const generateType = (batch: BatchType) => {
    let type;
    switch (batch.project.labelingTypes[0].name) {
      case "PLATE":
        type = "Plate";
        break;
      case "PLATE_TEXT":
        type = "PlateText";
        break;
      case "CLASSIF":
        type = "Class";
        break;
      case "SEGMENTATION":
        type = "Segmentation";
        break;
      default:
        type = "";
        break;
    }
    return type;
  };
  

export default generateType;