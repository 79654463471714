import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import DialogContent from "@material-ui/core/DialogContent";
import { RouteComponentProps } from "react-router";
import UiDialogTitle from "../../common/UiDialogTitle";
import withContext from "../../../tools/withContext";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 4}px`
    },
    formArea: {
      overflowY: "hidden"
    },
    list: {
      height: 250,
      overflowY: "scroll",
      minWidth: "345px"
    },
    input: {
      marginBottom: "1em",
      minWidth: "345px"
    },
    action: {
      paddingRight: "48px",
      paddingBottom: "24px"
    }
  });

interface PropsTypes extends RouteComponentProps<any>, React.Props<any> {
  onSubmit: Function;
  completed: string;
  verified: string;
  open: boolean;
  onClose: any;
  classes: any;
  ctx: any;
}

const guessStatus = (completed: string, verified: string) => {
  if (typeof completed === "undefined" && typeof verified === "undefined")
    return "null";
  if (completed === "true" && verified === "false") return "completed";
  if (completed === "true" && verified === "true") return "null";
};

const FilteringBatchesDialog = (props: PropsTypes) => {
  const { open, onClose, classes, completed, verified, onSubmit } = props;

  const [batchStatus, setBatchStatus] = useState(
    guessStatus(completed, verified)
  );

  const handleChange = (e: any) => {
    setBatchStatus(e.target.value);
    onSubmit(e.target.value !== "null" ? e.target.value : null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="list-actif-title">
      <form className={classes.formArea}>
        <UiDialogTitle title="Filtrer par statut" onCloseClick={onClose} />
        <DialogContent>
          <FormControl className={classes.formControl}>
            <RadioGroup
              aria-label="Status"
              name="status"
              className={classes.group}
              value={batchStatus}
              onChange={handleChange}
            >
              <FormControlLabel
                value="null"
                control={<Radio />}
                label="Aucun filtre"
              />
              <FormControlLabel
                value="completed"
                control={<Radio />}
                label="Complété"
              />
              <FormControlLabel
                value="verified"
                control={<Radio />}
                label="Vérifié"
              />
              <FormControlLabel
                value="not_verified_not_completed"
                control={<Radio />}
                label="Ni complété, ni vérifié"
              />              
            </RadioGroup>
          </FormControl>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(
  withRouter(withContext(FilteringBatchesDialog))
);
