/**
 * Renvoie les coordonnées du point d'ancrage de l'indicateur du mode "bord"
 * ainsi que le nom du bord ou coin ( left|right|bottom|top|leftTop|leftBottom|rightTop|rightBottom )
 */
const computeBorderedPointCoords = (coordsPointer: any, scaling: any, BORD_DETECT_LIMIT: number, previousPosition: any) => {
    const { newWidth, newHeight } = scaling;
    let x = coordsPointer.x;
    let y = coordsPointer.y;
    let border = '';

    if (coordsPointer.x <= BORD_DETECT_LIMIT && coordsPointer.y <= BORD_DETECT_LIMIT) {
        // bord hg
        border = previousPosition || 'left';
        x = 0;
        if (previousPosition === 'top') {
            x = coordsPointer.x;
            y = 0;
        }
    } else if (coordsPointer.x <= BORD_DETECT_LIMIT && coordsPointer.y >= newHeight - BORD_DETECT_LIMIT) {
        // bord bg
        border = previousPosition || 'left';
        x = 0;
        if (previousPosition === 'bottom') {
            x = coordsPointer.x;
            y = newHeight;
        }
    } else if (
        // bord hd
        coordsPointer.x >= newWidth - BORD_DETECT_LIMIT &&
        coordsPointer.y <= BORD_DETECT_LIMIT
    ) {
        border = previousPosition || 'right';
        x = newWidth;
        if (previousPosition === 'top') {
            y = 0;
            x = coordsPointer.x;
        }
    } else if (
        coordsPointer.x >= newWidth - BORD_DETECT_LIMIT &&
        coordsPointer.y >= newHeight - BORD_DETECT_LIMIT
    ) {
        // bord bd
        border = previousPosition || 'right';
        x = newWidth;
        if (previousPosition === 'bottom') {
            y = newHeight;
            x = coordsPointer.x;
        }
    } else if (
        coordsPointer.x > BORD_DETECT_LIMIT &&
        coordsPointer.x < BORD_DETECT_LIMIT * 2 &&
        coordsPointer.y > BORD_DETECT_LIMIT &&
        coordsPointer.y < BORD_DETECT_LIMIT * 2
    ) {
        // coin hgCoin
        border = 'leftTop';
        x = 0;
        y = 0;
    } else if (
        coordsPointer.x > BORD_DETECT_LIMIT &&
        coordsPointer.x < BORD_DETECT_LIMIT * 2 &&
        coordsPointer.y > newHeight - BORD_DETECT_LIMIT * 2 &&
        coordsPointer.y < newHeight - BORD_DETECT_LIMIT
    ) {
        // coin bgCoin
        border = 'leftBottom';
        x = 0;
        y = newHeight;
    } else if (
        // coin hdCoin
        coordsPointer.x > newWidth - BORD_DETECT_LIMIT * 2 &&
        coordsPointer.x < newWidth - BORD_DETECT_LIMIT &&
        coordsPointer.y > BORD_DETECT_LIMIT &&
        coordsPointer.y < BORD_DETECT_LIMIT * 2
    ) {
        border = 'rightTop';
        x = newWidth;
        y = 0;
    } else if (
        coordsPointer.x > newWidth - BORD_DETECT_LIMIT * 2 &&
        coordsPointer.x < newWidth - BORD_DETECT_LIMIT &&
        coordsPointer.y > newHeight - BORD_DETECT_LIMIT * 2 &&
        coordsPointer.y < newHeight - BORD_DETECT_LIMIT
    ) {
        // coin bdCoin
        border = 'rightBottom';
        x = newWidth;
        y = newHeight;
    } else {
        if (coordsPointer.x >= newWidth - BORD_DETECT_LIMIT) {
            x = newWidth;
            border = 'right';
        } else if (coordsPointer.x <= BORD_DETECT_LIMIT) {
            x = 0;
            border = 'left';
        }

        if (coordsPointer.y >= newHeight - BORD_DETECT_LIMIT) {
            y = newHeight;
            border = 'bottom';
        } else if (coordsPointer.y <= BORD_DETECT_LIMIT) {
            y = 0;
            border = 'top';
        }
    }
    return { x, y, border };
};

export default computeBorderedPointCoords;