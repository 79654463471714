import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import GlobalContext from "../GlobalContext";

const RedirectTo = (props: any): any => {
  return (
    <GlobalContext.Consumer>
      {(ctx: any) => {
        const routeComponent = (props2: any) =>
          !ctx.user ? props.render() : <Redirect to={{ pathname: "/" }} />;
        return <Route {...props} render={routeComponent} />;
      }}
    </GlobalContext.Consumer>
  );
};

export default RedirectTo;
