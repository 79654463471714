import React from "react";
import { withRouter } from "react-router-dom";
import { fabric } from "fabric";
import { minBy } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import fitInBox, { size } from "../../../tools/fitInBox";

const styles = (theme: Theme) =>
  createStyles({
    canvasContainer: {
      backgroundColor: "#edf2ff",
      margin: "0 auto",
      width: "100%",
      height: "100%",
    },
  });

export const i18nMapping: any = {
  FRAUD: "Fraude",
  LIGHT: "Lumière",
  BAD_RES: "Qualité d'image",
  BAD_TIMING: "Moment de prise de photo",
  OTHER: "Autre",
};

const convertImageLabelsToPrediction = (image: any) => {
  try {
    const rectangleLabels = image.labels.rectangleLabels.filter(
      (rL: any) => rL.position !== null
    );
    const lpPred = rectangleLabels.reduce(
      (memo: any, rL: any) => memo + rL.labelValue,
      ""
    );

    const characters_position = rectangleLabels.map((rL: any) => [
      rL.left,
      rL.top,
      rL.width,
      rL.height,
    ]);
    const lPP = image.labels.rectangleLabels.find(
      (rL: any) => rL.position === null
    );
    const license_plate_position = [
      lPP.left,
      lPP.top,
      lPP.width,
      lPP.height,
    ].map((v: string) => parseFloat(v));
    return { lpPred, extras: { license_plate_position, characters_position } };
  } catch (e) {
    console.log(e);
    return null;
  }
};

class PlateLabellisation extends React.Component<any, any> {
  private validationStateChangeKeys: string[] = ["a", "z", "e", "q", "s", "d"];
  private canvasRef = React.createRef<HTMLCanvasElement>();
  private canvasWrapperRef = React.createRef<HTMLDivElement>();
  private image: any;
  private canvas: any;
  private scaling: any;
  private textBox: any;

  constructor(props: any) {
    super(props);
    this.state = {
      selected: false,
      polygons: [],
    };
  }

  componentDidMount = () => {
    if (this.canvasRef.current !== null) {
      const { image } = this.props;
      //@ts-ignore
      this.configureFabricJSCanvas(this.canvasRef.current).then(
        (canvas: any) => {
          this.canvas = canvas;
          this.bindEvents();
          this.loadImage(image)
            .then(() => {
              const {
                labels: { rectangleLabels, country },
              } = image;
              if (rectangleLabels.length > 0) {
                const licencePlatePosition = rectangleLabels.find(
                  (l: any) => l.position === null
                );
                const { left, top, width, height } = licencePlatePosition;
                this.configureZoom({ left, top, width, height });
                const prediction: any = convertImageLabelsToPrediction(image);
                if (prediction) {
                  this.hydrate({ ...prediction, country }, "user");
                }
              } else {
              }
            })
            .catch((e: any) => console.log("error loading image", e));
        }
      );
    }
  };

  componentWillReceiveProps = (nextProps: any) => {
    const { image, keyJustPressed, index } = this.props;
    if (image.id !== nextProps.image.id) {
      this.loadImage(nextProps.image)
        .then(() => {
          const {
            labels: { rectangleLabels, country },
          } = nextProps.image;
          if (rectangleLabels.length > 0) {
            const licencePlatePosition = rectangleLabels.find(
              (l: any) => l.position === null
            );
            const { left, top, width, height } = licencePlatePosition;

            this.configureZoom({ left, top, width, height });
            const prediction: any = convertImageLabelsToPrediction(
              nextProps.image
            );
            if (prediction) {
              this.hydrate({ ...prediction, country }, "user");
              if (prediction.lpPred) {
                this.setState({ immatriculation: prediction.lpPred });
              }
            }
          } else {
          }
        })
        .catch((e: any) => console.log("error loading image", e));
    }

    if (
      keyJustPressed === null &&
      nextProps.keyJustPressed !== null &&
      this.validationStateChangeKeys.indexOf(nextProps.keyJustPressed) === index
    ) {
      this.toggleValidation();
    }

    if (
      this.validationStateChangeKeys.indexOf(keyJustPressed) === index &&
      nextProps.keyJustPressed === "c"
    ) {
      console.log("comment");
    }
  };

  bindEvents = () => {
    this.canvas.on("mouse:up", this.toggleValidation);
    this.canvas.on("mouse:dblclick", this.handleDblClick);
    this.canvas.on("mouse:wheel", this.handleMouseWheel);
  };

  handleMouseWheel = (o: any) => {
    const delta = o.e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom = zoom + delta / 100;

    if (zoom <= 1) {
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    } else {
      if (zoom > 20) zoom = 20;
      this.canvas.zoomToPoint({ x: o.e.offsetX, y: o.e.offsetY }, zoom);
    }

    o.e.preventDefault();
    o.e.stopPropagation();
  };

  handleDblClick = () => {
    const { history, index, noBatch } = this.props;
    history.push(`/labeler/${noBatch}/${index}`);
  };

  toggleValidation = (e: any = null) => {
    const selected = !this.state.selected;
    this.setState({ selected });
    if (!selected) {
      this.removeByType("line");
      const {
        labels: { rectangleLabels },
      } = this.props.image;
      if (rectangleLabels.length > 0) {
        const licencePlatePosition = rectangleLabels.find(
          (l: any) => l.position === null
        );
        const { left, top, width, height } = licencePlatePosition;
        this.configureZoom({ left, top, width, height });
      }
    } else {
      this.clearZoom();
      this.addSelection();
    }
  };

  addSelection = () => {
    const { newWidth, newHeight } = this.scaling;
    const fill = "red";
    const ligne1 = new fabric.Line([-5, -5, newWidth, newHeight], {
      fill,
      stroke: fill,
      strokeWidth: 8,
      hoverCursor: "pointer",
      selectable: false,
      evented: false,
    });
    const ligne2 = new fabric.Line([newWidth, 0, 0, newHeight], {
      fill,
      stroke: fill,
      strokeWidth: 8,
      hoverCursor: "pointer",
      selectable: false,
      evented: false,
    });
    this.canvas.add(ligne1);
    this.canvas.add(ligne2);
  };

  removeByType = (type: string) =>
    this.canvas
      .getObjects()
      .filter((o: any) => o.type === type)
      .forEach((o: any) => this.canvas.remove(o));

  configureFabricJSCanvas = (ref: HTMLCanvasElement) =>
    new Promise((resolve: any) => {
      const canvas = new fabric.Canvas(ref);
      const { width, height } = this.props;
      setTimeout(() => {
        //@ts-ignore
        const { offsetHeight, offsetWidth } = this.canvasWrapperRef.current;
        const taille: size = fitInBox(
          width,
          height,
          offsetWidth,
          offsetHeight,
          false
        );

        const { height: newHeight, width: newWidth } = taille;
        canvas.setWidth(newWidth);
        canvas.setHeight(newHeight);
        // canvas.uniScaleTransform = true;
        canvas.selection = false;
        canvas.defaultCursor = "pointer";

        this.scaling = {
          x: newWidth / width,
          y: newHeight / height,
          newHeight,
          newWidth,
        };
        resolve(canvas);
      }, 50);
    });

  configureZoom = (coords: any) => {
    const { left, top, width, height } = coords;

    const zoomHeight = this.props.height / height;
    const zoomWidth = this.props.width / width;

    const zoom = zoomWidth > zoomHeight ? zoomHeight : zoomWidth;
    this.canvas.setZoom(1);

    this.canvas.absolutePan({
      x: left * this.scaling.x - 1,
      y: top * this.scaling.y - 1,
    });

    const newZoom = zoom - 0.4;
    this.canvas.setZoom(newZoom);
    this.canvas.renderAll();
    setTimeout(() => {
      this.canvas.setZoom(newZoom - 0.4);
      this.canvas.renderAll();
    }, 1000);
  };

  clearZoom = () => {
    this.canvas.setZoom(1);
    this.canvas.absolutePan({
      x: 0,
      y: 0,
    });
  };

  loadImage = (image: any) =>
    new Promise((resolve: any, reject: any) => {
      this.setState({
        loadingImage: true,
        imageLoaded: false,
        errorLoadingImage: "",
        dirty: false,
      });

      this.canvas.remove(this.image);
      const url = image.url;

      fabric.util.loadImage(url, (img: any) => {
        if (img === null) {
          fabric.util.loadImage("/404.png", (img: any) => {
            this.setState({
              loadingImage: false,
              imageLoaded: false,
              errorLoadingImage: "Image 404",
            });
            this.image = new fabric.Image(img, {});
            this.canvas.add(this.image);
            return reject();
          });
        } else {
          const { newWidth, newHeight } = this.scaling;

          this.image = new fabric.Image(img, {});
          this.canvas.add(this.image);

          this.image.set({
            left: newWidth / 2,
            top: newHeight / 2,
            selectable: false,
            lockMovementX: true,
            centeredScaling: true,
            lockMovementY: true,
            hoverCursor: "arrow",
            scaleX: this.scaling.x,
            scaleY: this.scaling.y,
            originX: "center",
            originY: "center",
            opacity: 1,
          });

          this.canvas.renderAll();
          this.setState({
            loadingImage: false,
            imageLoaded: true,
            errorLoadingImage: "",
            dirty: image.lastLabeledBy === "model",
          });

          return resolve();
        }
      });
    });

  hydrate = (prediction: any, scope: string) => {
    this.removeByType("rect");
    const {
      lpPred,
      extras: { license_plate_position, characters_position },
    } = prediction;

    let first = null;
    const [leftP, topP, widthP, heightP] = license_plate_position;
    const { x, y } = this.scaling;

    // dessin des boxes caractères
    characters_position.forEach((l: any, index: number) => {
      const [left, top, width, height] = l;
      const box = new fabric.Rect({
        left: (left + leftP) * x,
        top: (top + topP) * y,
        width: width * x,
        height: height * y,
        hasRotatingPoint: false,
        strokeWidth: 0.2,
        stroke: scope === "user" ? "orange" : "red",
        selectable: false,
        originX: "left",
        originY: "top",
        fill: "rgba(255, 255, 255, 0.01)",
      });
      const labelValue = lpPred.slice(index, index + 1);

      //@ts-ignore
      box.set("index", index);
      //@ts-ignore
      box.set("labelValue", labelValue);
      //@ts-ignore
      box.set("originalLabelValue", labelValue);
      //@ts-ignore
      box.set("accuracy", 100);
      //@ts-ignore
      box.set("plateScope", scope);
      //@ts-ignore
      box.set("strokeWidthUnscaled", 0.2);
      this.canvas.add(box);
      this.canvas.bringToFront(box);
      if (index === 0) first = box;
    });

    // box de la plaque
    const plaque = new fabric.Rect({
      left: leftP * x,
      top: topP * y,
      width: widthP * x,
      height: heightP * y,
      hasRotatingPoint: false,
      strokeWidth: 0.5,
      stroke: scope === "user" ? "#FFF" : "red",
      selectable: false,
      originX: "left",
      originY: "top",
      fill: "rgba(255, 255, 255, 0.01)",
    });

    //@ts-ignore
    plaque.set("index", null);
    //@ts-ignore
    plaque.set("labelValue", "");
    //@ts-ignore
    plaque.set("plateScope", scope);
    this.canvas.add(plaque);

    this.canvas.sendToBack(plaque);
    this.canvas.sendToBack(this.image);
    this.canvas.setActiveObject(first);
    // this.addTextBox(lpPred, "#000", "#FFF");
    this.canvas.renderAll();
    this.refreshBoxIndexes();
  };

  clearAllByName = (name: string) =>
    this.canvas
      .getObjects()
      .filter((o: any) => o.get("name") && o.get("name").indexOf(name) !== -1)
      .map((o: any) => this.canvas.remove(o));

  refreshBoxIndexes = () => {
    if (!this.canvas) return [];
    const allRects = this.canvas
      .getObjects()
      .filter(
        (o: any) =>
          o.get("type") === "rect" &&
          o.get("index") !== null &&
          o.get("plateScope") === "user"
      );

    if (allRects.length === 0) return [];

    // on calcule la hauteur minimale à partir de laquelle on détecte le changement de ligne
    const minHeightRect = minBy(allRects, (r: any) => r.height * r.scaleY);
    const decalBoxMin = (minHeightRect.height * minHeightRect.scaleY) / 2;

    // on calcule la largeur minimale à partir de laquelle on détecte la box plaque
    const minSurfaceRect = minBy(
      allRects,
      (r: any) => r.width * r.scaleX * (r.height * r.scaleY)
    );
    const plateStep =
      minSurfaceRect.width *
      minSurfaceRect.scaleX *
      (minSurfaceRect.height * minSurfaceRect.scaleY) *
      4;
    const getCenterY = (o: any) => o.get("top") + o.get("height") / 2;

    allRects
      .sort((o1: any, o2: any) => {
        // gestion des lignes
        if (getCenterY(o1) - getCenterY(o2) > decalBoxMin) return 1;
        if (getCenterY(o2) - getCenterY(o1) > decalBoxMin) return -1;

        if (o1.get("left") > o2.get("left")) return 1;
        if (o1.get("left") < o2.get("left")) return -1;
        return 0;
      })
      .forEach((box: any, index: number) => {
        const idx =
          box.width * box.scaleX * (box.height * box.scaleY) > plateStep
            ? null
            : index;
        box.set("index", idx);
      });

    this.setState({ refreshingBoxIndexes: false });
  };

  getCurrentImage = () => {
    const {
      images,
      match: {
        params: { noImage },
      },
    } = this.props;

    return images[noImage];
  };

  addTextBox = (texte: string, bgColor: string, textColor: string) => {
    const { newWidth, newHeight } = this.scaling;
    const txt = new fabric.IText(texte, {
      left: newWidth / 2 - texte.length / 2,
      top: newHeight - 19,
      height: 50,
      width: newWidth / 4,
      fontSize: 20,
      fill: textColor,
      fontFamily: "Roboto",
      strokeWidth: 0.5,
      stroke: textColor,
      originY: "center",
      originX: "center",
    });

    const box = new fabric.Rect({
      left: 0,
      top: newHeight - 40,
      width: newWidth,
      height: 40,
      hasRotatingPoint: false,
      strokeWidth: 2,
      fill: bgColor,
      selectable: false,
      originX: "left",
      originY: "top",
    });

    this.textBox = new fabric.Group([box, txt]);

    this.canvas.add(this.textBox);
    this.canvas.bringToFront(this.textBox);
  };

  public render() {
    const { classes, index, setSelectedIndex, clearSelectedIndex } = this.props;

    return (
      <div
        className={classes.canvasContainer}
        ref={this.canvasWrapperRef}
        onMouseEnter={() => setSelectedIndex(index)}
        onMouseLeave={clearSelectedIndex}
      >
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(PlateLabellisation));
