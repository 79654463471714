import * as React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import GlobalContext from "../GlobalContext";

const PrivateRoute = ({
  component: Component,
  render,
  location,
  ...rest
}: any) => {
  window.localStorage.setItem("redirect", JSON.stringify(location));
  return (
    <GlobalContext.Consumer>
      {(ctx: any) => {
        const routeComponent = (props: any) =>
          ctx.user ? (
            React.createElement(Component, props)
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          );
        return <Route {...rest} render={routeComponent} />;
      }}
    </GlobalContext.Consumer>
  );
};

export default withRouter(PrivateRoute);
