import React, { useState, useEffect } from "react";
import User from "./component";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import withContext from "../../tools/withContext";
import ContextType from "../../types/Context";
import UserType from "../../types/User";

interface UserProps {
  ctx: ContextType;
  match: any;
}

const UserContainer = (props: UserProps) => {
  const {
    ctx: { appFetch, updateUserAuth },
    match: {
      params: { id }
    }
  } = props;
  const { USERS_PATH, PROJECTS_PATH } = constants.paths;
  const [user, setUser] = useState<any>(null);
  const [projects, setProjects] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");

  const fetchDatas = async () => {
    if (!id) {
      setUser({
        email: "",
        firstName: "",
        lastName: "",
        authorities: []
      });
      return;
    }
    setIsLoading(true);
    try {
      setIsError(false);

      // on récupère les infos utilisateur
      const userL = await appFetch(`${USERS_PATH}/${id}`);
      setUser(userL);

      const projs = await appFetch(`${PROJECTS_PATH}?isLight=true`);

      setProjects(projs.content);

      setIsLoading(false);
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);

      setUser({});
    }
  };

  const saveUser = async (usr: UserType) => {
    setIsLoading(true);
    const { email, firstName, lastName, ...userInfos } = usr;
    try {
      setIsError(false);
      const usrS: UserType = await appFetch(USERS_PATH, {
        method: usr.id ? "PUT" : "POST",
        body: JSON.stringify({
          ...userInfos,
          firstName,
          lastName,
          email,
          login: email,
        })
      });

      setIsLoading(false);
      setMessage(`Utilisateur ${usrS.id ? "modifié" : "ajouté"}`);
      setIsError(false);
      setIsSnackbarOpen(true);
      updateUserAuth({ email, firstName, lastName });
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      {user && <User saveUser={saveUser} user={user} projects={projects} isLoading={isLoading} />}
    </React.Fragment>
  );
};

export default withContext(UserContainer);
