import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import UiButton from "../common/UiButton";
import UiInput from "../common/UiInput";
import CustomParticles from "../common/CustomParticles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 10%, ${theme.palette.primary.light})`,
      height: "100%",
      width: "100%",
      position: "relative",
    },
    input: {
      margin: 0,
    },
    errorLine: {
      marginTop: theme.spacing.unit * 2,
      height: 45,
    },
    errorMesg: {
      fontWeight: "bold",
      color: "#FFF",
    },
    labeler: {
      color: "#FFF",
      textAlign: "left",
    },
    button: {
      backgroundColor: theme.palette.secondary.light,
      color: "#000",
    },
    link: {
      color: "#FFF",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    lastLine: {
      marginTop: theme.spacing.unit,
    },
    mdpOubli: {
      paddingTop: 22,
      textAlign: "left",
      zIndex: 1000,
    },
  });

const Login = (props: any) => {
  const { classes, onSubmitLogin, history } = props;

  const [submitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    id: "",
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      setError("");
      const authLink = await onSubmitLogin(
        credentials.username,
        credentials.password
      );
      if (authLink === "/authenticate/verify-code") {
        history.push("/verifyCode");
      } else if (authLink === "/authenticate/qr-code") {
        history.push("/qrcode");
      }
    } catch (err) {
      setIsSubmitting(false);
      setError("Votre email ou votre mot de passe est incorrect");
    }
  };

  const handleChange = (e: any) => {
    const cred = { ...credentials, [e.target.name]: e.target.value };
    setCredentials(cred);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <CustomParticles />
      <Grid item sm={3} xl={2}>
        <Grid
          container
          direction="column"
          spacing={8}
          component="form"
          onSubmit={handleSubmit}
          justify="center"
          alignItems="stretch"
        >
          <Grid item>
            <Typography
              variant="h4"
              color="inherit"
              className={classes.labeler}
            >
              Labeler
            </Typography>
          </Grid>
          <Grid item>
            <UiInput
              name="username"
              type="text"
              placeholder="identifiant / adresse email"
              className={classes.input}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <UiInput
              name="password"
              type="password"
              placeholder="mot de passe"
              className={classes.input}
              onChange={handleChange}
            />
          </Grid>
          <Grid item className={classes.mdpOubli}>
            <Typography>
              <Link to="/login-new-pwd" className={classes.link}>
                Mot de passe oublié ?
              </Link>
            </Typography>
          </Grid>
          <Grid item className={classes.lastLine}>
            <Grid container direction="row" justify="flex-end">
              <Grid item>
                <UiButton variant="contained" color="secondary" type="submit">
                  {submitting ? "Connexion..." : "Se connecter"}
                </UiButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.errorLine}>
            {error && (
              <Slide direction="right" in timeout={50}>
                <Typography className={classes.errorMesg}>{error}</Typography>
              </Slide>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(Login));
