import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import PersonIcon from "@material-ui/icons/Person";
import { intersection, uniq } from "lodash";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/GetApp";
import EventIcon from "@material-ui/icons/Event";
import withContext from "../../../tools/withContext";
import BatchDeadlineFormDialog from "./BatchDeadlineFormDialog";
import BatchAttribFormDialog from "./BatchAttribFormDialog";
import BatchesDeleteDialog from "./BatchesDeleteDialog";
import UiButton from "../../common/UiButton";
import BatchType from "../../../types/Batch";
import UserType from "../../../types/User";
import constants from "../../../tools/constants";
import requestFileDownload from "./requestDownloadFile";

const styles = (theme: Theme) =>
  createStyles({
    smallButton: {
      padding: "9px 16px",
    },
    icon: {
      marginRight: theme.spacing.unit,
    },
  });

interface PropsType {
  saveBatch: any;
  deleteBatch: any;
  ctx: any;
  classes: any;
  batches: BatchType[];
  users: UserType[];
  selected: number[];
  selectNone: Function;
}

const BatchesActionButton = (props: PropsType) => {
  const {
    users,
    batches,
    classes,
    saveBatch,
    deleteBatch,
    selected,
    selectNone,
    ctx: {
      user: {
        authorities: [userRole],
        token,
      },
    },
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [view, setView] = useState("attrib");
  const [isDialogDeleteOpen, setIsDialogDeleteOpen] = useState(false);

  const openDialog = (viewType: "attrib" | "deadline") => {
    setIsDialogOpen(true);
    setView(viewType);
  };

  const closeDialog = () => setIsDialogOpen(false);

  const openDialogDelete = () => setIsDialogDeleteOpen(true);

  const closeDialogDelete = () => setIsDialogDeleteOpen(false);

  const handleSave = (attribution: any) =>
    saveBatch(attribution).then(() => {
      selectNone([]);
      closeDialog();
    });

  const batchesSelected = batches.filter(
    (b: BatchType) => selected.indexOf(b.id) !== -1
  );

  const batchesSelectedProjectsIds = uniq(
    batchesSelected.reduce((m: any, b: any) => m.concat(b.project.id), [])
  );

  // récupérer les users rattachés à tous les projets batchesSelectedProjectsIds
  const usersForSelection = users.filter((u: any) => {
    const i = intersection(u.projects, batchesSelectedProjectsIds);
    return (
      (i.length > 0 && i.length === batchesSelectedProjectsIds.length) ||
      u.authorities.find((a: string) => a === "ROLE_ADMIN")
    );
  });

  //@ts-ignore
  const backHost = window["env"]["API_URL"];

  return (
    <Grid container spacing={8} direction="row">
      {selected.length > 0 && view === "deadline" && (
        <BatchDeadlineFormDialog
          open={isDialogOpen}
          onClose={closeDialog}
          onSave={handleSave}
          users={usersForSelection}
          selected={selected}
        />
      )}
      {selected.length > 0 && view === "attrib" && (
        <BatchAttribFormDialog
          open={isDialogOpen}
          onClose={closeDialog}
          onSave={handleSave}
          users={usersForSelection}
          batches={batchesSelected}
          selected={selected}
        />
      )}
      {selected.length > 1 && (
        <BatchesDeleteDialog
          open={isDialogDeleteOpen}
          onClose={closeDialogDelete}
          deleteBatch={deleteBatch}
          users={users}
          batches={batchesSelected}
          selected={selected}
        />
      )}
      {selected.length > 1 && (
        <Grid item>
          <UiButton
            color="secondary"
            variant="contained"
            className={classes.smallButton}
            onClick={() => openDialog("attrib")}
            disabled={usersForSelection.length === 0}
            title="Modifier l'attribution des lots sélectionnés"
          >
            <PersonIcon />
          </UiButton>
        </Grid>
      )}
      {selected.length > 1 && (
        <Grid item>
          <UiButton
            color="secondary"
            variant="contained"
            className={classes.smallButton}
            onClick={() => openDialog("deadline")}
            disabled={usersForSelection.length === 0}
            title="Modifier la deadline des lots sélectionnés"
          >
            <EventIcon />
          </UiButton>
        </Grid>
      )}
      {selected.length > 1 &&
        userRole === "ROLE_ADMIN" && [
          <Grid item>
            <UiButton
              color="secondary"
              variant="contained"
              className={classes.smallButton}
              title="Exporter au format CSV les lots sélectionnés"
              onClick={async () => {
                await requestFileDownload(
                  "GET",
                  `${backHost}${
                    constants.paths.BATCHES_EXPORT_PATH
                  }?type=CSV&batches=${selected.join(",")}`,
                  token,
                  "",
                  "application/json"
                );
              }}
            >
              <DownloadIcon className={classes.icon} />
              CSV
            </UiButton>
          </Grid>,
          <Grid item>
            <UiButton
              color="secondary"
              variant="contained"
              className={classes.smallButton}
              title="Exporter au format JSON les lots sélectionnés"
              onClick={async () => {
                await requestFileDownload(
                  "GET",
                  `${backHost}${
                    constants.paths.BATCHES_EXPORT_PATH
                  }?type=JSON&batches=${selected.join(",")}`,
                  token,
                  "",
                  "application/json"
                );
              }}
            >
              <DownloadIcon className={classes.icon} />
              JSON
            </UiButton>
          </Grid>,
        ]}
      {selected.length > 1 && (
        <Grid item>
          <UiButton
            color="secondary"
            variant="contained"
            title="Supprimer les lots sélectionnés"
            onClick={openDialogDelete}
            className={classes.smallButton}
          >
            <DeleteIcon />
          </UiButton>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(withContext(BatchesActionButton));
