/**
 * 
 * @param map object to map
 * @param lowerMap lowerMapping ref
 */
const lowerMapper = (map: any, lowerMap: any) =>
	Object.keys(map).reduce((memo: any, key: any) => {
		const keySplt = map[key].split(',');
		return {
			...memo,
			[key]:
				key !== 'sort' || !lowerMap[keySplt[0]]
					? map[key]
					: `${lowerMap[keySplt[0]]}${keySplt[0] ? `,${keySplt[1]}` : ''}`
		};
	}, {});

export default lowerMapper;
