import * as React from "react";
import { withRouter, Prompt } from "react-router-dom";
import classNames from "classnames";
import Helmet from "react-helmet";
import { isEqual } from "lodash";
import { fabric } from "fabric";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ImageCache from "../common/ImageCache";
import DisplayKeyMapping from "../common/DisplayKeyMapping";
import Progression from "../common/Progression";
import CanvasButtons from "../common/CanvasButtons";
import DrawerPhotoList from "../common/DrawerPhotoList.jsx";
import DrawerButton from "../common/DrawerButton";
import SkipDialog from "../common/SkipDialog";
import LabelisationAbstract from "../common/LabelisationAbstract";
import AlertDialog from "../common/AlertDialog";
import UpdateImagesDialog from "./components/ImportJsonDialog";
import UiInput from "../common/UiInput";
import withContext from "../../tools/withContext";
import fitInBox, { size } from "../../tools/fitInBox";

interface IState {
  x: number;
  y: number;
  isDown: boolean;
  width: number;
  height: number;
  currentImageIndex: number;
  submitting: boolean;
  justSaved: boolean;
  dirty: boolean;
  listImagesDrawerOpen: boolean;
  error: any;
  loadingImage: boolean;
  errorLoadingImage: string;
  imageLoaded: boolean;
  showHelp: boolean;
  country: string;
  certaintyLevel: "high" | "low";
  skipDialogOpen: boolean;
  immatInputValue: string;
  motifCleared: boolean;
  importJsonDialogOpen: boolean;
}

export const i18nMapping: any = {
  LIGHT: "Réflection lumineuse sur la plaque",
  BAD_RES: "Faible résolution",
  NO_LICENSE_PLATE: "Pas de plaque visible",
  NO_IMAGE: "Pas d'image observée",
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      paddingTop: 50,
    },
    canvasContainer: {
      backgroundColor: "#edf2ff",
      margin: "0 auto",
      width: "100%",
      height: "100%",
    },
    nbrImagesG: {
      padding: `${theme.spacing.unit}px 0`,
    },
    button: {
      lineHeight: "27px",
    },
    motifButton: {
      textAlign: "center",
      marginTop: theme.spacing.unit * 2,
    },
    grow: {
      flexGrow: 1,
    },
    leftCol: {
      padding: 4,
      paddingBottom: "2em",
    },
    fullHeight: {
      height: "100%",
    },
    paddedLeft: {
      paddingLeft: theme.spacing.unit * 2,
    },
    bold: {
      color: theme.palette.primary.dark,
      letterSpacing: "0.2em",
      fontSize: "1.3em",
    },
    centered: {
      textAlign: "center",
    },
    imageButton: {
      border: "none",
      padding: 0,
    },
    padded: {
      padding: 5,
    },
    country: {
      width: "100%",
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      justifyContent: "space-around",
    },
    countryItem: {
      flex: 1,
    },
    responsiveSection: {
      paddingTop: theme.spacing.unit * 2,
    },
    dirtyIndic: {
      padding: `${theme.spacing.unit}px 0`,
      color: "#8F9DEF",
    },
    fullWidth: {
      width: "100%",
    },
    clearSkip: {
      padding: 5,
      border: "none",
      backgroundColor: "transparent",
    },
    marginTop: {
      marginTop: theme.spacing.unit * 2,
    },
    error: {
      paddingLeft: theme.spacing.unit * 2,
      color: "red",
      fontSize: "0.8em",
      lineHeight: "20px",
      minHeight: "22px",
    },
  });

class LabelerPlate2 extends React.Component<any, IState> {
  private canvasRef = React.createRef<HTMLCanvasElement>();
  private canvasWrapperRef = React.createRef<HTMLDivElement>();
  private canvas: any;
  private image: any;
  private scaling: any;
  private keyMapping: any;
  public canvasContext: any;

  constructor(props: any) {
    super(props);
    const {
      match: {
        params: { noImage },
      },
    } = props;

    this.state = {
      x: 0,
      y: 0,
      isDown: false,
      width: 0,
      height: 0,
      currentImageIndex: noImage,
      submitting: false,
      justSaved: false,
      dirty: false,
      listImagesDrawerOpen: false,
      error: null,
      immatInputValue: "",
      loadingImage: false,
      errorLoadingImage: "",
      imageLoaded: false,
      showHelp: false,
      skipDialogOpen: false,
      country: props.paysDefault,
      certaintyLevel: "high",
      motifCleared: false,
      importJsonDialogOpen: false,
    };

    this.scaling = {
      y: 1,
      x: 1,
      newWidth: props.width,
      newHeight: props.height,
      ratio: props.width / props.height,
    };

    this.keyMapping = {
      Entrée: "Sauvegarde",
      "Ctrl + flèches": "Image suivante/précédente",
      Esc: "Dé-zoom et recentre l'image",
    };
  }

  componentDidMount = () => {
    if (this.canvasRef.current !== null) {
      //@ts-ignore
      this.configureFabricJSCanvas(this.canvasRef.current).then(
        (canvas: any) => {
          this.canvas = canvas;
          this.bindEvents();
          if (
            this.props.match.params.noImage !== `${this.props.images.length}`
          ) {
            this.loadImage(this.getCurrentImage())
              .then(() => {
                console.log("image loaded");
              })
              .catch((e: any) => console.log("error loading image", e));
          }
        }
      );
    }
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (
      Number(prevProps.match.params.noImage) !==
      Number(this.props.match.params.noImage)
    ) {
      this.clearZoom();
      if (this.props.match.params.noImage !== `${this.props.images.length}`) {
        const image = this.getCurrentImage();

        // pour la première image, la récupération de l'image ne
        // peut pas se faire dans le "cache" du coup on ajout un délai
        const timing = this.props.match.params.noImage === 0 ? 2000 : 0;
        setTimeout(
          () =>
            this.loadImage(image)
              .then(() => {
                console.log("image loaded");
              })
              .catch((e: any) => console.log("error loading image", e)),
          timing
        );
      }
    }

    if (
      prevState.submitting &&
      !this.state.submitting &&
      this.state.error === ""
    ) {
      this.handleNextImage();
    }
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (!isEqual(this.props.keyEvent, nextProps.keyEvent)) {
      if (nextProps.keyEvent.type === "keydown") {
        this.handleKeyDown(nextProps.keyEvent);
      }
    }

    const thisPropsImg = this.props.images[
      Number(this.props.match.params.noImage)
    ];
    const nextPropsImg =
      nextProps.images[Number(nextProps.match.params.noImage)];
    if (
      thisPropsImg &&
      nextPropsImg &&
      thisPropsImg.id === nextPropsImg.id &&
      ((thisPropsImg.motif === null && nextPropsImg.motif !== null) ||
        // modification d'un motif existant
        (thisPropsImg.motif !== null &&
          nextPropsImg.motif !== null &&
          thisPropsImg.motif !== nextPropsImg.motif))
    ) {
      setTimeout(() => {
        this.handleSubmit().then(() => console.log("motif submit"));
      }, 250);
    }
  };

  configureFabricJSCanvas = (ref: HTMLCanvasElement) =>
    new Promise((resolve: any) => {
      const canvas = new fabric.Canvas(ref, { imageSmoothingEnabled: false });
      const { width, height } = this.props;
      setTimeout(() => {
        //@ts-ignore
        const { offsetHeight, offsetWidth } = this.canvasWrapperRef.current;
        const taille: size = fitInBox(
          width,
          height,
          offsetWidth,
          offsetHeight,
          true
        );

        const { height: newHeight, width: newWidth } = taille;
        canvas.setWidth(newWidth);
        canvas.setHeight(newHeight);
        // canvas.uniScaleTransform = false;
        canvas.selection = false;
        canvas.defaultCursor = "pointer";

        this.scaling = {
          x: newWidth / width,
          y: newHeight / height,
          newHeight,
          newWidth,
        };
        resolve(canvas);
      }, 150);
    });
  handleKeyDown = (e: any) => {
    if (e.key === "ArrowLeft") {
      if (e.ctrlKey || e.altKey) {
        if (Number(this.props.match.params.noImage) > 0) {
          this.handlePreviousImage();
        }
        return;
      }
    }

    if (e.key === "ArrowRight") {
      if (e.ctrlKey || e.altKey) {
        if (this.isNavigationFwdPossible()) {
          this.handleNextImage();
        }
        return;
      }
    }

    if (e.key === "Escape") {
      this.clearZoom();
    }

    if (e.key === "+" && this.props.userRole === "ROLE_ADMIN") {
      this.handleOpenJsonImportDialog();
    }
  };

  bindEvents = () => {
    this.canvas.on("mouse:wheel", this.handleMouseWheel);
  };

  handleMouseWheel = (o: any) => {
    const delta = o.e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom = zoom + delta / 200;

    if (zoom <= 1) {
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    } else {
      if (zoom > 20) zoom = 20;
      this.canvas.zoomToPoint({ x: o.e.offsetX, y: o.e.offsetY }, zoom);
    }

    o.e.preventDefault();
    o.e.stopPropagation();
  };

  clearZoom = () => {
    this.canvas.setZoom(1);
    this.canvas.absolutePan({
      x: 0,
      y: 0,
    });
  };

  getCurrentImage = () => {
    const {
      images,
      match: {
        params: { noImage },
      },
    } = this.props;

    return images[noImage];
  };

  loadImage = (image: any) =>
    new Promise((resolve: any, reject: any) => {
      this.setState({
        loadingImage: true,
        imageLoaded: false,
        errorLoadingImage: "",
        certaintyLevel: image.labels.certaintyLevel || "high",
        immatInputValue: image.labels.simpleLabels[0] || "",
        country: image.labels.country || this.props.paysDefault,
        dirty: false,
      });

      this.canvas.remove(this.image);
      const url = image.url;

      fabric.util.loadImage(url, (img: any) => {
        if (img === null) {
          fabric.util.loadImage("/404.png", (img: any) => {
            this.setState({
              loadingImage: false,
              imageLoaded: false,
              errorLoadingImage: "Image 404",
            });
            this.image = new fabric.Image(img, {});
            this.canvas.add(this.image);
            return reject();
          });
        } else {
          const { width, height } = img;
          const { newWidth, newHeight } = this.scaling;

          this.scaling = {
            x: newWidth / width,
            y: newHeight / height,
            newHeight,
            newWidth,
          };

          this.image = new fabric.Image(img, {});

          this.image.set({
            left: 0,
            top: 0,
            selectable: false,
            lockMovementX: true,
            centeredScaling: true,
            lockMovementY: true,
            hoverCursor: "arrow",
            scaleX: newWidth / width,
            scaleY: newHeight / height,
            originX: "left",
            originY: "top",
            opacity: 1,
          });

          this.canvas.add(this.image);
          this.canvas.renderAll();
          this.setState({
            loadingImage: false,
            imageLoaded: true,
            errorLoadingImage: "",
            dirty: image.lastLabeledBy === "model",
          });

          return resolve();
        }
      });
    });

  handleImmatInputChange = (e: any) => {
    this.setState({
      immatInputValue: e.target.value.toUpperCase(),
      dirty: true,
    });
  };

  handleChangeCertainty = (e: any) => {
    this.setState({ certaintyLevel: e.target.value, dirty: true });
  };

  changeMotif = (motifSkip: any | null) => {
    const { storeMotif } = this.props;

    storeMotif(motifSkip);

    this.setState({
      dirty: true,
      skipDialogOpen: false,
    });
  };

  clearSkip = () => {
    const { storeMotif } = this.props;

    storeMotif(null);
    this.setState({
      dirty: true,
      skipDialogOpen: false,
    });
  };

  clearImmatriculation = (e: any) => {
    const {
      match: {
        params: { noImage },
      },
      clearImmatriculation,
      images,
    } = this.props;
    if (images[Number(noImage)]) {
      clearImmatriculation(images[Number(noImage)].id);
    }
    this.setState({ dirty: true, certaintyLevel: "high", country: "FR" });
  };

  handleOpenSkipDialog = (e: any) => {
    this.setState({ skipDialogOpen: true });
  };

  handleCloseSkipDialog = (e: any) => {
    this.setState({ skipDialogOpen: false });
  };

  handleToggleListeImagesDrawer = () =>
    this.setState({ listImagesDrawerOpen: !this.state.listImagesDrawerOpen });

  handleOpenJsonImportDialog = () =>
    this.setState({ importJsonDialogOpen: true });

  handleCloseJsonImportDialog = () =>
    this.setState({ importJsonDialogOpen: false });

  isNavigationFwdPossible = () => {
    const {
      match: {
        params: { noImage },
      },
      images,
    } = this.props;
    const image = images[Number(noImage)];
    const nextImage = images[Number(noImage) + 1];
    return (
      (image &&
        image.lastLabeledBy !== "model" &&
        ((image.labels && image.labels.simpleLabels.length > 0) ||
          image.motif) &&
        !this.state.dirty) ||
      (nextImage &&
        nextImage.lastLabeledBy &&
        nextImage.lastLabeledBy !== "model" &&
        ((nextImage.labels && nextImage.labels.simpleLabels.length > 0) ||
          nextImage.motif))
    );
  };

  handleSubmit = (e?: any) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const {
      images,
      onSave,
      match: {
        params: { noImage: currentImageIndex },
      },
    } = this.props;
    const image = images[currentImageIndex];

    this.setState({
      submitting: true,
      error: "",
    });

    const datas = this.getDatas();

    //const lastLabeledBy = isEqual(image.labels, datas.labels) ? image.lastLabeledBy : null;

    return new Promise((resolve, reject) => {
      onSave({ ...image, ...datas, lastLabeledBy: null })
        .then((image: any) => {
          this.setState({
            dirty: false,
            submitting: false,
          });
          return resolve(image);
        })
        .catch((e: any) => {
          this.setState({
            submitting: false,
            error: e.message,
          });
          return reject(e);
        });
    });
  };

  getDatas = () => {
    const { country, immatInputValue, certaintyLevel } = this.state;
    const {
      match: {
        params: { noImage },
      },
      images,
    } = this.props;

    if (!images[Number(noImage)]) {
      console.log("error : getDatas");
      return;
    }

    return {
      labels: {
        ...images[Number(noImage)].labels,
        simpleLabels: immatInputValue === "" ? [] : [immatInputValue],
        country,
        certaintyLevel,
      },
      motif: images[Number(noImage)].motif,
    };
  };

  handlePreviousImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(
      `/labelerPlateText/${noBatch}/${Number(noImage) - 1}`
    );
  };

  handleNextImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(
      `/labelerPlateText/${noBatch}/${Number(noImage) + 1}`
    );
  };

  handleToggleHelp = () => this.setState({ showHelp: !this.state.showHelp });

  handlePaysChanged = (e: any) =>
    this.setState({ country: e.target.value, dirty: true });

  goToBatchesList = () =>
    this.props.history.push("/batches/0?sort=createdDate,desc");

  navigateToLastLabelized = () => {
    const {
      match: {
        params: { noBatch },
      },
      history,
      images,
    } = this.props;
    const indexFirstImageNonLabellisee = images.findIndex(
      (img: any) =>
        (img.labels.simpleLabels.length === 0 &&
          (img.motif === null || img.motif === "")) ||
        !img.lastLabeledBy ||
        img.lastLabeledBy === "model"
    );

    if (indexFirstImageNonLabellisee !== -1) {
      history.push(
        `/labelerPlateText/${noBatch}/${indexFirstImageNonLabellisee}`
      );
    }
  };

  public render() {
    const {
      classes,
      match: {
        params: { noImage, noBatch },
      },
      images,
      motifs,
      nomBatch,
      pays,
      onSave,
    } = this.props;

    const {
      dirty,
      listImagesDrawerOpen,
      country,
      certaintyLevel,
      skipDialogOpen,
      immatInputValue,
      showHelp,
      justSaved,
      importJsonDialogOpen,
    } = this.state;

    const imagesWithLabelOrMotif = images.filter(
      (img: any) =>
        (img.labels.simpleLabels.length > 0 ||
          (img.motif !== null && img.motif !== "")) &&
        img.lastLabeledBy !== "model"
    );
    const nbrLabelisees = imagesWithLabelOrMotif.length;

    const image = this.getCurrentImage();
    const out = Number(noImage) >= images.length;
    const finished = nbrLabelisees === images.length;

    let alertOnClickHandler;
    if (out) alertOnClickHandler = () => this.navigateToLastLabelized();
    if (finished) alertOnClickHandler = () => this.goToBatchesList();

    let textAlert = "";
    let titreAlert = "";
    if (out) {
      if (!finished) {
        textAlert = "Une image n'a pas été labellisée";
        titreAlert = "Labellisation incomplète";
      } else {
        textAlert = "Labellisation de ce lot terminée !";
        titreAlert = "C'est fini !";
      }
    }

    const error =
      country === "FR" && immatInputValue.search(/[IOU]/g) !== -1
        ? "Caractères O,U et I non autorisés pour les plaques FR"
        : null;

    return (
      <Grid
        container
        spacing={16}
        direction="row"
        justify="flex-start"
        className={classes.root}
      >
        <Helmet>
          <title>
            Plate / {nomBatch.split("-")[0]} / {noImage}
          </title>
        </Helmet>
        {Number(noImage) >= images.length && (
          <ImageCache images={images} noImageToCache={Number(noImage) + 1} />
        )}
        {listImagesDrawerOpen && (
          <DrawerPhotoList
            open={listImagesDrawerOpen}
            images={imagesWithLabelOrMotif}
            noBatch={noBatch}
            noImage={noImage}
            labelingTypePathname="labelerPlate"
          />
        )}
        {imagesWithLabelOrMotif.length > 1 && (
          <DrawerButton
            onClick={this.handleToggleListeImagesDrawer}
            drawerOpen={listImagesDrawerOpen}
          />
        )}
        <Prompt
          when={dirty}
          message="Des modifications n'ont pas été sauvegardées. Continuer ?"
        />
        <AlertDialog
          open={out || finished}
          onClose={alertOnClickHandler}
          text={textAlert}
          title={titreAlert}
          onAccept={alertOnClickHandler}
          acceptLabel="OK"
        />
        <SkipDialog
          open={skipDialogOpen}
          onClose={this.handleCloseSkipDialog}
          motifSelected={image && image.motif}
          onAccept={this.changeMotif}
          motifs={motifs}
          i18nMapping={i18nMapping}
          defaultMotifIndex={0} //@TOCHECK
        />
        <UpdateImagesDialog
          open={importJsonDialogOpen}
          saveImage={onSave}
          onClose={this.handleCloseJsonImportDialog}
        />
        <Grid item xs={8} xl={9} className={classes.grow}>
          <Grid
            container
            direction="column"
            alignContent="stretch"
            alignItems="stretch"
            justify="flex-start"
            component={Paper}
            className={classNames(classes.leftCol, classes.fullHeight)}
          >
            <Grid item>
              {/** TODO finir de brancher LabelisationAbstract **/}
              <LabelisationAbstract
                noImage={noImage}
                nomBatch={nomBatch}
                images={images}
                dirty={dirty}
                justSaved={justSaved}
              />
            </Grid>
            <Grid item className={classNames(classes.padded, classes.grow)}>
              <div
                className={classes.canvasContainer}
                ref={this.canvasWrapperRef}
              >
                <canvas ref={this.canvasRef} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} xl={3} className={classes.grow}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            component={Paper}
            className={classes.fullHeight}
          >
            <Grid item>
              {image && (
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={8}
                >
                  <Grid item>
                    <Progression
                      total={images.length}
                      nbrLabelisees={nbrLabelisees}
                      onHelpClicked={this.handleToggleHelp}
                      showHelp={showHelp}
                    />
                  </Grid>
                  {!showHelp && image.motif !== null && (
                    <Grid item className={classes.centered}>
                      <span>
                        Image passée :{" "}
                        <strong>{i18nMapping[image.motif]}</strong>
                      </span>
                      <button
                        className={classes.clearSkip}
                        onClick={this.clearSkip}
                      >
                        x
                      </button>
                    </Grid>
                  )}
                  {image && !showHelp && image.motif === null && (
                    <Grid item style={{ width: "100%" }}>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        component="form"
                        onSubmit={this.handleSubmit}
                        spacing={8}
                        className={classes.paddedLeft}
                      >
                        <Grid item sm={12} className={classes.paddedLeft}>
                          <label>
                            Immatriculation{" "}
                            {image.lastLabeledBy === "model"
                              ? "détectée"
                              : "visible"}
                          </label>
                        </Grid>
                        <Grid item sm={12} className={classes.paddedLeft}>
                          <UiInput
                            fullWidth
                            onChange={this.handleImmatInputChange}
                            autoFocus
                            name="immat"
                            value={immatInputValue}
                            className={classes.inputImmat}
                          />
                          <button
                            type="submit"
                            disabled={immatInputValue === "" || error !== null}
                            style={{ display: "none" }}
                          >
                            valider
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    {error !== null && (
                      <div className={classes.error}>{error}</div>
                    )}
                  </Grid>
                  {!showHelp && image.motif === null && (
                    <Grid item>
                      <FormControl className={classes.paddedLeft}>
                        <FormLabel>
                          Degré de certitude de l'immatriculation saisie
                        </FormLabel>
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          value={certaintyLevel}
                          className={classes.country}
                          onChange={this.handleChangeCertainty}
                          row
                        >
                          <FormControlLabel
                            value="high"
                            control={<Radio />}
                            label="Élevé"
                            className={classes.countryItem}
                          />
                          <FormControlLabel
                            value="low"
                            control={<Radio />}
                            label="Faible"
                            className={classes.countryItem}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {!showHelp && image && image.motif === null && (
                    <Grid item>
                      <FormControl
                        className={classNames(
                          classes.paddedLeft,
                          classes.marginTop
                        )}
                      >
                        <FormLabel>Pays</FormLabel>
                        <RadioGroup
                          aria-label="country"
                          name="country"
                          className={classes.country}
                          value={country}
                          onChange={this.handlePaysChanged}
                          row
                        >
                          {Object.keys(pays).map((k: string) => (
                            <FormControlLabel
                              value={k}
                              control={<Radio />}
                              label={pays[k]}
                              className={classes.countryItem}
                              key={k}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {showHelp && (
                    <Grid item className={classes.fullWidth}>
                      <DisplayKeyMapping keyMapping={this.keyMapping} />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item className={classes.fullWidth}>
              {image && (
                <Grid item style={{ flexGrow: 1 }}>
                  <CanvasButtons
                    onNavigateBackClicked={this.handlePreviousImage}
                    onNavigateFwdClicked={this.handleNextImage}
                    onSaveClicked={this.handleSubmit}
                    savingDisabled={immatInputValue === "" || error !== null}
                    navigateBackVisible={Number(noImage) > 0}
                    navigateFwdVisible={this.isNavigationFwdPossible()}
                    onSkipClicked={this.handleOpenSkipDialog}
                    motif={image.motif}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withContext(LabelerPlate2)));
