import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing.unit
      }
    },
    input: {
      flex: 1,
      borderRadius: "4px 0 0 4px",
      backgroundColor: theme.palette.common.white,
      border: "1px solid rgba(72,72,72,.2)",
      fontSize: 15,
      padding: "10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      }
    },
    label: {
      fontSize: 16,
      fontWeight: 200,
      display: "block",
      marginBottom: `${theme.spacing.unit}px`
    }
  });

const btnStyles = (theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      textTransform: "none",
      borderRadius: "0 4px 4px 0",
      width: "100%",
      height: "41px",
      fontSize: 17,
      padding: "6px",
      border: "1px solid",
      lineHeight: 1.6,
      backgroundColor: "#007bff",
      borderColor: "#007bff",
      fontFamily: "Roboto",
      "&:hover": {
        backgroundColor: "#0069d9",
        borderColor: "#0062cc"
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf"
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
      }
    }
  });

const UiButton = withStyles(btnStyles)(Button);

interface PropTypes {
  classes: any;
  label: string;
  required: boolean;
  buttonDisabled: boolean;
  onClick: any;
  buttonLabel: string;
  placeHolder: string;
  inputRef: any;
  typeButton: string;
}

const InputButton = (props: any) => {
  const {
    classes,
    label,
    required,
    buttonDisabled,
    onClick,
    buttonLabel,
    placeHolder,
    inputRef,
    typeButton,
    ...rest
  } = props;
  return (
    <Grid container direction="row" style={{ paddingRight: 24 }}>
      <Grid item xs={12}>
        <label className={classes.label}>
          {label} {required && <sup>*</sup>}
        </label>
      </Grid>
      <Grid item xs={11}>
        <InputBase
          {...rest}
          inputProps={{ ref: inputRef }}
          required
          fullWidth
          placeholder={placeHolder}
          classes={{
            root: classes.root,
            input: classes.input
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <UiButton
          onClick={typeButton === "button" ? onClick : null}
          disabled={buttonDisabled}
          type={typeButton}
        >
          <AddIcon />
        </UiButton>
      </Grid>
    </Grid>
  );
};

UiButton.defaultProps = {
  //@ts-ignore
  typeButton: "button"
};

export default withStyles(styles)(InputButton);
