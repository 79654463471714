const getDownloadFile = (fileName: string, blob: any) => {
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  // we need to append the element to the dom -> otherwise it will not work in firefox
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const requestFileDownload = (
  method: string,
  url: string,
  jwtToken?: string,
  body?: string,
  contentType?: string
) => {
  const headers = new Headers();

  contentType && headers.append("Content-Type", contentType);
  jwtToken && headers.append("Authorization", "Bearer " + jwtToken);

  const URI = url;
  const options: any = { method, headers };
  if (body) {
    options["body"] = body;
  }
  const request = new Request(URI, options);

  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(request);
      const blob = await res.blob();
      const filename = res.headers
        .get("Content-disposition")
        ?.split(" ")[1]
        ?.split("=")[1];

      if (res.status === 200) {
        getDownloadFile(filename ?? "", blob);
        resolve({
          code: 200,
          res,
          blob,
        });
      } else if (res.status >= 400) {
        reject({
          message: "Unexpected error",
        });
      }
    } catch (e) {
      reject({
        message: (e as any).message,
      });
    }
  });
};

export default requestFileDownload;
