import React, { useState, useEffect } from "react";
import axios from "axios";
import Import from "./component";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import withContext from "../../tools/withContext";
import ContextType from "../../types/Context";

interface PropTypes {
  ctx: ContextType;
  match: any;
}

const ImportContainer = (props: PropTypes) => {
  const {
    ctx: { appFetch, user },
    match: {
      params: { projectId, type },
    },
  } = props;
  const {
    PROJECTS_PATH,
    COUNT_ES,
    IMPORT_ES,
    UPLOAD_FILES,
    LIST_STAGGING_PATH,
    LIST_ES_METATDATA,
    UPLOAD_PROPERTIES
  } = constants.paths;
  const [project, setProject] = useState<any>({});
  const [uploadProperties, setUploadProperies] = useState<any>(null)
  const [metadatas, setMetadatas] = useState<any[]>([]);
  const [folders, setFolders] = useState<string[]>([]);
  const [numberImages, setNumberImages] = useState<any>(null);
  const [isUploading, setIsUpLoading] = useState(false);
  const [progress, setProgress] = useState<Number>(0);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");

  const fetchDatas = async () => {
    try {
      setIsError(false);

      // on récupère les batches
      const datas1 = await appFetch(`${PROJECTS_PATH}/${projectId}`);
      setProject(datas1);
      const datas2 = await appFetch(
        `${LIST_STAGGING_PATH}?projectId=${projectId}`
      );
      setFolders(datas2);
      if (datas1.dataSource === "ES") {
        const datas3 = await appFetch(
          `${LIST_ES_METATDATA}?projectId=${projectId}`
        );
        setMetadatas(datas3);
      }

      const datas4 = await appFetch(UPLOAD_PROPERTIES);
      setUploadProperies(datas4);
    } catch (e) {
      //, activated: true
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const onUploadProgress = (e: ProgressEvent) => {
    setProgress(Math.round((e.loaded / e.total) * 100));
  };

  const uploadFiles = async (params: any) => {
    axios.interceptors.response.use(
      (response: any) => {
        console.log(response);
        return response;
      },
      (error: any) => {
        console.log("error", error);
        return Promise.reject(error);
      }
    );
    try {
      const { formData, subFolderName } = params;
      setIsError(false);
      setIsUpLoading(true);

      const Authorization = `Bearer ${user.token}`;
      formData.append("projectId", project.id);
      formData.append("destFolder", subFolderName);

      const url =
        process.env.NODE_ENV === "production"
          //@ts-ignore
          ? `${window["env"]["API_URL"]}${UPLOAD_FILES}`
          : UPLOAD_FILES;
      await axios.post(url, formData, {
        onUploadProgress,
        headers: {
          Authorization,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });

      setIsUpLoading(false);
      setMessage(`Upload des images terminé !`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      setIsUpLoading(false);
      setIsError(true);
      console.log(e);
      setMessage("Limite taille upload atteinte");
      setIsSnackbarOpen(true);
      throw new Error();
    }
  };

  const importEs = async (params: any) => {
    const {
      dateFrom,
      dateTo,
      subFolderName: destFolder,
      projectId,
      filterMetadatas,
    } = params;
    try {
      setIsError(false);
      await appFetch(IMPORT_ES, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          projectId,
          destFolder,
          dateFrom,
          dateTo,
          filterMap: filterMetadatas.reduce(
            (m: any, f: any) => ({ ...m, [f.key]: f.value }),
            {}
          ),
        }),
      });

      setMessage(`Importation ES lancée !`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const clearCount = () => setNumberImages(null);

  const countEs = async (params: any) => {
    const { dateFrom, dateTo, projectId, filterMetadatas } = params;
    try {
      setIsError(false);
      setNumberImages(null);
      const nbImages = await appFetch(COUNT_ES, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          projectId,
          destFolder: null,
          dateFrom,
          dateTo,
          filterMap: filterMetadatas.reduce(
            (m: any, f: any) => ({ ...m, [f.key]: f.value }),
            {}
          ),
        }),
      });

      setNumberImages(Number(nbImages));
      setIsError(false);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      {uploadProperties && <Import
        uploadFiles={uploadFiles}
        project={project}
        type={type}
        folders={folders}
        metadatas={metadatas}
        importEs={importEs}
        countEs={countEs}
        numberImages={numberImages}
        clearCount={clearCount}
        progress={progress}
        isUploading={isUploading}
        uploadProperties={uploadProperties}
      />}
    </React.Fragment>
  );
};

export default withContext(ImportContainer);
