import { withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import LinearProgress from "@material-ui/core/LinearProgress";


const progressStyles = (theme: Theme) =>
  createStyles({
    bar: {
      height: "10px"
    },
    colorSecondary: {
      backgroundColor: lighten(theme.palette.secondary.main, 0.7)
    },
    barColorSecondary: {
      backgroundColor: theme.palette.secondary.main
    },
    colorPrimary: {
      backgroundColor: lighten(theme.palette.primary.main, 0.7)
    },
    barColorPrimary: {
      backgroundColor: theme.palette.primary.main
    }    
  });

const UiProgress = withStyles(progressStyles)(LinearProgress);
 export default UiProgress;
