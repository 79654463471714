import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { withRouter } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import PersonIcon from "@material-ui/icons/Person";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withContext from "../tools/withContext";

const styles = (theme: Theme) =>
  createStyles({
    button: {},
  });

const Profil = (props: any) => {
  const {
    ctx: { user },
    onLogout,
    history,
  } = props;
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const menuButtonClicked = (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  const navigateToChangePwd = () => {
    const { pathname, search } = props.location;
    history.push(`/change-password?redirectPathname=${pathname}&redirectQuery=${search}`);
    closeMenu();
  };

  const navigateToProfil = () => {
    history.push(`/users/${user.login}`);
    closeMenu();
  };

  const navigateToNotifications = () => {
    history.push(`/notifications`);
    closeMenu();
  };


  return (
    <React.Fragment>
      <IconButton onClick={menuButtonClicked} color="inherit">
        <PersonIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={anchorEl !== null}
        onClose={closeMenu}
        disableAutoFocusItem
        elevation={2}
      >
        <MenuItem onClick={() => navigateToChangePwd()}>
          Changer mot de passe
        </MenuItem>
        <MenuItem onClick={() => navigateToProfil()}>Votre profil</MenuItem>
        <MenuItem onClick={() => navigateToNotifications()}>
          Notifications
        </MenuItem>
        <MenuItem onClick={onLogout}>Déconnexion</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default withStyles(styles)(withContext(withRouter(Profil)));
