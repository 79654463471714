import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import UiButton from "../../common/UiButton";
import UiProgress from "../../common/UiProgess";
import SelectionDoubleList from "../../common/SelectionDoubleList";
import withContext from "../../../tools/withContext";
import constants from "../../../tools/constants";
import UserType from "../../../types/User";
import { identity } from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: `calc(100vh - 64px - ${theme.spacing.unit * 5}px)`,
    },
    list: {
      height: 250,
      width: "100%",
      overflowY: "scroll",
      backgroundColor: "#faffff",
      border: "solid 1px #2948ff4d",
    },
    progressLabel: {
      paddingBottom: theme.spacing.unit * 2,
    },
  });

interface PropTypes {
  users: UserType[];
  classes: any;
  onCancel: any;
  projectId: string;
  ctx: any;
  saveUser: any;
  history: any;
}

const ProjectUsersManager = (props: PropTypes) => {
  const {
    users,
    classes,
    projectId,
    ctx: { appFetch },
    saveUser,
    history,
  } = props;

  const [projectUsers, setProjectUsers] = useState<UserType[]>([]);

  const [mutations, setMutations] = useState<any>({}); // { [<userId>]: ""}
  const [usersToSave, setUsersToSave] = useState<UserType[]>([]);

  const [availableUsers, setAvailableUsers] = useState<UserType[]>([]);
  const [nbrToSave, setNbrToSave] = useState<number>(0);
  const [userSubmittedIdentity, setUserSubmittedIdentity] = useState("");
  const [saving, setSaving] = useState<boolean | null | undefined>(null); // null pas commencé, undefined: pas terminé, false: erreur, true terminé

  const handleAdd = (selection: any[]) => {
    setProjectUsers(projectUsers.concat(selection));
    setMutations(
      selection.reduce(
        (memo: any, el: any) => {
          if (memo[el.id] !== "minus") {
            return { ...memo, [el.id]: "plus" };
          } else {
            // it on trouve l'utilisateur en mutation "minus" en l'enlève simplement
            const { [el.id]: cetEl, ...rest } = memo;
            return rest;
          }
        },
        { ...mutations }
      )
    );
  };

  const handleRemove = (selection: any[]) => {
    setProjectUsers(
      projectUsers.filter(
        (uP: any, index: number) => !selection.find((i: any) => i.id === uP.id)
      )
    );
    setMutations(
      selection.reduce(
        (memo: any, el: any) => {
          if (memo[el.id] !== "plus") {
            return { ...memo, [el.id]: "minus" };
          } else {
            // it on trouve l'utilisateur en mutation "plus" en l'enlève simplement
            const { [el.id]: cetEl, ...rest } = memo;
            return rest;
          }
        },
        { ...mutations }
      )
    );
  };

  const onSelectionUpdated = (type: "add" | "remove", selection: any[]) => {
    if (type !== "add") {
      handleRemove(selection);
    } else {
      handleAdd(selection);
    }
  };

  const handleTerminate = () => {
    setSaving(null);
    history.push("/projects/0?sort=name,desc");
  };

  useEffect(() => {
    appFetch(
      `${constants.paths.PROJECT_USERS_PATH}?projectId=${projectId}`
    ).then((res: any) => setProjectUsers(res.content));
  }, [projectId, appFetch]);

  useEffect(() => {
    setAvailableUsers(
      users.filter((u: any) => !projectUsers.find((uP: any) => uP.id === u.id))
    );
  }, [projectUsers, users]);

  useEffect(() => {
    if (saving !== false && saving !== null) {
      if (usersToSave.length) {
        const [usrToSave, ...rest] = usersToSave;
        const { firstName, lastName } = usrToSave;
        setUserSubmittedIdentity(`${firstName} ${lastName}`);
        saveUser(usrToSave).then((resp: any) => {
          if (rest) {
            setUsersToSave(rest);
          } else {
            setSaving(false);
          }
        });
      } else {
        setUserSubmittedIdentity("");
        setSaving(true);
      }
    }
  }, [saving, usersToSave]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const usrsToSave = Object.keys(mutations).reduce((m: any, id: string) => {
      const usr = users.find((u: any) => u.id === id);
      return usr
        ? m.concat([
            {
              ...usr,
              projects:
                mutations[id] === "plus"
                  ? [...new Set<string>(usr.projects.concat([projectId]))]
                  : usr.projects.filter((id: string) => id !== projectId),
            },
          ])
        : m;
    }, []);
    setUsersToSave(usrsToSave);
    setNbrToSave(usrsToSave.length);
    setSaving(undefined);
  };

  const itemTextRenderer = (i: any) => `${i.lastName} ${i.firstName}`;

  if (saving !== null) {
    return (
      <Grid
        container
        direction="row"
        spacing={8}
        justify="center"
        style={{ padding: "1em" }}
      >
        <Grid item xs={12}>
          <Grid container direction="column" spacing={8}>
            <Grid item>
              <label className={classes.progressLabel}>
                {typeof saving === "undefined"
                  ? `Ajout de l'utilisateur ${userSubmittedIdentity}`
                  : saving === false
                  ? "Une erreur s'est produite"
                  : "Opération effectuée"}
              </label>
            </Grid>
            <Grid item>
              <UiProgress
                variant="determinate"
                color="secondary"
                value={((nbrToSave - usersToSave.length) * 100) / nbrToSave}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="flex-end" spacing={8}>
            <Grid item xs={4}>
              <UiButton
                style={{ marginTop: 16 }}
                fullWidth
                color="secondary"
                variant="contained"
                onClick={handleTerminate}
                disabled={saving !== true && saving !== false}
              >
                Terminé
              </UiButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (true) {
    return (
      <Grid container direction="row" spacing={8} justify="center">
        <Grid item xs={12}>
          <SelectionDoubleList
            availableItems={availableUsers}
            selectedItems={projectUsers}
            itemTextRendrer={itemTextRenderer}
            onSelectionUpdated={onSelectionUpdated}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="flex-end" spacing={8}>
            <Grid item xs={4}>
              <UiButton
                style={{ marginTop: 16 }}
                fullWidth
                color="primary"
                variant="outlined"
                onClick={handleTerminate}
              >
                Annuler
              </UiButton>
            </Grid>
            <Grid item xs={4}>
              <UiButton
                style={{ marginTop: 16 }}
                fullWidth
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
              >
                Valider
              </UiButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default withStyles(styles)(withContext(ProjectUsersManager));
