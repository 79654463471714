import React, { useState, useEffect } from "react";
import ProjectsOperations from "./component";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import useInterval from "../../tools/useInterval";
import withContext from "../../tools/withContext";
import useRouter from "../../tools/useRouter";
import ProjectType from "../../types/Project";
import UserType from "../../types/User";

// interface ProjectProps {
//     ctx: ContextType;
//     match: any;
// }

const ProjectsOperationContainer = (props: any) => {
  const router = useRouter();
  const {
    ctx: { appFetch },
    match: {
      params: { noPage }
    },
    location: { search }
  } = props;
  const { USERS_PATH, BATCH_GENERATION_PROGRESS_PATH } = constants.paths;
  const [users, setUsers] = useState<UserType[]>([]);
  const [progress, setProgress] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [interval, changeInterval] = useState(10000);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");

  // on map les projects pour rendre les infos à trier facilement accessibles ( labeler, project )
  const projectMapper = (project: ProjectType, users: any[]) => {
    const admins = users.filter(
      (u: any) =>
        u.projects.includes(project.id) &&
        u.authorities[0] === "ROLE_PROJECT_ADMIN"
    );
    return {
      ...project,
      labelingType: project.labelingTypes[0].name,
      clientStr: (
        <span>
          {project.client.name}
          <br />
          {admins.length > 0 ? (
            <small style={{ color: "gray" }}>{` ${admins
              .map((a: any) => a.lastName)
              .join(", ")}`}</small>
          ) : (
            ""
          )}
        </span>
      )
    };
  };

  const fetchDatas = async () => {
    try {
      setIsError(false);

      // on récupère les projects
      const usersData: any = await appFetch(USERS_PATH);

      setUsers(usersData.content);
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const fetchProgress = async () => {
    const progress = await appFetch(BATCH_GENERATION_PROGRESS_PATH);
    const progressMapped = progress.reverse().map((prog: any) => ({
      ...prog,
      project: projectMapper(
        prog.project,
        users.filter((u: any) => u.projects.length > 0)
      )
    }));

    setProgress(progressMapped);
    setIsLoading(false);
  };

  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noPage, router.query]);

  useEffect(() => changeInterval(search !== "" ? 700 : 3000), [search]);

  useInterval(() => fetchProgress(), interval);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      <ProjectsOperations
        isLoading={isLoading}
        progress={progress}
        appFetch={appFetch}
      />
    </React.Fragment>
  );
};

export default withContext(ProjectsOperationContainer);
