import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router-dom";
import { uniqBy } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import { RouteComponentProps } from "react-router";
import ProjectType from "../../../types/Project";
import UiInput from "../../common/UiInput";
import UiButton from "../../common/UiButton";
import UiDialogActions from "../../common/UiDialogActions";
import UiDialogTitle from "../../common/UiDialogTitle";
import UISelectMultipleProjects from "../../common/UISelectMultipleProjects";
import UiSelect from "../../common/UiSelect";
import UserType from "../../../types/User";
import withContext from "../../../tools/withContext";
import constants from "../../../tools/constants";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 4}px`
    },
    formArea: {
      overflowY: "hidden"
    },
    action: {
      paddingRight: "48px",
      paddingBottom: "24px"
    },
    error: {
      textAlign: "center",
      color: "red"
    },
    visible: {
      visibility: "visible"
    },
    hidden: {
      visibility: "hidden"
    }
  });

const modelUser: UserType = {
  lastName: "",
  firstName: "",
  email: "",
  authorities: [],
  projects: [],
  createdDate: new Date()
};

interface PropsTypes extends RouteComponentProps<any>, React.Props<any> {
  user?: UserType;
  projects: ProjectType[];
  open: boolean;
  onClose: any;
  onSave: any;
  classes: any;
  ctx: any;
}

interface FiltersType {
  NONE: Function;
  CLIENT: Function;
}

const UserFormDialog = (props: PropsTypes) => {
  const { user, projects, open, onClose, classes, onSave, ctx } = props;
  const userRole = ctx.user.authorities[0];

  const adminProjectValues: any =
    userRole === "ROLE_PROJECT_ADMIN"
      ? {
          projects: ctx.user.projects,
          authorities: ["ROLE_LABELER"]
        }
      : {};

  const initialValues: UserType = user || {
    ...modelUser,
    ...adminProjectValues
  };

  const [values, setValues] = useState<UserType>({ ...initialValues });
  const [clientId, setClientId] = useState("");
  const [error, setError] = useState<string>("");

  const roles =
    userRole === "ROLE_ADMIN"
      ? [
          { id: "ROLE_LABELER", nom: "Labeler" },
          { id: "ROLE_ADMIN", nom: "Admin" },
          { id: "ROLE_PROJECT_ADMIN", nom: "Admin Projet" }
        ]
      : [{ id: "ROLE_LABELER", nom: "Labeler" }]; // un ROLE_PROJECT_ADMIN peut uniquement créer des labeler

  const handleChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]:
        e.target.name === "authorities"
          ? e.target.value !== ""
            ? [e.target.value]
            : []
          : e.target.value
    });

  const handleChangeClient = (e: any) => setClientId(e.target.value);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    return onSave(
      values.authorities[0] !== "ROLE_ADMIN"
        ? values
        : { ...values, projects: [] }
    );
  };

  const handleBlur = () => {
    ctx
      .appFetch(`${constants.paths.USERS_PATH}/${values.email}`)
      .then(() => setError("Cet utilisateur existe déjà."))
      .catch((e: any) => setError(""));
  };

  useEffect(() => {
    setValues({ ...initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (user) {
      setValues({ ...user });
    }
  }, [user]);

  const { lastName, firstName, email, authorities } = values;
  const currentRole = authorities[0];

  // quand on change de rôle, on mets projets à  []
  useEffect(() => {
    setValues(v => ({ ...v, projects: [] }));
    if (!["ROLE_LABELER", "ROLE_PROJECT_ADMIN"].includes(currentRole))
      setClientId("");
  }, [currentRole]);

  const isValid = () => {
    return (
      error === "" &&
      lastName &&
      lastName.length > 0 &&
      firstName &&
      firstName.length > 0 &&
      email &&
      email.length > 0 &&
      authorities &&
      authorities.length === 1 &&
      (authorities[0] === "ROLE_ADMIN" || values.projects.length > 0)
    );
  };

  const clients = uniqBy(
    projects.map((p: any) => p.client),
    (client: any) => client.id
  );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="list-actif-title">
      <form onSubmit={handleSubmit} className={classes.formArea}>
        <UiDialogTitle
          title={`${user ? "Modifier" : "Créer"} un utilisateur`}
          onCloseClick={onClose}
        />
        <DialogContent>
          <Grid container spacing={0} direction="row" justify="space-between">
            <Grid item sm={6} className={classes.form}>
              <UiInput
                type="email"
                name="email"
                label="Email"
                disabled={!!user && typeof user.id !== "undefined"}
                required
                fullWidth
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
                error
              />
            </Grid>
            <Grid item sm={6} className={classes.form}>
              <UiSelect
                label="Rôle"
                name="authorities"
                fullWidth
                required
                value={
                  authorities && authorities.length > 0 ? authorities[0] : ""
                }
                onChange={handleChange}
                style={{ display: "block", minWidth: "250px" }}
              >
                {!values.id && <option value="" />}
                {roles.map((role: any, index: number) => (
                  <option value={role.id} key={index}>
                    {role.nom}
                  </option>
                ))}
              </UiSelect>
            </Grid>
            <Grid item sm={6} className={classes.form}>
              <UiInput
                type="text"
                name="firstName"
                label="Prénom"
                fullWidth
                required
                value={firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} className={classes.form}>
              <UiInput
                type="text"
                name="lastName"
                label="Nom"
                fullWidth
                required
                value={lastName}
                onChange={handleChange}
              />
            </Grid>
            {currentRole === "ROLE_LABELER" ||
              (currentRole === "ROLE_PROJECT_ADMIN" && clients.length > 1 && (
                <Grid item sm={12} className={classes.form}>
                  <UiSelect
                    label="Tri par client"
                    fullWidth
                    value={clientId}
                    onChange={handleChangeClient}
                    style={{ display: "block", minWidth: "250px" }}
                  >
                    <option value="" />
                    {clients.map((client: any, index: number) => (
                      <option value={client.id} key={index}>
                        {client.name}
                      </option>
                    ))}
                  </UiSelect>
                </Grid>
              ))}
            {values.authorities && values.authorities[0] !== "ROLE_ADMIN" && (
              <Grid item sm={12} className={classes.form}>
                <UISelectMultipleProjects
                  label="Projets"
                  handleChange={handleChange}
                  datas={values}
                  projects={projects.filter(
                    (project: ProjectType) =>
                      clientId === "" || project.clientId === clientId
                  )}
                  allProjects={projects}
                />
              </Grid>
            )}
            <Grid
              item
              sm={12}
              className={classNames(classes.error, {
                [classes.visible]: error !== "",
                [classes.hidden]: error === ""
              })}
            >
              Cet utilisateur existe déjà
            </Grid>
          </Grid>
        </DialogContent>
        <UiDialogActions className={classes.action}>
          <UiButton
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!isValid()}
          >
            Valider
          </UiButton>
        </UiDialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(withRouter(withContext(UserFormDialog)));
