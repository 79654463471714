import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import withContext from "../../tools/withContext";
import { useHotkeys } from "../../tools/react-hotkeys-hook";

const styles = theme => ({
  grid: {
    display: "grid",
    overflowX: "auto"
  },
  rowDef: {
    display: "contents"
  },
  row: {
    "& > div": {
      boxShadow: "0px 4px 1px rgba(0,0,0, 0.02)",
      marginBottom: `${theme.spacing.unit}px`,
      cursor: "arrow",
      border: "none",
      backgroundColor: "#FFF"
    },
    "&:hover > div": {
      boxShadow: "0px 4px 1px rgba(0,0,0, 0.06)",
      backgroundColor: `${darken("#FFF", 0.035)}`
    }
  },
  rowSelected: {
    "& > div": {
      cursor: "arrow",
      border: "none",
      marginBottom: `${theme.spacing.unit}px`,
      boxShadow: "0px 4px 1px rgba(0,0,0, 0.06)",
      backgroundColor: `${darken("#FFF", 0.035)}`
    }
  },
  alCenter: {
    textAlign: "center"
  },
  alRight: {
    textAlign: "right"
  },
  topPadded: {
    paddingTop: theme.spacing.unit
  },
  actionsPadded: {
    paddingTop: 6,
    paddingRight: theme.spacing.unit
  }
});

const TableBody = ({
  classes,
  datas,
  selected,
  ctx: { user },
  handleSelection,
  buildColumns,
  rows,
  height,
  noPage,
  onShiftStateChanged
}) => {
  const divRef = useRef(null);
  const [keyEvent, setKeyEvent] = useState({ shiftKey: false });

  useHotkeys("*", (e) => {
    if (e.key === "Shift") {
      setKeyEvent({
        shiftKey: e.type === "keydown"
      });
    }
  });


  const userRole = user ? user.authorities[0] : null;
  const isRowSelected = id => selected.indexOf(id) !== -1;
  const gridTemplateColumns = rows
    .filter(
      r =>
        !r.onlyAdmin || r.onlyRoles === null || r.onlyRoles.includes(userRole)
    )
    .map(row => `${row.size}fr`)
    .join(" ");

  useEffect(() => {
    if (divRef) {
      divRef.current.scrollTo(0, 0);
    }
  }, [noPage]);

  // propagate shift key state to the parent component if requested
  useEffect(() => {
    if (onShiftStateChanged) {
      onShiftStateChanged(keyEvent.shiftKey);
    }
  }, [keyEvent, onShiftStateChanged]);

  return (
    <div
      className={classes.grid}
      ref={divRef}
      style={{ gridTemplateColumns, maxHeight: height - 10 }}
    >
      {datas.map((d, index) => {
        const isSelected = isRowSelected(d.id);
        return (
          <div
            className={classNames(classes.rowDef, {
              [classes.row]: !isSelected,
              [classes.rowSelected]: isSelected
            })}
            onClick={
              handleSelection ? event => handleSelection(event, d.id) : null
            }
            key={index}
          >
            {buildColumns(d, isSelected).map((Component, index) => {
              return React.cloneElement(Component, {
                key: index,
                index,
                className: classNames(
                  {},
                  {
                    [classes.alCenter]: rows[index].align === "center",
                    [classes.alRight]: rows[index].align === "right",
                    [classes.topPadded]:
                      (rows[index].label !== "" || index > 0) &&
                      index < rows.length - 1,
                    [classes.actionsPadded]: index === rows.length - 1
                  }
                ),
                onClick: handleSelection
                  ? event => handleSelection(event, d.id)
                  : null
              });
            })}
          </div>
        );
      })}
    </div>
  );
};

export default withContext(withStyles(styles)(TableBody));
