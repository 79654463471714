import React, { useState, useEffect } from "react";
import { throttle } from "lodash";
import Labeler from "./component";
import Spinner from "../common/Spinner";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import { useHotkeys } from "../../tools/react-hotkeys-hook";
import withContext from "../../tools/withContext";

const LabelerContainerF = (props: any) => {
  const {
    match: {
      params: { noBatch, noImage },
    },
    ctx: { appFetch },
  } = props;
  const [tags, setTags] = useState<any[]>([]);
  const [motifs, setMotifs] = useState<string[]>([]);
  const [nomBatch, setNomBatch] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [dimensions, setDimensions] = useState({ height: 576, width: 720 });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [keyEvent, setKeyEvent] = useState<any>(null);

  const hotkeyCallback = throttle((e: any) => {
    if (e.key === "Tab") {
      e.preventDefault();
    }
    if (e.key === "Enter") {
      e.stopPropagation();
    }
    const { key, type, shiftKey, altKey, ctrlKey } = e;
    setKeyEvent({ key, type, shiftKey, altKey, ctrlKey });
  }, 750);

  useHotkeys("*", hotkeyCallback);

  const clearKeypressedState = () => setKeyEvent(null);

  /** Fetch image list for batch noBatch */
  const fetchImages = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const images = await appFetch(
        `/api/images/batches/${noBatch}/all?offset=0&size=1000`
      );
      setImages(images);
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
    }
  };

  /** Load batch tags*/
  const fetchBatch = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const batch = await appFetch(
        `${constants.paths.BATCHES_PATH}/${noBatch}`
      );
      const labelingTypes = batch.project.labelingTypes[0];
      const tagsWithColors = labelingTypes.tags.map(
        (t: any, index: number) => ({
          ...constants.colors[index],
          legend: t,
          visible: true,
        })
      );
      setTags(tagsWithColors);
      setMotifs(labelingTypes.reasons || ["ERROR"]);
      setDimensions({ height: batch.maxHeight, width: batch.maxWidth });
      setNomBatch(batch.name);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
    }
  };

  /** Save image's labelization (labels or motif _reason to skip_) */
  const saveImage = async (image: any) => {
    const { id, labels, motif, lastLabeledBy } = image;
    try {
      setIsError(false);

      const newImageDatas = {
        id,
        labels,
        motif,
        lastLabeledBy, // null si l'on veut que le backend remplace par username
      };

      setImages(
        images.map((img: any) =>
          img.id === image.id ? { ...img, ...newImageDatas } : img
        )
      );

      await appFetch(constants.paths.IMAGES_PATH, {
        method: "PUT",
        body: JSON.stringify(newImageDatas),
      });

      setIsError(false);
    } catch (e) {
      // restore previous state just in case it was modified before the exception was thrown
      setImages(images);

      setIsError(true);
      setIsSnackbarOpen(true);
      setMessage(e.message);
    }
  };

  const storeMotif = (motif: string | null, clearLabels: boolean = true) => {
    setImages(
      images.map((img: any, index: number) =>
        index === Number(noImage)
          ? {
              ...img,
              labels: !clearLabels
                ? img.labels
                : {
                    rectangleLabels: [],
                    polygonLabels: [],
                    simpleLabels: [],
                    country: null,
                  },
              motif,
              lastLabeledBy: null, // null si l'on veut que le backend remplace par username
            }
          : img
      )
    );
  };

  /** Close snackbar */
  const closeSnackbar = () => setIsSnackbarOpen(false);

  const changeTagsVisibility = (index: Number) =>
    setTags(
      tags.map((t: any, idx: Number) => ({
        ...t,
        visible: idx === index ? !t.visible : t.visible,
      }))
    );

  const changeAllTagsVisibility = (visible: Boolean) =>
    setTags(
      tags.map((t: any, idx: Number) => ({
        ...t,
        visible,
      }))
    );

  useEffect(() => {
    fetchImages();
    fetchBatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // sur les anciens lots maxWidth et maxHeight ne sont pas renseignés
    // du coup on utilise l'ancienne méthode : on prend pour dimensions du canvas
    // les dimensions de la première image
    if (images.length > 0 && dimensions.height === 0) {
      const image: any = new Image();
      image.src = images[0].url;
      image.onload = (img: any) => {
        const { height, width } = img.target;
        setDimensions({ height, width });
      };
    }
  }, [images, dimensions]);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      {isLoading && <Spinner message="Chargement des images" />}
      {!isLoading &&
        images.length > 0 &&
        motifs.length > 0 &&
        dimensions.height > 0 && (
          <Labeler
            onSave={saveImage}
            storeMotif={storeMotif}
            loading={isLoading}
            images={images}
            motifs={motifs}
            height={dimensions.height}
            width={dimensions.width}
            labels={tags}
            nomBatch={nomBatch}
            changeVisibility={changeTagsVisibility}
            toggleAllBoxesVisibility={changeAllTagsVisibility}
            keyEvent={keyEvent}
            clearKeyevent={clearKeypressedState}
          />
        )}
    </React.Fragment>
  );
};

export default withContext(LabelerContainerF);
