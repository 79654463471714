import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Tag from "./Tag";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing.unit * 2,
    },
  });

interface PropTypes {
  classes: any;
  tags: any[];
}

const TagListSample = (props: PropTypes) => {
  const {
    classes,
    tags
  } = props;

  return (
    <Grid
      container
      direction="row"
      spacing={16}
      justify="flex-start"
      className={classes.root}
    >
      {tags.map((t: string) => (
        <Tag key={t} id={t} label={t} noSuppr />
      ))}
    </Grid>
  );
};

export default withStyles(styles)(TagListSample);
