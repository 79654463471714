import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SettingsIcon from "@material-ui/icons/Settings";
import withContext from "../../tools/withContext";
import EnhancedTableHeader from "../common/EnhancedTableHeader";
import TableBody from "../common/TableBody";
import TableNavigation from "../common/TableNavigation";
import ProjectActionButton from "./components/ProjectActionButton";
import ProjectsActionButton from "./components/ProjectsActionButton";
import UiSearchInputField from "../common/UiSearchInputField";
import ProjectType from "../../types/Project";
import ContextType from "../../types/Context";
import DimensionsContainer from "../common/DimensionsContainer";
import useRouter from "../../tools/useRouter";

const rows = [
  {
    id: "name",
    numeric: false,
    sortable: true,
    disablePadding: true,
    label: "Nom du projet",
    onlyRoles: null,
    size: 2,
    align: "center"
  },
  {
    id: "labelingType",
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: "Type de labellisation",
    onlyRoles: null,
    size: 3,
    align: "center"
  },
  {
    id: "clientStr",
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: "Client",
    onlyRoles: null,
    size: 2,
    align: "center"
  },
  {
    id: "secondsToFinish",
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: "Lots",
    onlyRoles: null,
    size: 3,
    align: "center"
  },
  {
    id: "actions",
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: "Actions",
    onlyRoles: null,
    size: 1,
    align: "right"
  }
];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 50
    },
    noData: {
      textAlign: "center",
      padding: theme.spacing.unit * 2
    },
    toolbar: {
      textAlign: "right",
      padding: theme.spacing.unit * 2
    },
    progress: {
      padding: `0 ${theme.spacing.unit * 2}px`
    },
    fullWidth: {
      width: "100%"
    }
  });

interface PropsType {
  classes: any;
  ctx: ContextType;
  projects: ProjectType[];
  progress: any[];
  deleteProject: Function;
  isLoading: boolean;
  onlyProcessingProjects: boolean;
  theme: any;
  pagination: any;
  history: any;
  match: any;
  appFetch: any;
  searchTerm: string;
  setSearchTerm: Function;
}

const Projects = (props: PropsType) => {
  const {
    classes,
    ctx: { user },
    projects,
    isLoading,
    pagination,
    match: {
      params: { noPage }
    },
  } = props;
  const router = useRouter();
  const searchParsed = router.query;
  const s = searchParsed.searchTerm || "";

  const [order, setOrder] = useState(
    //@ts-ignore
    searchParsed.sort ? searchParsed.sort.split(",")[1] : "desc"
  );
  const [orderBy, setOrderBy] = useState(
    //@ts-ignore
    searchParsed.sort ? searchParsed.sort.split(",")[0] : "name"
  );
  //@ts-ignore
  const [searchTerm, setSearchTerm] = useState<string>(s);
  const [selected, setSelected] = useState<string[]>([]);

  const handleRequestSort = (event: any, property: string) => {
    let _orderBy = property;
    let _order = "desc";

    if (orderBy === property && order === "desc") {
      _order = "asc";
    }

    props.history.push(
      `/projects/${noPage}?${queryString.stringify(
        { ...searchParsed, sort: `${_orderBy},${_order}` },
        { strict: false }
      )}`
    );
    setOrderBy(_orderBy);
    setOrder(_order);
  };

  const handleSelection = (event: any, batchSelectedId: any) => {
    const selectedIndex = selected.indexOf(batchSelectedId);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, batchSelectedId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isAdmin = user && user.authorities[0] === "ROLE_ADMIN";

  const buildColumns = (project: ProjectType) => {
    return [
      <div>{project.name}</div>,
      <div>{project.labelingType}</div>,
      <div>{project.clientStr}</div>,
      <div>
        {project.totalBatches > 0 && (
          <Link to={`/batches/0?projectId=${project.id}&sort=createdDate,desc`}>
            Afficher les lots
          </Link>
        )}
      </div>,
      <div>
        <ProjectActionButton project={project} isAdmin={isAdmin} />
      </div>
    ];
  };

  const handleChangePage = (noP: any) => {
    props.history.push(
      `/projects/${noP}?${queryString.stringify(router.query)}`
    );
  };

  const handleSearch = (str: string) => {
    setSearchTerm(str);
    props.history.push(
      `/projects/${str.length > 0 ? "0" : noPage}?${queryString.stringify({
        ...router.query,
        searchTerm: str.length > 0 ? str : undefined
      })}`
    );
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Helmet>
        <title>Liste des projets</title>
      </Helmet>
      <Grid item className={classes.fullWidth}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.toolbar}
        >
          <Grid item>
            <Grid container spacing={8}>
              <Grid item>
                {
                  //@ts-ignore
                  <Typography component="h2" style={{ fontSize: "2.5em" }}>
                    Liste des projets
                  </Typography>
                }
              </Grid>
              <Grid item style={{ paddingTop: 26 }}>
                <Link
                  title="Afficher les opérations récentes"
                  to="/projectsOperations"
                >
                  <SettingsIcon color="secondary" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{isLoading && <CircularProgress color="primary" />}</Grid>
          <Grid item>
            <Grid container direction="row" spacing={8}>
              <Grid item style={{ minHeight: 70 }}>
                {isAdmin && <ProjectsActionButton />}
              </Grid>
              <Grid item>
                <UiSearchInputField
                  searchString={searchTerm}
                  setSearchString={handleSearch}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {projects.length === 0 && !isLoading && (
        <Grid item>
          <Typography className={classes.noData} variant="h4">
            Pour l'instant, aucun projet{" "}
            {isAdmin && searchTerm !== "" && " trouvé..."}
            {!isAdmin && " ne vous a été attribué."}
          </Typography>
        </Grid>
      )}
      {projects.length > 0 && (
        <>
          <Grid item className={classes.fullWidth}>
            <EnhancedTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={projects.length}
              rows={rows}
              withCheck={false}
              numSelected={selected.length}
              actionsCellSize={3}
            />
          </Grid>
          {rows.length > 0 && (
            <DimensionsContainer>
              <TableBody
                handleSelection={handleSelection}
                selected={selected}
                buildColumns={buildColumns}
                datas={projects}
                rows={rows}
                noPage={noPage}
              />
            </DimensionsContainer>
          )}
          {pagination && (
            <Grid item className={classes.fullWidth}>
              <TableNavigation
                pagination={pagination}
                onChangePage={handleChangePage}
                itemName="Projets"
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withContext(withTheme()(Projects)))
);
