import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import IconAdd from "@material-ui/icons/Add";
import IconRemove from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import UiSelect from "../../common/UiSelect";
import UiInput from "../../common/UiInput";
import UiButton from "../../common/UiButton";

const styles = (theme: Theme) =>
  createStyles({
    row: {
      backgroundColor: "silver"
    },
    input: {
      width: 100,
    }
  });

const MetadataFilterBuilder = (props: any) => {
  const { onChange } = props;
  const [filters, setFilters] = useState<any[]>([]);

  const addFilter = () => setFilters(filters.concat({ key: props.metadatas[0], value: "" }));

  const removeFilter = (idx: number) =>
    setFilters(filters.filter((f: any, index: number) => idx !== index));

  const handleInputChange = (e: any) => {
    setFilters(filters.map((f: any, index: number) => `input_${index}` === e.target.name ? { ...f, value: e.target.value } : f))
  }

  const handleSelectChange = (e: any) => {
    setFilters(filters.map((f: any, index: number) => `select_${index}` === e.target.name ? { ...f, key: e.target.value } : f))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(filters), [filters]);

  return (
    <Grid container direction="column">
      <Grid item style={{ textAlign: "right" }}>
        <UiButton onClick={addFilter} outlined="flat">
          <IconAdd />
          <Typography variant="body1">Ajouter un filtre</Typography>
        </UiButton>
      </Grid>
      <Grid item>
        {filters.map((f: any, index: number) => (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={8}
          >
            <Grid item sm={7}>
                <UiSelect name={`select_${index}`} onChange={handleSelectChange} fullWidth>
                {props.metadatas.map((m: any, index: number) => (
                    <option key={index} value={m}>
                    {m.split('.')[1]}
                    </option>
                ))}
                </UiSelect>            
            </Grid>
            <Grid item sm={3}>
                <UiInput name={`input_${index}`} onChange={handleInputChange} fullWidth label={null}/>
            </Grid>
            <Grid item sm={2}>
                <IconButton onClick={() => removeFilter(index)}>
                  <IconRemove />
                </IconButton>            
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(MetadataFilterBuilder);
