import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 10,
      marginLeft: theme.spacing.unit
    }
  });

interface PropTypes {
  classes: any;
  disabled: boolean;
}

const ArrowIcon = (props: PropTypes) => (
  <SvgIcon {...props} className={props.classes.icon}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10L12 0L21 10H3Z"
      fill="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 16L12 24L3 16L21 16Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="12"
        y1="0"
        x2="12"
        y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.disabled ? "gray" : "#FF9966"} />
        <stop offset="1" stopColor={props.disabled ? "silver" : "#FF5E62"} />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="12"
        y1="24"
        x2="12"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.disabled ? "gray" : "#FF9966"} />
        <stop offset="1" stopColor={props.disabled ? "silver" : "#FF5E62"} />
      </linearGradient>
    </defs>
  </SvgIcon>
);

ArrowIcon.defaultProps = {
  disabled: false
};

export default withStyles(styles)(ArrowIcon);
