import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import UiButton from "../common/UiButton";

const styles = (theme: Theme) =>
  createStyles({
    input: {
      borderRadius: "3px 0 0 3px",
      padding: 7,
      fontSize: 17,
      borderTop: "1px solid silver",
      borderLeft: "1px solid silver",
      borderBottom: "1px solid silver",
      width: 200
    },
    button: {
      borderRadius: "0 3px 3px 0",
      padding: 8,
      fontSize: 17,
      borderTop: "1px solid silver",
      borderRight: "1px solid silver",
      borderBottom: "1px solid silver",
      width: 50
    }
  });

interface PropTypes {
  classes: any;
  setSearchString: any;
  searchString: string;
}

const UiSearchInputField = (props: PropTypes) => {
  const { classes, setSearchString, searchString } = props;
  const [value, setValue] = useState<string>(searchString);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (value === searchString && value !== "") {
      setValue("");
      setSearchString("");
      return;
    }
    setSearchString(value);
  };

  const onChange = (e: any) => setValue(e.target.value);

  return (
    <Grid
      container
      direction="row"
      className={classes.searchInput}
      component="form"
      onSubmit={onSubmit}
    >
      {
        //@ts-ignore
        <Grid item component="input" name="recherche" className={classes.input} placeholder="Rechercher" value={value} onChange={onChange}/>
      }
      <Grid item>
        <UiButton
          color={
            searchString === "" || searchString !== value
              ? "secondary"
              : "primary"
          }
          variant="contained"
          className={classes.button}
          type="submit"
        >
          {(searchString === "" || searchString !== value) && <SearchIcon />}
          {searchString !== "" && searchString === value && <ClearIcon />}
        </UiButton>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(UiSearchInputField);
