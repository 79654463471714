import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing.unit
    },
    select: {
      borderRadius: 4,
      padding: "7px 7px",
      boxSizing: "border-box",
      fontSize: 16,
      position: "relative",
      border: "1px solid rgba(72,72,72,.2)",
      appearance: "none",
      color: "#444f59",
      background: "url(/assets/arrow-select.png) no-repeat 100%",
      "label + &": {
        marginTop: theme.spacing.unit
      }
    },
    option: {
      backgroundColor: "red",
      fontSize: "3em"
    },
    label: {
      display: "block",
      fontSize: 16,
      fontWeight: 200
    }
  });

const UISelect = (props: any) => {
  const { classes, label, required, children, className, minWidth, ...rest } = props;

  return (
    <React.Fragment>
      {label && (
        <label className={classes.label}>
          {label}
          {required && (
            <span>
              {" "}
              <sup>*</sup>
            </span>
          )}
        </label>
      )}
      <select
        {...rest}
        className={classNames(
          classes.select,
          className ? { [className]: true } : {}
        )}
        style={{ width: "100%" }}
      >
        {children}
      </select>
    </React.Fragment>
  );
};

UISelect.defaultProps = {
  minWidth: "250px"
};

export default withStyles(styles)(UISelect);
