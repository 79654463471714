import React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: `calc(100vh - 64px - ${theme.spacing.unit * 5}px)`
    },
    grow: {
      flexGrow: 1
    }
  });

const Homepage = (props: any) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <Slide direction="down" in>
            <Typography className={classes.title} variant="h1">
              Labeler
            </Typography>
          </Slide>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Homepage);
