import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import JSZIP from "jszip";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import RootRef from "@material-ui/core/RootRef";
import createStyles from "@material-ui/core/styles/createStyles";
import { format, parse } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { useDropzone } from "react-dropzone";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import Paper from "@material-ui/core/Paper";
import UiButton from "../common/UiButton";
import UiInput from "../common/UiInput";
import UiProgress from "../common/UiProgess";
import UiFormTitle from "../common/UiFormTitle";
import MetadataFilterBuilder from "./components/MetadataFilterBuilder";
import { Calendar } from "../common/Calendar";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: `calc(100vh - 64px - ${theme.spacing.unit * 5}px)`,
    },
    paper: {
      minWidth: "100%",
      borderRadius: "5px",
    },
    comment: {
      margin: "0px 16px",
      minHeight: 19,
      fontSize: 12,
    },
    marginTopNeg: {
      marginTop: "-16px",
    },
    title: {
      color: "white",
    },
    form: {
      padding: `${theme.spacing.unit * 4}px`,
    },
    input: {
      backgroundColor: "#FFF",
    },
    margin: {
      margin: theme.spacing.unit,
    },
    bootstrapRoot: {
      "label + &": {
        marginTop: theme.spacing.unit,
      },
    },
    bootstrapInput: {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      border: "1px solid rgba(72,72,72,.2)",
      fontSize: 16,
      padding: "10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
    bootstrapFormLabel: {
      fontSize: 18,
    },
    select: {
      width: "250px",
      borderRadius: 4,
      padding: "7px 7px",
      boxSizing: "border-box",
      // margin: "5px 0 0",
      fontSize: 16,
      position: "relative",
      border: "1px solid rgba(72,72,72,.2)",
      appearance: "none",
      color: "#444f59",
      background: "url(/assets/arrow-select.png) no-repeat 100%",
      "label + &": {
        marginTop: theme.spacing.unit,
      },
    },
    label: {
      fontWeight: 200,
      display: "block",
      fontSize: 16,
    },
    marginTop: {
      marginTop: theme.spacing.unit * 2,
    },
    dropContainer: {
      border: `dashed 1px ${theme.palette.primary.main}`,
      marginTop: theme.spacing.unit * 2,
      padding: 0,
      minHeight: 125,
      borderRadius: "3px",
      fontSize: "1.4em",
      color: theme.palette.primary.main,
    },
    uploading: {
      border: `solid 1px ${theme.palette.primary.main}`,
    },
    uploadProperties: {
      color: "gray",
      textAlign: "center",
      marginBottom: theme.spacing.unit * 2,
    },
  });

const Import = (props: any) => {
  const {
    classes,
    history,
    uploadFiles,
    importEs,
    countEs,
    project,
    type,
    numberImages,
    metadatas,
    progress,
    uploadProperties,
  } = props;

  const [datas, setDatas] = useState<any>({});
  const [error, setError] = useState<string>("");
  const [filenames, setFilenames] = useState<any>({});
  const [numberFiles, setNumberFiles] = useState(0);
  const [totalFiles, setTotalFiles] = useState(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [dDebut, setDateDebut] = useState<any>(new Date());
  const [dFin, setDateFin] = useState<any>(new Date());
  const [filterMetadatas, setFilterMetadatas] = useState<any>([]);
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles.length);
      var formData = datas.formData || new FormData();

      const droppedZip = acceptedFiles.some(
        (f: any) => f.name.indexOf(".zip") !== -1
      );
      const droppedNotZip =
        acceptedFiles.filter((f: any) => f.name.indexOf(".zip") === -1).length >
        0;
      const hasZip =
        typeof Object.keys(filenames).find(
          (k: string) => k.indexOf(".zip") !== -1
        ) !== "undefined";
      const hasNotZip =
        typeof Object.keys(filenames).find(
          (k: string) => k.indexOf(".zip") === -1
        ) !== "undefined";

      if ((droppedZip && hasNotZip) || (droppedNotZip && hasZip)) {
        console.log("error");
        setError(
          "Vous ne pouvez pas uploader à la fois des .zip et des images"
        );
        return;
      }

      let added = 0;

      if (!droppedZip) {
        console.log("dropped images...");
        const zipdoc = new JSZIP();
        for (var file of acceptedFiles) {
          if (typeof filenames[file.name] === "undefined") {
            zipdoc.file(file.name, file);
            setFilenames({ ...filenames, [file.name]: true });
            added++;
          }
        }

        zipdoc.generateAsync({ type: "blob" }).then(function(blob: any) {
          formData.append("filesZip", blob, "images.zip");
          setNumberFiles(numberFiles + added);
          setDatas({ ...datas, formData });
          setError("");
        });

        return;
      } else {
        console.log("dropped zip...");
        const names: any[] = [];
        for (var file of acceptedFiles) {
          console.log('file', file);
          if (typeof filenames[file.name] === "undefined" && !names.includes(file.name)) {
            formData.append("filesZip", file, file.name);
            names.push(file.name);
            added++;
          }
        }
        setFilenames({ ...filenames, ...names.reduce((memo, n: string) => ({ ...memo, [n]: true }), {}) });
        setDatas({ ...datas, formData });
      }
    },
    [datas, numberFiles]
    );

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log("acceptedFiles", acceptedFiles);
      console.log("reçu");
      setTotalFiles(acceptedFiles.length);
    },
    [numberFiles]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept:
      "image/jpeg, image/png, zip, application/octet-stream, application/zip",
    multiple: true,
    onDropAccepted,
    onDrop,
  });

  useEffect(() => {
    setNumberFiles(0);
    setDatas((d: any) => ({ ...d, projectId: project.id }));
  }, [project]);

  const handleChange = (e: any) =>
    setDatas({
      ...datas,
      [e.target.name]: e.target.value,
    });

  const d = new Date();
  const dUTCOffset = d.getTimezoneOffset() / 60;
  const sign = dUTCOffset < 0 ? "+" : "-";
  const offset = dUTCOffset < 10 ? `0${dUTCOffset * -1}` : `${dUTCOffset * -1}`;
  const offsetComplet = `${sign}${offset}:00`;

  const submit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    setError("");
    const { subFolderName, projectId, formData } = datas;
    if (type === "es") {
      importEs({
        projectId,
        subFolderName,
        dateFrom: `${format(
          parse(dDebut),
          `YYYY-MM-DDTHH:mm:[00]${offsetComplet}`,
          { locale: frLocale }
        )}`,
        dateTo: `${format(
          parse(dFin),
          `YYYY-MM-DDTHH:mm:[59]${offsetComplet}`,
          { locale: frLocale }
        )}`,
        filterMetadatas,
      })
        .then(() => {
          setSubmitting(false);
          setTimeout(() => history.push("/projectsOperations", 2000));
        })
        .catch((e: any) => setSubmitting(false));
    } else {
      uploadFiles({ formData, subFolderName })
        .then(() => setTimeout(() => history.push("/projectsOperations"), 2000))
        .catch((e: any) => {
          setSubmitting(false);
          setError("Limite d'upload à 5Go atteinte");
        });
    }
  };

  const handleCountImages = (e: any) => {
    e.preventDefault();
    const { projectId } = datas;
    countEs({
      projectId,
      dateFrom: `${format(
        parse(dDebut),
        `YYYY-MM-DDTHH:mm:[00]${offsetComplet}`,
        { locale: frLocale }
      )}`,
      dateTo: `${format(parse(dFin), `YYYY-MM-DDTHH:mm:[59]${offsetComplet}`, {
        locale: frLocale,
      })}`,
      filterMetadatas,
    }).then(() => console.log("ok"));
  };

  const handleMetadatasChange = (filters: any) => {
    setFilterMetadatas(
      filters.filter((f: any) => f.key.length > 0 && f.value.length > 0)
    );
  };

  const handleChangeStartDate = (d: any) => {
    setDateDebut(d);
  };

  const handleChangeEndDate = (d: any) => {
    setDateFin(d);
  };

  useEffect(() => {
    if (totalFiles !== null) {
      console.log("test", totalFiles !== null && !datas.formData);
    }
  }, [totalFiles]);

  useEffect(() => {
    console.log(filenames, datas);
  }, [filenames]);  

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      className={classes.root}
    >
      <Helmet>
        <title>Importer des images</title>
      </Helmet>
      <Grid item sm={8}>
        <Paper className={classes.paper}>
          <UiFormTitle>{`Importer les images ${
            project.name ? `pour le projet ${project.name}` : ""
          }`}</UiFormTitle>
          <form onSubmit={submit} id="form">
            <Grid
              container
              spacing={8}
              direction="row"
              justify="space-between"
              className={classes.form}
            >
              <Grid item sm={6}>
                <UiInput
                  label="Dossier de stockage"
                  autoFocus
                  name="subFolderName"
                  required
                  onChange={handleChange}
                  value={datas.subFolderName}
                  tabIndex={2}
                />
              </Grid>
              <Grid item sm={6}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="stretch"
                  spacing={8}
                >
                  {type === "es" && (
                    <Grid item>
                      <label className={classes.label}>
                        Sélectionnez une période <sup>*</sup>
                      </label>
                      <Calendar
                        istartDate={dDebut}
                        iendDate={dFin}
                        isSeconds={false}
                        isAm={false}
                        handleChangeStartDate={handleChangeStartDate}
                        handleChangeEndDate={handleChangeEndDate}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {type === "dossierlocal" && progress === 0 && (
                <Grid item sm={12} style={{ textAlign: "right" }}>
                  <UiButton
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={open}
                    disabled={error !== ""}
                  >
                    Ajouter des fichiers
                  </UiButton>
                </Grid>
              )}
              {type === "dossierlocal" && progress > 0 && (
                <Grid item sm={12}>
                  <Grid
                    container
                    className={classNames(classes.dropContainer, {
                      [classes.uploading]: true,
                    })}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item sm={8}>
                      <UiProgress
                        variant="determinate"
                        color="secondary"
                        value={progress}
                      />
                      <small>Images uploadées à {progress} %</small>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {type === "dossierlocal" && progress === 0 && (
                <Grid item sm={12}>
                  <Grid
                    container
                    className={classes.dropContainer}
                    alignItems="center"
                    justify="center"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isDragActive && !datas.formData && (
                      <Grid item>Vous pouvez déposer !</Grid>
                    )}
                    {!isDragActive && (
                      <Grid item>
                        {!datas.formData && totalFiles === null && (
                          <p>
                            Glisser déposer vos images ou cliquer sur le bouton
                            "Ajouter des fichiers"
                          </p>
                        )}
                        {!datas.formData &&
                          totalFiles === null &&
                          uploadProperties && (
                            <div className={classes.uploadProperties}>
                              Max {uploadProperties["max-file-size"]} par
                              fichier et au total max{" "}
                              {uploadProperties["max-request-size"]}
                            </div>
                          )}
                        {datas.formData && <span>Reçu ! {numberFiles > 1 ? ` (${numberFiles})` : ''}</span>}
                        {totalFiles !== null && !datas.formData && (
                          <span>Réception de {totalFiles} fichiers...</span>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {type === "es" && (
                <Grid item sm={6}>
                  {type === "es" && (
                    <MetadataFilterBuilder
                      metadatas={metadatas}
                      onChange={handleMetadatasChange}
                    />
                  )}
                </Grid>
              )}
              {type === "es" && (
                <Grid item sm={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={8}
                    style={{ height: "100%" }}
                  >
                    <Grid item sm={6}>
                      {`Nombre d'images : ${
                        numberImages !== null ? numberImages : "..."
                      }`}
                    </Grid>
                    <Grid item sm={2}>
                      <IconButton onClick={handleCountImages}>
                        <RefreshIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item sm={12}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  spacing={16}
                >
                  <Grid item sm={8}>
                    {error !== "" && (
                      <div style={{ marginTop: 16 }}>
                        <span style={{ color: "red" }}>Erreur : {error}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid item sm={4}>
                    <UiButton
                      style={{ marginTop: 16 }}
                      fullWidth
                      color="secondary"
                      variant="contained"
                      type="submit"
                      tabIndex={3}
                      disabled={submitting || error !== ""}
                    >
                      Importer vos images
                    </UiButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
  //@ts-check
};

export default withStyles(styles)(withRouter(Import));
