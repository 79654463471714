import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import isEqual from "lodash/isEqual";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import UiButton from "../common/UiButton";
import UiInput from "../common/UiInput";
import UiUiSelect from "../common/UiSelect";
import UISelectMultipleProjects from "../common/UISelectMultipleProjects";
import UiFormTitle from "../common/UiFormTitle";
import { mappingUserRoles } from "../users/component";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: `calc(100vh - 64px - ${theme.spacing.unit * 5}px)`
    },
    paper: {
      minWidth: "100%"
    },
    title: {
      color: "white"
    },
    padded: {
      padding: theme.spacing.unit * 3
    },
    input: {
      backgroundColor: "#FFF"
    },
    button: {
      marginTop: theme.spacing.unit * 3
    },
    projects: {
      minHeight: 99
    }
  });

const User = (props: any) => {
  const { classes, user, saveUser, history, projects } = props;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [datas, setDatas] = useState(props.user);

  useEffect(() => setDatas(user), [user]);

  const isLabeler =
    user && user.authorities && user.authorities[0] === "ROLE_LABELER";

  const handleChange = (e: any) =>
    setDatas({
      ...datas,
      [e.target.name]:
        e.target.name !== "authorities" ? e.target.value : [e.target.value]
    });

  useEffect(() => setIsDirty(!isEqual(datas, props.user)), [datas, props.user]);    

  const onSubmit = (e: any) => {
    e.preventDefault();
    saveUser(datas).then(() =>
      setTimeout(
        () =>
          history.push(
            user.authorities[0] === "ROLE_LABELER"
              ? "/batches/0?sort=createdDate,desc"
              : "/userlist/0"
          ),
        1500
      )
    );
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Helmet>
        <title>Profil utilisateur</title>
      </Helmet>
      <Grid item lg={6} xl={4}>
        <Paper elevation={2} className={classes.paper}>
          <UiFormTitle>{isLabeler ? "V" : `Modifier v`}otre profil</UiFormTitle>
          <form onSubmit={onSubmit}>
            <Grid
              container
              spacing={24}
              direction="row"
              justify="space-between"
              className={classes.padded}
            >
              <Grid item lg={6}>
                <UiInput
                  type="email"
                  name="email"
                  label="Email"
                  disabled
                  required
                  fullWidth
                  value={datas.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item lg={6}>
                <UiUiSelect
                  label="Rôle"
                  name="authorities"
                  disabled
                  fullWidth
                  required
                  value={
                    datas.authorities && datas.authorities.length > 0
                      ? datas.authorities[0]
                      : ""
                  }
                  onChange={handleChange}
                  style={{ display: "block", minWidth: "250px" }}
                >
                  <option value="" />
                  <option value="ROLE_ADMIN">
                    {mappingUserRoles["ROLE_ADMIN"]}
                  </option>
                  <option value="ROLE_PROJECT_ADMIN">
                    {mappingUserRoles["ROLE_PROJECT_ADMIN"]}
                  </option>
                  <option value="ROLE_LABELER">
                    {mappingUserRoles["ROLE_LABELER"]}
                  </option>
                </UiUiSelect>
              </Grid>
              <Grid item lg={6}>
                <UiInput
                  type="text"
                  name="firstName"
                  label="Prénom"
                  disabled={isLabeler}
                  fullWidth
                  required
                  value={datas.firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item lg={6}>
                <UiInput
                  type="text"
                  name="lastName"
                  label="Nom"
                  disabled={isLabeler}
                  fullWidth
                  required
                  value={datas.lastName}
                  onChange={handleChange}
                />
              </Grid>
              {user &&
                user.authorities &&
                user.authorities[0] !== "ROLE_ADMIN" && (
                  <Grid item lg={12} className={classes.projects}>
                    {projects.length > 0 && (
                      <UISelectMultipleProjects
                        label="Projets"
                        handleChange={handleChange}
                        datas={user}
                        projects={projects}
                        disabled={true}
                      />
                    )}
                  </Grid>
                )}
              <Grid item lg={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.button}
                >
                  <Grid item lg={3}>
                    <UiButton
                      to="/batches/0?sort=createdDate,desc"
                      color="primary"
                      component={Link}
                    >
                      Annuler
                    </UiButton>
                  </Grid>
                  <Grid item lg={3}>
                    <UiButton
                      color="secondary"
                      variant="contained"
                      disabled={isLabeler || !isDirty}
                      type="submit"
                      fullWidth
                    >
                      Valider
                    </UiButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(User));
