import React, { useState, useEffect } from "react";
import Project from "./component";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import withContext from "../../tools/withContext";
import ContextType from "../../types/Context";
import UserType from "../../types/User";
import ProjectType from "../../types/Project";

interface ProjectProps {
  ctx: ContextType;
  match: any;
}

const ProjectContainer = (props: ProjectProps) => {
  const {
    ctx: { appFetch },
    match: {
      params: { id }
    }
  } = props;
  const {
    PROJECTS_PATH,
    USER_PROJECTS_PATH,
    LIST_CLIENT_PATH,
    LIST_ES_INDICES,
    USERS_PATH,
    TAG_LIST
  } = constants.paths;
  const [project, setProject] = useState<any>({});
  const [clients, setClients] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [indices, setIndices] = useState<any[]>([]);
  const [tagLists, setTagLists] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");

  const fetchDatas = async () => {
    // on récupère les tagLists

    const datas2 = await appFetch(`${TAG_LIST}`);
    setTagLists(datas2);

    if (!id) {
      setProject({
        name: "",
        bucket: "",
        labelingTypes: [
          {
            name: "RECTANGLE_BOXING",
            tags: []
          }
        ]
      });
      return;
    }

    setIsLoading(true);
    try {
      setIsError(false);

      // on récupère les projects
      const datas = await appFetch(`${PROJECTS_PATH}/${id}`);

      setProject(datas);
      setIsLoading(false);
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);

      setProject([]);
    }
  };

  const loadClients = async () => {
    setIsLoading(true);
    try {
      setIsError(false);

      // on récupère les projects
      const datas = await appFetch(LIST_CLIENT_PATH);
      setClients(datas);
      setIsLoading(false);
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const loadUsers = async () => {
    setIsLoading(true);
    try {
      setIsError(false);

      // on récupère les projects
      const datas = await appFetch(
        `${USERS_PATH}?size=1000&sort=filterFields.lowerLastName,asc`
      );
      setUsers(datas.content);
      setIsLoading(false);
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const saveUser = async (u: UserType) => {
    setIsLoading(true);
    try {
      setIsError(false);
      const body = JSON.stringify({ ...u, login: u.email });
      await appFetch(constants.paths.USERS_PATH, {
        method: "PUT",
        body
      });
      setIsLoading(false);
      setIsError(false);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
    }
  };

  const checkProjectName = async (nameToCheck: string) => {
    const projects = await appFetch(
      `${USER_PROJECTS_PATH}?size=1&searchTerm=${nameToCheck}`
    );

    const exact = projects.content.reduce(
      (m: any, p: ProjectType) => m || p.name === nameToCheck,
      false
    );
    if (exact) {
      throw new Error();
    }
  };

  const loadIndices = async () => {
    setIsLoading(true);
    try {
      setIsError(false);

      // on récupère les projects
      const datas = await appFetch(LIST_ES_INDICES);
      setIndices(datas);
      setIsLoading(false);
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);

      setIndices([]);
    }
  };

  const saveProject = async (project: any) => {
    setIsLoading(true);

    try {
      setIsError(false);
      const {
        name,
        admins: adminIds,
        clientId,
        environment: esEnvironment,
        indice: esIndice,
        dataSource,
        labelingTypes,
        tagListId
      } = project;

      await appFetch(PROJECTS_PATH, {
        method: id ? "PUT" : "POST",
        body: JSON.stringify({
          id,
          name,
          clientId,
          adminIds,
          dataSource,
          tagListId,
          labelingTypes,
          esSourceConf:
            dataSource === "S3"
              ? null
              : {
                  esEnvironment,
                  esIndice,
                  esType: "classify"
                }
        }),
        headers: { "Content-Type": "application/json" }
      });

      setIsLoading(false);
      setMessage(`Project ${id ? "modifié" : "créé"}`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchDatas();
    loadIndices();
    loadClients();
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      <Project
        saveProject={saveProject}
        checkProjectName={checkProjectName}
        saveUser={saveUser}
        project={project}
        clients={clients}
        indices={indices}
        users={users}
        loading={isLoading}
        tagLists={tagLists}
        projectId={id}
      />
    </React.Fragment>
  );
};

export default withContext(ProjectContainer);
