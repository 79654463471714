import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      listStyleType: "none",
      padding: `0 ${theme.spacing.unit * 2}px`,
      marginTop: 0,
      "& span": {
        fontWeight: 500
      }
    },
    bold: {
      fontWeight: 500
    }
  });

interface PropsTypes {
  keyMapping: any;
  classes: any;
}

const DisplayKeyMapping = (props: PropsTypes) => {
  const { classes, keyMapping } = props;
  return (
    <ul className={classes.root}>
      {Object.keys(keyMapping).map((k: string, idx: number) => (
        <li key={idx}>
          <Typography
            color="primary"
            component="span"
            inline
            className={classes.bold}
          >
            {k}
          </Typography>{" "}
          : {keyMapping[k]}
        </li>
      ))}
    </ul>
  );
};

export default withStyles(styles)(DisplayKeyMapping);
