import * as React from "react";
import { withRouter, Prompt } from "react-router-dom";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import shortid from "shortid";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { fabric } from "fabric";
import { isEqual } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CanvasButtons from "../common/CanvasButtons";
import Progression from "../common/Progression";
import Labels from "../common/Labels";
import LabelsFromList from "../common/LabelsFromList";
import AlertDialog from "../common/AlertDialog";
// import valuesToInt from "../../tools/valuesToInt";
// import valuesToString from "../../tools/valuesToString";
import DrawerPhotoList from "../common/DrawerPhotoList";
import SkipDialog from "../common/SkipDialog";
import DrawerButton from "../common/DrawerButton";
import LabelisationAbstract from "../common/LabelisationAbstract";
import DisplayKeyMapping from "../common/DisplayKeyMapping";
import fitInBox, { size } from "../../tools/fitInBox";
import constants from "../../tools/constants";
import UpdateImagesDialog from "./components/ImportJsonDialog";
import Superpositions from "./components/Superpositions";
import ImageCache from "../common/ImageCache";

interface IState {
  isDown: boolean;
  origX: any;
  origY: any;
  width: number;
  height: number;
  direction: string;
  creating: boolean;
  labelSelectedIndex: number;
  submitting: boolean;
  justSaved: boolean;
  skipDialogOpen: boolean;
  dirty: boolean;
  listImagesDrawerOpen: boolean;
  importJsonDialogOpen: boolean;
  error: any;
  keepLabelsOnNextImage: boolean;
  someBoxesHidden: boolean;
  majDown: boolean;
  showHelp: boolean;
  superpositions: any;
  imageLoaded: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      paddingTop: 50,
    },
    canvasContainer: {
      backgroundColor: "#edf2ff",
      margin: "0 auto",
      width: "100%",
      height: "100%",
    },
    motifButton: {
      textAlign: "center",
      marginTop: theme.spacing.unit * 2,
    },
    padded: {
      padding: 5,
    },
    fullWidth: {
      width: "100%",
    },
    centered: {
      textAlign: "center",
    },
    grow: {
      flexGrow: 1,
    },
    leftCol: {
      padding: 4,
      paddingBottom: "2em",
    },
    fullHeight: {
      height: "100%",
    },
    paddedLeft: {
      paddingLeft: "1em",
    },
    clearSkip: {
      padding: 5,
      border: "none",
      backgroundColor: "transparent",
    },
  });

const i18nMapping: any = {
  NOTHING_TO_SELECT: "Rien à sélectionner",
  UNREADABLE: "Image floue",
  CAMERA_FLIPPED: "Caméra tournée",
};

const DISTANCE_MIN_BOX = 3;
const SAVED_FEEDBACK_DURATION = 1500;
const CORNER_STYLES: any = {
  cornerColor: "rgba(255,255,255,0.8)",
  cornerStyle: "rect",
  cornerStrokeColor: "gray",
  transparentCorners: false,
};
const RECTANGLE_SELECTION_OPACITY = 0.3;

class Labeler extends React.Component<any, IState> {
  private canvasRef = React.createRef<HTMLCanvasElement>();
  private canvasWrapperRef = React.createRef<HTMLDivElement>();
  private canvas: any;
  private titleBox: any;
  private image: any;
  private scaling: any;
  private rectangleSelection: any;
  private delayedCallbackMove: any;
  private keyMapping: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isDown: false,
      origX: 0,
      origY: 0,
      width: 0,
      height: 0,
      direction: "",
      creating: false,
      labelSelectedIndex: 0,
      submitting: false,
      justSaved: false,
      dirty: false,
      skipDialogOpen: false,
      listImagesDrawerOpen: false,
      error: null,
      importJsonDialogOpen: false,
      keepLabelsOnNextImage: false,
      someBoxesHidden: false,
      majDown: false,
      showHelp: false,
      superpositions: [],
      imageLoaded: false,
    };

    this.scaling = {
      y: 1,
      x: 1,
      newWidth: props.width,
      newHeight: props.height,
    };

    this.keyMapping = {
      i: "Sauvegarde et copie les boxes sur l'image suivante",
      "Suppr ou d": "Supprime la box sélectionnée",
      w: "Supprime toutes les boxes",
      h: "Cacher/Afficher les boxes",
      c: "Copier la box",
      Entrée: "Sauvegarde",
      flèches: "Déplace la box active",
      "Ctrl + flèches": "Image suivante/précédente",
      "Maj + flèches": "Modifie la taille de la box active",
      "Maj + Déplacement souris": "se déplacer dans l'image zoomée",
      Esc: "Dé-zoom et recentre l'image",
    };
  }

  componentDidMount = () => {
    if (this.canvasRef.current !== null) {
      //@ts-ignore
      this.configureFabricJSCanvas(this.canvasRef.current).then(
        (canvas: any) => {
          this.canvas = canvas;
          this.bindEvents();
          if (
            this.props.match.params.noImage !== `${this.props.images.length}`
          ) {
            this.loadImage();
          }
        }
      );
    }
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (this.props.match.params.noImage !== nextProps.match.params.noImage) {
      // on efface l'image en cours, sauf si c'est terminé
      if (nextProps.match.params.noImage !== `${nextProps.images.length}`) {
        this.clearAllByType("rect");
        this.clearAllByType("text");
        this.canvas.remove(this.image);
        this.image = null;
      }
    }

    if (!isEqual(this.props.labels, nextProps.labels)) {
      this.updateBoxesVisibility(nextProps.labels);
    }

    if (!isEqual(this.props.keyEvent, nextProps.keyEvent)) {
      if (nextProps.keyEvent.type === "keyup") {
        this.handleKeyUp(nextProps.keyEvent);
      } else {
        this.handleKeyDown(nextProps.keyEvent);
      }
    }

    const thisPropsImg = this.props.images[
      Number(this.props.match.params.noImage)
    ];
    const nextPropsImg =
      nextProps.images[Number(nextProps.match.params.noImage)];
    if (
      thisPropsImg &&
      nextPropsImg &&
      thisPropsImg.id === nextPropsImg.id &&
      ((thisPropsImg.motif === null && nextPropsImg.motif !== null) ||
        // modification d'un motif existant
        (thisPropsImg.motif !== null &&
          nextPropsImg.motif !== null &&
          thisPropsImg.motif !== nextPropsImg.motif))
    ) {
      console.log("motif changed");
      setTimeout(() => {
        this.handleSave();
      }, 250);
    }
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    // console.log('image :', this.props.match.params.noImage);
    // console.log(
    //   "testMotif",
    //   prevState.motifSkip,
    //   this.state.motifSkip,
    //   (prevState.motifSkip === "" &&
    //     this.state.motifSkip !== "") ||
    //     prevState.motifSkip !== this.state.motifSkip
    // );
    if (
      Number(prevProps.match.params.noImage) !==
      Number(this.props.match.params.noImage)
    ) {
      this.setState({ submitting: false });
      if (this.props.match.params.noImage !== `${this.props.images.length}`) {
        this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        this.canvas.zoomToPoint({ x: 0, y: 0 }, 1);
        this.loadImage();
      }
      // }
      // else if (
      //   (prevState.motifSkip === "" && this.state.motifSkip !== "") ||
      //   prevState.motifSkip !== this.state.motifSkip
      // ) {
      //   // le motif a été validé, lancer la sauvegarde
      //   // console.log()

      //     if (
      //       prevState.isDown === this.state.isDown ||
      //       (this.state.isDown === false &&
      //         prevState.motifSkip === "" &&
      //         this.state.motifSkip !== "")
      //     ) {
      //       console.log("motifsave", this.state.justSaved);
      //       this.handleSave();
      //       setTimeout(() => {
      //         console.log('correction');
      //         this.setState({ submitting: false });
      //       }, 500);
      //     }
    } else if (prevState.labelSelectedIndex !== this.state.labelSelectedIndex) {
      // la couleur a été changée par le biais des boutons
      const activeObject = this.canvas.getActiveObject();
      if (activeObject && this.props.labels[this.state.labelSelectedIndex]) {
        console.log(
          "modif object actif en " +
            this.props.labels[this.state.labelSelectedIndex].legend
        );
        activeObject.set(
          "stroke",
          this.props.labels[this.state.labelSelectedIndex].color
        );
        activeObject.set(
          "fill",
          fade(
            this.props.labels[this.state.labelSelectedIndex].color,
            constants.BOX_OPACITY
          )
        );
        this.canvas.renderAll();
        this.setState({ dirty: true });
      }
    } else if (!prevState.submitting && this.state.submitting) {
      this.handleSubmit();
    } else if (!prevState.justSaved && this.state.justSaved) {
      setTimeout(
        () => this.setState({ justSaved: false }),
        SAVED_FEEDBACK_DURATION
      );
    }

    if (prevState.submitting !== this.state.submitting) {
      console.log("submitting", this.state.submitting);
    }
  };

  configureFabricJSCanvas = (ref: HTMLCanvasElement) =>
    new Promise((resolve: any) => {
      const canvas = new fabric.Canvas(ref, { imageSmoothingEnabled: false });
      const { width, height } = this.props;
      setTimeout(() => {
        //@ts-ignore
        const { offsetHeight, offsetWidth } = this.canvasWrapperRef.current;
        const taille: size = fitInBox(
          width,
          height,
          offsetWidth,
          offsetHeight,
          true
        );

        const { height: newHeight, width: newWidth } = taille;
        canvas.setWidth(newWidth);
        canvas.setHeight(newHeight);
        // canvas.uniScaleTransform = true;
        canvas.selection = false;
        canvas.defaultCursor = "pointer";

        this.scaling = {
          x: newWidth / width,
          y: newHeight / height,
          newHeight,
          newWidth,
        };
        resolve(canvas);
      }, 50);
    });

  bindEvents = () => {
    this.canvas.on("mouse:down", this.handleMouseDown);
    this.canvas.on("mouse:up", this.handleMouseUp);
    this.canvas.on("mouse:move", this.handleMouseMove);
    this.canvas.on("mouse:wheel", this.handleMouseWheel);
    this.canvas.on("mouse:over", this.handleMouseOver);
    this.canvas.on("mouse:dblclick", this.handleDoubleClick);

    this.canvas.on("object:added", this.handleObjectAdded);
    this.canvas.on("object:moved", this.handleObjectMoved);
    this.canvas.on("object:moving", this.handleObjectMoving);
    this.canvas.on("object:scaled", this.handleObjectScaled);
    this.canvas.on("object:scaling", this.updateScaling);

    this.canvas.on("selection:created", this.handleSelectionCreated);
    this.canvas.on("selection:updated", this.handleSelectionCreated);
    this.canvas.on("selection:cleared", this.handleSelectionCleared);

    this.canvas.on("object:moving", this.delayedCallbackMove);
  };

  handleKeyDown = (e: any) => {
    if (e.key === "Shift") {
      this.setState({ majDown: true });
      this.canvas.setCursor("move");
    }

    if (["Delete", "d", "D"].includes(e.key) && this.isOneBoxSelected()) {
      this.setState({ dirty: true });
      this.clearBoxTitle();
      this.handleRemoveActiveObject();
    }

    if (e.key.toUpperCase() === "W") {
      this.setState({ dirty: true });
      this.clearAllByType("rect");
      this.clearBoxTitle();
    }

    // sélection du motif par défaut on Enter
    // désactivée quand on choisit le tag depuis une liste
    if (
      e.key === "Enter" &&
      !this.props.tagsFromList &&
      this.state.imageLoaded
    ) {
      const {
        images,
        match: {
          params: { noImage },
        },
      } = this.props;
      const image = images[Number(noImage)];

      if (this.countRects() === 0 && image && image.motif === null) {
        this.changeMotif("UNREADABLE");
      } else {
        if (this.state.dirty) {
          console.log("enter save");
          this.saveLabels();
        } else if (this.isNavigationFwdPossible()) {
          this.handleNextImage();
        }
      }
    }

    if (e.key.toUpperCase() === "I") {
      if (this.countRects() > 0) {
        this.setState({ keepLabelsOnNextImage: true });
        setTimeout(() => this.handleSave(), 100);
      }
    }

    if (e.key === "c") {
      this.copyActiveBox();
    }

    if (e.key === "+" && this.props.userRole === "ROLE_ADMIN") {
      this.handleOpenJsonImportDialog();
    }

    // diminuer le pas de déplacement quand le zoom est fort
    let step = 2;
    if (e.key.indexOf("Arrow") !== -1) {
      let zoom = this.canvas.getZoom();
      step = 2 / zoom;
    }

    if (e.key === "ArrowDown") {
      const activeObject = this.canvas.getActiveObject();
      if (!activeObject) {
        return;
      }
      if (e.shiftKey) {
        activeObject.set({
          height: activeObject.height + step,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      } else {
        activeObject.set({
          top: activeObject.top + step,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      }

      this.canvas.renderAll();
    }

    if (e.key === "ArrowUp") {
      const activeObject = this.canvas.getActiveObject();
      if (!activeObject) {
        return;
      }
      if (e.shiftKey) {
        activeObject.set({
          height: activeObject.height - step,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      } else {
        activeObject.set({
          top: activeObject.top - step,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      }

      this.canvas.renderAll();
    }

    if (e.key === "ArrowLeft") {
      const activeObject = this.canvas.getActiveObject();

      if (e.ctrlKey || e.altKey) {
        if (Number(this.props.match.params.noImage) > 0) {
          this.handlePreviousImage();
        }
        return;
      }

      if (!activeObject) return;

      if (e.shiftKey) {
        activeObject.set({
          width: activeObject.width - step,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      } else {
        activeObject.set({
          left: activeObject.left - step,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      }

      this.canvas.renderAll();
    }

    if (e.key === "ArrowRight") {
      const activeObject = this.canvas.getActiveObject();

      if (e.ctrlKey || e.altKey) {
        if (this.isNavigationFwdPossible()) {
          this.handleNextImage();
        }
        return;
      }

      if (!activeObject) return;

      if (e.shiftKey) {
        activeObject.set({
          width: activeObject.width + step,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      } else {
        activeObject.set({
          left: activeObject.left + step,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      }

      this.canvas.renderAll();
    }

    if (e.key.toUpperCase() === "H") {
      this.toggleAllBoxesVisiblity();
    }

    if (e.key === "Tab") {
      if (e.shiftKey) {
        this.activatePrevBox();
      } else {
        this.activateNextBox();
      }
    }
  };

  handleKeyUp = (e: any) => {
    if (e.key === "Escape") {
      this.canvas.setCursor("pointer");
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      this.setState({ origX: null, origY: null });
    }

    if (e.key === "Shift") {
      this.canvas.setCursor("pointer");
      this.setState({ majDown: false, origX: null, origY: null });
    }
  };

  handleMouseOver = (o: any) => {
    if (o.target && o.target.type === "image") {
      this.canvas.setCursor("default");
    }
  };

  handleMouseDown = (o: any) => {
    this.clearBoxTitle();
    const { x, y } = this.canvas.getPointer(o.e);
    this.setState({ isDown: true, origX: x, origY: y });
  };

  handleMouseUp = (o: any) => {
    const {
      creating,
      origX,
      origY,
      width,
      height,
      direction,
      labelSelectedIndex,
      majDown,
    } = this.state;

    let rectangle: any;
    let dirty = this.state.dirty;

    if (majDown) {
      return;
    }

    if (this.isOneBoxSelected()) {
      //@TODO Encore utile ?

      // si une box est inclue dans la box sélectionnée
      // et si cette box est sous le pointer, l'activer
      const containedBox = this.getContainedBoxUnderPointer(
        this.canvas.getActiveObject()
      );
      if (containedBox) {
        this.canvas.setActiveObject(containedBox);
        this.handleSelectionCreated();
      }
    } else if (creating) {
      let corner = { left: origX, top: origY };
      if (direction === "bhd") corner.top = origY - height;
      if (direction === "hbg") corner.left = origX - width;
      if (direction === "bhg") {
        corner.left = origX - width;
        corner.top = origY - height;
      }
      const stroke = this.props.labels[labelSelectedIndex].color;
      rectangle = new fabric.Rect({
        ...corner,
        width,
        height,
        hasRotatingPoint: false,
        strokeWidth: 1,
        strokeUniform: true,
        stroke,
        selectable: true,
        originX: "left",
        originY: "top",
        fill: fade(stroke, constants.BOX_OPACITY),
        ...CORNER_STYLES,
      });
      //@ts-ignore
      rectangle.set("id", shortid());
      this.canvas.add(rectangle);
      this.canvas.remove(this.rectangleSelection);
      this.rectangleSelection = null;
      dirty = true; // image modifiée non sauvegardée
    }
    this.setState({
      isDown: false,
      origX: null,
      origY: null,
      creating: false,
      dirty,
    });
  };

  updateScaling = (o: any) => {
    // const obj = o.target;
    // obj.strokeWidth = 1; // / ((obj.scaleX + obj.scaleY) / 2);
    // const activeObject = this.canvas.getActiveObject();
    // activeObject.set("strokeWidth", obj.strokeWidth);
  };

  handleMouseMove = (e: any) => {
    const { isDown, origX, origY, majDown } = this.state;
    if (this.isOneBoxSelected()) return;
    let { x, y } = this.canvas.getPointer(e.e);

    // touche Shift enfoncée + mouvement souris => panning
    if (majDown && e && e.e) {
      const { tl, tr, br } = this.canvas.calcViewportBoundaries();
      let dplX;
      let dplY;

      if (e.e.movementX > 0) {
        // vers la droite
        dplX = tl.x - e.e.movementX > 0 ? e.e.movementX : 0;
      } else {
        // vers la gauche
        dplX = tr.x - e.e.movementX < this.scaling.newWidth ? e.e.movementX : 0;
      }

      if (e.e.movementY > 0) {
        // vers le bas
        dplY = tl.y - e.e.movementY > 0 ? e.e.movementY : 0;
      } else {
        // vers le haut
        dplY =
          br.y - e.e.movementY < this.scaling.newHeight ? e.e.movementY : 0;
      }

      var delta = new fabric.Point(dplX, dplY);
      this.canvas.relativePan(delta);
      return;
    }

    if (isDown && origX) {
      const width = Math.abs(
        origX -
          (x < this.scaling.newWidth ? (x > 0 ? x : 0) : this.scaling.newWidth)
      );
      let height = Math.abs(
        origY -
          (y < this.scaling.newHeight
            ? y > 0
              ? y
              : 0
            : this.scaling.newHeight)
      );

      if (width > DISTANCE_MIN_BOX && height > DISTANCE_MIN_BOX) {
        let corner = { left: origX, top: origY };
        let direction = "hbd";

        if (origX < x && origY > y) {
          corner = { left: origX, top: origY - height };
          direction = "bhd";
        }

        if (origX > x && origY < y) {
          corner = { left: origX - width, top: origY };
          direction = "hbg";
        }

        if (origX > x && origY > y) {
          corner = { left: origX - width, top: origY - height };
          direction = "bhg";
        }

        if (!this.rectangleSelection) {
          this.rectangleSelection = new fabric.Rect({
            ...corner,
            width,
            height,
            hasRotatingPoint: false,
            strokeWidth: 1,
            strokeUniform: true,
            stroke: "white",
            selectable: false,
            originX: "left",
            originY: "top",
            fill: `rgba(255,255,255, ${RECTANGLE_SELECTION_OPACITY})`,
            ...CORNER_STYLES,
          });
          //@ts-ignore
          this.rectangleSelection.set("id", "tmp"); // permet d'exlure le rectangle de sélection
          // de l'algo traitant les boxes superposées
          this.canvas.add(this.rectangleSelection);
        } else {
          const { newWidth, newHeight } = this.scaling;
          this.rectangleSelection.setCoords();
          const w =
            corner.left + width <= newWidth
              ? width
              : this.rectangleSelection.width;
          const h =
            corner.top + height <= newHeight
              ? height
              : this.rectangleSelection.height;
          this.rectangleSelection
            .set("width", w)
            .set("height", h)
            .set("top", corner.top)
            .set("left", corner.left);
        }
        this.setState({ creating: true, width, height, direction });
        this.canvas.renderAll();
      }
    }
  };

  handleMouseWheel = (o: any) => {
    const delta = o.e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom = zoom + delta / 200;

    if (zoom <= 1) {
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    } else {
      if (zoom > 20) zoom = 20;
      this.canvas.zoomToPoint({ x: o.e.offsetX, y: o.e.offsetY }, zoom);
    }

    o.e.preventDefault();
    o.e.stopPropagation();
  };

  // Calculate corner size in function of rectangle dimensions
  calculateCornerSize = (width: number, height: number) => {
    return width < 50 || height < 50 ? 7 : 10;
  };

  handleSelectionCreated = () => {
    const activeObject = this.canvas.getActiveObject();
    let labelSelectedIndex =
      this.props.labels.findIndex(
        (c: any) => c.color === activeObject.stroke
      ) || 0;

    // quand on clique sur la titleBox, labelSelectedIndex n'est pas trouvé
    // la prochaine box crée va planter : pas de couleur liée au label
    if (labelSelectedIndex === -1) {
      return;
    }

    // Set corner size in function of rectangle dimensions
    const cornerSize = this.calculateCornerSize(
      activeObject.width,
      activeObject.height
    );

    activeObject.set({
      cornerSize,
    });

    setTimeout(() => this.drawBoxTitle(1500), 50);
    this.setState({ creating: false, labelSelectedIndex });
  };

  handleObjectMoved = (o: any) => {
    this.setState({
      dirty: true,
      superpositions: this.deleteObjectFromSuperpositions(o.target),
    });
    this.groupBoxesSuperImposed(o.target);
  };

  handleObjectMoving = (e: any) => {
    const { left, top, height, width, scaleX, scaleY } = e.target;
    this.restrictMove(e, { left, top, height, width, scaleX, scaleY });
  };

  restrictMove = (e: any, dimensions: any) => {
    console.log("restrict");
    const { left, top, height, width, scaleX, scaleY } = dimensions;
    const { newWidth, newHeight } = this.scaling;
    const rightOver = left + width * scaleX > newWidth;
    const bottomOver = top + height * scaleY > newHeight;

    if (rightOver) {
      e.target.left = newWidth - width * scaleX - 1;
    }

    if (bottomOver) {
      e.target.top = newHeight - height * scaleY - 1;
    }

    if (e.target.left < 0) e.target.left = 0;
    if (e.target.top < 0) e.target.top = 0;
  };

  handleObjectScaled = (o: any) => {
    console.log("scaled");
    const { newWidth, newHeight } = this.scaling;

    if (o.by === "x" || o.by === "equally") {
      const { width, left, scaleX } = o.target;
      const cornerRightX = left + width * scaleX;
      if (cornerRightX > newWidth) {
        const newScaleX = (newWidth - left) / width;
        o.target.scaleX = newScaleX;
      } else if (left < 0) {
        o.target.left = 0;
      }
    }

    if (o.by === "y" || o.by === "equally") {
      const { height, top, scaleY } = o.target;
      const cornerBottomY = top + height * scaleY;
      if (cornerBottomY > newHeight) {
        const newScaleY = (newHeight - top) / height;
        o.target.scaleY = newScaleY;
      } else if (top < 0) {
        o.target.top = 0;
      }
    }

    this.updateObjectControls(o.target);

    this.setState({
      dirty: true,
      superpositions: this.deleteObjectFromSuperpositions(o.target),
    });

    this.groupBoxesSuperImposed(o.target);
    this.canvas.renderAll();
  };

  updateObjectControls = (o: any) => {
    // Set corner size in function of rectangle dimensions
    const width = o.get("width") * o.get("scaleX");
    const height = o.get("height") * o.get("scaleY");
    const cornerSize = this.calculateCornerSize(width, height);

    o.set({ cornerSize });
  };

  handleSelectionCleared = () => {
    this.setState({ creating: false });
  };

  findIndexRectActif = (allRects: [any], activeObject: any): number => {
    const { top: aT, left: aL, height: aH, width: aW } = activeObject;
    return allRects.findIndex(
      ({ top: rT, left: rL, height: rH, width: rW }: any) =>
        isEqual(
          {
            top: Math.round(aT),
            left: Math.round(aL),
            height: Math.round(aH),
            width: Math.round(aW),
          },
          {
            top: Math.round(rT),
            left: Math.round(rL),
            height: Math.round(rH),
            width: Math.round(rW),
          }
        )
    );
  };

  findCanvasObjectToSelect = (rectToFind: any): number => {
    const { top: nT, left: nL, height: nH, width: nW } = rectToFind;
    return this.canvas._objects.findIndex(
      ({ top: oT, left: oL, height: oH, width: oW }: any) => {
        return isEqual(
          {
            top: Math.round(nT),
            left: Math.round(nL),
            height: Math.round(nH),
            width: Math.round(nW),
          },
          {
            top: Math.round(oT),
            left: Math.round(oL),
            height: Math.round(oH),
            width: Math.round(oW),
          }
        );
      }
    );
  };

  activatePrevBox = () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      const rects = this.getAllRects();
      const index = this.findIndexRectActif(rects, activeObject);

      if (index !== -1) {
        // calculer l'index de l'objet suivant
        const indexPrev = index === 0 ? rects.length - 1 : index - 1;
        const objectToSelectIndex = this.findCanvasObjectToSelect(
          rects[indexPrev]
        );

        if (objectToSelectIndex !== -1) {
          this.canvas.setActiveObject(this.canvas.item(objectToSelectIndex));
          this.canvas.renderAll();
        }
      }
    }
  };

  activateNextBox = () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      const rects = this.getAllRects();
      const index = this.findIndexRectActif(rects, activeObject);

      if (index !== -1) {
        // calculer l'index de l'objet suivant
        const indexNext = index === rects.length - 1 ? 0 : index + 1;
        const objectToSelectIndex = this.findCanvasObjectToSelect(
          rects[indexNext]
        );

        if (objectToSelectIndex !== -1) {
          this.canvas.setActiveObject(this.canvas.item(objectToSelectIndex));
          this.canvas.renderAll();
        }
      }
    }
  };

  handleDoubleClick = () => {
    const {
      images,
      match: {
        params: { noImage },
      },
    } = this.props;
    const image = images[Number(noImage)];

    if (this.countRects() > 0 || image.motif) {
      if (this.state.dirty) {
        if (this.countRects() > 0) {
          this.clearSkip();
        }
        this.handleSave();
      } else if (this.isNavigationFwdPossible()) {
        this.handleNextImage();
      }
    }
  };

  // seules les images ont une propriété cacheKey
  // on utilise ce test comme condition de tri
  // pour s'assurer que les premiers index du tableau _objects
  // contienne toutes les images
  handleObjectAdded = (o: any) => {
    if (o.target.get("type") === "rect" && o.target.get("id") !== "tmp") {
      this.groupBoxesSuperImposed(o.target);
    }
    const withCacheKey = this.canvas._objects.filter((o: any) =>
      o.get("cacheKey")
    );
    const withoutCacheKey = []
      .concat(this.canvas._objects.filter((o: any) => !o.get("cacheKey")))
      .sort((o1: any, o2: any) => (o1.isContainedWithinObject(o2) ? 1 : -1));
    this.canvas._objects = withCacheKey.concat(withoutCacheKey);
    this.updateObjectControls(o.target);
    this.canvas.renderAll();
  };

  deleteObjectFromSuperpositions = (o: any) =>
    this.state.superpositions
      .filter(
        // on commence par retirer les arraySup ne contenant que l'objet déplacé
        (arraySup: any[]) => {
          const contient = arraySup.find(
            (rect: any) => rect.get("id") === o.get("id")
          );
          return !contient || (contient && arraySup.length > 1);
        }
      )
      .map((
        arraySup: any[] // ensuite si l'objet déplacé fait partie d'une superpositon
      ) =>
        // on ne garde que les autres objets
        arraySup.filter((rect: any) => rect.get("id") !== o.get("id"))
      );

  keepOneObjectFromSuperposisons = (objectToKeepId: string) => {
    this.higlightingSuperposition(objectToKeepId, false);
    let toRemoveFromCanvas: any[] = [];
    this.setState({
      dirty: true,
      superpositions: this.state.superpositions.map((arraySup: any[]) => {
        let restant = arraySup;
        if (arraySup.find((r: any) => r.get("id") === objectToKeepId)) {
          // c'est cette superposition qu'il faut nettoyer

          // on n'en garde qu'un, celui qui a pour id objectToKeepId
          restant = arraySup.filter(
            (rect: any) => rect.get("id") === objectToKeepId
          );

          // on programme la suppression des autres
          toRemoveFromCanvas = toRemoveFromCanvas.concat(
            arraySup.filter((rect: any) => rect.get("id") !== objectToKeepId)
          );
        }
        return restant;
      }),
    });
    toRemoveFromCanvas.forEach((toD: any) => this.canvas.remove(toD));
    this.canvas.renderAll();
  };

  higlightingSuperposition = (objectId: any, highlight: boolean = true) => {
    const superpositions = this.state.superpositions.filter(
      (arrayRect: any[]) =>
        arrayRect.find((rect: any) => rect.get("id") === objectId)
    );

    this.canvas._objects = this.canvas._objects.map((o: any) => {
      const found = superpositions[0].find(
        (rect: any) => rect.get("id") === o.get("id")
      );
      if (found) {
        if (highlight) {
          o.set("fill", "rgba(255,255,255,0.5)");
          // this.animateSelection(o);
        } else {
          o.set("fill", fade(found.get("stroke"), constants.BOX_OPACITY));
        }
      }
      return o;
    });
    this.canvas.renderAll();
  };

  groupBoxesSuperImposed = (rect: any) => {
    const NEARBY_THRESOLD = 15;
    const isNearBy = (box: any) =>
      Math.abs(rect.get("top") - box.get("top")) <= NEARBY_THRESOLD &&
      Math.abs(rect.get("left") - box.get("left")) <= NEARBY_THRESOLD &&
      Math.abs(rect.get("top") - box.get("top")) <= NEARBY_THRESOLD &&
      rect.get("width") * rect.get("scaleX") -
        box.get("width") * box.get("scaleX") <=
        NEARBY_THRESOLD &&
      rect.get("height") * rect.get("scaleX") -
        box.get("height") * box.get("scaleY") <=
        NEARBY_THRESOLD;

    const boxSuperImposedIdx = this.state.superpositions.findIndex(
      (groupBoxArray: any[]) => groupBoxArray.find(isNearBy)
    );

    if (boxSuperImposedIdx === -1) {
      this.setState({
        superpositions: this.state.superpositions.concat([[rect]]),
      });
      return;
    }

    this.setState({
      superpositions: this.state.superpositions.map(
        (bArray: any, idx: number) =>
          idx === boxSuperImposedIdx ? bArray.concat(rect) : bArray
      ),
    });
  };

  updateSuperpositions = (o: any) => {
    const boxDeletedIsEqual = (rect: any) =>
      Math.abs(rect.get("top") - o.get("top")) === 0 &&
      Math.abs(rect.get("left") - o.get("left")) === 0 &&
      Math.abs(rect.get("top") - o.get("top")) === 0 &&
      rect.get("width") * rect.get("scaleX") -
        o.get("width") * o.get("scaleX") ===
        0 &&
      rect.get("height") * rect.get("scaleX") -
        o.get("height") * o.get("scaleY") ===
        0;

    this.setState({
      superpositions: this.state.superpositions
        .filter(
          (arrayBox: any) =>
            // tout d'abord on garde tous les groupes > 1 (ils seront traités dans le map)
            // et on supprime les tableaux d'1 élément unique === el supprimé
            arrayBox.length > 1 ||
            arrayBox.length !== 0 ||
            !boxDeletedIsEqual(arrayBox[0])
        )
        .map((arrayBox: any) => {
          if (arrayBox.length === 1) return arrayBox;
          const idx = arrayBox.findIndex((box: any) => !boxDeletedIsEqual(box));
          if (idx === -1) return arrayBox;
          return arrayBox.filter((box: any, index: number) => index !== idx);
        }),
    });
  };

  shrink = (o: any) => {
    // const fill = o.get('fill');
    // o.animate('fill', top  + 10, {
    // 	duration,
    // 	onChange: this.canvas.renderAll.bind(this.canvas),
    // 	onComplete: () => {
    // 		o.animate('top', top, {
    // 			duration,
    // 			onChange: this.canvas.renderAll.bind(this.canvas)
    // 		});
    // 	}
    // });
    const top = o.get("top");
    const left = o.get("left");
    const width = o.get("width");
    const height = o.get("height");
    const scaleX = o.get("scaleX");
    const scaleY = o.get("scaleY");

    const duration = 100;
    o.animate("top", top + (width * scaleX) / 2, {
      duration,
      onChange: this.canvas.renderAll.bind(this.canvas),
      onComplete: () => {
        // o.animate('top', top, {
        // 	duration,
        // 	onChange: this.canvas.renderAll.bind(this.canvas)
        // });
      },
    });
    o.animate("left", left + (height * scaleY) / 2, {
      duration,
      onChange: this.canvas.renderAll.bind(this.canvas),
      onComplete: () => {
        // o.animate('left', left, {
        // 	duration,
        // 	onChange: this.canvas.renderAll.bind(this.canvas)
        // });
      },
    });
    o.animate("width", 0, {
      duration,
      onChange: this.canvas.renderAll.bind(this.canvas),
      onComplete: () => {
        // o.animate('width', width, {
        // 	duration,
        // 	onChange: this.canvas.renderAll.bind(this.canvas)
        // });
      },
    });
    o.animate("height", 0, {
      duration,
      onChange: this.canvas.renderAll.bind(this.canvas),
      onComplete: () => {
        // o.animate('height', height, {
        // 	duration,
        // 	onChange: this.canvas.renderAll.bind(this.canvas)
        // });
      },
    });
  };

  copyActiveBox = () => {
    const { newWidth } = this.scaling;
    const margin = 10;

    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) return;

    const x = activeObject.get("left");
    // const y = activeObject.get("top");
    const width = activeObject.get("width") * activeObject.get("scaleX");
    const height = activeObject.get("height") * activeObject.get("scaleY");

    const left =
      x + width * 2 + margin > newWidth
        ? x - margin - width
        : x + width + margin;
    const top = activeObject.get("top");

    const newBox = new fabric.Rect({
      left,
      top,
      width,
      height,
      hasRotatingPoint: false,
      strokeWidth: 1,
      strokeUniform: true,
      stroke: activeObject.get("stroke"),
      selectable: true,
      originX: "left",
      originY: "top",
      fill: activeObject.get("fill"),
      ...CORNER_STYLES,
    });

    //@ts-ignore
    newBox.set("id", shortid());

    this.canvas.add(newBox);
    this.canvas.setActiveObject(newBox);
    this.setState({ dirty: true });
  };

  getContainedBoxUnderPointer = (objContainer: any) => {
    const { x, y } = this.canvas.getPointer();
    return this.canvas
      .getObjects()
      .filter((o: any) => o.type === "rect")
      .find(
        (o: any) =>
          o.isContainedWithinObject(objContainer) &&
          x >= o.left &&
          x <= o.left + o.width &&
          y >= o.top &&
          y <= o.top + o.height
      );
  };

  loadImage = () => {
    const {
      images,
      match: {
        params: { noImage },
      },
    } = this.props;

    const { keepLabelsOnNextImage } = this.state;
    this.setState({ imageLoaded: false });
    //valeurs par défaut
    let labels: any[] = [];
    let url = "/404.png";
    const image = images[Number(noImage)];
    if (image) {
      url = image.url;
      if (!keepLabelsOnNextImage) {
        labels = image.labels.rectangleLabels;
      } else {
        const imagePrecente = images[Number(noImage) - 1];
        if (imagePrecente) {
          labels = imagePrecente.labels.rectangleLabels;
        }
      }
    }

    console.log("loadImage", noImage, labels);
    var img = new Image();

    img.onload = () => {
      fabric.Image.fromURL(img.src, () =>
        this.addImageToCanvas(img, labels, image.lastLabeledBy)
      );
    };

    img.onerror = (ev) => {
      fabric.util.loadImage("/404.png", (img: any) => {
        this.addImageToCanvas(img, []);
      });
    };

    img.src = url;
  };

  addImageToCanvas = (
    img: any,
    labels: object[],
    lastLabeledBy: String = "model"
  ) => {
    const { width, height } = img;
    const { newWidth, newHeight } = this.scaling;

    this.scaling = {
      x: newWidth / width,
      y: newHeight / height,
      newHeight,
      newWidth,
    };

    this.image = new fabric.Image(img, {});
    const { keepLabelsOnNextImage } = this.state;

    this.image.set({
      left: 0,
      top: 0,
      selectable: false,
      lockMovementX: true,
      centeredScaling: true,
      lockMovementY: true,
      hoverCursor: "arrow",
      scaleX: newWidth / width,
      scaleY: newHeight / height,
      originX: "left",
      originY: "top",
      opacity: 1,
    });

    this.canvas.add(this.image);
    this.canvas.renderAll();

    if (labels) {
      this.hydrateRects(labels);
      this.setState((oldstate: any) => ({
        dirty:
          keepLabelsOnNextImage || lastLabeledBy === "model" ? true : false,
        keepLabelsOnNextImage: false,
        labelSelectedIndex: labels.length > 1 ? 0 : oldstate.labelSelectedIndex,
        submitting: false,
        superpositions: [],
        imageLoaded: true,
      }));
    } else {
      this.setState({ superpositions: [], submitting: false });
    }
  };

  hydrateRects = (labels: object[]) => {
    labels.forEach((o: any) => {
      const { top, left, width, height, labelValue } = o;
      const labelSelectedIndex = this.props.labels.findIndex(
        (l: any) => l.legend === labelValue
      );
      const { x, y } = this.scaling;
      const stroke = this.props.labels[labelSelectedIndex].color;

      const box = new fabric.Rect({
        left: left * x,
        top: top * y,
        width: width * x,
        height: height * y,
        hasRotatingPoint: false,
        strokeWidth: 1,
        strokeUniform: true,
        stroke,
        selectable: true,
        originX: "left",
        originY: "top",
        fill: fade(stroke, constants.BOX_OPACITY),
        ...CORNER_STYLES,
      });

      //@ts-ignore
      box.set("id", shortid());
      this.canvas.add(box);
    });
  };

  drawBoxTitle = (timeout: number = 2500) => {
    const { labelSelectedIndex } = this.state;
    const { width: canvasWidth } = this.props;
    const activeObject = this.canvas.getActiveObject();

    if (!activeObject || !this.props.labels[labelSelectedIndex]) return;

    const x = activeObject.get("left");
    const y = activeObject.get("top");
    const width = activeObject.get("width");
    const height = activeObject.get("height");
    const labelText = this.props.labels[labelSelectedIndex].legend;
    const textBoxWidth = labelText.length * 15 + 10;
    let diff = width - textBoxWidth;
    let textBoxLeft = x + width / 2;

    if (diff < 0) {
      diff = -diff;
      if (diff / 2 > x) textBoxLeft = x + diff / 2;
      if (x > canvasWidth - diff / 2) textBoxLeft = x - diff / 2 + width / 2;
    }

    if (this.titleBox) {
      this.clearBoxTitle();
    }

    let zoom = this.canvas.getZoom();
    let top = y > 40 ? y - 25 / zoom : y + height + 25 / zoom;

    const titleBox = new fabric.Rect({
      left: textBoxLeft,
      top: top,
      width: textBoxWidth / zoom,
      height: 35 / zoom,
      hasRotatingPoint: false,
      strokeWidth: 1 / zoom,
      strokeUniform: true,
      stroke: "gray",
      selectable: false,
      originX: "center",
      originY: "center",
      fill: "rgba(255,255,255,0.8)",
      ...CORNER_STYLES,
    });

    const text = new fabric.IText(labelText, {
      left: textBoxLeft,
      top: top,
      height: 35 / zoom,
      width: textBoxWidth / zoom,
      fontSize: 20 / zoom,
      fill: "#000",
      fontFamily: "Roboto",
      strokeWidth: 0.5 / zoom,
      strokeUniform: true,
      stroke: "#000",
      originY: "center",
      originX: "center",
    });

    this.titleBox = new fabric.Group([titleBox, text]);
    this.canvas.add(this.titleBox);
    setTimeout(() => this.clearBoxTitle(), timeout);
  };

  clearBoxTitle = () => {
    if (!this.titleBox) return;
    this.canvas.remove(this.titleBox);
    this.titleBox = null;
  };

  setlabelSelectedIndex = (labelSelectedIndex: number) => {
    this.setState({ labelSelectedIndex });
  };

  handleRemoveActiveObject = () => {
    const activeObject = this.canvas.getActiveObject();

    if (activeObject.get("type") === "rect") {
      const superpositions = this.deleteObjectFromSuperpositions(activeObject);
      this.shrink(activeObject);
      setTimeout(() => {
        this.canvas.remove(activeObject);

        // s'il n'y a plus aucun rectangle, retirer state dirty
        // pour forcer la procédure skip si sauvegarde @TODO vérifier comportement
        if (this.countRects() === 0) {
          this.setState({ dirty: false, superpositions });
          return;
        }
        this.setState({ dirty: true, superpositions });
      }, 150);
    }
  };

  toggleAllRectsVisibilityStatus = (status: boolean = false) => {
    this.canvas
      .getObjects()
      .filter((o: any) => o.get("type") === "rect")
      .map((o: any) => o.set("visible", status));
    return;
  };

  clearAllByType = (type: string) => {
    this.canvas
      .getObjects()
      .filter((o: any) => o.get("type") === type)
      .map((o: any) => this.canvas.remove(o));
    return;
  };

  getAllRects = () => {
    if (!this.canvas) return [];
    const rects = this.canvas
      .getObjects()
      .filter((o: any) => o.type === "rect");
    return rects.length === 0
      ? []
      : rects.map((o: any) => {
          let tag = this.props.labels.find((c: any) => c.color === o.stroke);
          let labelValue = tag ? tag.legend : "err";
          return {
            width: Math.round(o.get("width") * o.get("scaleX")),
            height: Math.round(o.get("height") * o.get("scaleY")),
            left: Math.round(o.get("left")),
            top: Math.round(o.get("top")),
            labelValue,
          };
        });
  };

  countRects = (): number =>
    this.canvas.getObjects().filter((o: any) => o.type === "rect").length;

  updateBoxesVisibility = (labels: any) => {
    this.canvas
      .getObjects()
      .filter((o: any) => o.type === "rect")
      .forEach((r: any) => {
        let tag = labels.find((c: any) => c.color === r.stroke);
        r.set("visible", tag.visible);
      });

    this.canvas.discardActiveObject();
    this.clearBoxTitle();
    this.canvas.renderAll();
  };

  toggleAllBoxesVisiblity = () => {
    const { someBoxesHidden } = this.state;
    this.props.toggleAllBoxesVisibility(someBoxesHidden);
    this.setState({ someBoxesHidden: !someBoxesHidden });
  };

  changeMotif = (motifSkip: string | null, clearLabels: boolean = true) => {
    const { storeMotif } = this.props;

    storeMotif(motifSkip, clearLabels);

    this.setState({
      dirty: true,
      skipDialogOpen: false,
      submitting: false,
    });
  };

  clearSkip = () => {
    const { storeMotif } = this.props;

    storeMotif(null, true);

    this.setState({
      dirty: true,
      skipDialogOpen: false,
    });
  };

  handleCloseSkipDialog = () => this.setState({ skipDialogOpen: false });

  handleOpenSkipDialog = () => this.setState({ skipDialogOpen: true });

  handleSubmit = () => {
    const { dirty, keepLabelsOnNextImage } = this.state;
    const {
      images,
      onSave,
      match: {
        params: { noImage, noBatch },
      },
      history,
    } = this.props;
    const { x, y } = this.scaling;
    const image = images[Number(noImage)];

    // si l'image n'a pas été modifiée et s'il n'y a pas de rectangles, et s'il n'y a pas de motif (déjà skippée) afficher le dialog skip
    const nbrRectangles = this.countRects();

    let labels = this.getAllRects().map((l: any) => {
      return {
        ...l,
        left: l.left / x,
        top: l.top / y,
        width: l.width / x,
        height: l.height / y,
      };
    });

    return new Promise((resolve: any, reject: any) => {
      // s'il n'y a pas eu de modification d'une image
      // labélisée et pas de demande de copie des boxes, on ne sauvegarde pas
      if (
        !dirty &&
        !keepLabelsOnNextImage &&
        (nbrRectangles > 0 || image.motif !== null)
      ) {
        return resolve();
      }

      const datas = {
        ...image,
        labels: {
          ...image.labels,
          rectangleLabels: image.motif !== null ? [] : labels,
          //.map((l: any) => valuesToString(l)),
          country: "",
        },
        motif: image.motif,
        lastLabeledBy: null,
      };

      console.log("save", datas);
      this.toggleAllRectsVisibilityStatus(false);
      this.clearBoxTitle();
      this.clearAllByType("rect");
      this.canvas.remove(this.image);
      this.image = null;

      if (!keepLabelsOnNextImage) {
        // s'il n'y a pas la nécéssité d'attendre que la sauvegarde soit faite
        // passer à l'image suivante
        history.push(`/labeler/${noBatch}/${Number(noImage) + 1}`);
      }
      onSave(datas)
        .then((image: any) => {
          if (keepLabelsOnNextImage) {
            // il fallait attendre que la sauvegarde soit faite pour avoir les labels
            // on passe à l'image suivante
            history.push(`/labeler/${noBatch}/${Number(noImage) + 1}`);
          }
          this.setState({ justSaved: true, submitting: false });
          return resolve(image);
        })
        .then(() => {
          this.setState({ justSaved: false, submitting: false });
          return resolve();
        });
    });
  };

  // nous utilisons cette méthode supplémentaire
  // pour s'assurer qu'une image avec un motif
  // puisse être modifiée en image avec labels
  saveLabels = () => {
    this.changeMotif(null, false);
    setTimeout(() => this.handleSave(), 50);
  };

  handleSave = () => {
    console.log("save");
    if (!this.state.submitting) {
      this.setState({ submitting: true, error: null, justSaved: false });
    }
  };

  handleToggleListeImagesDrawer = () =>
    this.setState({ listImagesDrawerOpen: !this.state.listImagesDrawerOpen });

  handleOpenJsonImportDialog = () =>
    this.setState({ importJsonDialogOpen: true });

  handleCloseJsonImportDialog = () =>
    this.setState({ importJsonDialogOpen: false });

  isNavigationFwdPossible = () => {
    const {
      match: {
        params: { noImage },
      },
      images,
    } = this.props;
    const image = images[Number(noImage)];
    const nextImage = images[Number(noImage) + 1];

    return (
      (image &&
        image.lastLabeledBy !== "model" &&
        ((image.labels && image.labels.rectangleLabels.length > 0) ||
          image.motif) &&
        !this.state.dirty) ||
      (nextImage &&
        nextImage.lastLabeledBy &&
        nextImage.lastLabeledBy !== "model" &&
        ((nextImage.labels && nextImage.labels.rectangleLabels.length > 0) ||
          nextImage.motif))
    );
  };

  closeListImagesDrawer = () => this.setState({ listImagesDrawerOpen: false });

  navigateToLastLabelized = () => {
    const {
      match: {
        params: { noBatch },
      },
      history,
      images,
    } = this.props;
    const indexFirstImageNonLabellisee = images.findIndex(
      (img: any) =>
        (img.labels.rectangleLabels.length === 0 &&
          (img.motif === null || img.motif === "")) ||
        !img.lastLabeledBy ||
        img.lastLabeledBy === "model"
    );

    if (indexFirstImageNonLabellisee !== -1) {
      history.push(`/labeler/${noBatch}/${indexFirstImageNonLabellisee}`);
    }
  };

  handlePreviousImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(`/labeler/${noBatch}/${Number(noImage) - 1}`);
  };

  handleNextImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(`/labeler/${noBatch}/${Number(noImage) + 1}`);
  };

  goToBatchesList = () =>
    this.props.history.push("/batches/0?sort=createdDate,desc");

  handleToggleHelp = () => this.setState({ showHelp: !this.state.showHelp });

  isOneBoxSelected = () =>
    this.canvas &&
    typeof this.canvas.getActiveObject() !== "undefined" &&
    this.canvas.getActiveObject() !== null;

  public render() {
    const {
      classes,
      match: {
        params: { noImage, noBatch },
      },
      motifs,
      nomBatch,
      images,
      onSave,
      saveLabel,
      changeVisibility,
      tagsFromList,
      labels,
    } = this.props;

    const {
      skipDialogOpen,
      labelSelectedIndex,
      dirty,
      listImagesDrawerOpen,
      importJsonDialogOpen,
      someBoxesHidden,
      showHelp,
      superpositions,
      justSaved,
      submitting,
      imageLoaded,
    } = this.state;

    const imagesWithLabelOrMotif = images.filter(
      (img: any) =>
        (img.labels.rectangleLabels.length > 0 ||
          (img.motif !== null && img.motif !== "")) &&
        img.lastLabeledBy !== "model"
    );
    const nombreRectangles = this.canvas ? this.countRects() : 0;
    const nbrLabelisees = imagesWithLabelOrMotif.length;
    const image = images[Number(noImage)];

    const out = Number(noImage) >= images.length;
    const finished = nbrLabelisees === images.length;
    const textAlert = !finished
      ? "Au moins image n'a pas été labellisée"
      : "Labellisation de ce lot terminée !";
    const titreAlert = !finished ? "Labellisation incomplète" : "C'est fini !";
    let alertOnClickHandler =
      out && !finished
        ? () => this.navigateToLastLabelized()
        : () => this.goToBatchesList();

    return (
      <Grid
        container
        spacing={16}
        direction="row"
        justify="flex-start"
        className={classes.root}
      >
        <Helmet>
          <title>
            Boxing / {nomBatch.split("-")[0]} / {noImage}
          </title>
        </Helmet>
        {listImagesDrawerOpen && (
          <DrawerPhotoList
            open={listImagesDrawerOpen}
            images={imagesWithLabelOrMotif}
            noBatch={noBatch}
            noImage={noImage}
            labelingTypePathname="labeler"
          />
        )}
        {imagesWithLabelOrMotif.length > 1 && (
          <DrawerButton
            onClick={this.handleToggleListeImagesDrawer}
            drawerOpen={listImagesDrawerOpen}
          />
        )}
        {Number(noImage) >= images.length && (
          <ImageCache images={images} noImageToCache={Number(noImage) + 1} />
        )}
        <Prompt
          when={dirty && !submitting && !out}
          message="Des modifications n'ont pas été sauvegardées. Continuer ?"
        />
        <SkipDialog
          open={skipDialogOpen}
          motifSelected={image && image.motif}
          onAccept={this.changeMotif}
          onClose={this.handleCloseSkipDialog}
          motifs={motifs}
          i18nMapping={i18nMapping}
          defaultMotifIndex={1}
        />
        <UpdateImagesDialog
          open={importJsonDialogOpen}
          saveImage={onSave}
          onClose={this.handleCloseJsonImportDialog}
        />
        <AlertDialog
          open={out}
          onClose={alertOnClickHandler}
          text={textAlert}
          title={titreAlert}
          onAccept={alertOnClickHandler}
          acceptLabel="OK"
        />
        <Grid item xs={8} xl={9} className={classes.grow}>
          <Grid
            container
            direction="column"
            alignContent="stretch"
            alignItems="stretch"
            justify="flex-start"
            component={Paper}
            className={classNames(classes.leftCol, classes.fullHeight)}
          >
            <Grid item className={classes.padded}>
              <LabelisationAbstract
                noImage={noImage}
                nomBatch={nomBatch}
                images={images}
                dirty={dirty}
                justSaved={justSaved}
              />
            </Grid>
            <Grid item className={classNames(classes.padded, classes.grow)}>
              <div
                className={classes.canvasContainer}
                ref={this.canvasWrapperRef}
              >
                <canvas ref={this.canvasRef} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} xl={3} className={classes.grow}>
          <Grid
            container
            component={Paper}
            direction="column"
            justify="space-between"
            alignItems="stretch"
            className={classes.fullHeight}
          >
            <Grid item className={classes.fullWidth}>
              <Grid container direction="column" spacing={16}>
                {image && (
                  <Grid item>
                    <Progression
                      total={images.length}
                      nbrLabelisees={nbrLabelisees}
                      onHelpClicked={this.handleToggleHelp}
                      showHelp={showHelp}
                    />
                  </Grid>
                )}
                <Grid item>
                  {!showHelp && !tagsFromList && (
                    <Labels
                      labels={labels.map((l: any) => ({
                        ...l,
                        occurences: this.getAllRects().filter(
                          (p: any) => p.labelValue === l.legend
                        ).length,
                      }))}
                      onClick={this.setlabelSelectedIndex}
                      onVisibilityClick={changeVisibility}
                      selected={
                        image && (!image.motif || nombreRectangles > 0)
                          ? labelSelectedIndex
                          : null
                      }
                    />
                  )}
                  {tagsFromList && (
                    <LabelsFromList
                      labels={labels}
                      images={images}
                      saveLabel={saveLabel}
                      onSelect={this.setlabelSelectedIndex}
                      selected={
                        image && (!image.motif || nombreRectangles > 0)
                          ? labelSelectedIndex
                          : null
                      }
                      frequentLabels={images
                        .filter((i: any) => i.labels.rectangleLabels.length > 0)
                        .reduce(
                          (memo: any, i: any) =>
                            memo.concat(
                              i.labels.rectangleLabels.map(
                                (l: any) => l.labelValue
                              )
                            ),
                          []
                        )
                        .reduce(
                          (memo: any, i: any) => ({
                            ...memo,
                            [i]: memo[i] ? memo[i] + 1 : 1,
                          }),
                          {}
                        )}
                      frequentLabelsNumber={8}
                    />
                  )}
                  {showHelp && (
                    <DisplayKeyMapping keyMapping={this.keyMapping} />
                  )}
                </Grid>
                {!showHelp &&
                  superpositions.find((arrBox: any[]) => arrBox.length > 1) && (
                    <Superpositions
                      superpositions={superpositions}
                      onClear={this.keepOneObjectFromSuperposisons}
                      higlighting={this.higlightingSuperposition}
                    />
                  )}
              </Grid>
            </Grid>
            {image && image.motif && (
              <Grid
                item
                className={classNames(classes.centered, classes.fullWidth)}
              >
                <span>
                  Image passée : <strong>{i18nMapping[image.motif]}</strong>
                </span>
                <button className={classes.clearSkip} onClick={this.clearSkip}>
                  x
                </button>
              </Grid>
            )}
            <Grid item className={classes.fullWidth}>
              <Grid container direction="column">
                <Grid item className={classes.paddedLeft}>
                  <Typography>Actions :</Typography>
                </Grid>
                {image && !(finished && out) && (
                  <Grid item>
                    <CanvasButtons
                      onDeleteClicked={this.handleRemoveActiveObject}
                      onSaveClicked={this.saveLabels}
                      onSkipClicked={this.handleOpenSkipDialog}
                      onNavigateBackClicked={this.handlePreviousImage}
                      onNavigateFwdClicked={this.handleNextImage}
                      selected={this.isOneBoxSelected()}
                      navigateBackVisible={imageLoaded && Number(noImage) > 0}
                      navigateFwdVisible={
                        imageLoaded && this.isNavigationFwdPossible()
                      }
                      someItemsHidden={someBoxesHidden}
                      nombreItems={nombreRectangles}
                      hiddenableShapeName="les rectangles ( H )"
                      savingDisabled={
                        nombreRectangles === 0 ||
                        !dirty ||
                        (false &&
                          superpositions.find(
                            (arraySup: any[]) => arraySup.length > 1
                          ))
                      }
                      onToggleVisibility={this.toggleAllBoxesVisiblity}
                      motif={image.motif}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(Labeler));
