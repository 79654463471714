import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import UiProgress from "../../common/UiProgess";

const styles = (theme: Theme) =>
  createStyles({
    labels: {
      marginTop: theme.spacing.unit,
      padding: `0 ${theme.spacing.unit * 2}px`
    },
    sectionTitle: {
      textAlign: "center"
    },
  });

const ProgressDelete = (props: any) => {
  const { total, nbreBatchesSupprimes, classes } = props;
  return (
    <Grid
      container
      direction="column"
      spacing={8}
      alignItems={"stretch"}
      justify={"flex-start"}
      className={classes.labels}
    >
      <Grid item>
        <UiProgress
          variant="determinate"
          color="secondary"
          value={(nbreBatchesSupprimes * 100) / total}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ProgressDelete);
