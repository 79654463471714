import React, { useState, useEffect } from "react";
import queryString from "query-string";
import Batches from "./component";
import { useHotkeys } from "../../tools/react-hotkeys-hook";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import withContext from "../../tools/withContext";
import BatchType from "../../types/Batch";
import UserType from "../../types/User";
import useRouter from "../../tools/useRouter";
import lowerMapper from "../../tools/lowerMapper";

// interface BatchProps {
//     ctx: ContextType;
//     match: any;
//     location: any;
// }

const BatchContainer = (props: any) => {
  const router = useRouter();
  const {
    ctx: { appFetch },
    match: {
      params: { noPage },
    },
    location: { search },
  } = props;

  const {
    PROJECT_BATCHES_PATH,
    USER_USERS_PATH,
    BATCHES_PATH,
    USER_BATCHES_PATH,
    BATCHES_AFFECTATION_PATH,
    BATCHES_DEADLINE_PATH,
  } = constants.paths;

  const [users, setUsers] = useState<UserType[]>([]);
  const [pagination, setPagination] = useState<any>(null);
  const [batches, setBatches] = useState<BatchType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [keyEvent, setKeyEvent] = useState<any>({ shiftKey: false });

  useHotkeys("*", (e) => {
    if (e.key === "Shift") {
      setKeyEvent({
        shiftKey: e.type === "keydown",
      });
    }
  });

  // on map les batches pour rendre les infos à trier facilement accessibles ( labeler, project )
  const batchMapper = (batch: BatchType) => {
    return {
      ...batch,
      projectName: batch.project.name,
      labelerStr: batch.labeler
        ? `${batch.labeler.lastName} ${batch.labeler.firstName}`.trim()
        : "<non attribué>",
      labelerId: batch.labeler ? batch.labeler.id : undefined,
    };
  };

  const lowerMap: any = {
    name: "lowerName",
    "filterFields.projectName": "filterFields.lowerProjectName",
    "filterFields.labelerFirstName": "filterFields.lowerLabelerFirstName",
  };

  const fetchDatas = async () => {
    setIsLoading(true);
    setBatches([]);
    try {
      setIsError(false);
      // const query = `?page=${noPage}${
      //   searchParsed.searchTerm ? `&searchTerm=${searchParsed.searchTerm}` : ""
      // }${searchParsed.batchIds ? `&batchIds=${searchParsed.batchIds}` : ""}${
      //   searchParsed.projectId ? `&id=${searchParsed.projectId}` : ""
      // }${
      //   searchParsed.sort
      //     ? `&sort=${searchParsed.sort}`
      //     : "&sort=createdDate,asc"
      // }`;

      const BATCHES_PATH =
        search.indexOf("projectId") === -1
          ? USER_BATCHES_PATH
          : PROJECT_BATCHES_PATH;
      const [datas1, datas2] = await Promise.all([
        appFetch(`${USER_USERS_PATH}?size=1000`),
        appFetch(
          `${BATCHES_PATH}?${queryString.stringify({
            ...lowerMapper(router.query, lowerMap),
            page: noPage,
          })}`
        ),
      ]);
      setIsLoading(false);
      setUsers(datas1.content);

      // BATCHES_PATH renvoie le tableau de données dans la propriété content d'un objet
      // mais USER_BATCHES_PATH renvoie directement un tableau
      const {
        content,
        number,
        totalPages,
        first,
        last,
        totalElements,
      } = datas2;
      const arrayDatas: BatchType[] = content || datas2;
      const batchesLoaded = arrayDatas.map((batch: any) => batchMapper(batch));
      setBatches(batchesLoaded);
      setPagination({ number, totalPages, first, last, totalElements });
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);

      setUsers([]);
      setBatches([]);
    }
  };

  const saveBatch = async (batch: BatchType) => {
    setIsLoading(true);

    try {
      setIsError(false);
      const { id, labelerId, name, completed, verified, deadline } = batch;
      const batchS: BatchType = await appFetch(BATCHES_PATH, {
        method: id ? "PUT" : "POST",
        body: JSON.stringify({
          id,
          labelerId,
          name,
          completed,
          verified,
          deadline,
        }),
        headers: { "Content-Type": "application/json" },
      });

      const batchInserted = batchMapper(batchS);
      setBatches(
        id
          ? batches.map((b: any) => (b.id === id ? batchInserted : b))
          : //@ts-ignore
            batches.concat([batchS])
      );
      setIsLoading(false);
      setMessage(`Batch modifié`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const massUpdate = async (payload: any) => {
    const { ids, deadline, labelerId } = payload;
    const isLabelerUpdate = typeof labelerId !== "undefined";
    const body: any = { ids };

    if (isLabelerUpdate) {
      body.labelerId = labelerId;
    } else {
      body.deadline = deadline;
    }

    setIsLoading(true);
    setIsError(false);

    try {
      const batchS: BatchType[] = await appFetch(
        isLabelerUpdate ? BATCHES_AFFECTATION_PATH : BATCHES_DEADLINE_PATH,
        {
          method: "PUT",
          body: JSON.stringify(body),
          headers: { "Content-Type": "application/json" },
        }
      );

      setBatches(
        batches.map((b: any) => {
          if (ids.indexOf(b.id) !== -1) {
            const bUpdt = batchS.find((bS: any) => bS.id === b.id);

            const newBatch = batches.find((bS) => bS.id === b.id);

            if (newBatch && bUpdt) {
              newBatch.projectName = bUpdt.project.name;
              newBatch.filterFields = bUpdt.filterFields;
              newBatch.labelerStr = bUpdt.labeler
                ? `${bUpdt.labeler.lastName} ${bUpdt.labeler.firstName}`.trim()
                : "<non attribué>";
              newBatch.labelerId = bUpdt.labeler ? bUpdt.labeler.id : undefined;
              newBatch.deadline = bUpdt.deadline;

              return newBatch;
            }

            throw new Error("Réponse serveur non conforme");
          }
          return b;
        })
      );
      setIsLoading(false);
      setMessage(`Batch modifié`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const deleteDeadline = async (u: BatchType) => {
    setIsLoading(true);
    try {
      setIsError(false);
      await appFetch(`${BATCHES_PATH}/${u.id}/deadline`, { method: "DELETE" });

      //@ts-ignore
      setBatches(
        batches.map((batch: BatchType) =>
          batch.id !== u.id ? batch : { ...batch, deadline: null }
        )
      );
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsSnackbarOpen(true);
    }
  };

  const deleteBatch = async (u: BatchType) => {
    setIsLoading(true);
    try {
      setIsError(false);
      await appFetch(`${BATCHES_PATH}/${u.id}/status`, { method: "DELETE" });

      //@ts-ignore
      setBatches(batches.filter((usr: any) => usr.id !== u.id));
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsSnackbarOpen(true);
    }
  };

  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noPage, search]);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      <Batches
        saveBatch={saveBatch}
        pagination={pagination}
        massUpdate={massUpdate}
        deleteBatch={deleteBatch}
        deleteDeadline={deleteDeadline}
        isLoading={isLoading}
        batches={batches}
        users={users}
        keyEvent={keyEvent}
      />
    </React.Fragment>
  );
};

export default withContext(BatchContainer);
