import React, { useState, useEffect } from "react";
import queryString from "query-string";
import Projects from "./component";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import withContext from "../../tools/withContext";
import useRouter from "../../tools/useRouter";
import ProjectType from "../../types/Project";
import lowerMapper from "../../tools/lowerMapper";

const lowerMap: any = {
  name: "lowerName",
  clientStr: "filterFields.lowerClientName",
  labelingType: "filterFields.lowerLabelingTypeName",
};

const ProjectsContainer = (props: any) => {
  const router = useRouter();
  const {
    ctx: { appFetch },
    match: {
      params: { noPage },
    },
  } = props;
  const { USER_PROJECTS_PATH, USERS_PATH, PROJECTS_PATH } = constants.paths;
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [pagination, setPagination] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");

  // on map les projects pour rendre les infos à trier facilement accessibles ( labeler, project )
  const projectMapper = (project: ProjectType, users: any[]) => {
    const admins = users.filter(
      (u: any) =>
        u.projects.includes(project.id) &&
        u.authorities[0] === "ROLE_PROJECT_ADMIN"
    );
    return {
      ...project,
      labelingType: project.labelingTypes[0].name,
      clientStr: (
        <span>
          {project.client.name}
          <br />
          {admins.length > 0 ? (
            <small style={{ color: "gray" }}>{` ${admins
              .map((a: any) => a.lastName)
              .join(", ")}`}</small>
          ) : (
            ""
          )}
        </span>
      ),
    };
  };

  const fetchDatas = async () => {
    setIsLoading(true);
    try {
      setIsError(false);

      // on récupère les projects
      const [datas, usersData] = await Promise.all([
        appFetch(
          `${USER_PROJECTS_PATH}?${queryString.stringify({
            ...lowerMapper(router.query, lowerMap),
            page: noPage,
          })}`
        ),
        appFetch(USERS_PATH),
      ]);
      const { content, number, totalPages, first, last, totalElements } = datas;

      const usersWithProject = usersData.content.filter(
        (u: any) => u.projects.length > 0
      );

      setProjects(content.map((p: any) => projectMapper(p, usersWithProject)));

      setIsLoading(false);
      setPagination({ number, totalPages, first, last, totalElements });
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);

      setProjects([]);
    }
  };

  const saveProject = async (project: ProjectType) => {
    setIsLoading(true);

    try {
      setIsError(false);
      const { id } = project;
      const projectS: ProjectType[] = await appFetch(PROJECTS_PATH, {
        method: id ? "PUT" : "POST",
        body: JSON.stringify(project),
        headers: { "Content-Type": "application/json" },
      });

      setProjects(projectS.map((p: any) => (p.id === id ? p : projectS)));
      setIsLoading(false);
      setMessage(`Projet ${project.id ? "modifié" : "créé"}`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const deleteProject = async (u: ProjectType) => {
    setIsLoading(true);
    try {
      setIsError(false);
      await appFetch(`${PROJECTS_PATH}/${u.id}`, { method: "DELETE" });

      //@ts-ignore
      setProjects(projects.filter((usr: any) => usr.id !== u.id));
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsSnackbarOpen(true);
    }
  };

  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noPage, router.query]);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      <Projects
        saveProject={saveProject}
        deleteProject={deleteProject}
        isLoading={isLoading}
        projects={projects}
        pagination={pagination}
        onlyProcessingProjects={
          typeof router.query.onlyProgress !== "undefined"
        }
        appFetch={appFetch}
      />
    </React.Fragment>
  );
};

export default withContext(ProjectsContainer);
