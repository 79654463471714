import React from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const styles = (theme: Theme) =>
  createStyles({
    active: {
      color: theme.palette.secondary.main
    },
    notActive: {
      color: "rgba(0,0,0, 0.45)"
    }
  });

interface PropTypes {
  active: boolean;
  children: any;
  classes: any;
}

const UiTableSortLabel = (props: PropTypes) => {
  const { active, classes } = props;
  return (
    <TableSortLabel
      {...props}
      className={classes[active ? "active" : "notActive"]}
    >
      {props.children}
    </TableSortLabel>
  );
};

export default withStyles(styles)(withTheme()(UiTableSortLabel));
