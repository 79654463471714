import React from "react";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import { withStyles, withTheme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import UiProgress from "../../common/UiProgess";
import UiProgressMixed from "../../common/UiProgessMixed";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "0 50px"
    },
    rootBig: {
      padding: "0 25px"
    },
    caption: {
      textAlign: "center"
    }
  });

interface PropsTypes {
  classes: any;
  theme: Theme;
  totalLabelDocs: number;
  totalLabelDocsByModel: number;
  totalEligibleDocs: number;
}

const ProgressBar = (props: PropsTypes) => {
  const {
    classes,
    theme,
    totalLabelDocs,
    totalLabelDocsByModel,
    totalEligibleDocs
  } = props;
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const onlyByModel = totalLabelDocs === 0 && totalLabelDocsByModel > 0;
  const onlyByUser = totalLabelDocsByModel === 0;
  const modelAndUser = totalLabelDocs > 0 && totalLabelDocsByModel > 0;
  const totalLabelized = onlyByModel ? totalLabelDocsByModel : totalLabelDocs;
  return (
    <div className={matches ? classes.root : classes.rootBig}>
      {(onlyByModel || onlyByUser) && (
        <UiProgress
          variant="determinate"
          color={onlyByModel ? "secondary" : "primary"}
          value={(totalLabelized * 100) / totalEligibleDocs}
        />
      )}
      {modelAndUser && (
        <UiProgressMixed
          variant="determinate"
          color="primary"
          value={(totalLabelized * 100) / totalEligibleDocs}
        />
      )}
      <div className={classes.caption}>
        <small>
          {totalLabelized}/{totalEligibleDocs} photos{" "}
          {onlyByModel && " par le model"}
        </small>
      </div>
    </div>
  );
};

export default withStyles(styles)(withTheme()(ProgressBar));
