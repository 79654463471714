import React from "react";
import styled, { keyframes, css } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const shaking = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const Box = styled.div`
  flex: 1;
  padding: 8px;
  margin: 16px 8px 0 8px;
  border: 1px solid #028DFF;
  color: #2948FF;
  background-Color: #E0EEFD;
  border-radius: 3px;
  position: relative;
  text-align: center;
  font-weight: ${(p: any) => (p.shake ? "bold" : "normal")}  
  max-width: 75px;
  min-width: 75px;
  animation: ${(p: any) =>
    p.shake
      ? css`
          ${shaking} 200ms
        `
      : "none"}
3};
`;

const styles = (theme: Theme) =>
  createStyles({
    box: {
      flex: 1,
      padding: `${theme.spacing.unit}px`,
      margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px 0 ${
        theme.spacing.unit
      }px`,
      border: `1px solid #028DFF`,
      color: "#2948FF",
      backgroundColor: "#E0EEFD",
      borderRadius: `${theme.shape.borderRadius}px`,
      position: "relative",
      textAlign: "center",
      maxWidth: 75,
      minWidth: 75,
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    button: {
      display: "box",
      position: "absolute",
      paddingLeft: 5,
      paddingRight: 5,
      paddingBottom: 2,
      border: "none",
      right: -9,
      top: -9,
      borderRadius: "50px",
      backgroundColor: "#028DFF",
      color: "#FFF",
      lineHeight: "15px",
      "&.sortable-ghost": {
        backgroundColor: "#c8ebfb",
      },
    },
    wobble: {
      animation: `$wobbling`,
    },
  });

const Tag = (props: any) => {
  const { classes, label, noSuppr, onClick, ...rest } = props;
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };
  return (
    <Box {...rest}>
      <div className={classes.label} title={label}>
        {label}
      </div>
      {!noSuppr && (
        <button className={classes.button} onClick={handleClick}>
          x
        </button>
      )}
    </Box>
  );
};

Tag.defaultProps = {
  noSuppr: false,
};

export default withStyles(styles)(Tag);
