import * as React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UiLinkButton from "./common/UiLinkButton";
import LoginButton from "./LoginButton";
import Profil from "./Profil";

const styles = (theme: Theme) =>
  createStyles({
    colorPrimary: {
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 10%, ${theme.palette.primary.light})`,
    },
  });

const CustomAppBar = (props: any) => {
  const {
    classes,
    user,
    location: { pathname },
    handleLogout
  } = props;

  const isAdmin =
    user &&
    (user.authorities[0] === "ROLE_ADMIN" ||
      user.authorities[0] === "ROLE_PROJECT_ADMIN");

  const noTopBar =
    pathname.search("login") !== -1 ||
    pathname.search("mosaic") !== -1 ||
    (pathname.search("password") !== -1 && !props.user) ||
    pathname.search("qrcode") !== -1 ||
    pathname.search("verifyCode") !== -1;

  if (noTopBar) return null;

  return (
    <AppBar
      position="fixed"
      elevation={2}
      className={classes.colorPrimary}
    >
      <Toolbar>
        <Grid container direction="row" spacing={16}>
          <Grid item>
            <UiLinkButton href="/">
              <Typography variant="h6" color="inherit">
                Labeler
              </Typography>
            </UiLinkButton>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={0}
              style={{ width: isAdmin ? 275 : 80, height: 42 }}
              justify="flex-end"
              alignItems="flex-end"
            >
              {isAdmin && <Grid item />}
              {isAdmin && (
                <Grid item>
                  <UiLinkButton href="/projects/0?sort=name,desc">Projets</UiLinkButton>
                </Grid>
              )}
              {user && (
                <Grid item>
                  <UiLinkButton href="/batches/0?sort=createdDate,desc">Lots</UiLinkButton>
                </Grid>
              )}
              {isAdmin && (
                <Grid item>
                  <UiLinkButton href="/userlist/0?sort=firstName,asc">Utilisateurs</UiLinkButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {user && <Profil onLogout={handleLogout} />}
        {!user && <LoginButton onLogout={handleLogout} />}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(withRouter(CustomAppBar));
