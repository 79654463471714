import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import DialogContent from "@material-ui/core/DialogContent";
import { RouteComponentProps } from "react-router";
import UiDialogTitle from "../../common/UiDialogTitle";
import UiButton from "../../common/UiButton";
import UiDialogActions from "../../common/UiDialogActions";
import SelectionDoubleList from "../../common/SelectionDoubleList";
import UserType from "../../../types/User";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%",
    },
    titre: {
      minWidth: "550px",
    },
    list: {
      height: 250,
      width: "100%",
      overflowY: "scroll",
      backgroundColor: "#faffff",
      border: "solid 1px #2948ff4d",
    },
  });

interface PropsType extends RouteComponentProps<any>, React.Props<any> {
  onSave: any;
  onClose: any;
  open: boolean;
  classes: any;
  users: UserType[];
  selectionMax: number;
}

const UsersFormDialog = (props: PropsType) => {
  const { users, open, onClose, classes, onSave } = props;
  const [batchUsers, setBatchUsers] = useState<UserType[]>([]);
  const [availableUsers, setAvailableUsers] = useState<UserType[]>([]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onSave(batchUsers.map((u: UserType) => u.id));
    onClose();
  };

  useEffect(() => {
    setAvailableUsers(
      users.filter((u: any) => !batchUsers.find((uP: any) => uP.id === u.id))
    );
  }, [batchUsers, users]);

  const onSelectionUpdated = (type: string, selection: any[]) => {    
    setBatchUsers(
      type === "add"
      ? batchUsers.concat(selection)
      : batchUsers.filter((bU: UserType) => !selection.find((sel: any) => sel.id === bU.id))
    );
  }

  const itemTextRenderer = (i: any) => `${i.lastName} ${i.firstName}`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="list-actif-title"
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={handleSubmit}>
        <UiDialogTitle onCloseClick={onClose} title="Répartition des lots créés entre les labelers suivants" />
        <DialogContent>
          <SelectionDoubleList
            availableItems={availableUsers}
            itemTextRendrer={itemTextRenderer}
            onSelectionUpdated={onSelectionUpdated}
            selectedItems={batchUsers}
          />
        </DialogContent>
        <UiDialogActions style={{ marginRight: 23 }}>
          <UiButton
            variant="contained"
            color="secondary"
            type="submit"
            disabled={batchUsers.length === 0}
          >
            Valider
          </UiButton>
        </UiDialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(withRouter(UsersFormDialog));
