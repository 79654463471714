import * as React from "react";
import { withRouter, Prompt } from "react-router-dom";
import classNames from "classnames";
import Helmet from "react-helmet";
import { fabric } from "fabric";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { minBy, maxBy, isEqual } from "lodash";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import ImageCache from "../common/ImageCache";
import LetterBoxes from "./components/LetterBoxes";
import ResponsiveSection from "./components/ResponsiveSection";
import Progression from "../common/Progression";
import CanvasButtons from "../common/CanvasButtons";
import DrawerPhotoList from "../common/DrawerPhotoList.jsx";
import DrawerButton from "../common/DrawerButton";
import DisplayKeyMapping from "../common/DisplayKeyMapping";
import SkipDialog from "../common/SkipDialog";
import withContext from "../../tools/withContext";
import constants from "../../tools/constants";
import fitInBox, { size } from "../../tools/fitInBox";
import AlertDialog from "../common/AlertDialog";
import LabelisationAbstract from "../common/LabelisationAbstract";
import UiButton from "../common/UiButton";
import UiInput from "../common/UiInput";

interface IState {
  x: number;
  y: number;
  isDown: boolean;
  origX: any;
  origY: any;
  width: number;
  height: number;
  direction: string;
  creating: boolean;
  moving: boolean;
  currentImageIndex: number;
  submitting: boolean;
  justSaved: boolean;
  dirty: boolean;
  listImagesDrawerOpen: boolean;
  error: any;
  predicting: boolean;
  errorPredict: string;
  loadingImage: boolean;
  errorLoadingImage: string;
  imageLoaded: boolean;
  meta: any;
  timeout: Number;
  showHelp: boolean;
  country: string;
  skipDialogOpen: boolean;
  immatriculation: string;
  immatInputValue: string;
  selectedIndex: string | null;
  refreshingBoxIndexes: boolean;
  locked: boolean;
  majDown: boolean;
  isScaling: boolean;
  isMoving: boolean;
  allBoxesHidden: boolean;
  plateBoxHidden: boolean;
  showPrediction: boolean;
  motifCleared: boolean;
}

const cornerSize = 6;
const selectedBorderColor = "orange";
const plateSelectedBorderColor = "yellow";
const plateBorderColor = "#FFF";
const keyboardAdjustStep = 1;

export const i18nMapping: any = {
  FRAUD: "Fraude",
  LIGHT: "Lumière",
  BAD_RES: "Qualité d'image",
  BAD_TIMING: "Moment de prise de photo",
  OTHER: "Autre",
};

const convertImageLabelsToPrediction = (image: any) => {
  try {
    const rectangleLabels = image.labels.rectangleLabels.filter(
      (rL: any) => rL.position !== null
    );
    const lpPred = rectangleLabels.reduce(
      (memo: any, rL: any) => memo + rL.labelValue,
      ""
    );

    const characters_position = rectangleLabels.map((rL: any) => [
      rL.left,
      rL.top,
      rL.width,
      rL.height,
    ]);
    const lPP = image.labels.rectangleLabels.find(
      (rL: any) => rL.position === null
    );
    const license_plate_position = [
      lPP.left,
      lPP.top,
      lPP.width,
      lPP.height,
    ].map((v: string) => parseFloat(v));
    return { lpPred, extras: { license_plate_position, characters_position } };
  } catch (e) {
    console.log(e);
    return null;
  }
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      paddingTop: 50,
    },
    canvasContainer: {
      backgroundColor: "#edf2ff",
      margin: "0 auto",
      width: "100%",
      height: "100%",
    },
    nbrImagesG: {
      padding: `${theme.spacing.unit}px 0`,
    },
    button: {
      lineHeight: "27px",
    },
    motifButton: {
      textAlign: "center",
      marginTop: theme.spacing.unit * 2,
    },
    grow: {
      flexGrow: 1,
    },
    leftCol: {
      padding: 4,
      paddingBottom: "2em",
    },
    fullHeight: {
      height: "100%",
    },
    paddedLeft: {
      paddingLeft: theme.spacing.unit * 2,
    },
    bold: {
      color: theme.palette.primary.dark,
      letterSpacing: "0.2em",
      fontSize: "1.3em",
    },
    centered: {
      textAlign: "center",
    },
    imageButton: {
      border: "none",
      padding: 0,
    },
    padded: {
      padding: 5,
    },
    country: {
      width: "100%",
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      justifyContent: "space-around",
    },
    countryItem: {
      flex: 1,
    },
    responsiveSection: {
      paddingTop: theme.spacing.unit * 2,
    },
    dirtyIndic: {
      padding: `${theme.spacing.unit}px 0`,
      color: "#8F9DEF",
    },
    fullWidth: {
      width: "100%",
    },
    clearSkip: {
      padding: 5,
      border: "none",
      backgroundColor: "transparent",
    },
    error: {
      color: "red",
      fontSize: "0.8em",
      lineHeight: "20px",
      minHeight: "22px",
    },
  });

class LabelerPlate2 extends React.Component<any, IState> {
  private canvasRef = React.createRef<HTMLCanvasElement>();
  private canvasWrapperRef = React.createRef<HTMLDivElement>();
  private canvas: any;
  private image: any;
  private scaling: any;
  private rectangleSelection: any;
  public canvasContext: any;
  private keyMapping: any;

  constructor(props: any) {
    super(props);
    const {
      match: {
        params: { noImage },
      },
    } = props;

    this.state = {
      x: 0,
      y: 0,
      isDown: false,
      origX: 0,
      origY: 0,
      width: 0,
      height: 0,
      direction: "",
      creating: false,
      moving: false,
      currentImageIndex: noImage,
      submitting: false,
      justSaved: false,
      dirty: false,
      listImagesDrawerOpen: false,
      error: null,
      meta: {
        top: 633,
        left: 495,
        height: 120,
        width: 179,
      },
      immatriculation: "",
      immatInputValue: "",
      predicting: false,
      errorPredict: "",
      loadingImage: false,
      errorLoadingImage: "",
      imageLoaded: false,
      timeout: 1200,
      showHelp: false,
      skipDialogOpen: false,
      country: "FR",
      selectedIndex: null,
      refreshingBoxIndexes: false,
      locked: false,
      majDown: false,
      isScaling: false,
      isMoving: false,
      allBoxesHidden: false,
      plateBoxHidden: false,
      showPrediction: false,
      motifCleared: false,
    };

    this.scaling = {
      y: 1,
      x: 1,
      newWidth: props.width,
      newHeight: props.height,
      ratio: props.width / props.height,
    };

    this.keyMapping = {
      c: "Copie la box courante",
      "Suppr ou d": "Supprime la box sélectionnée",
      "Maj Suppr ou Maj d": "Supprime toutes les boxes",
      w: "Supprime toutes les boxes",
      h: "Cacher/Afficher les boxes",
      Entrée: "Sauvegarde",
      flèches: "Déplace la box active",
      "Ctrl + flèches": "Image suivante/précédente",
      "Maj + flèches": "Modifie la taille de la box active",
      "Maj + Déplacement souris": "se déplacer dans l'image zoomée",
      Esc: "Dé-zoom et recentre l'image",
    };
  }

  componentDidMount = () => {
    if (this.canvasRef.current !== null) {
      //@ts-ignore
      this.configureFabricJSCanvas(this.canvasRef.current).then(
        (canvas: any) => {
          this.canvas = canvas;
          this.bindEvents();
          if (
            this.props.match.params.noImage !== `${this.props.images.length}`
          ) {
            this.loadPredictions()
              .then(() => {
                this.setState({
                  predicting: false,
                  errorPredict: "",
                });
              })
              .catch((e) =>
                this.setState({
                  predicting: false,
                  errorPredict: e,
                })
              );
          }
        }
      );
    }
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (
      Number(prevProps.match.params.noImage) !==
      Number(this.props.match.params.noImage)
    ) {
      if (this.props.match.params.noImage !== `${this.props.images.length}`) {
        this.clearAllByType("rect");
        this.loadPredictions()
          .then(() =>
            this.setState({
              predicting: false,
              errorPredict: "",
              motifCleared: false,
            })
          )
          .catch((e) =>
            this.setState({
              predicting: false,
              errorPredict: e,
              motifCleared: false,
            })
          );
      }
    }
    if (prevState.predicting && !this.state.predicting) {
      this.clearZoom();
      const image = this.getCurrentImage();

      // pour la première image, la récupération de l'image ne
      // peut pas se faire dans le "cache" du coup on ajout un délai
      const timing = this.props.match.params.noImage === 0 ? 2000 : 0;
      setTimeout(
        () =>
          this.loadImage(image)
            .then(() => {
              const {
                labels: { rectangleLabels, country },
              } = image;
              if (rectangleLabels.length > 0) {
                const licencePlatePosition = rectangleLabels.find(
                  (l: any) => l.position === null
                );
                const { left, top, width, height } = licencePlatePosition;
                this.configureZoom({ left, top, width, height });
                const prediction: any = convertImageLabelsToPrediction(image);
                if (prediction) {
                  this.hydrate({ ...prediction, country }, "user");
                  if (prediction.lpPred) {
                    this.setState({ immatriculation: prediction.lpPred });
                  }
                }
              } else {
                if (
                  image.prediction &&
                  image.prediction.extras &&
                  image.prediction.extras.license_plate_position &&
                  image.prediction.extras.characters_position
                ) {
                  const [
                    left,
                    top,
                    width,
                    height,
                  ] = image.prediction.extras.license_plate_position;
                  this.configureZoom({ left, top, width, height });
                  this.hydrate(image.prediction, "user");
                }
              }
            })
            .catch((e: any) => console.log("error loading image", e)),
        timing
      );
    }

    if (
      prevState.submitting &&
      !this.state.submitting &&
      this.state.error === ""
    ) {
      this.handleNextImage();
    }

    // if (
    //   prevState.motifSkip === "" &&
    //   this.state.motifSkip !== "" &&
    //   this.state.dirty
    // ) {
    //   this.handleSubmit();
    // }

    if (prevState.showPrediction !== this.state.showPrediction) {
      if (this.state.showPrediction) {
        const image = this.getCurrentImage();
        this.hydrate(image.prediction, "prediction");
      } else {
        this.getBoxes("prediction").forEach((b: any) => this.canvas.remove(b));
      }
    }
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (!isEqual(this.props.keyEvent, nextProps.keyEvent)) {
      if (nextProps.keyEvent.type === "keyup") {
        this.handleKeyUp(nextProps.keyEvent);
      } else {
        this.handleKeyDown(nextProps.keyEvent);
      }
    }

    const thisPropsImg = this.props.images[
      Number(this.props.match.params.noImage)
    ];
    const nextPropsImg =
      nextProps.images[Number(nextProps.match.params.noImage)];
    if (
      thisPropsImg &&
      nextPropsImg &&
      thisPropsImg.id === nextPropsImg.id &&
      ((thisPropsImg.motif === null && nextPropsImg.motif !== null) ||
        // modification d'un motif existant
        (thisPropsImg.motif !== null &&
          nextPropsImg.motif !== null &&
          thisPropsImg.motif !== nextPropsImg.motif))
    ) {
      setTimeout(() => {
        this.handleSubmit().then(() => console.log("motif submit"));
      }, 250);
    }
  };

  bindEvents = () => {
    this.canvas.on("mouse:over", this.handleMouseOver);
    this.canvas.on("mouse:down", this.handleMouseDown);
    this.canvas.on("mouse:up", this.handleMouseUp);
    this.canvas.on("mouse:move", this.handleMouseMove);
    this.canvas.on("mouse:wheel", this.handleMouseWheel);
    this.canvas.on("selection:created", this.handleSelectionCreated);
    this.canvas.on("selection:updated", this.handleSelectionUpdated);
    this.canvas.on("selection:cleared", this.handleSelectionCleared);
    this.canvas.on("object:moved", this.handleObjectMoved);
    this.canvas.on("object:modified", this.handleObjectModified);
    this.canvas.observe("object:scaling", this.updateScaling);
    this.canvas.observe("object:moving", this.updateMoving);
  };

  configureFabricJSCanvas = (ref: HTMLCanvasElement) =>
    new Promise((resolve: any) => {
      const canvas = new fabric.Canvas(ref);
      const { width, height } = this.props;
      setTimeout(() => {
        //@ts-ignore
        const { offsetHeight, offsetWidth } = this.canvasWrapperRef.current;
        const taille: size = fitInBox(
          width,
          height,
          offsetWidth,
          offsetHeight,
          true
        );

        const { height: newHeight, width: newWidth } = taille;
        canvas.setWidth(newWidth);
        canvas.setHeight(newHeight);
        // canvas.uniScaleTransform = false;
        canvas.selection = false;
        canvas.defaultCursor = "pointer";

        this.scaling = {
          x: newWidth / width,
          y: newHeight / height,
          newHeight,
          newWidth,
        };
        resolve(canvas);
      }, 150);
    });

  configureZoom = (coords: any) => {
    const { left, top, width, height } = coords;

    const zoomHeight = this.image.height / height;
    const zoomWidth = this.image.width / width;

    const zoom = zoomWidth > zoomHeight ? zoomHeight : zoomWidth;
    this.canvas.setZoom(1);

    this.canvas.absolutePan({
      x: left * this.scaling.x - 1,
      y: top * this.scaling.y - 1,
    });
    this.canvas.renderAll();

    const newZoom = zoom - 0.4;
    this.canvas.setZoom(newZoom);
    this.canvas.renderAll();
  };

  clearZoom = () => {
    this.canvas.setZoom(1);
    this.canvas.absolutePan({
      x: 0,
      y: 0,
    });
  };

  handleKeyDown = (e: any) => {
    const activeObject = this.canvas.getActiveObject();

    if (e.key === "Tab") {
      if (e.shiftKey) {
        this.activatePrevBox();
      } else {
        this.activateNextBox();
      }
    }

    if (e.key === "Shift") {
      this.setState({ majDown: true });
      this.canvas.setCursor("move");
    }

    if (["Delete", "d", "D"].includes(e.key)) {
      if (this.state.majDown) {
        this.handleDeleteAllBoxes();
      } else if (this.isOneBoxSelected()) {
        this.handleDeleteActiveBox();
      }
    }

    if (["w", "W"].includes(e.key)) {
      this.clearAllByType("rect");
      this.setState({ dirty: true });
    }

    if (e.key === "Enter" && this.state.imageLoaded) {
      if (this.isLabelisationFinished()) {
        this.handleSubmit();
      }
    }

    if (e.key === "ArrowDown") {
      if (!activeObject) {
        return;
      }
      if (e.shiftKey) {
        activeObject.set({
          height: activeObject.height + keyboardAdjustStep,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      } else {
        activeObject.set({
          top: activeObject.top + keyboardAdjustStep,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      }

      this.canvas.renderAll();
    }

    if (e.key === "ArrowUp") {
      if (!activeObject) {
        return;
      }

      if (e.shiftKey) {
        activeObject.set({
          height: activeObject.height - keyboardAdjustStep,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      } else {
        activeObject.set({
          top: activeObject.top - keyboardAdjustStep,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      }

      this.canvas.renderAll();
    }

    if (e.key === "ArrowLeft") {
      if (e.ctrlKey || e.altKey) {
        if (Number(this.props.match.params.noImage) > 0) {
          this.handlePreviousImage();
        }
        return;
      }

      if (!activeObject) return;

      if (e.shiftKey) {
        activeObject.set({
          width: activeObject.width - keyboardAdjustStep,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      } else {
        activeObject.set({
          left: activeObject.left - keyboardAdjustStep,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      }

      this.canvas.renderAll();
    }

    if (e.key === "ArrowRight") {
      if (e.ctrlKey || e.altKey) {
        if (this.isNavigationFwdPossible()) {
          this.handleNextImage();
        }
        return;
      }

      if (!activeObject) return;

      if (e.shiftKey) {
        activeObject.set({
          width: activeObject.width + keyboardAdjustStep,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      } else {
        activeObject.set({
          left: activeObject.left + keyboardAdjustStep,
        });
        this.setState({ dirty: true });
        activeObject.setCoords();
      }

      this.canvas.renderAll();
    }

    if (e.key === "h" || e.key === "H") {
      this.toggleAllBoxesVisiblity();
    }

    if (e.key === "Escape") {
      this.clearZoom();
    }

    if (activeObject && e.key === "c") {
      this.createSiblingBox(activeObject);
    }
  };

  handleKeyUp = (e: any) => {
    if (e.key === "Shift") {
      this.canvas.setCursor("pointer");
      this.setState({ majDown: false, origX: null, origY: null });
    }
  };

  handleObjectMoved = () => {
    this.refreshBoxIndexes();
    this.setState({ dirty: true, refreshingBoxIndexes: true });
  };

  handleObjectModified = (e: any) => {
    const o = e.target;
    o.set("strokeWidth", o.get("index") === null ? 0.5 : 0.2);
  };

  handleSelectionCreated = (o: any) => {
    // parfois les bordures non sélectionnées
    // sont passées par fabricjs à strokeWidth = 0.125
    // par précaution, on passe tous les strokeWidth des objets à 0.5 ou 0.2
    this.canvas
      .getObjects()
      .map((o: any) =>
        o.set("strokeWidth", o.get("index") === null ? 0.5 : 0.2)
      );
    o.selected.forEach((r: any) => {
      const isPlateBox = r.get("index") === null;
      const selColor = isPlateBox
        ? plateSelectedBorderColor
        : selectedBorderColor;

      const strokeWidth = isPlateBox ? 0.5 : 0.2;
      r.set({
        cornerColor: selColor,
        cornerStrokeColor: selColor,
        stroke: selColor,
        strokeWidth,
        cornerSize,
        transparentCorners: false,
      });

      this.setState({
        creating: false,
        selectedIndex: r.get("index"),
      });

      if (isPlateBox) {
        this.canvas.moveTo(r, 1); // l'image est en 0
      }
    });
  };

  handleSelectionUpdated = (o: any) => {
    this.canvas
      .getObjects()
      .map((o: any) =>
        o.set("strokeWidth", o.get("index") === null ? 0.5 : 0.2)
      );
    o.selected.forEach((r: any) => {
      const isPlateBox = r.get("index") === null;
      const selColor = isPlateBox
        ? plateSelectedBorderColor
        : selectedBorderColor;
      const strokeWidth = isPlateBox ? 0.5 : 0.2;

      r.set({
        cornerColor: selColor,
        cornerStrokeColor: selColor,
        transparentCorners: false,
        cornerSize,
        strokeWidth,
        stroke: selColor,
      });

      this.setState({
        creating: false,
        selectedIndex: r.get("index"),
      });
    });

    o.deselected.forEach((r: any) => {
      const isPlateBox = r.get("index") === null;
      const selColor = isPlateBox ? plateBorderColor : selectedBorderColor;
      const strokeWidth = isPlateBox ? 0.5 : 0.2;

      r.set({
        cornerColor: selColor,
        cornerStrokeColor: selColor,
        stroke: selColor,
        cornerSize,
        strokeWidth,
        transparentCorners: true,
      });
    });
  };

  handleSelectionCleared = (o: any) => {
    // parfois les bordures non sélectionnées
    // sont passées par fabricjs à strokeWidth = 0.125
    // par précaution, on passe tous les strokeWidth des objets à 0.5 ou 0.2
    this.canvas
      .getObjects()
      .map((o: any) =>
        o.set("strokeWidth", o.get("index") === null ? 0.5 : 0.2)
      );
    if (!o.deselected) return;
    o.deselected.forEach((r: any) => {
      const isPlateBox = r.get("index") === null;
      const color = isPlateBox ? plateBorderColor : selectedBorderColor;
      const strokeWidth = isPlateBox ? 0.5 : 0.2;

      r.set({
        cornerColor: color,
        cornerStrokeColor: color,
        transparentCorners: true,
        cornerSize,
        strokeWidth,
        stroke: color,
      });
    });

    // o.deselected.forEach((r: any) => r.set("stroke", r.get('index') === null ? "#FFF" : "LawnGreen"));
    this.setState({ creating: false, selectedIndex: null });
  };

  updateScaling = (e: any) => {
    var o = e.target;
    if (!o) return;
    if (!o.strokeWidthUnscaled && o.strokeWidth) {
      o.strokeWidthUnscaled = o.strokeWidth;
    }
    if (o.strokeWidthUnscaled) {
      o.strokeWidth = o.strokeWidthUnscaled / (o.scaleX + o.scaleY) / 2;
    }
    this.setState({ dirty: true, isScaling: true });
  };

  updateMoving = () => {
    this.setState({ isMoving: true });
  };

  handleMouseDown = (o: any) => {
    const { x, y } = this.canvas.getPointer(o.e);
    const image = this.getCurrentImage();
    // pas de boxing possible quand pas de prédiction et plaque non saisie
    if (!image.prediction && this.state.immatriculation === "") {
      return;
    }
    this.setState({ isDown: true, origX: x, origY: y });
  };

  handleMouseOver = (o: any) => {
    if (o.target && o.target.type === "image") {
      this.canvas.setCursor("default");
    }
  };

  handleMouseWheel = (o: any) => {
    const delta = o.e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom = zoom + delta / 20;

    if (zoom <= 1) {
      zoom = 1;
      // this.canvas.zoomToPoint({ x: -o.e.offsetX, y: -o.e.offsetY }, zoom);
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    } else {
      if (zoom > 20) zoom = 20;
      this.canvas.zoomToPoint({ x: o.e.offsetX, y: o.e.offsetY }, zoom);
    }
    this.canvas.requestRenderAll();
    o.e.preventDefault();
    o.e.stopPropagation();
  };

  handleMouseUp = (o: any) => {
    const { creating, origX, origY, width, height, direction } = this.state;

    let rectangle: any;
    const image = this.getCurrentImage();
    let dirty = this.state.dirty;

    let immatriculation =
      image.prediction &&
      image.prediction.extras &&
      image.prediction.extras.license_plate_position &&
      image.prediction.lpPred !== ""
        ? image.prediction.lpPred
        : this.state.immatriculation;
    let labelValue = "";

    const boxes = this.getBoxes();
    const index = boxes.length;

    if (creating && !this.isOneBoxSelected()) {
      let corner = { left: origX, top: origY };
      if (direction === "bhd") corner.top = origY - height;
      if (direction === "hbg") corner.left = origX - width;
      if (direction === "bhg") {
        corner.left = origX - width;
        corner.top = origY - height;
      }

      // on considère qu'il s'agit de la plateBox qui finalise la sélection
      // si la largeur est au moins 3 fois supérieure à la box la moins large

      // const min = minBy(boxes, (b: any) => b.width * b.scaleY);
      // const isPlateBox = min && width > min.width * min.scaleY * 3;

      // on calcule la hauteur minimale des boxes
      // pour empecher la création de boxes trop petites
      if (boxes.length > 0) {
        const minHeightBox = maxBy(boxes, (b: any) => b.height * b.scaleY);
        if (height < (minHeightBox.height * minHeightBox.scaleY) / 3) {
          this.setState({
            isDown: false,
            origX: null,
            origY: null,
            creating: false,
          });
          this.canvas.remove(this.rectangleSelection);
          this.rectangleSelection = null;
          return;
        }
      }

      rectangle = new fabric.Rect({
        ...corner,
        width,
        height,
        hasRotatingPoint: false,
        selectable: true,
        originX: "left",
        originY: "top",
        fill: "rgba(255,255,255,0.01)",
      });

      this.canvas.add(rectangle);
      this.canvas.remove(this.rectangleSelection);

      const isPlateBox = boxes.length > 0 ? this.isPlateBox(rectangle) : false;

      if (!isPlateBox && index > immatriculation.length) {
        immatriculation = `${immatriculation}?`;
      }

      labelValue = isPlateBox
        ? labelValue
        : immatriculation.slice(index, index + 1) || "";

      rectangle.set("labelValue", labelValue);
      rectangle.set("strokeWidth", isPlateBox ? 0.5 : 0.2);
      rectangle.set(
        "stroke",
        isPlateBox ? plateSelectedBorderColor : selectedBorderColor
      );
      rectangle.set("plateScope", "user");
      rectangle.set("accuracy", 0.5);

      // quand il y a la plate box on connait le nombre de chars
      if (isPlateBox) {
        immatriculation = immatriculation.slice(0, index + 1);
        rectangle.set("index", null);
      }

      this.rectangleSelection = null;
      this.canvas.setActiveObject(rectangle);
      dirty = true; // image modifiée non sauvegardée
    }
    this.setState({
      isDown: false,
      origX: null,
      origY: null,
      creating: false,
      isScaling: false,
      isMoving: false,
      dirty,
      immatriculation,
      refreshingBoxIndexes: true,
    });
    this.refreshBoxIndexes();
    this.canvas.renderAll();
  };

  activateNextBox = (object?: any) => {
    const refObject = object || this.canvas.getActiveObject();
    if (refObject && refObject.get("type") === "rect") {
      const boxes = this.getBoxes();

      const nextIndex =
        refObject.get("index") === null
          ? 0
          : refObject.get("index") < boxes.length - 2
          ? refObject.get("index") + 1
          : null;

      const nextBox = this.canvas
        .getObjects()
        .filter((b: any) => b.get("type") === "rect")
        .find((b: any) => b.get("index") === nextIndex);

      if (nextBox) {
        const canvasIndex = this.canvas._objects.findIndex(
          (b: any) => b.get("index") === nextBox.get("index")
        );

        this.canvas.setActiveObject(this.canvas.item(canvasIndex));
        this.canvas.renderAll();
      }
    }
  };

  activatePrevBox = () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.get("type") === "rect") {
      const boxes = this.getBoxes();

      const nextIndex =
        activeObject.get("index") === null
          ? boxes.length - 2
          : activeObject.get("index") > 0
          ? activeObject.get("index") - 1
          : null;

      const nextBox = this.canvas
        .getObjects()
        .filter((b: any) => b.get("type") === "rect")
        .find((b: any) => b.get("index") === nextIndex);

      const canvasIndex = this.canvas._objects.findIndex(
        (b: any) => b.get("index") === nextBox.get("index")
      );

      if (nextBox) {
        this.canvas.setActiveObject(this.canvas.item(canvasIndex));
        this.canvas.renderAll();
      }
    }
  };

  isPlateBox = (object: any) =>
    // on teste si toutes les box sont contenues dans object
    this.getBoxes().reduce(
      (memo: any, box: any) => memo || box.isContainedWithinObject(object),
      false
    );

  handleMouseMove = (o: any) => {
    const { isDown, origX, origY, majDown, isScaling, isMoving } = this.state;

    if (isScaling || isMoving) return;

    // touche Shift enfoncée + mouvement souris => panning
    if (majDown && o && o.e) {
      const { tl, tr, br } = this.canvas.calcViewportBoundaries();
      let dplX;
      let dplY;

      if (o.e.movementX > 0) {
        // vers la droite
        dplX = tl.x - o.e.movementX > 0 ? o.e.movementX : 0;
      } else {
        // vers la gauche
        dplX = tr.x - o.e.movementX < this.scaling.newWidth ? o.e.movementX : 0;
      }

      if (o.e.movementY > 0) {
        // vers le bas
        dplY = tl.y - o.e.movementY > 0 ? o.e.movementY : 0;
      } else {
        // vers le haut
        dplY =
          br.y - o.e.movementY < this.scaling.newHeight ? o.e.movementY : 0;
      }

      var delta = new fabric.Point(dplX, dplY);
      this.canvas.relativePan(delta);
      return;
    }

    // quand le plateBox a été dessinée, on ne peut plus
    // créer de box

    // const plateBoxExists = boxes.find((b: any) => b.get("index") === null);
    // Assan if (!this.isOneBoxSelected()) return;

    let { x, y } = this.canvas.getPointer(o.e);

    this.setState({ x, y });

    if (isDown && origX) {
      const width = Math.abs(origX - x);
      let height = Math.abs(origY - y);
      let corner = { left: origX, top: origY };
      let direction = "hbd";

      if (origX < x && origY > y) {
        corner = { left: origX, top: origY - height };
        direction = "bhd";
      }

      if (origX > x && origY < y) {
        corner = { left: origX - width, top: origY };
        direction = "hbg";
      }

      if (origX > x && origY > y) {
        corner = { left: origX - width, top: origY - height };
        direction = "bhg";
      }

      if (!this.rectangleSelection) {
        this.rectangleSelection = new fabric.Rect({
          ...corner,
          width,
          height,
          hasRotatingPoint: false,
          strokeWidth: 0.2,
          stroke: "#ffffff",
          selectable: false,
          originX: "left",
          originY: "top",
          fill: "rgba(255,255,255,0.4)",
        });
        this.rectangleSelection.set("accuracy", 0.5);
        this.canvas.add(this.rectangleSelection);
      } else if (this.rectangleSelection) {
        this.rectangleSelection
          .set("width", width)
          .set("height", height)
          .set("top", corner.top)
          .set("left", corner.left);
      }
      this.setState({ creating: true, width, height, direction });
    }
    //this.updateScaling(o);
    this.canvas.renderAll();
  };

  getCurrentImage = () => {
    const {
      images,
      match: {
        params: { noImage },
      },
    } = this.props;

    return images[noImage];
  };

  loadPredictions = () =>
    new Promise((resolve: any, reject: any) => {
      const {
        images,
        ctx: { appFetch },
        match: {
          params: { noImage },
        },
        search,
      } = this.props;
      const image = images[noImage];

      this.setState({
        predicting: true,
        errorPredict: "",
        immatriculation: "",
        immatInputValue: "",
      });

      let imageSuivante: any = null;
      let imagePrecedente: any = null;

      if (Number(noImage) < Number(images.length) - 1) {
        imageSuivante = images[Number(noImage) + 1];
      }

      if (Number(noImage) > 0) {
        imagePrecedente = images[Number(noImage) - 1];
      }

      const predNull = {
        lpPred: "",
        extras: {
          license_plate_prediction: null,
          character_position_null: null,
        },
      };

      const desactivatePredictions = true;
      if (desactivatePredictions) {
        console.log("predictions désactivées");
        this.props.storePrediction(predNull, imagePrecedente.id);
        this.props.storePrediction(predNull, image.id);
        this.props.storePrediction(predNull, imageSuivante.id);
        return resolve(predNull);
      }

      const predictionsLoader: any = [];

      // image précédente
      predictionsLoader.push(
        imagePrecedente && !imagePrecedente.prediction
          ? appFetch(
              `${constants.paths.PATH_PREDICT}?imageId=${imagePrecedente.id}`
            )
          : new Promise((resolve: any) => resolve(null))
      );

      // image courante
      predictionsLoader.push(
        true || !image.prediction || search !== ""
          ? appFetch(`${constants.paths.PATH_PREDICT}?imageId=${image.id}`)
          : new Promise((resolve: any) => resolve(image.prediction))
      );

      // image suivante
      predictionsLoader.push(
        imageSuivante && !imageSuivante.prediction
          ? appFetch(
              `${constants.paths.PATH_PREDICT}?imageId=${imageSuivante.id}`
            )
          : new Promise((resolve: any) => resolve(null))
      );

      Promise.all(predictionsLoader)
        .then((res: any) => {
          if (res[0]) this.props.storePrediction(res[0], imagePrecedente.id);
          if (res[1]) this.props.storePrediction(res[1], image.id);
          if (res[2]) this.props.storePrediction(res[2], imageSuivante.id);
          return resolve(res[1]);
        })
        .catch((e: any) => {
          console.log("erreur prediction", e);
          this.props.storePrediction(predNull, imagePrecedente.id);
          this.props.storePrediction(predNull, image.id);
          this.props.storePrediction(predNull, imageSuivante.id);
          reject(e);
        });
    });

  loadImage = (image: any) =>
    new Promise((resolve: any, reject: any) => {
      this.setState({
        loadingImage: true,
        imageLoaded: false,
        errorLoadingImage: "",
        dirty: false,
      });

      this.canvas.remove(this.image);
      const url = image.url;

      var img = new Image();

      img.onload = () => {
        fabric.Image.fromURL(img.src, () => {
          const { width, height } = img;
          const { newWidth, newHeight } = this.scaling;

          this.scaling = {
            x: newWidth / width,
            y: newHeight / height,
            newHeight,
            newWidth,
          };

          this.image = new fabric.Image(img, {});

          this.image.set({
            left: 0,
            top: 0,
            selectable: false,
            lockMovementX: true,
            centeredScaling: true,
            lockMovementY: true,
            hoverCursor: "arrow",
            scaleX: newWidth / width,
            scaleY: newHeight / height,
            originX: "left",
            originY: "top",
            opacity: 1,
          });

          this.canvas.add(this.image);
          this.canvas.renderAll();
          this.setState({
            loadingImage: false,
            imageLoaded: true,
            errorLoadingImage: "",
            dirty: image.lastLabeledBy === "model",
          });

          return resolve();
        });
      };

      img.onerror = (ev) => {
        fabric.util.loadImage("/404.png", (img: any) => {
          fabric.util.loadImage("/404.png", (img: any) => {
            this.setState({
              loadingImage: false,
              imageLoaded: false,
              errorLoadingImage: "Image 404",
            });
            this.image = new fabric.Image(img, {});
            this.canvas.add(this.image);
            return reject();
          });
        });
      };

      img.src = url;
    });

  hydrate = (prediction: any, scope: string) => {
    console.log("ici", prediction, scope);
    if (!prediction.extras) {
      console.log("prediction sans coord plaque");
      return;
    }
    const {
      lpPred,
      extras: { license_plate_position, characters_position },
      country,
    } = prediction;

    let first = null;
    const [leftP, topP, widthP, heightP] = license_plate_position;
    const { x, y } = this.scaling;

    // dessin des boxes caractères
    characters_position.forEach((l: any, index: number) => {
      const [left, top, width, height] = l;
      const box = new fabric.Rect({
        left: (left + leftP) * x,
        top: (top + topP) * y,
        width: width * x,
        height: height * y,
        hasRotatingPoint: false,
        strokeWidth: 0.2,
        stroke: scope === "user" ? "orange" : "red",
        selectable: true,
        originX: "left",
        originY: "top",
        fill: "rgba(255, 255, 255, 0.01)",
      });
      const labelValue = lpPred.slice(index, index + 1);

      //@ts-ignore
      box.set("index", index);
      //@ts-ignore
      box.set("labelValue", labelValue);
      //@ts-ignore
      box.set("originalLabelValue", labelValue);
      //@ts-ignore
      box.set("accuracy", 100);
      //@ts-ignore
      box.set("plateScope", scope);
      //@ts-ignore
      box.set("strokeWidthUnscaled", 0.2);
      this.canvas.add(box);
      this.canvas.bringToFront(box);
      if (index === 0) first = box;
    });

    // box de la plaque
    const plaque = new fabric.Rect({
      left: leftP * x,
      top: topP * y,
      width: widthP * x,
      height: heightP * y,
      hasRotatingPoint: false,
      strokeWidth: 0.5,
      stroke: scope === "user" ? "#FFF" : "red",
      selectable: !this.state.locked,
      originX: "left",
      originY: "top",
      fill: "rgba(255, 255, 255, 0.01)",
    });

    //@ts-ignore
    plaque.set("index", null);
    //@ts-ignore
    plaque.set("labelValue", "");
    //@ts-ignore
    plaque.set("plateScope", scope);
    this.canvas.add(plaque);

    this.canvas.sendToBack(plaque);
    this.canvas.sendToBack(this.image);
    this.canvas.setActiveObject(first);
    this.canvas.renderAll();
    this.refreshBoxIndexes();
    this.setState({ country: country || "FR" });
  };

  findBoxByPosition = (position: number) => {
    if (!this.canvas) return;
    const boxes = this.canvas
      .getObjects()
      .filter((o: any) => o.get("type") === "rect")
      .filter((o: any) => {
        return (
          typeof o.get("index") !== undefined && o.get("index") === position
        );
      });
    return boxes.length === 1 ? boxes[0] : undefined;
  };

  handleHover = (e: any, position: any) => {
    const box = this.findBoxByPosition(position);
    if (box) {
      this.canvas.setActiveObject(box);
      this.canvas.renderAll();
    }
  };

  handleFocus = (e: any, position: any) => {
    e.target.select();
    this.handleHover(e, position);
  };

  handleInputChange = (character: string, index: number) => {
    if (!this.canvas) return;
    const box = this.findBoxByPosition(index);
    let immatriculation;
    if (box) {
      box.set(
        "labelValue",
        character.length > 0 ? character.toUpperCase() : box.get("labelValue")
      );

      immatriculation = this.getBoxes().reduce(
        (m: any, b: any) => `${m}${b.get("labelValue")}`,
        ""
      );
    } else {
      //@TODO encore utile ?
      immatriculation = this.state.immatriculation
        .split("")
        .map((i: any, idx: any) =>
          idx === index
            ? character.length > 0
              ? character.toUpperCase()
              : i
            : i
        )
        .join("");
    }

    this.setState({ dirty: true, immatriculation });
  };

  handleImmatSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ immatriculation: this.state.immatInputValue, dirty: true });
  };

  handleImmatInputChange = (e: any) => {
    this.setState({ immatInputValue: e.target.value.toUpperCase() });
  };

  handleEditImmatriculation = () => {
    this.clearAllByType("rect");
    this.setState((oldState: any) => ({
      immatInputValue: oldState.immatriculation,
      immatriculation: "",
    }));
  };

  handleDeleteActiveBox = () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      const boxes = this.getBoxes();

      // s'il y a plus d'une box, on active la suivante
      if (boxes.length > 1) {
        this.activateNextBox(activeObject);
      }
      this.canvas.remove(activeObject);
      this.refreshBoxIndexes();
      this.setState({
        refreshingBoxIndexes: true,
        dirty: true,
      });
    }
  };

  handleDeleteAllBoxes = () => {
    this.clearAllByType("rect");
    this.refreshBoxIndexes();
    this.setState({
      refreshingBoxIndexes: true,
      dirty: true,
    });
  };

  changeMotif = (motifSkip: any | null) => {
    const { storeMotif } = this.props;

    storeMotif(motifSkip);

    this.setState({
      dirty: true,
      skipDialogOpen: false,
    });
  };

  clearSkip = () => {
    const { storeMotif } = this.props;

    storeMotif(null);

    this.setState({
      dirty: true,
      skipDialogOpen: false,
    });
  };

  handleOpenSkipDialog = (e: any) => {
    this.setState({ skipDialogOpen: true });
  };

  handleCloseSkipDialog = (e: any) => {
    this.setState({ skipDialogOpen: false });
  };

  handleToggleListeImagesDrawer = () =>
    this.setState({ listImagesDrawerOpen: !this.state.listImagesDrawerOpen });

  isNavigationFwdPossible = () => {
    const {
      match: {
        params: { noImage },
      },
      images,
    } = this.props;
    const image = images[Number(noImage)];
    const nextImage = images[Number(noImage) + 1];
    return (
      (image &&
        image.labels &&
        (image.labels.rectangleLabels.length > 0 || image.motif) &&
        !this.state.dirty) ||
      (nextImage &&
        ((nextImage.labels && nextImage.labels.rectangleLabels.length > 0) ||
          nextImage.motif))
    );
  };

  getBoxes = (scope: string = "user") => {
    if (!this.canvas) return [];
    return this.canvas
      .getObjects()
      .filter((o: any) => {
        return o.get("type") === "rect" && o.get("plateScope") === scope;
      })
      .sort((o1: any, o2: any) => {
        if (o1.get("index") > o2.get("index")) return 1;
        if (o1.get("index") < o2.get("index")) return -1;
        return 0;
      });
  };

  refreshBoxIndexes = () => {
    if (!this.canvas) return [];
    const allRects = this.canvas
      .getObjects()
      .filter(
        (o: any) =>
          o.get("type") === "rect" &&
          o.get("index") !== null &&
          o.get("plateScope") === "user"
      );

    if (allRects.length === 0) return [];

    // on calcule la hauteur minimale à partir de laquelle on détecte le changement de ligne
    const minHeightRect = minBy(allRects, (r: any) => r.height * r.scaleY);
    const decalBoxMin = (minHeightRect.height * minHeightRect.scaleY) / 2;

    // on calcule la largeur minimale à partir de laquelle on détecte la box plaque
    const minSurfaceRect = minBy(
      allRects,
      (r: any) => r.width * r.scaleX * (r.height * r.scaleY)
    );
    const plateStep =
      minSurfaceRect.width *
      minSurfaceRect.scaleX *
      (minSurfaceRect.height * minSurfaceRect.scaleY) *
      4;
    const getCenterY = (o: any) => o.get("top") + o.get("height") / 2;

    allRects
      .sort((o1: any, o2: any) => {
        // gestion des lignes
        if (getCenterY(o1) - getCenterY(o2) > decalBoxMin) return 1;
        if (getCenterY(o2) - getCenterY(o1) > decalBoxMin) return -1;

        if (o1.get("left") > o2.get("left")) return 1;
        if (o1.get("left") < o2.get("left")) return -1;
        return 0;
      })
      .forEach((box: any, index: number) => {
        const idx =
          box.width * box.scaleX * (box.height * box.scaleY) > plateStep
            ? null
            : index;
        box.set("index", idx);
      });

    this.setState({ refreshingBoxIndexes: false });
  };

  getPlateBox = () => {
    return this.getBoxes().find((b: any) => b.get("index") === null);
  };

  handleSubmit = () => {
    const {
      images,
      onSave,
      match: {
        params: { noImage: currentImageIndex },
      },
    } = this.props;
    const image = images[currentImageIndex];

    this.setState({
      submitting: true,
      error: "",
    });

    const datas = this.getDatas();

    return new Promise((resolve, reject) => {
      onSave({ ...image, ...datas, lastLabeledBy: null })
        .then((image: any) => {
          this.setState({
            dirty: false,
            submitting: false,
          });
          return resolve(image);
        })
        .catch((e: any) => {
          this.setState({
            submitting: false,
            error: e.message,
          });
          return reject(e);
        });
    });
  };

  getDatas = () => {
    const { x, y } = this.scaling;
    const { country } = this.state;
    const {
      match: {
        params: { noImage },
      },
      images,
    } = this.props;

    const boxes = this.getBoxes();
    let plateBox = this.getPlateBox();

    if (plateBox) {
      plateBox = plateBox.toJSON();
      const plateBoxing = {
        position: null,
        labelValue: "",
        left: plateBox.left / x,
        top: plateBox.top / y,
        width: (plateBox.width * plateBox.scaleX) / x,
        height: (plateBox.height * plateBox.scaleY) / y,
      };
      return {
        labels:
          boxes.length === 0
            ? []
            : boxes
                .filter((b: any) => b.index !== null)
                .map((box: any) => {
                  const b = box.toJSON();
                  return {
                    position: box.get("index"),
                    labelValue: box.get("labelValue"),
                    left: (b.left - plateBox.left) / x,
                    top: (b.top - plateBox.top) / y,
                    width: (b.width * b.scaleX) / x,
                    height: (b.height * b.scaleY) / y,
                  };
                })
                .concat([plateBoxing]),
        country,
        motif: images[Number(noImage)] ? images[Number(noImage)].motif : null,
      };
    } else {
      if (images[Number(noImage)] && images[Number(noImage)].motif !== null) {
        return { labels: [], country, motif: images[Number(noImage)].motif };
      }
    }
  };

  handlePreviousImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(`/labelerPlate/${noBatch}/${Number(noImage) - 1}`);
  };

  handleNextImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(`/labelerPlate/${noBatch}/${Number(noImage) + 1}`);
  };

  togglePlateBoxVisiblity = () => {
    const plateBox = this.getPlateBox();
    this.canvas.discardActiveObject();
    // this.canvas.clear
    if (plateBox) {
      plateBox.set("visible", !plateBox.get("visible"));
      this.setState({ plateBoxHidden: !plateBox.get("visible") });
      this.canvas.renderAll();
    }
  };

  clearAllByType = (type: string) =>
    this.canvas
      .getObjects()
      .filter((o: any) => o.get("type") === type)
      .forEach((o: any) => {
        this.canvas.remove(o);
      });

  handleToggleHelp = () => this.setState({ showHelp: !this.state.showHelp });

  createSiblingBox = (activeBox: any) => {
    const image = this.getCurrentImage();
    const lpPred =
      image.prediction && image.prediction.lpPred !== ""
        ? image.prediction.lpPred
        : this.state.immatriculation;
    if (!lpPred || lpPred === "") return;

    const nextLabelValue = lpPred.slice(
      activeBox.get("index") + 1,
      activeBox.get("index") + 2
    );
    let box = this.getFirstBoxAfterActiveObjectByLabel(nextLabelValue);

    const left =
      activeBox.get("left") + activeBox.get("width") * activeBox.get("scaleX");
    const top = activeBox.get("top");
    const width = activeBox.get("width") * activeBox.get("scaleX");
    const height = activeBox.get("height") * activeBox.get("scaleY");

    if (!box) {
      // nouvelles valeurs
      box = new fabric.Rect({
        left,
        top,
        width,
        height,
        hasRotatingPoint: false,
        strokeWidth: 0.2,
        stroke: "orange",
        selectable: true,
        originX: "left",
        originY: "top",
        fill: "rgba(255, 255, 255, 0.01)",
      });

      //@ts-ignore
      box.set("index", activeBox.get("index") + 1);
      //@ts-ignore
      box.set("labelValue", nextLabelValue);
      //@ts-ignore
      box.set("plateScope", "user");
      //@ts-ignore
      box.set("originalLabelValue", nextLabelValue);
      //@ts-ignore
      box.set("accuracy", 100);
      //@ts-ignore
      box.set("strokeWidthUnscaled", 0.2);
      this.canvas.add(box);
    } else {
      box.set("height", height);
      box.set("width", width);
      box.set("top", top);
      box.set("left", left);
    }

    this.canvas.setActiveObject(box);
    this.canvas.bringToFront(box);
  };

  toggleAllBoxesVisiblity = () => {
    this.canvas
      .getObjects()
      .filter((o: any) => o.type === "rect")
      .forEach((r: any) => r.set("visible", !r.get("visible")));
    this.setState({ allBoxesHidden: !this.state.allBoxesHidden });
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  };

  getFirstBoxAfterActiveObjectByLabel = (labelValue: string) => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject || activeObject.get("index") === null) return null;

    const label = this.canvas
      .getObjects()
      .find(
        (o: any) =>
          o.get("labelValue") === labelValue &&
          o.get("index") &&
          o.get("index") > activeObject.get("index")
      );

    return label;
  };

  toggelShowPrediction = () =>
    this.setState({ showPrediction: !this.state.showPrediction });

  handlePaysChanged = (e: any) =>
    this.setState({ country: e.target.value, dirty: true });

  isOneBoxSelected = () =>
    this.canvas &&
    typeof this.canvas.getActiveObject() !== "undefined" &&
    this.canvas.getActiveObject() !== null;

  isLabelisationFinished = () => {
    const letterBoxes = this.getBoxes().filter(
      (b: any) => b.get("index") !== null && b.get("plateScope") === "user"
    );
    return this.getPlateBox() && letterBoxes.length > 0;
  };

  goToBatchesList = () =>
    this.props.history.push("/batches/0?sort=createdDate,desc");

  navigateToLastLabelized = () => {
    const {
      match: {
        params: { noBatch },
      },
      history,
      images,
    } = this.props;
    const indexFirstImageNonLabellisee = images.findIndex(
      (img: any) =>
        (img.labels.rectangleLabels.length === 0 &&
          (img.motif === null || img.motif === "")) ||
        !img.lastLabeledBy ||
        img.lastLabeledBy === "model"
    );

    if (indexFirstImageNonLabellisee !== -1) {
      history.push(`/labelerPlate/${noBatch}/${indexFirstImageNonLabellisee}`);
    }
  };

  public render() {
    const {
      classes,
      match: {
        params: { noImage, noBatch },
      },
      images,
      motifs,
      nomBatch,
    } = this.props;

    const {
      dirty,
      listImagesDrawerOpen,
      selectedIndex,
      predicting,
      country,
      skipDialogOpen,
      immatriculation,
      immatInputValue,
      plateBoxHidden,
      showPrediction,
      showHelp,
      justSaved,
      imageLoaded,
    } = this.state;

    const imagesWithLabelOrMotif = images.filter(
      (img: any) => img.labels.rectangleLabels.length > 0 || img.motif !== null
    );
    const nbrLabelisees = imagesWithLabelOrMotif.length;

    const image = this.getCurrentImage();
    const out = Number(noImage) >= images.length;
    const finished = nbrLabelisees === images.length;

    let alertOnClickHandler;
    if (out) alertOnClickHandler = () => this.navigateToLastLabelized();
    if (finished) alertOnClickHandler = () => this.goToBatchesList();

    let textAlert = "";
    let titreAlert = "";
    if (out) {
      if (!finished) {
        textAlert = "Une image n'a pas été labellisée";
        titreAlert = "Labellisation incomplète";
      } else {
        textAlert = "Labellisation de ce lot terminée !";
        titreAlert = "C'est fini !";
      }
    }

    const isLabelisationFinished = this.isLabelisationFinished();
    const imageCouranteLabelisee =
      image && image.labels.rectangleLabels.length > 0;

    const error =
      country === "FR" && immatInputValue.search(/[IOU]/g) !== -1
        ? "Caractères O,U et I non autorisés pour les plaques FR"
        : null;

    return (
      <Grid
        container
        spacing={16}
        direction="row"
        justify="flex-start"
        className={classes.root}
      >
        <Helmet>
          <title>
            Plate / {nomBatch.split("-")[0]} / {noImage}
          </title>
        </Helmet>
        {Number(noImage) >= images.length && (
          <ImageCache images={images} noImageToCache={Number(noImage) + 1} />
        )}
        {listImagesDrawerOpen && (
          <DrawerPhotoList
            open={listImagesDrawerOpen}
            images={imagesWithLabelOrMotif}
            noBatch={noBatch}
            noImage={noImage}
            labelingTypePathname="labelerPlate"
          />
        )}
        {imagesWithLabelOrMotif.length > 1 && (
          <DrawerButton
            onClick={this.handleToggleListeImagesDrawer}
            drawerOpen={listImagesDrawerOpen}
          />
        )}
        <Prompt
          when={dirty}
          message="Des modifications n'ont pas été sauvegardées. Continuer ?"
        />
        <AlertDialog
          open={out || finished}
          onClose={alertOnClickHandler}
          text={textAlert}
          title={titreAlert}
          onAccept={alertOnClickHandler}
          acceptLabel="OK"
        />
        <SkipDialog
          open={skipDialogOpen}
          onClose={this.handleCloseSkipDialog}
          motifSelected={image && image.motif}
          onAccept={this.changeMotif}
          motifs={motifs}
          i18nMapping={i18nMapping}
          defaultMotifIndex={0} //@TOCHECK
        />
        <Grid item xs={8} xl={9} className={classes.grow}>
          <Grid
            container
            direction="column"
            alignContent="stretch"
            alignItems="stretch"
            justify="flex-start"
            component={Paper}
            className={classNames(classes.leftCol, classes.fullHeight)}
          >
            <Grid item>
              {/** TODO finir de brancher LabelisationAbstract **/}
              <LabelisationAbstract
                noImage={noImage}
                nomBatch={nomBatch}
                images={images}
                dirty={dirty}
                justSaved={justSaved}
              />
            </Grid>
            <Grid item className={classNames(classes.padded, classes.grow)}>
              <div
                className={classes.canvasContainer}
                ref={this.canvasWrapperRef}
              >
                <canvas ref={this.canvasRef} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} xl={3} className={classes.grow}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            component={Paper}
            className={classes.fullHeight}
          >
            <Grid item>
              {image && (
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={8}
                >
                  <Grid item>
                    <Progression
                      total={images.length}
                      nbrLabelisees={nbrLabelisees}
                      onHelpClicked={this.handleToggleHelp}
                      showHelp={showHelp}
                    />
                  </Grid>
                  {!showHelp && (
                    <Grid item>
                      <ResponsiveSection
                        image={image}
                        immatriculation={immatriculation}
                        toggelShowPrediction={this.toggelShowPrediction}
                        showPrediction={showPrediction}
                        clearZoom={this.clearZoom}
                        classes={classes} //@TODO passer le style dans le composant
                        editImmatriculation={this.handleEditImmatriculation}
                      />
                    </Grid>
                  )}
                  {!showHelp && image.motif !== null && (
                    <Grid item className={classes.centered}>
                      <span>
                        Image passée :{" "}
                        <strong>{i18nMapping[image.motif]}</strong>
                      </span>
                      <button
                        className={classes.clearSkip}
                        onClick={this.clearSkip}
                      >
                        x
                      </button>
                    </Grid>
                  )}
                  {!showHelp &&
                    image &&
                    ((image.prediction && image.prediction.lpPred !== "") ||
                      immatriculation !== "") &&
                    image.motif === null && (
                      <LetterBoxes
                        letterBoxes={this.getBoxes().filter(
                          (b: any) =>
                            b.get("index") !== null && b.get("plateScope")
                        )}
                        onMouseOver={this.handleHover}
                        onFocus={this.handleFocus}
                        onChange={this.handleInputChange}
                        nbrBoxes={this.getBoxes().length}
                        selectedIndex={selectedIndex}
                        predictionCyclope={
                          image.prediction ? image.prediction.lpPred : null
                        }
                      />
                    )}
                  {!showHelp &&
                    !predicting &&
                    image &&
                    (!image.prediction || image.prediction.lpPred === "") &&
                    immatriculation === "" &&
                    !imageCouranteLabelisee &&
                    image.motif === null && (
                      <Grid item style={{ width: "100%" }}>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          component="form"
                          onSubmit={this.handleImmatSubmit}
                          spacing={8}
                          className={classes.paddedLeft}
                        >
                          <Grid item sm={12}>
                            <label>Immatriculation visible</label>
                          </Grid>
                          <Grid item sm={8}>
                            <UiInput
                              fullWidth={false}
                              onChange={this.handleImmatInputChange}
                              autoFocus
                              name="immat"
                              value={immatInputValue}
                              disabled={!!immatriculation}
                              className={classes.inputImmat}
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <UiButton
                              color="secondary"
                              variant="outlined"
                              type="submit"
                              fullWidth
                              style={{ height: "41px" }}
                              disabled={error !== null}
                            >
                              Valider
                            </UiButton>
                          </Grid>
                          <Grid item>
                            {error !== null && (
                              <div className={classes.error}>{error}</div>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  {!showHelp && image && image.motif === null && (
                    <Grid item>
                      <FormControl className={classes.fullWidth}>
                        <RadioGroup
                          aria-label="country"
                          name="country"
                          className={classes.country}
                          value={country}
                          onChange={this.handlePaysChanged}
                          row
                        >
                          <FormControlLabel
                            value="FR"
                            control={<Radio />}
                            label="France"
                            className={classes.countryItem}
                          />
                          <FormControlLabel
                            value="IT"
                            control={<Radio />}
                            label="Italie"
                            className={classes.countryItem}
                          />
                          <FormControlLabel
                            value="DE"
                            control={<Radio />}
                            label="Allemagne"
                            className={classes.countryItem}
                          />
                          <FormControlLabel
                            value="Au"
                            control={<Radio />}
                            label="Autre"
                            className={classes.countryItem}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {false &&
                    !showHelp &&
                    image &&
                    !image.prediction &&
                    immatriculation && (
                      <Grid item className={classes.fullWidth}>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          <Slide direction="right" in timeout={100}>
                            <Grid item className={classes.paddedLeft}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={8}
                              >
                                <Grid item>Immatriculation saisie :</Grid>
                                <Grid item>
                                  <strong className={classes.bold}>
                                    {immatriculation}
                                  </strong>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Slide>
                        </Grid>
                      </Grid>
                    )}
                  {showHelp && (
                    <Grid item className={classes.fullWidth}>
                      <DisplayKeyMapping keyMapping={this.keyMapping} />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item className={classes.fullWidth}>
              {image && (
                <Grid item style={{ flexGrow: 1 }}>
                  <CanvasButtons
                    onDeleteClicked={this.handleDeleteActiveBox}
                    onSaveClicked={this.handleSubmit}
                    onSkipClicked={this.handleOpenSkipDialog}
                    onNavigateBackClicked={this.handlePreviousImage}
                    onNavigateFwdClicked={this.handleNextImage}
                    selected={this.isOneBoxSelected()}
                    navigateBackVisible={imageLoaded && Number(noImage) > 0}
                    navigateFwdVisible={
                      imageLoaded && this.isNavigationFwdPossible()
                    }
                    someItemsHidden={plateBoxHidden}
                    nombreItems={isLabelisationFinished ? 1 : 0}
                    savingDisabled={
                      !isLabelisationFinished ||
                      showPrediction ||
                      error !== null
                    }
                    hiddenableShapeName="le rectangle de la plaque ( H )"
                    onToggleVisibility={this.togglePlateBoxVisiblity}
                    motif={image.motif}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withContext(LabelerPlate2)));
