const MAX_SIZE = 10;

export const getHistory = (userId: string): any[] => {
    let history = window.localStorage.getItem(`batches_${userId}`);
    if (!history) {
        window.localStorage.setItem(`batches_${userId}`, JSON.stringify([]));
        history = JSON.stringify([]);
    }
    return JSON.parse(history);
};

export const pushToHistory = (batchId: any, userId: string) => {
    const history = getHistory(userId);
    if (history.includes(batchId)) {
        return ;
    }
    if (history.length >= MAX_SIZE) history.shift();
    history.push(batchId);
    window.localStorage.setItem(`batches_${userId}`, JSON.stringify(history));
}