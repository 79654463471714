export const storeUser = (user: object): void => {
  window.localStorage.setItem("user", JSON.stringify(user));
};

export const clearUser = (): void => {
  window.localStorage.removeItem("user");
};

export const getUser = (): any => {
  const user = window.localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};
