import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import UiButton from "../../common/UiButton";
import MenuTransition from "../../common/MenuTransition";
import UiArrowIcon from "../../common/UiArrowIcon";
import ProjectType from "../../../types/Project";

interface PropTypes extends RouteComponentProps<any>, React.Props<any> {
  project: ProjectType;
  history: any;
  classes: any;
  isAdmin: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    fixedWidth: {
      paddingRight: theme.spacing.unit * 2,
    },
  });

const ProjectActionButton = (props: PropTypes) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { project, history, classes, isAdmin } = props;

  const onMenuClick = (event: any, index: any) => {
    if (index === 0) {
      history.push(
        `/projects/${project.id}/import/${
          project.dataSource === "ES" ? "es" : "dossierlocal"
        }`
      );
    } else if (index === 1) {
      history.push(`/projects/${project.id}/batches/create`);
    } else if (index === 2) {
      history.push(`/project/${project.id}?view=form`);
    } else {
      history.push(`/project/${project.id}?view=users`);
    }
    closeMenu();
  };

  const actionButtonClicked = (e: any) => setAnchorEl(e.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <UiButton
        variant="outlined"
        color="secondary"
        onClick={(e: any) => actionButtonClicked(e)}
        className={classes.fixedWidth}
      >
        Actions
        <UiArrowIcon />
      </UiButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        disableAutoFocusItem
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        TransitionComponent={MenuTransition}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem key={0} onClick={(e: any) => onMenuClick(e, 0)}>
          Importer les images
        </MenuItem>
        <MenuItem
          key={1}
          onClick={(e: any) => onMenuClick(e, 1)}
          disabled={project.stagingFolders.length === 0}
        >
          Générer des lots
        </MenuItem>
        {isAdmin && (
          <MenuItem key={2} onClick={(e: any) => onMenuClick(e, 2)}>
            Modifier le projet
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem key={1} onClick={(e: any) => onMenuClick(e, 3)}>
            Utilisateurs du projet
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default withStyles(styles)(withRouter(ProjectActionButton));
