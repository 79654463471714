import * as React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Labeler from "./labeler";
import LabelerPlate from "./labelerPlate";
import LabelerPlateText from "./labelerPlateText";
import labelerClass from "./labelerClass";
import labelerSegmentation from "./labelerSegmentation";
import mosaic from "./mosaic";
import Login from "./login";
import withAuthorization from "../tools/authorization";
import PrivateRoute from "../tools/privateRoute";
import RedirectTo from "../tools/redirectRoute";
import Batches from "./batches";
import Batch from "./batch";
import Import from "./import";
import Users from "./users";
import User from "./user";
import Notifications from "./notifications";
import Projects from "./projects";
import ProjectsOperations from "./projectsOperations";
import Project from "./project";
import ChangePwd from "./changePassword";
import ResetPwd from "./resetPassword";
import PwdForgotten from "./passwordForgotten/";
import HomePage from "./homepage";
import Page2FA from "./2FA";

const NotFound = () => <h1>Pas trouvé</h1>;

const ABatch = withAuthorization(withRouter(Batch), [
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const AMosaic = withAuthorization(withRouter(mosaic), [
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const AImport = withAuthorization(withRouter(Import), [
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const ALabeler = withAuthorization(Labeler, [
  "ROLE_LABELER",
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const ALabelerPlate = withAuthorization(withRouter(LabelerPlate), [
  "ROLE_LABELER",
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const ALabelerPlateText = withAuthorization(withRouter(LabelerPlateText), [
  "ROLE_LABELER",
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const AlabelerClass = withAuthorization(withRouter(labelerClass), [
  "ROLE_LABELER",
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const AlabelerSegmentation = withAuthorization(
  withRouter(labelerSegmentation),
  ["ROLE_LABELER", "ROLE_ADMIN", "ROLE_PROJECT_ADMIN"]
);

const AUsers = withAuthorization(withRouter(Users), [
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const AUser = withAuthorization(User, [
  "ROLE_LABELER",
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const ANotifications = withAuthorization(Notifications, ["ROLE_ADMIN"]);

const AProjects = withAuthorization(withRouter(Projects), [
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const AProjectsOperations = withAuthorization(withRouter(ProjectsOperations), [
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const AProject = withAuthorization(withRouter(Project), ["ROLE_ADMIN"]);

const ABatches = withAuthorization(withRouter(Batches), [
  "ROLE_LABELER",
  "ROLE_ADMIN",
  "ROLE_PROJECT_ADMIN",
]);

const MainRouter = (props: any) => {
  return (
    <React.Fragment>
      <Switch>
        <PrivateRoute
          path="/labeler/:noBatch/:noImage"
          component={ALabeler}
          exact
        />
        <PrivateRoute
          path="/mosaic/:noBatch/:noPage"
          component={AMosaic}
          exact
        />
        <PrivateRoute
          path="/labelerPlate/:noBatch/:noImage"
          component={ALabelerPlate}
          exact
        />
        <PrivateRoute
          path="/labelerPlateText/:noBatch/:noImage"
          component={ALabelerPlateText}
          exact
        />
        <PrivateRoute
          path="/labelerClass/:noBatch/:noImage"
          component={AlabelerClass}
          exact
        />
        <PrivateRoute
          path="/labelerSegmentation/:noBatch/:noImage/:w?/:h?"
          component={AlabelerSegmentation}
          exact
        />
        <PrivateRoute path="/new_user" component={AUser} exact />
        <PrivateRoute path="/users/:id" component={AUser} exact />
        <PrivateRoute path="/notifications" component={ANotifications} exact />
        <PrivateRoute path="/userlist/:noPage" component={AUsers} exact />
        <PrivateRoute
          path="/projects/:projectId/batches/create"
          component={ABatch}
          exact
        />
        <Route
          path="/projects/:projectId/import/:type"
          component={AImport}
          exact
        />
        <PrivateRoute path="/new_project" component={AProject} exact />
        <PrivateRoute path="/project/:id" component={AProject} exact />
        <PrivateRoute path="/projects/:noPage" component={AProjects} exact />
        <PrivateRoute
          path="/projectsOperations"
          component={AProjectsOperations}
          exact
        />
        <PrivateRoute path="/batches/:noPage" component={ABatches} exact />
        <PrivateRoute path="/change-password" component={ChangePwd} />
        <Route path="/login-change-pwd" component={ResetPwd} />
        <Route path="/login-new-pwd" component={PwdForgotten} />
        <RedirectTo
          path="/login"
          render={() => <Login onSubmitLogin={props.handleSubmitLogin} />}
        />
        <Route
          path="/qrcode"
          render={() => <Page2FA type="qrcode" onSubmitVerify={props.onSubmitVerify} getQrCode={props.getQrCode} />}
        />
        <Route
          path="/verifyCode"
          render={() => <Page2FA type="verify" onSubmitVerify={props.onSubmitVerify} getQrCode={props.getQrCode} />}
        />
        <Route path="/" component={HomePage} />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default MainRouter;
