import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%",
    },
    titre: {
      minWidth: "550px",
    },
    list: {
      height: 250,
      width: "100%",
      overflowY: "scroll",
      backgroundColor: "#faffff",
      border: "solid 1px #2948ff4d",
    },
  });

interface PropTypes {
  availableItems: any[];
  selectedItems: any[];
  classes: any;
  onSelectionUpdated: Function;
  itemTextRendrer: Function;
}

const SelectionDoubleList = (props: PropTypes) => {
  const { classes, itemTextRendrer, onSelectionUpdated } = props;
  // const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [
    selectedItemsSelectionIndexes,
    setSelectedItemsSelectionIndexes,
  ] = useState<number[]>([]);

  const [availableItems, setAvailableItems] = useState<any[]>([]);
  const [
    availableItemsSelectionIndexes,
    setAvailableItemsSelectionIndexes,
  ] = useState<number[]>([]);

  const handleSelectAvailableItem = (e: any, index: number) =>
    setAvailableItemsSelectionIndexes(
      availableItemsSelectionIndexes.includes(index)
        ? availableItemsSelectionIndexes.filter((idx: number) => idx !== index)
        : availableItemsSelectionIndexes.concat([index])
    );

  const handleSelectUserProject = (e: any, index: number) =>
    setSelectedItemsSelectionIndexes(
      selectedItemsSelectionIndexes.includes(index)
        ? selectedItemsSelectionIndexes.filter((idx: number) => idx !== index)
        : selectedItemsSelectionIndexes.concat([index])
    );

  const handleAdd = (e: any) => {
    const itSel = availableItemsSelectionIndexes.map((idx: number) => availableItems[idx]);
    // setSelectedItems(selectedItems.concat(itSel));
    setAvailableItemsSelectionIndexes([]);
    onSelectionUpdated("add", itSel);
  };

  const handleRemove = (e: any) => {
    // setSelectedItems(
    //   selectedItems.filter(
    //     (uP: any, index: number) =>
    //       !selectedItemsSelectionIndexes.includes(index)
    //   )
    // );
    setSelectedItemsSelectionIndexes([]);
    onSelectionUpdated("remove", selectedItemsSelectionIndexes.map((idx: number) => props.selectedItems[idx]));
  };

  useEffect(() => setAvailableItems(props.availableItems), [
    props.availableItems,
  ]);

  // useEffect(() => setSelectedItems(props.selectedItems), [
  //   props.selectedItems,
  // ]);


  return (
    <Grid
      container
      direction="row"
      spacing={8}
      justify="center"
      style={{ padding: "1em" }}
    >
      <Grid item xs={5}>
        <Grid container direction="column">
          <Grid item>
            <Typography>Utilisateurs non assignés</Typography>
          </Grid>
          <Grid item>
            <List className={classes.list}>
              {availableItems.map((i: any, index: number) => (
                <ListItem
                  key={index}
                  button
                  dense
                  onClick={(e) => handleSelectAvailableItem(e, index)}
                  title={i.id}
                  selected={availableItemsSelectionIndexes.includes(index)}
                >
                  <ListItemText primary={itemTextRendrer(i)} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} style={{ textAlign: "center" }}>
        <Grid
          container
          direction="column"
          justify="center"
          style={{ height: "100%" }}
          spacing={8}
        >
          <Grid item>
            <Button
              onClick={handleAdd}
              disabled={
                availableItemsSelectionIndexes.length === 0
                // ||
                // batchUsers.length >= selectionMax
              }
              variant="outlined"
              size="small"
              style={{ minWidth: "15px" }}
            >
              <ChevronRightIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleRemove}
              disabled={selectedItemsSelectionIndexes.length === 0}
              variant="outlined"
              size="small"
              style={{ minWidth: "15px" }}
            >
              <ChevronLeftIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Typography>Labelers sélectionnés</Typography>
        <List className={classes.list}>
          {props.selectedItems.map((i: any, index: number) => (
            <ListItem
              key={index}
              button
              dense
              onClick={(e) => handleSelectUserProject(e, index)}
              selected={selectedItemsSelectionIndexes.includes(index)}
              title={i.id}
            >
              <ListItemText primary={itemTextRendrer(i)} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SelectionDoubleList);
