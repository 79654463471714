import React from "react";
import { ReactSortable } from "react-sortablejs";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Tag from "./Tag";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      marginBottom: "20px",
    },
  });

const Tags = (props: any) => {
  const { datas, setList, removeTag, classes, currentTag } = props;

  const handleRemoveTag = (id: any) => {
    removeTag(id);
  };

  return (
    <ReactSortable
      list={datas}
      setList={setList}
      animation={150}
      className={classes.root}
    >
      {props.datas.map((item: any) => (
        <Tag
          key={item.id}
          label={item.id}
          shake={item.id === currentTag}
          onClick={() => handleRemoveTag(item.id)}
        />
      ))}
    </ReactSortable>
  );
};

export default withStyles(styles)(Tags);
