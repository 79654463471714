import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import UISelectMultipleProjects from "../common/UISelectMultipleProjects";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: `calc(100vh - 64px - ${theme.spacing.unit * 5}px)`,
    },
    form: {
      padding: theme.spacing.unit * 2,
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.04),0px 8px 10px 1px rgba(0,0,0,0.03),0px 3px 14px 2px rgba(0,0,0,0.04)",
      width: "100%",
    },
    btn: {
      margin: theme.spacing.unit,
    },
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
  });

const Notifications = (props: any) => {
  const {
    classes,
    projects,
    saveReportingConf,
    notifSettings,
    sendReport,
    requestSent,
  } = props;
  const [datas, setDatas] = useState<any>(notifSettings);
  const [updated, setUpdated] = useState(false);

  const handleChange = (e: any) => {
    setUpdated(true);
    setDatas({
      ...datas,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeCB = (key: string, e: any) => {
    setUpdated(true);
    setDatas({ ...datas, [key]: !datas[key] });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    saveReportingConf(datas);
  };

  const handleSendReport = () => {
    sendReport();
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={5}>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            className={classes.form}
          >
            <Grid item>
              <UISelectMultipleProjects
                label="Notifications mail pour les projets"
                handleChange={handleChange}
                datas={datas}
                projects={projects}
                required
                disabled={requestSent}
              />
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={datas.batchCompletedReport}
                        disabled={requestSent}
                        onChange={(e: any) =>
                          handleChangeCB("batchCompletedReport", e)
                        }
                        value="batchCompletedReport"
                      />
                    }
                    label="Me notifier par mail dès qu'un lot est labellisé et qu'il doit être vérifié"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={datas.mondayReport}
                        disabled={requestSent}
                        onChange={(e: any) => handleChangeCB("mondayReport", e)}
                        value="mondayReport"
                      />
                    }
                    label=" M'envoyer par mail un rapport d'avancement sur mes projets tous les lundis matins"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Button
                    title="M'envoyer un rapport d'avancement sur mes projets tout de suite par mail"
                    className={classes.btn}
                    variant="outlined"
                    onClick={handleSendReport}
                    disabled={requestSent}
                  >
                    <SendIcon className={classes.leftIcon} />
                    Rapport express
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.btn}
                    disabled={requestSent}
                  >
                    {updated ? "Modifier" : "Valider"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Notifications);
