import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Button from "@material-ui/core/Button";

const styles = (theme: Theme) =>
  createStyles({
    disabled: {
      color: "silver"
    }
  });

interface PropTypes {
  className?: string;
  classes: any;
  children: any;
  color?: any;
  variant?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: any;
  tabIndex?: number;
  style?: any;
  onClick?: any;
  title?: string;
  href?: string;
  autoFocus: boolean;
  outlined?: string;
  to?: string;
  component?: any;
}

const UiButton = (props: PropTypes) => {
  const {
    variant,
    color,
    children,
    className,
    classes,
    disabled,
    ...rest
  } = props;
  return (
    <Button
      {...rest}
      variant={variant}
      color={color}
      disabled={disabled}
      className={classNames(className, {
        [classes.disabled]: disabled
      })}
    >
      {children}
    </Button>
  );
};

UiButton.defaultProps = {
  type: "submit",
  fullWidth: false,
  autoFocus: false
};

export default withStyles(styles)(UiButton);
