import React from "react";
import { withRouter, Link } from "react-router-dom";
import { parse, format, addHours } from "date-fns";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import withContext from "../../tools/withContext";
import EnhancedTableHeader from "../common/EnhancedTableHeader";
import TableBody from "../common/TableBody";
import ProjectActionButton from "../projects/components/ProjectActionButton";
import UiProgress from "../common/UiProgess";
import ProjectType from "../../types/Project";
import ContextType from "../../types/Context";
import UserType from "../../types/User";
import DimensionsContainer from "../common/DimensionsContainer";

const rows = [
  {
    id: "name",
    numeric: false,
    sortable: false,
    disablePadding: true,
    label: "Nom du projet",
    onlyRoles: null,
    size: 2,
    align: "center",
  },
  {
    id: "labelingType",
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: "Type de labellisation",
    onlyRoles: null,
    size: 3,
    align: "center",
  },
  {
    id: "operationType",
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: "Opération",
    onlyRoles: null,
    size: 3,
    align: "center",
  },
  {
    id: "createdAt",
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: "Lancée le",
    onlyRoles: null,
    size: 2,
    align: "center",
  },
  {
    id: "secondsToFinish",
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: "Lots",
    onlyRoles: null,
    size: 3,
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: "Actions",
    onlyRoles: null,
    size: 1,
    align: "right",
  },
];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 50,
    },
    noData: {
      textAlign: "center",
      padding: theme.spacing.unit * 2,
    },
    toolbar: {
      textAlign: "right",
      padding: theme.spacing.unit * 2,
    },
    progress: {
      padding: `0 ${theme.spacing.unit * 2}px`,
    },
    fullWidth: {
      width: "100%",
    },
  });

interface PropsType {
  classes: any;
  ctx: ContextType;
  progress: any[];
  deleteProject: Function;
  users: UserType[];
  isLoading: boolean;
  theme: any;
  pagination: any;
  history: any;
  match: any;
  appFetch: any;
  searchTerm: string;
  setSearchTerm: Function;
}

interface ProgressType {
  operationType: "UPLOAD_FILES" | "CREATE_BATCH";
  status: "FINISHED" | "STARTED";
  nbDone: number;
  nbTotal: number;
  secondsToFinish: number;
  createdAt: string;
  updatedAt: string;
  messageError: null | string;
}

interface ProjectWithProgressType extends ProjectType {
  progress: ProgressType;
}

const ProjectsOperations = (props: PropsType) => {
  const {
    classes,
    progress,
    isLoading,
    match: {
      params: { noPage },
    },
    ctx: { user },
  } = props;

  const buildColumns = (progressData: any) => {
    let operation =
      progressData.operationType === "CREATE_BATCH"
        ? "Génération des lots : "
        : "Importation des images :";

    return [
      <div>{progressData.project.name}</div>,
      <div>{progressData.project.labelingType}</div>,
      <div>{progressData.operationType}</div>,
      <div>
        {format(addHours(parse(progressData.createdAt), 2), "DD/MM à HH:mm:ss")}
      </div>,
      <div>
        {progressData.status !== "FINISHED" && (
          <div className={classes.progress}>
            <UiProgress
              variant="determinate"
              color="secondary"
              value={(progressData.nbDone * 100) / progressData.nbTotal}
            />
            <small style={{ display: "block", textAlign: "left" }}>
              {operation} {progressData.nbDone}/{progressData.nbTotal} images
            </small>
          </div>
        )}
        {progressData.operationType === "CREATE_BATCH" &&
          progressData.status === "FINISHED" &&
          progressData.project.totalBatches > 0 && (
            <Link
              to={`/batches/0?projectId=${progressData.project.id}&sort=createdDate,desc`}
            >
              Afficher les lots
            </Link>
          )}
        {progressData.operationType !== "CREATE_BATCH" &&
          progressData.status === "FINISHED" && (
            <span>{`${operation} terminée`}</span>
          )}
      </div>,
      <div>
        <ProjectActionButton
          project={progressData.project}
          isAdmin={isAdmin}
        />
      </div>,
    ];
  };

  const isAdmin = user && user.authorities[0] === "ROLE_ADMIN";

  return (
    <Grid container direction="column" className={classes.root}>
      <Helmet>
        <title>Importations récentes</title>
      </Helmet>
      <Grid item className={classes.fullWidth}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.toolbar}
        >
          <Grid item>
            {
              //@ts-ignore
              <Typography component="h2" style={{ fontSize: "2.5em" }}>
                Liste des opérations récentes
              </Typography>
            }
          </Grid>
          <Grid item>{isLoading && <CircularProgress color="primary" />}</Grid>
        </Grid>
      </Grid>
      {progress.length === 0 && !isLoading && (
        <Grid item>
          <Typography className={classes.noData} variant="h4">
            Aucune opération récente
          </Typography>
        </Grid>
      )}
      {progress.length > 0 && (
        <>
          <Grid item className={classes.fullWidth}>
            <EnhancedTableHeader
              rowCount={progress.length}
              rows={rows}
              withCheck={false}
              actionsCellSize={3}
            />
          </Grid>
          {rows.length > 0 && (
            <DimensionsContainer>
              <TableBody
                selected={[]}
                handleSelection={null}
                buildColumns={buildColumns}
                datas={progress}
                rows={rows}
                noPage={noPage}
              />
            </DimensionsContainer>
          )}
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withContext(withTheme()(ProjectsOperations)))
);
