import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import UiSelect from "../../common/UiSelect";
import withContext from "../../../tools/withContext";
import constants from "../../../tools/constants";
import TagListSample from "./TagListSample";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing.unit * 2,
    },
  });

interface PropTypes {
  tagListId: string;
  ctx: any;
  onChange: any;
  classes: any;
  value: any;
  disabled: boolean;
  tagLists: any[];
}

interface TagType {
  id: string;
  name: string;
}

const TagList = (props: PropTypes) => {
  const {
    ctx: { appFetch },
    tagListId,
    onChange,
    value,
    tagLists,
    disabled,
  } = props;
  const [tags, setTags] = useState<any>({});

  useEffect(() => {
    const promises = tagLists.map((t: TagType) =>
      appFetch(`${constants.paths.TAG_LIST}/${t.id}`)
    );
    Promise.all(promises).then((res: any) => {
      setTags({
        ...res.reduce(
          (memo: any, item: any) => ({
            ...memo,
            [item.id]: item.tags.slice(0, 4).concat(["..."]),
          }),
          {}
        ),
      });
    });
  }, []);

  return (
    <>
      <UiSelect
        name="tagListId"
        required
        disabled={disabled}
        value={value}
        onChange={onChange}
        label=""
      >
        <option value="">Aucune liste sélectionnée</option>
        {Object.keys(tags).length > 0 &&
          tagLists.map((tagList: any) => (
            <option
              key={tagList.id}
              value={tagList.id}
              title={tags[tagList.id].join(", ")}
            >
              {tagList.name}
            </option>
          ))}
      </UiSelect>
      {tagListId && <TagListSample tags={tags[tagListId]} />}
    </>
  );
};

export default withContext(withStyles(styles)(TagList));
