import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import UiButton from "../../common/UiButton";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      padding: "9px 16px",
      minWidth: "250px",
    },
  });

interface PropsType {
  classes: any;
}

const ProjectsActionButton = (props: PropsType) => {
  const { classes } = props;
  return (
    <React.Fragment>

      {<UiButton
        color="secondary"
        variant="contained"
        className={classes.button}
        to="/new_project"
        component={Link}
      >
        Nouveau projet
      </UiButton>}
    </React.Fragment>
  );
};

export default withStyles(styles)(ProjectsActionButton);
