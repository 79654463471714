import React from "react";
import classNames from "classnames";
import { withTheme } from "@material-ui/core/styles";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";

const styles = (theme: Theme) =>
  createStyles({
    nbrImagesG: {
      padding: `${theme.spacing.unit}px 0`,
      color: "#8F9DEF"
    },
    enhanced: {
      color: "black"
    },
    noPadding: {
      padding: 0
    },
    justSaved: {
      color: "green"
    }
  });

interface PropTypes {
  classes: any;
  noImage: Number;
  nomBatch: string;
  images: any[];
  dirty: boolean;
  justSaved: boolean;
  theme: Theme;
}

const LabelisationAbstract = (props: PropTypes) => {
  const { classes, noImage, nomBatch, images, dirty, justSaved, theme } = props;
  let matches = useMediaQuery(theme.breakpoints.up("xl"));
  return (
    <Grid container direction="row" justify="space-between">
      <Grid item>
        <Typography
          className={classNames(classes.nbrImagesG, {
            [classes.noPadding]: !matches
          })}
        >
          Image{" "}
          <span className={classes.enhanced}>n° {Number(noImage) + 1}</span> du
          lot <span className={classes.enhanced}>{nomBatch}</span> qui comporte{" "}
          <span className={classes.enhanced}>{images.length} images</span>{" "}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          component="span"
          className={classNames(classes.nbrImagesG, {
            [classes.noPadding]: !matches,
            [classes.justSaved]: justSaved
          })}
        >
          {dirty && !justSaved && "Modifiée"}
          {!dirty && justSaved && "Sauvegardé !"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTheme()(LabelisationAbstract));
