import * as React from "react";
import Helmet from "react-helmet";
import { withRouter, Prompt } from "react-router-dom";
import { fabric } from "fabric";
import { isEqual, minBy, maxBy } from "lodash";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import DrawerPhotoList from "../common/DrawerPhotoList.jsx";
import DrawerButton from "../common/DrawerButton";
import CanvasButtons from "../common/CanvasButtons";
import Labels from "../common/Labels";
import Progression from "../common/Progression";
import DisplayKeyMapping from "../common/DisplayKeyMapping";
import SkipDialog from "../common/SkipDialog";
import withContext from "../../tools/withContext";
import fitInBox, { size } from "../../tools/fitInBox";
import AlertDialog from "../common/AlertDialog";
import ImageCache from "../common/ImageCache";
import LabelisationAbstract from "../common/LabelisationAbstract";
import computeBorderedPointCoords from "./tools/computeBorderedPointCoords";

interface PointType {
  x: number;
  y: number;
}

interface Polygon {
  labelValue: string;
  points: PointType[];
  done: boolean;
  deleted: boolean;
}

interface IState {
  dirty: boolean;
  closing: boolean;
  currentImageIndex: number;
  skipDialogOpen: boolean;
  listImagesDrawerOpen: boolean;
  error: any;
  polygons: Polygon[];
  labelSelectedIndex: any;
  polygonJustUpdated: string | null;
  polygonPointUpdatedIndex: number | null;
  somePolygonHidden: boolean;
  keepLabelsOnNextImage: boolean;
  polySelectedName: string;
  origX: any;
  origY: any;
  appendModeFirstNode: any; // appendMode
  appendModeLastNode: any;
  appendMode: boolean;
  selectMode: boolean;
  borderMode: boolean;
  majDown: boolean;
  showHelp: boolean;
  justSaved: boolean;
  justDoubleClicked: boolean;
  imageLoaded: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      paddingTop: 50,
    },
    canvasContainer: {
      backgroundColor: "#edf2ff",
      margin: "0 auto",
      width: "100%",
      height: "100%",
    },
    nbrImagesG: {
      padding: `${theme.spacing.unit}px 0`,
    },
    button: {
      lineHeight: "27px",
    },
    motifButton: {
      textAlign: "center",
      marginTop: theme.spacing.unit * 2,
    },
    centered: {
      textAlign: "center",
    },
    fullWidth: {
      width: "100%",
    },
  });

const i18nMapping: any = {
  UNREADABLE: "Image floue",
  CROPPED: "Image tronquée",
  OCCLUSION: "Occlusion",
};

const SEPARATOR = "||";
const SAVED_FEEDBACK_DURATION = 1500;
const BORD_DETECT_LIMIT = 50;

class LabelerSegmentation extends React.Component<any, IState> {
  private canvasRef = React.createRef<HTMLCanvasElement>();
  private canvasWrapperRef = React.createRef<HTMLDivElement>();
  private canvas: any;
  private image: any;
  private scaling: any;
  private borderModeIndicator: any;
  private borderModeMaskItems: any[];
  public canvasContext: any;
  public keyMapping: any;

  constructor(props: any) {
    super(props);
    const {
      match: {
        params: { noImage, w, h },
      },
    } = props;

    this.state = {
      dirty: false,
      origX: null,
      origY: null,
      closing: false,
      currentImageIndex: noImage,
      skipDialogOpen: false,
      listImagesDrawerOpen: false,
      error: null,
      labelSelectedIndex: 0,
      polygons: [],
      polygonJustUpdated: null, // name of poly
      polygonPointUpdatedIndex: null,
      somePolygonHidden: false,
      keepLabelsOnNextImage: false,
      borderMode: false,
      appendMode: false,
      appendModeFirstNode: null,
      appendModeLastNode: null,
      selectMode: false,
      polySelectedName: "",
      majDown: false,
      showHelp: false,
      justSaved: false,
      justDoubleClicked: false,
      imageLoaded: false,
    };

    const imageHeight = h || props.height;
    const imageWidth = w || props.width;
    this.scaling = {
      y: 1,
      x: 1,
      newWidth: w || props.width,
      newHeight: props.height,
      ratio: imageWidth / imageHeight,
    };
    this.keyMapping = {
      i: "Sauvegarde et copie les formes sur l'image suivante",
      "Suppr ou d": "Supprime le noeud ou la forme sélectionnée",
      w: "Supprimer toutes les formes",
      Entrée: "Sauvegarde",
      Tab: "Label suivant",
      "Maj + Tab": "Label précédent",
      b: "Bord mode, créer les points sur le bord de l'image",
      a: "Append mode",
      s: "Select mode",
      "Maj + Déplacement souris": "se déplacer dans l'image zoomée",
      Esc: "Dé-zoom et recentre l'image",
    };
    this.borderModeIndicator = null;
    this.borderModeMaskItems = [];
    setTimeout(() => this.loadImage(), 100);
  }

  componentDidMount = () => {
    if (this.canvasRef.current !== null) {
      //@ts-ignore
      this.configureFabricJSCanvas(this.canvasRef.current).then(
        (canvas: any) => {
          this.canvas = canvas;
          this.bindEvents();
          this.createBorderModeMask();
          if (
            this.props.match.params.noImage !== `${this.props.images.length}`
          ) {
            this.loadImage();
          }
        }
      );
    }
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (this.props.match.params.noImage !== nextProps.match.params.noImage) {
      // on efface l'image en cours, sauf si c'est terminé
      if (nextProps.match.params.noImage !== `${nextProps.images.length}`) {
        this.clearAllByType("rect");
        this.clearAllByType("polygon");
        this.canvas.remove(this.image);
        this.image = null;
      }
    }

    if (!isEqual(this.props.labels, nextProps.labels)) {
      this.updateBoxesVisibility(nextProps.labels);
    }

    if (!isEqual(this.props.keyEvent, nextProps.keyEvent)) {
      if (nextProps.keyEvent.type === "keyup") {
        this.handleKeyUp(nextProps.keyEvent);
      } else {
        this.handleKeyDown(nextProps.keyEvent);
      }
    }

    const thisPropsImg = this.props.images[
      Number(this.props.match.params.noImage)
    ];
    const nextPropsImg =
      nextProps.images[Number(nextProps.match.params.noImage)];
    if (
      thisPropsImg &&
      nextPropsImg &&
      thisPropsImg.id === nextPropsImg.id &&
      ((thisPropsImg.motif === null && nextPropsImg.motif !== null) ||
        // modification d'un motif existant
        (thisPropsImg.motif !== null &&
          nextPropsImg.motif !== null &&
          thisPropsImg.motif !== nextPropsImg.motif))
    ) {
      console.log("motif changed");
      setTimeout(() => {
        this.handleSave();
      }, 250);
    }
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (
      Number(prevProps.match.params.noImage) !==
      Number(this.props.match.params.noImage)
    ) {
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      this.canvas.zoomToPoint({ x: 0, y: 0 }, 1);
      if (this.props.match.params.noImage !== `${this.props.images.length}`) {
        this.loadImage();
      }
    }

    if (
      this.state.polygonJustUpdated &&
      prevState.polygonJustUpdated !== this.state.polygonJustUpdated
    ) {
      const { justDoubleClicked } = this.state;
      const polyUpdt = this.state.polygons.find(
        (p) => p.labelValue === this.state.polygonJustUpdated && !p.deleted
      );

      console.log(
        "polygonJustUpdated",
        this.state.polygonJustUpdated,
        polyUpdt
      );
      // quand polygone clos ou tous les pts du polygone supprimés sauf 1 ->  points.length === 1
      if (polyUpdt && polyUpdt.done && polyUpdt.points.length === 1) {
        const polygons = this.state.polygons.filter(
          (poly: any) => poly.labelValue !== this.state.polygonJustUpdated
        );
        this.setState({
          polygons,
          polygonJustUpdated: null,
          justDoubleClicked: false,
        });
        if (polygons.length === 0) {
          this.clearAllByType("rect");
        } else {
          if (justDoubleClicked) {
            this.saveLabels();
          }
        }
      } else {
        this.drawPolygon(polyUpdt, this.state.polygonPointUpdatedIndex);
      }
    }

    if (!prevState.justSaved && this.state.justSaved) {
      setTimeout(
        () => this.setState({ justSaved: false }),
        SAVED_FEEDBACK_DURATION
      );
    }

    // if (prevState.motifSkip === "" && this.state.motifSkip !== "") {
    //   this.handleSave();
    // }

    if (prevState.borderMode && !this.state.borderMode) {
      this.removeBorderModeIndicator();
    }

    if (
      prevState.appendModeFirstNode !== null &&
      prevState.appendModeLastNode === null &&
      this.state.appendModeLastNode !== null
    ) {
      this.appendNodes();
    }

    if (!isEqual(prevState.polygons, this.state.polygons)) {
      console.log("poly", this.state.polygons);
    }
  };

  configureFabricJSCanvas = (ref: HTMLCanvasElement) =>
    new Promise((resolve: any) => {
      const canvas = new fabric.Canvas(ref);
      const { width, height } = this.props;
      setTimeout(() => {
        //@ts-ignore
        const { offsetHeight, offsetWidth } = this.canvasWrapperRef.current;
        const taille: size = fitInBox(
          width,
          height,
          offsetWidth,
          offsetHeight,
          true
        );

        const { height: newHeight, width: newWidth } = taille;
        canvas.setWidth(newWidth);
        canvas.setHeight(newHeight);
        // canvas.uniScaleTransform = true;
        canvas.selection = false;
        canvas.defaultCursor = "pointer";
        canvas.selection = false;

        this.scaling = {
          x: newWidth / width,
          y: newHeight / height,
          newHeight,
          newWidth,
        };
        resolve(canvas);
      }, 50);
    });

  bindEvents = () => {
    this.canvas.on("mouse:up", this.handleMouseUp);
    this.canvas.on("mouse:wheel", this.handleMouseWheel);
    // this.canvas.on("mouse:hover", this.handleMouseHover);
    this.canvas.on("mouse:move", this.handleMouseMove);

    this.canvas.on("object:moved", this.handleObjectMoved);
    this.canvas.on("selection:created", this.handleSelectionCreated);
    this.canvas.on("selection:cleared", this.handleSelectionCleared);
    this.canvas.on("mouse:dblclick", this.handleDoubleClick);

    this.canvas.on("mouse:over", (e: any) => {
      if (
        e.target &&
        e.target.get("type") === "rect" &&
        !e.target.get("mask")
      ) {
        console.log(e.target.get("name"));
        // e.target.set("fill", "blue");
        e.target.set("stroke", "red");
        this.canvas.setCursor("pointer");
        this.canvas.renderAll();
      }
    });

    this.canvas.on("mouse:out", (e: any) => {
      this.whitify();
    });
  };

  createBorderModeMask = () => {
    const { newWidth, newHeight } = this.scaling;
    [
      [
        BORD_DETECT_LIMIT,
        newHeight - BORD_DETECT_LIMIT,
        newWidth - 2 * BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        0.3,
      ],
      [
        BORD_DETECT_LIMIT,
        0,
        newWidth - 2 * BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        0.3,
      ],
      [
        0,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        newHeight - 2 * BORD_DETECT_LIMIT,
        0.3,
      ],
      [
        newWidth - BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        newHeight - 2 * BORD_DETECT_LIMIT,
        0.3,
      ],
      [
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        0.3,
      ],
      [
        newWidth - BORD_DETECT_LIMIT * 2,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        0.3,
      ],
      [
        BORD_DETECT_LIMIT,
        newHeight - BORD_DETECT_LIMIT * 2,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        0.3,
      ],
      [
        newWidth - BORD_DETECT_LIMIT * 2,
        newHeight - BORD_DETECT_LIMIT * 2,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        0.3,
      ],
      [0, 0, BORD_DETECT_LIMIT, BORD_DETECT_LIMIT, 0.3],
      [
        newWidth - BORD_DETECT_LIMIT,
        0,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        0.3,
      ],
      [
        0,
        newHeight - BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        0.3,
      ],
      [
        newWidth - BORD_DETECT_LIMIT,
        newHeight - BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        BORD_DETECT_LIMIT,
        0.3,
      ],
    ].forEach(([left, top, width, height, opacity]) => {
      const mask = new fabric.Rect({
        top,
        left,
        width,
        height,
        fill: `rgba(0, 0, 0, ${opacity})`,
        strokeWidth: 0,
        visible: false,
        selectable: false,
      });

      //@ts-ignore
      mask.set("mask", true);
      this.canvas.add(mask);
      this.borderModeMaskItems.push(mask);
    });
  };

  handleKeyDown = (e: any) => {
    if (e.key === "ArrowLeft" && Number(this.props.match.params.noImage) > 0) {
      this.handlePreviousImage();
    }

    if (e.key === "ArrowRight" && this.isNavigationFwdPossible()) {
      this.handleNextImage();
    }

    if (e.key === "b") {
      this.setState({ borderMode: true });
      this.borderModeMaskItems.forEach((m: any) => {
        m.set("visible", true);
        this.canvas.bringToFront(m);
      });
      this.canvas.renderAll();
    }

    if (e.key === "s" || e.key === "S") {
      this.setState({ selectMode: true });
      this.canvas.setCursor("grab");
    }

    if (e.key === "a") {
      this.setState({ appendMode: true });
    }

    if (e.key === "Escape") {
      this.clearZoom();
    }

    if (e.key === "Tab") {
      let labelSelectedIndex = 0;
      if (e.shiftKey) {
        labelSelectedIndex =
          this.state.labelSelectedIndex > 0
            ? this.state.labelSelectedIndex - 1
            : this.props.labels.length - 1;
      } else {
        labelSelectedIndex =
          this.state.labelSelectedIndex < this.props.labels.length - 1
            ? this.state.labelSelectedIndex + 1
            : 0;
      }
      this.setState({ labelSelectedIndex });
    }

    if (e.key === "Enter" && this.state.imageLoaded) {
      const polygonsDone = this.state.polygons.filter((poly: any) => poly.done);
      const polygonsNotDone = this.state.polygons.filter(
        (poly: any) => !poly.done
      );
      if (polygonsDone.length === 0) {
        this.changeMotif("UNREADABLE");
      } else {
        if (polygonsNotDone.length > 0) {
          alert("Un polygone n'a pas été cloturé !");
        } else {
          this.saveLabels();
        }
      }
    }

    if (e.key === "Control") {
      this.setState({ closing: true });
    }

    if (e.key === "i" || e.key === "I") {
      this.setState({ keepLabelsOnNextImage: true });
      setTimeout(() => this.saveLabels(), 100);
    }

    if (e.key === "Shift") {
      this.setState({ majDown: true });
      this.canvas.setCursor("move");
    }

    if (e.key === "w") {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Supprimer toutes les labellisations ?")) {
        this.clearAll();
        this.setState({ polygons: [] });
      }
    }

    if (["Delete", "d", "D"].includes(e.key)) {
      this.handleDeleteObject();
    }
  };

  handleKeyUp = (e: any) => {
    if (e.key === "b") {
      this.setState({ borderMode: false });
      this.borderModeMaskItems.forEach((m: any) => {
        m.set("visible", false);
        this.canvas.bringToFront(m);
      });
      this.removeBorderModeIndicator();
    }

    if (e.key === "s") {
      this.setState({ selectMode: false });
      this.canvas.setCursor("pointer");
    }

    if (e.key === "a") {
      this.setState({ appendMode: false });
    }

    if (e.key === "Escape") {
      this.canvas.setCursor("pointer");
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      this.setState({ origX: null, origY: null });
    }

    if (e.key === "Shift") {
      this.canvas.setCursor("pointer");
      this.setState({ majDown: false, origX: null, origY: null });
    }
  };

  clearZoom = () => {
    this.canvas.setZoom(1);
    this.canvas.absolutePan({
      x: 0,
      y: 0,
    });
  };

  handleMouseMove = (e: any) => {
    const { majDown, selectMode, borderMode } = this.state;
    // touche Shift enfoncée + mouvement souris => panning
    if (selectMode) this.canvas.setCursor("grab");
    if (majDown) this.canvas.setCursor("move");

    if (!selectMode && !majDown) {
      const numberPolygonDone = this.polygonDoneCount();
      const polyNotDone = this.findPolygonNotDone(numberPolygonDone);
      this.canvas.setCursor(polyNotDone ? "crosshair" : "default");
    }

    if (borderMode) {
      // determiner le bord
      const LINE_LENGTH = BORD_DETECT_LIMIT / 4;
      const borderModeIndicator = this.borderModeIndicator
        ? this.borderModeIndicator.get("borderSide")
        : null;
      const coords = computeBorderedPointCoords(
        this.canvas.getPointer(e.e),
        this.scaling,
        BORD_DETECT_LIMIT,
        borderModeIndicator
      );
      const fill = "#FFF";
      let indicatorCoords;
      switch (coords.border) {
        case "bottom":
          indicatorCoords = [
            coords.x,
            coords.y,
            coords.x,
            coords.y - LINE_LENGTH,
          ];
          break;
        case "top":
          indicatorCoords = [
            coords.x,
            coords.y,
            coords.x,
            coords.y + LINE_LENGTH,
          ];
          break;
        case "right":
          indicatorCoords = [
            coords.x,
            coords.y,
            coords.x - LINE_LENGTH,
            coords.y,
          ];
          break;
        case "left":
          indicatorCoords = [
            coords.x,
            coords.y,
            coords.x + LINE_LENGTH,
            coords.y,
          ];
          break;
        case "rightBottom":
          indicatorCoords = [
            coords.x,
            coords.y,
            coords.x - LINE_LENGTH,
            coords.y - LINE_LENGTH,
          ];
          break;
        case "leftBottom":
          indicatorCoords = [
            coords.x,
            coords.y,
            coords.x + LINE_LENGTH,
            coords.y - LINE_LENGTH,
          ];
          break;
        case "rightTop":
          indicatorCoords = [
            coords.x,
            coords.y,
            coords.x - LINE_LENGTH,
            coords.y + LINE_LENGTH,
          ];
          break;
        case "leftTop":
          indicatorCoords = [
            coords.x,
            coords.y,
            coords.x + LINE_LENGTH,
            coords.y + LINE_LENGTH,
          ];
          break;
      }

      if (!indicatorCoords) {
        this.removeBorderModeIndicator();
        return;
      }

      if (!this.borderModeIndicator) {
        this.borderModeIndicator = new fabric.Line(indicatorCoords, {
          fill,
          stroke: fill,
          strokeWidth: 2,
          opacity: 1,
          selectable: false,
          evented: false,
        });
        //@ts-ignore
        this.borderModeIndicator.set("borderSide", coords.border);
        this.canvas.add(this.borderModeIndicator);
      } else {
        this.borderModeIndicator.set({
          x1: indicatorCoords[0],
          x2: indicatorCoords[2],
          y1: indicatorCoords[1],
          y2: indicatorCoords[3],
        });
      }
      this.canvas.renderAll();
    }

    if (majDown && e && e.e) {
      const { tl, tr, br } = this.canvas.calcViewportBoundaries();
      let dplX;
      let dplY;

      if (e.e.movementX > 0) {
        // vers la droite
        dplX = tl.x - e.e.movementX > 0 ? e.e.movementX : 0;
      } else {
        // vers la gauche
        dplX = tr.x - e.e.movementX < this.scaling.newWidth ? e.e.movementX : 0;
      }

      if (e.e.movementY > 0) {
        // vers le bas
        dplY = tl.y - e.e.movementY > 0 ? e.e.movementY : 0;
      } else {
        // vers le haut
        dplY =
          br.y - e.e.movementY < this.scaling.newHeight ? e.e.movementY : 0;
      }

      var delta = new fabric.Point(dplX, dplY);
      this.canvas.relativePan(delta);
      return;
    }
  };

  handleMouseUp = (o: any) => {
    const {
      labelSelectedIndex,
      closing,
      borderMode,
      appendMode,
      selectMode,
      polySelectedName,
    } = this.state;

    if (o.target && o.target.get("type") === "rect" && !o.target.get("mask")) {
      this.canvas.discardActiveObject();
      this.canvas.setActiveObject(o.target);
      return;
    }

    if (this.isOneBoxSelected() || appendMode) return;

    let { x, y } = this.canvas.getPointer(o.e);

    if (this.closeToExistingPoint(x, y)) return;

    if (borderMode) {
      // determiner le bord
      const borderModeIndicator = this.borderModeIndicator
        ? this.borderModeIndicator.get("borderSide")
        : null;
      const coords = computeBorderedPointCoords(
        { x, y },
        this.scaling,
        BORD_DETECT_LIMIT,
        borderModeIndicator
      );
      x = coords.x;
      y = coords.y;
    }

    if (selectMode) {
      if (polySelectedName) {
        const polySelected = this.canvas
          .getObjects()
          .find((o: any) => o.get("name") === polySelectedName);
        if (polySelected) {
          const labelForPoly = this.props.labels.find(
            (l: any) =>
              l.legend === polySelected.get("name").split(SEPARATOR)[0]
          );
          polySelected.set("fill", labelForPoly.color);
        }
      }
      const poly = this.findPolygonThatContains(x, y);
      if (poly && polySelectedName !== poly.get("name")) {
        poly.set("fill", "#FFF");
        this.setState({ polySelectedName: poly.get("name") });
        this.canvas.renderAll();
      } else {
        this.setState({ polySelectedName: "" });
      }
      return;
    }

    // y a t'il un polygone en cours pour ce label ?
    const numberPolygonDone = this.polygonDoneCount();
    const polyNotDone = this.findPolygonNotDone(numberPolygonDone);

    if (polyNotDone) {
      // oui
      this.setState({
        polygons: this.state.polygons.map((p, idx: number) =>
          p.labelValue !== polyNotDone.labelValue || p.done
            ? p
            : {
                done: closing,
                labelValue: polyNotDone.labelValue,
                points: polyNotDone.points.concat({ x, y }),
                deleted: p.deleted,
              }
        ),
        polygonJustUpdated: polyNotDone.labelValue,
      });
    } else {
      // non, on ajoute un
      const labelValue = `${this.props.labels[labelSelectedIndex].legend}${SEPARATOR}${numberPolygonDone}`;

      this.setState({
        polygons: this.state.polygons.concat({
          labelValue,
          points: [{ x, y }],
          done: false,
          deleted: false,
        }),
        polygonJustUpdated: labelValue,
      });
    }
  };

  handleDoubleClick = (o: any) => {
    const polyEnCours = this.state.polygons.find(
      (poly) => !poly.done && !poly.deleted
    );
    const polygons = this.state.polygons.map((p) =>
      p.done ? p : { ...p, done: true }
    );
    if (polyEnCours) {
      this.setState({
        polygons,
        polygonJustUpdated: polyEnCours.labelValue,
        justDoubleClicked: true,
      });
      this.whitify();
      this.canvas.setCursor("default");
    }
  };

  handleMouseWheel = (o: any) => {
    const delta = o.e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom = zoom + delta / 20;

    if (zoom <= 1) {
      zoom = 1;
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    } else {
      if (zoom > 20) zoom = 20;
      this.canvas.zoomToPoint({ x: o.e.offsetX, y: o.e.offsetY }, zoom);
    }
    o.e.preventDefault();
    o.e.stopPropagation();
  };

  handleObjectMoved = (o: any) => {
    const [name, index, pointIndex] = o.target.get("name").split(SEPARATOR);

    const polygons = this.state.polygons.map((pol) => {
      if (pol.labelValue === `${name}${SEPARATOR}${index}`) {
        return {
          ...pol,
          points: pol.points.map((p: any, idx: number) => {
            return idx === Number(pointIndex)
              ? { x: o.target.get("left") + 4, y: o.target.get("top") + 4 }
              : p;
          }),
        };
      }
      return pol;
    });
    const polygonFound = this.state.polygons.find(
      (pol) => pol.labelValue === `${name}${SEPARATOR}${index}`
    );

    if (polygonFound) {
      this.setState({
        polygons,
        polygonJustUpdated: polygonFound.labelValue,
        polygonPointUpdatedIndex: Number(pointIndex),
      });

      o.target.set("fill", "red");
      o.target.set("stroke", "red");
    }
  };

  handleSelectionCleared = (e: any) => {
    if (e.deselected) {
      e.deselected.forEach((d: any) => {
        d.set("stroke", "#FFF");
        d.set("fill", "#FFF");
      });
    } else {
      e.target.set("stroke", "#FFF");
      e.target.set("fill", "#FFF");
    }
    this.canvas.requestRenderAll();
  };

  handleSelectionCreated = (e: any) => {
    const activeObject = this.canvas.getActiveObject();

    if (this.state.appendMode && !e.e) {
      // const { left, top } = activeObject;
      // this.canvas.discardActiveObject();
      // this.canvas.renderAll();
      //const polyNotDone = this.findPolygonNotDone();
      const polyNotDone = this.state.polygons.find(
        (p) => !p.done && !p.deleted
      );
      if (polyNotDone) {
        // console.log(polyNotDone, activeObject.get('name'));
        // est-ce que activeObject fait partie de polyNotDone ?
        const activeNameSplt = activeObject.get("name").split(SEPARATOR);
        const polyLabelValueSplt = polyNotDone.labelValue.split(SEPARATOR);

        if (
          activeNameSplt[0] !== polyLabelValueSplt[0] ||
          activeNameSplt[1] !== polyLabelValueSplt[1]
        ) {
          if (this.state.appendModeFirstNode === null) {
            console.log("first");
            this.setState({ appendModeFirstNode: activeObject });
          } else if (this.state.appendModeLastNode === null) {
            console.log("last");
            this.setState({ appendModeLastNode: activeObject });
          }
        }
        return;
      }
    }

    if (activeObject && activeObject.get("name")) {
      // on passe tous les rectangles en blanc
      this.whitify(true);
      const name = activeObject.get("name").split(SEPARATOR)[0];
      activeObject.set("fill", "red");
      this.canvas.requestRenderAll();
      this.setState({
        labelSelectedIndex: this.props.labels.findIndex(
          (l: any) => l.legend === name
        ),
      });
    }
  };

  removeBorderModeIndicator = () => {
    this.canvas.remove(this.borderModeIndicator);
    this.borderModeIndicator = null;
  };

  appendNodes = () => {
    const name1 = this.state.appendModeFirstNode.get("name");
    const name2 = this.state.appendModeLastNode.get("name");
    const [label1, polyIdx1, polyPointIdx1] = name1.split(SEPARATOR);
    const [label2, polyIdx2, polyPointIdx2] = name2.split(SEPARATOR);

    // les deux points doivent appartenir au même polygone
    if (label1 === label2) {
      const firstIdx = parseInt(polyPointIdx1, 10);
      const lastIdx = parseInt(polyPointIdx2, 10);
      // console.log('indexes', firstIdx, lastIdx);
      const step = firstIdx > lastIdx ? -1 : 1;
      // console.log('first', this.state.appendModeFirstNode.get('name'));
      // console.log('last', this.state.appendModeLastNode.get('name'));
      // console.log('allPolys', this.state.polygons);
      const poly = this.state.polygons.find(
        (poly: any) => poly.labelValue === `${label1}${SEPARATOR}${polyIdx1}`
      );

      if (poly) {
        const polyDirection =
          poly.points[0].x < poly.points[1].x ? "horaire" : "horaireInvers";
        // console.log('poly', poly );

        // /**
        const minX = minBy(poly.points, (p: any) => p.x);
        const maxY = maxBy(poly.points, (p: any) => p.y);
        if (minX && maxY) {
          const rebasedPoints = poly.points.map((p: any) => ({
            x: p.x - minX.x,
            y: maxY.y - p.y,
          }));
          // console.log('rebasedPoints', rebasedPoints);
          const x1 = rebasedPoints[firstIdx].x;
          const x2 = rebasedPoints[lastIdx].x;
          const y1 = rebasedPoints[firstIdx].y;
          const y2 = rebasedPoints[lastIdx].y;
          const m = (y2 - y1) / (x2 - x1);
          const direction = m <= 1 ? "horizontal" : "vertical";
          console.log("m", m);
          console.log("direction", direction);
          // **/

          // trouver le polygone contenant les points
          // console.log('polygons', this.state.polygons);
          // console.log('name1', name1.slice(0, name1.length - 3));

          //const polyNotDone = this.findPolygonNotDone();
          const polyNotDone = this.state.polygons.find((p) => !p.done);
          if (polyNotDone) {
            if (direction === "horizontal") {
              const lastPointY =
                polyNotDone.points[polyNotDone.points.length - 1].y;
            } else {
              const lastPointX =
                polyNotDone.points[polyNotDone.points.length - 1].x;
            }

            const newPointsRoute1: any[] = [];
            const newPointsRoute2: any[] = [];
            // console.log("polyDest", poly);
            // console.log('step', polyDirection, firstIdx, lastIdx, step);
            let cpt = firstIdx;
            while (cpt !== lastIdx) {
              newPointsRoute1.push(poly.points[cpt]);
            }
            newPointsRoute1.push(poly.points[cpt]);

            // for(var cpt = firstIdx; cpt >= lastIdx; cpt = cpt++) {
            // 	newPointsRoute1.push(poly.points[cpt]);
            // }

            // for(var cpt = firstIdx; cpt <= lastIdx; cpt = cpt--) {
            // 	newPointsRoute2.push(poly.points[cpt]);
            // }

            const polygons = this.state.polygons.map((p) =>
              p.done
                ? p
                : { ...p, points: p.points.concat(newPointsRoute1), done: true }
            );
            this.setState({
              polygons,
              polygonJustUpdated: polyNotDone.labelValue,
            });
          }
        }
      }
    }
  };

  polygonDoneCount = () =>
    this.state.polygons.filter((p: any) => {
      return (
        p.labelValue.indexOf(
          this.props.labels[this.state.labelSelectedIndex].legend
        ) !== -1 && p.done
      );
    }).length;

  findPolygonNotDone = (noPoly: any = null) =>
    this.state.polygons.find(
      (p) =>
        p.labelValue ===
          `${
            this.props.labels[this.state.labelSelectedIndex].legend
          }${SEPARATOR}${noPoly || this.polygonDoneCount()}` &&
        !p.done &&
        !p.deleted
    );

  closeToExistingPoint = (x: number, y: number) => {
    const labelName = this.props.labels[this.state.labelSelectedIndex].legend;
    const notDonePolygon = this.state.polygons.find(
      (p) => p.labelValue.indexOf(labelName) !== -1 && !p.done
    );
    if (!notDonePolygon) return false;

    const closeLimit = 5;
    return notDonePolygon.points.reduce(
      (memo: any, point: any, index: number, datas: any) => {
        const { x: xP, y: yP } = point;
        return (
          memo ||
          (Math.abs(x - xP) <= closeLimit && Math.abs(y - yP) <= closeLimit)
        );
      },
      false
    );
  };

  loadPolygons = (labels: any) => {
    const polys = labels.reduce(
      (memo: any, l: any) => ({
        ...memo,
        [l.labelValue]: memo[l.labelValue] ? memo[l.labelValue].concat(l) : [l],
      }),
      {}
    );
    const polygons = Object.keys(polys).reduce(
      (memo: any, key: string) =>
        memo.concat(
          polys[key].reduce(
            (m: any, l: any, idx: number) =>
              m.concat({
                labelValue: `${key}${SEPARATOR}${idx}`,
                done: true,
                deleted: false,
                points: l.allX.map((x: any, index: number) => ({
                  x: x * this.scaling.x,
                  y: l.allY[index] * this.scaling.y,
                })),
              }),
            []
          )
        ),
      []
    );
    this.setState({ polygons });
  };

  bringAllRectsToFront = () =>
    this.canvas
      .getObjects()
      .filter((o: any) => o.type === "rect")
      .forEach((rect: any) => this.canvas.bringToFront(rect));

  drawPolygon = (poly: any, pointIdxToActivate: number | null = null) => {
    const pointRectSize = 8;
    const demiSize = pointRectSize / 2;
    const labelForPoly = this.props.labels.find(
      (l: any) => l.legend === poly.labelValue.split(SEPARATOR)[0]
    );

    const fill = labelForPoly
      ? labelForPoly.color
      : this.props.labels[this.state.labelSelectedIndex].color;

    const polygon =
      poly.points.length !== 2
        ? new fabric.Polygon(poly.points, {
            fill,
            opacity: 0.4,
            selectable: false,
            objectCaching: false,
            stroke: darken(fill, 0.5),
            strokeWidth: 3,
          })
        : new fabric.Line(
            [
              poly.points[0].x,
              poly.points[0].y,
              poly.points[1].x,
              poly.points[1].y,
            ],
            {
              fill,
              stroke: fill,
              strokeWidth: 2,
              opacity: 0.4,
              selectable: false,
              evented: false,
            }
          );

    //@ts-ignore
    polygon.set("name", `${poly.labelValue}`);

    this.clearAllByName(`${poly.labelValue}`);

    this.canvas.add(polygon);

    // création des points d'accroche
    poly.points.forEach((p: any, index: number) => {
      const rect = new fabric.Rect({
        left: p.x - demiSize,
        top: p.y - demiSize,
        width: pointRectSize,
        height: pointRectSize, //: Math.floor(width / ratio),
        hasRotatingPoint: false,
        strokeWidth: 1,
        stroke: "#FFF",
        selectable: true,
        lockUniScaling: true,
        originX: "left",
        originY: "top",
        fill: index === poly.points.length - 1 ? darken(fill, 1) : "#FFF",
      });
      rect.set("name", `${poly.labelValue}${SEPARATOR}${index}`);
      //@ts-ignore
      rect.set("mask", false);

      rect.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
        mtr: false,
      });

      this.canvas.add(rect);
      if (poly.points.length === 0) {
        this.whitify();
        this.canvas.setActiveObject(rect);
      }

      if (this.canvas) {
        rect.bringToFront();
      }

      if (pointIdxToActivate === index) {
        this.canvas.setActiveObject(rect);
      }
    });
    this.bringAllRectsToFront();

    // supprimer le motif s'il y en a un
    this.changeMotif(null, false);

    this.setState({
      polygonJustUpdated: null,
      polygonPointUpdatedIndex: null,
      dirty: true,
      justDoubleClicked: false,
    });
  };

  loadImage = () => {
    const { keepLabelsOnNextImage } = this.state;
    const {
      images,
      match: {
        params: { noImage },
      },
    } = this.props;

    if (this.canvas) {
      this.canvas.getObjects().forEach((o: any) => this.canvas.remove(o));
    }

    this.setState({ imageLoaded: false });

    //valeurs par défaut
    //eslint-disable-next-line
    let labels: any[] = [];
    let url = "/404.png";

    const image = images[noImage];

    if (image) {
      url = image.url;
      if (!keepLabelsOnNextImage) {
        labels = image.labels.polygonLabels;
      } else {
        const imagePrecente = images[noImage - 1];
        if (imagePrecente) {
          labels = imagePrecente.labels.polygonLabels;
        }
      }
    }

    var img = new Image();

    img.onload = () => {
      fabric.Image.fromURL(img.src, () => {
        if (image) {
          const { width, height } = img;
          const { newWidth, newHeight } = this.scaling;

          this.scaling = {
            x: newWidth / width,
            y: newHeight / height,
            newHeight,
            newWidth,
          };

          this.loadPolygons(labels);
        }
        // si des coordonnées ont été sauvegarder en base
        // on utilisera directement les données depuis la base dans drawPlateBox
        setTimeout(() => this.addImageToCanvas(img, image.lastLabeledBy), 50);
      });
    };

    img.onerror = (ev) => {
      fabric.util.loadImage("/404.png", (img: any) => {
        setTimeout(() => this.addImageToCanvas(img), 50);
      });
    };

    img.src = url;
  };

  addImageToCanvas = (img: any, lastLabeledBy: String = "model") => {
    const { x: scaleX, y: scaleY } = this.scaling;

    const { keepLabelsOnNextImage } = this.state;
    this.image = new fabric.Image(img, {});

    this.image.set({
      left: 0,
      top: 0,
      selectable: false,
      lockMovementX: true,
      centeredScaling: true,
      lockMovementY: true,
      hoverCursor: "crosshair",
      scaleX,
      scaleY,
      originX: "left",
      originY: "top",
      opacity: 1,
    });

    this.canvas.add(this.image);
    this.canvas.renderAll();

    this.state.polygons.forEach((p: any) => this.drawPolygon(p));

    this.setState({
      dirty: keepLabelsOnNextImage || lastLabeledBy === "model" ? true : false,
      keepLabelsOnNextImage: false,
      imageLoaded: true,
      labelSelectedIndex: 0,
    });
  };

  toggleAllRectsVisibilityStatus = (status: boolean = false) => {
    this.canvas
      .getObjects()
      .filter((o: any) => o.get("type") === "rect")
      .map((o: any) => o.set("visible", status));
  };

  clearAllByType = (type: string) => {
    this.canvas
      .getObjects()
      .filter((o: any) => o.get("type") === type && !o.get("mask"))
      .map((o: any) => this.canvas.remove(o));
  };

  clearAllByName = (name: string) =>
    this.canvas
      .getObjects()
      .filter((o: any) => o.get("name") && o.get("name").indexOf(name) !== -1)
      .map((o: any) => this.canvas.remove(o));

  clearAll = () =>
    this.canvas
      .getObjects()
      .filter((o: any) => o.get("name"))
      .map((o: any) => this.canvas.remove(o));

  countPolygons = (): number =>
    this.state.polygons.filter((p: any) => p.done).length;

  handleSubmit = (labels: any) => {
    const { dirty } = this.state;
    const {
      images,
      onSave,
      match: {
        params: { noImage: currentImageIndex },
      },
    } = this.props;

    const image = images[currentImageIndex];
    const { motif } = image;
    return new Promise((resolve: any, reject: any) => {
      if (dirty && labels.length === 0 && image.motif === null) return reject();
      onSave({
        ...image,
        motif,
        labels: {
          ...image.labels,
          polygonLabels: motif !== null ? [] : labels,
        },
        lastLabeledBy: null,
      })
        .then((image: any) => {
          this.setState({ justSaved: true });
          return resolve(image);
        })
        .then(() => {
          return resolve();
        });
    });
  };

  handleToggleListeImagesDrawer = () =>
    this.setState({ listImagesDrawerOpen: !this.state.listImagesDrawerOpen });

  isNavigationFwdPossible = () => {
    const {
      match: {
        params: { noImage },
      },
      images,
    } = this.props;
    const image = images[Number(noImage)];
    const nextImage = images[Number(noImage) + 1];

    return (
      (image &&
        ((image.labels && image.labels.polygonLabels.length > 0) ||
          image.motif) &&
        !this.state.dirty) ||
      (nextImage &&
        ((nextImage.labels && nextImage.labels.polygonLabels.length > 0) ||
          nextImage.motif))
    );
  };

  closeListImagesDrawer = () => this.setState({ listImagesDrawerOpen: false });

  handleCloseAlert = () => {
    this.props.history.push("/batches/0?sort=createdDate,desc");
  };

  navigateToLastLabelized = () => {
    const {
      match: {
        params: { noBatch },
      },
      history,
      images,
    } = this.props;
    const indexFirstImageNonLabellisee = images.findIndex(
      (img: any) =>
        (img.labels.rectangleLabels.length === 0 &&
          (img.motif === null || img.motif === "")) ||
        !img.lastLabeledBy ||
        img.lastLabeledBy === "model"
    );

    if (indexFirstImageNonLabellisee !== -1) {
      history.push(
        `/labelerSegmentation/${noBatch}/${indexFirstImageNonLabellisee}`
      );
    }
  };

  handlePreviousImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(
      `/labelerSegmentation/${noBatch}/${Number(noImage) - 1}`
    );
  };

  handleNextImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(
      `/labelerSegmentation/${noBatch}/${Number(noImage) + 1}`
    );
  };

  goToBatchesList = () =>
    this.props.history.push("/batches/0?sort=createdDate,desc");

  // nous utilisons cette méthode supplémentaire
  // pour s'assurer qu'une image avec un motif
  // puisse être modifiée en image avec labels
  saveLabels = () => {
    this.changeMotif(null, false);
    setTimeout(() => this.handleSave(), 50);
  };

  handleSave = () => {
    const { x, y } = this.scaling;
    const { polygons } = this.state;
    const labels = polygons
      .filter((p) => !p.deleted)
      .map((p: any) => ({
        allX: p.points.map((p: any) => p.x / x),
        allY: p.points.map((p: any) => p.y / y),
        labelValue: p.labelValue.split(SEPARATOR)[0],
      }));

    this.handleSubmit(labels).then(() => {
      this.canvas.remove(this.image);
      this.image = null;
      this.setState({ dirty: false });
      setTimeout(() => this.handleNextImage(), 50);
    });
  };

  setDirty = (dirty: boolean = true) => this.setState({ dirty });

  setLabel = (labelSelectedIndex: number) => {
    this.setState({ labelSelectedIndex });
  };

  changeMotif = (motifSkip: string | null, clear: boolean = true) => {
    const { storeMotif } = this.props;
    this.setState({ dirty: true, skipDialogOpen: false });
    storeMotif(motifSkip, clear);
  };

  handleOpenSkipDialog = (e: any) => {
    this.setState({ skipDialogOpen: true });
  };

  handleCloseSkipDialog = (e: any) => {
    this.setState({ skipDialogOpen: false });
  };

  handleDeleteObject = () => {
    const { polygons, polySelectedName } = this.state;
    const object = this.canvas.getActiveObject();

    if (polySelectedName !== "") {
      this.clearAllByName(polySelectedName);
      const polygons = this.state.polygons.filter(
        (p: any) => p.labelValue !== polySelectedName
      );
      this.setState({ polygons });
      this.setState({ polySelectedName: "", dirty: true, polygons });
      return;
    }

    const orphean = this.getOpheanNode();
    if (orphean) {
      const name = orphean
        .get("name")
        .split(SEPARATOR)
        .slice(0, 2)
        .join(SEPARATOR);
      this.clearAllByName(name);
      const polygons = this.state.polygons.map((p) =>
        p.labelValue !== name ? p : { ...p, deleted: true }
      );
      this.setState({ polygons });
      this.canvas.setCursor("default");
      return;
    }

    if (object && object.type === "rect") {
      const [name, index, pointIndex] = object.get("name").split(SEPARATOR);
      const polygonJustUpdated = polygons.find(
        (p) => p.labelValue === `${name}${SEPARATOR}${index}`
      );
      if (polygonJustUpdated) {
        this.setState({
          polygons: polygons.map((poly: any) =>
            poly.labelValue !== `${name}${SEPARATOR}${index}`
              ? poly
              : {
                  ...poly,
                  points: poly.points.filter(
                    (p: any, index: number) => index !== Number(pointIndex)
                  ),
                }
          ),
          polygonJustUpdated: polygonJustUpdated.labelValue,
        });
      }
    }

    const polyNotDone = this.findPolygonNotDone();
    if (polyNotDone) {
      const allButLast = polyNotDone.points.slice(0, -1);
      this.setState({
        polygons: this.state.polygons.map((p, idx: number) =>
          p.labelValue !== polyNotDone.labelValue || p.done
            ? p
            : {
                done: false,
                labelValue: polyNotDone.labelValue,
                points: allButLast,
                deleted: p.deleted,
              }
        ),
        polygonJustUpdated: polyNotDone.labelValue,
      });
    }
  };

  areAllPolygonsDone = () => !this.state.polygons.find((p) => !p.done);

  updateBoxesVisibility = (labels: any) => {
    this.canvas
      .getObjects()
      .filter((o: any) => o.type === "polygon" && !o.get("mask"))
      .forEach((poly: any) => {
        const label = poly.get("name").split(SEPARATOR)[0];
        let tag = labels.find((c: any) => c.legend === label);

        poly.set("visible", tag.visible);

        // il faut aussi cacher les box des selection du polygone
        this.canvas
          .getObjects()
          .filter(
            (o2: any) =>
              o2.type === "rect" &&
              !o2.get("mask") &&
              o2.get("name").indexOf(poly.get("name")) !== -1
          )
          .forEach((r: any) => r.set("visible", tag.visible));
      });

    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  };

  toggleAllBoxesVisiblity = () => {
    const { somePolygonHidden } = this.state;
    this.props.toggleAllBoxesVisibility(somePolygonHidden);
    this.setState({ somePolygonHidden: !somePolygonHidden });
  };

  handleAddClicked = () => {
    const object = this.canvas.getActiveObject();
    if (object && object.type === "rect" && !object.get("mask")) {
      const [name, index, pointIndex] = object.get("name").split(SEPARATOR);

      const polygonToUpdate = this.state.polygons.find(
        (p) => p.labelValue === `${name}${SEPARATOR}${index}`
      );

      if (polygonToUpdate) {
        const nextPointIndex =
          Number(pointIndex) === polygonToUpdate.points.length - 1
            ? 0
            : Number(pointIndex) + 1;

        const { x, y } = polygonToUpdate.points[nextPointIndex];
        const newX = x - (x - polygonToUpdate.points[Number(pointIndex)].x) / 2;
        const newY = y - (y - polygonToUpdate.points[Number(pointIndex)].y) / 2;
        const insertIdx =
          Number(pointIndex) === polygonToUpdate.points.length - 1
            ? 0
            : Number(pointIndex) + 1;

        polygonToUpdate.points.splice(insertIdx, 0, { x: newX, y: newY });

        const polygons = this.state.polygons.map((poly) =>
          poly.labelValue !== `${name}${SEPARATOR}${index}`
            ? poly
            : polygonToUpdate
        );

        this.setState({
          polygons,
          polygonJustUpdated: polygonToUpdate.labelValue,
        });
      }
    }
  };

  handleToggleHelp = () => this.setState({ showHelp: !this.state.showHelp });

  isOneBoxSelected = () => {
    if (!this.canvas) return false;
    const selection = this.canvas.getActiveObject();
    if (
      typeof selection !== "undefined" &&
      selection &&
      selection.get("type") === "rect" &&
      !selection.get("mask")
    ) {
      return true;
    }
    return false;
  };

  // renvoie le noeud qui n'est pas attaché à un polygon
  // on l'utilise pour permettre de supprimer facilement un
  // noeud crée par erreur
  getOpheanNode = () => {
    if (!this.canvas) return null;
    const polyNotDone = this.findPolygonNotDone();
    if (polyNotDone && polyNotDone.points.length === 1) {
      return this.canvas
        .getObjects()
        .find(
          (o: any, index: number) =>
            o.get("name") === `${polyNotDone.labelValue}${SEPARATOR}0`
        );
    }
    return null;
  };

  whitify = (forceAll: boolean = false) => {
    this.canvas
      .getObjects()
      .filter((o: any) => {
        //@ts-ignore
        return (
          o.get("type") === "rect" &&
          (forceAll || o.get("fill") === "#FFF") && // ne pas blanchir les noeuds sélectionnés
          !o.get("mask")
        );
      })
      .forEach((o: any) => {
        o.set("stroke", "#FFF");
        o.set("fill", "#FFF");
      });
    this.canvas.renderAll();
  };

  findPolygonThatContains = (x: number, y: number) => {
    const r = new fabric.Rect({
      left: x,
      top: y,
      width: 2,
      height: 2,
      hasRotatingPoint: false,
      hoverCursor: "pointer",
      strokeWidth: 1,
      stroke: "#FFF",
      selectable: true,
      lockUniScaling: true,
      originX: "left",
      originY: "top",
    });
    this.canvas.add(r);
    const poly = this.canvas
      .getObjects()
      .filter((o: any) => o.get("type") === "polygon" && !o.get("mask"))
      .find((o: any) => r.isContainedWithinObject(o));
    this.canvas.remove(r);
    return poly;
  };

  public render() {
    const {
      classes,
      match: {
        params: { noImage, noBatch },
      },
      images,
      motifs,
      nomBatch,
      changeVisibility,
      labels,
    } = this.props;

    const {
      dirty,
      listImagesDrawerOpen,
      labelSelectedIndex,
      somePolygonHidden,
      showHelp,
      skipDialogOpen,
      polySelectedName,
      justSaved,
      polygons,
      imageLoaded,
    } = this.state;

    const imagesWithLabelOrMotif = images.filter(
      (img: any) => img.labels.polygonLabels.length > 0 || img.motif !== null
    );

    const nbrLabelisees = imagesWithLabelOrMotif.length;
    const nombrePolygons = this.countPolygons();
    const polygonNotDone = this.findPolygonNotDone();
    const image = images[Number(noImage)];
    const out = Number(noImage) >= images.length;
    const finished = nbrLabelisees === images.length;

    let alertOnClickHandler = null;
    if (out) alertOnClickHandler = () => this.navigateToLastLabelized();
    if (finished) alertOnClickHandler = () => this.handleCloseAlert();

    let textAlert = "";
    let titreAlert = "";
    if (out) {
      if (!finished) {
        textAlert = "Une image n'a pas été labellisée";
        titreAlert = "Labellisation incomplète";
      } else {
        textAlert = "Labellisation de ce lot terminée !";
        titreAlert = "C'est fini !";
      }
    }

    const orphean = this.getOpheanNode();

    return (
      <Grid
        container
        spacing={16}
        direction="row"
        justify="flex-start"
        className={classes.root}
      >
        <Helmet>
          <title>
            Segm. / {nomBatch.split("-")[0]} / {noImage}
          </title>
        </Helmet>
        {Number(noImage) >= images.length && (
          <ImageCache images={images} noImageToCache={Number(noImage) + 1} />
        )}
        {listImagesDrawerOpen && (
          <DrawerPhotoList
            open={listImagesDrawerOpen}
            images={imagesWithLabelOrMotif}
            noBatch={noBatch}
            noImage={noImage}
            labelingTypePathname="labelerSegmentation"
          />
        )}
        {imagesWithLabelOrMotif.length > 1 && (
          <DrawerButton
            onClick={this.handleToggleListeImagesDrawer}
            drawerOpen={listImagesDrawerOpen}
          />
        )}
        <SkipDialog
          motifs={motifs}
          motifSelected={image && image.motif}
          open={skipDialogOpen}
          onAccept={this.changeMotif}
          onClose={this.handleCloseSkipDialog}
          i18nMapping={i18nMapping}
          defaultMotifIndex={1}
        />
        <Prompt
          when={dirty}
          message="Des modifications n'ont pas été sauvegardées. Continuer ?"
        />
        <AlertDialog
          open={out || finished}
          onClose={alertOnClickHandler}
          text={textAlert}
          title={titreAlert}
          onAccept={alertOnClickHandler}
          acceptLabel="OK"
        />
        <Grid item sm={7} style={{ flexGrow: 1 }}>
          <Grid
            container
            direction="column"
            spacing={8}
            component={Paper}
            alignItems="stretch"
            style={{ height: "100%", padding: "4px", paddingBottom: "2em" }}
          >
            <Grid item>
              <LabelisationAbstract
                noImage={noImage}
                nomBatch={nomBatch}
                images={images}
                dirty={dirty}
                justSaved={justSaved}
              />
            </Grid>
            <Grid item style={{ flexGrow: 1, padding: 5 }}>
              <div
                className={classes.canvasContainer}
                ref={this.canvasWrapperRef}
              >
                <canvas ref={this.canvasRef} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={5} style={{ flexGrow: 1 }}>
          <Grid
            container
            component={Paper}
            direction="column"
            justify="space-between"
            alignItems="stretch"
            style={{ height: "100%" }}
          >
            <Grid item className={classes.fullWidth}>
              <Grid container direction="column" spacing={16}>
                {image && (
                  <Grid item>
                    <Progression
                      total={images.length}
                      nbrLabelisees={nbrLabelisees}
                      onHelpClicked={this.handleToggleHelp}
                      showHelp={showHelp}
                    />
                  </Grid>
                )}
                <Grid item>
                  {!showHelp && (
                    <Labels
                      labels={labels.map((l: any) => ({
                        ...l,
                        occurences: polygons.filter(
                          (p: any) =>
                            p.labelValue.indexOf(l.legend) !== -1 && !p.deleted
                        ).length,
                      }))}
                      onClick={this.setLabel}
                      selected={labelSelectedIndex}
                      onVisibilityClick={changeVisibility}
                    />
                  )}
                  {showHelp && (
                    <DisplayKeyMapping keyMapping={this.keyMapping} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {image && image.motif && (
              <Grid item className={classes.centered}>
                <span>
                  Image passée : <strong>{i18nMapping[image.motif]}</strong>
                </span>
              </Grid>
            )}
            <Grid item className={classes.fullWidth}>
              <Grid container direction="column">
                <Grid item style={{ paddingLeft: "1em" }}>
                  <Typography>Actions :</Typography>
                </Grid>
                {image && !(finished && out) && (
                  <Grid item style={{ flexGrow: 1 }}>
                    <CanvasButtons
                      onDeleteClicked={this.handleDeleteObject}
                      onSaveClicked={this.saveLabels}
                      onNavigateBackClicked={this.handlePreviousImage}
                      onNavigateFwdClicked={this.handleNextImage}
                      selected={
                        this.isOneBoxSelected() ||
                        polySelectedName !== "" ||
                        orphean !== null ||
                        typeof this.findPolygonNotDone() !== "undefined"
                      }
                      navigateBackVisible={imageLoaded && Number(noImage) > 0}
                      navigateFwdVisible={
                        imageLoaded && this.isNavigationFwdPossible()
                      }
                      someItemsHidden={somePolygonHidden}
                      nombreItems={nombrePolygons}
                      savingDisabled={nombrePolygons === 0 || !!polygonNotDone}
                      hiddenableShapeName="les polygones ( H )"
                      onToggleVisibility={this.toggleAllBoxesVisiblity}
                      onAddClicked={this.handleAddClicked}
                      onSkipClicked={this.handleOpenSkipDialog}
                      motif={image.motif}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withContext(LabelerSegmentation)));
