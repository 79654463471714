export default {
  BOX_OPACITY: 0.2, // opacité des boxes en labellisation boxing
  drawerWidth: 250,
  paths: {
    IMAGES_PATH: "/api/images",
    AUTH_PATH: "/api/authenticate",
    BATCHES_PATH: "/api/batches",
    PROJECT_BATCHES_PATH: "/api/batches/project",
    TAG_LIST: "/api/tagLists",
    TAG_LIST_UPDATE: "/api/tagLists/update",
    BATCHES_EXPORT_PATH: "/api/batches/export",
    BATCHES_AFFECTATION_PATH: "/api/batches/toUser",
    BATCHES_DEADLINE_PATH: "/api/batches/updateDeadline",
    BATCH_PREFIX_EXISTS: "/api/batches/isNameTaken",
    PROJECTS_PATH: "/api/projects",
    USER_PROJECTS_PATH: "/api/projects/user",
    USER_REPORT_PATH: "/api/user/report",
    PROJECT_USERS_PATH: "/api/users/project",
    BATCH_GENERATION_PROGRESS_PATH:
      "/api/files/operations/inProgressOrFinishedLessThan5hAgo ",
    USER_BATCHES_PATH: "/api/batches/user",
    USERS_PATH: "/api/users",
    USER_USERS_PATH: "/api/users/user",
    REGISTER_USER: "/api/register",
    INIT_RESET_PASSWORD: "/api/account/reset-password/init",
    FINISH_RESET_PASSWORD: "/api/account/reset-password/finish",
    CHANGE_PASSWORD: "/api/account/change-password",
    GENERATE_BATCHES: "/api/batches/create",
    IMPORT_ES: "/api/files/esDownload",
    UPLOAD_PROPERTIES: "/app/uploadProperties",
    LIST_ES_INDICES: "/api/files/listEsIndices",
    LIST_ES_METATDATA: "/api/files/listEsMetadata",
    UPLOAD_FILES: "/api/files/uploadFiles",
    COUNT_ES: "/api/files/esCount",
    LIST_STAGGING_PATH: "/api/files/listStagingFolders",
    LIST_CLIENT_PATH: "/api/clients",
    PATH_PREDICT: "/api/images/predict",
    QRCODE_PATH: "/api/authenticate/qr-code",
    VERIFY_CODE_PATH: "/api/authenticate/verify-code",
  },
  motifsSkip: ["Rien à sélectionner", "Image floue"],
  colors: [
    { color: "#00A1FF", textColor: "white" },
    { color: "#7AD088", textColor: "white" },
    { color: "#F34955", textColor: "white" },
    { color: "#FFBD00", textColor: "white" },
    { color: "#7C73E3", textColor: "white" },
    { color: "#FF9966", textColor: "white" },
    { color: "#00C2A8", textColor: "white" },
    { color: "#FF00A8", textColor: "white" },
    { color: "#0036FF", textColor: "white" },
    { color: "#00E2E2", textColor: "white" },
    { color: "#00A1FE", textColor: "white" },
    { color: "#7AD087", textColor: "white" },
    { color: "#F34954", textColor: "white" },
    { color: "#FFBD01", textColor: "white" },
    { color: "#7C73E4", textColor: "white" },
    { color: "#FF9967", textColor: "white" },
    { color: "#00C2A9", textColor: "white" },
    { color: "#FF00A9", textColor: "white" },
    { color: "#0036FE", textColor: "white" },
    { color: "#00E2E1", textColor: "white" },
  ],
};
