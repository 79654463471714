import React, { useState, useEffect } from "react";
import { format, parse } from "date-fns";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DeleteIcon from "@material-ui/icons/Delete"
import DialogContent from "@material-ui/core/DialogContent";
import { RouteComponentProps } from "react-router";
import { stableSort, getSorting } from "../../../tools/sortingTools";
import UiDialogTitle from "../../common/UiDialogTitle";
import UiButton from "../../common/UiButton";
import UiInput from "../../common/UiInput";
import UiDialogActions from "../../common/UiDialogActions";
import BatchType from "../../../types/Batch";
import UserType from "../../../types/User";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%",
    },
    titre: {
      minWidth: "550px",
    },
    deleteIcon: {
      marginRight: theme.spacing.unit 
    }
  });

interface PropsType extends RouteComponentProps<any>, React.Props<any> {
  batch?: BatchType;
  selected?: any[];
  onSave: any;
  onClose: any;
  open: boolean;
  classes: any;
  users: UserType[];
  deleteDeadline?: Function;
}

const BatchFormDialog = (props: PropsType) => {
  const {
    batch,
    onSave,
    open,
    onClose,
    selected,
    deleteDeadline,
    classes,
  } = props;
  const [values, setValues] = useState({
    ids: selected,
    labelerId: "NULL",
    deadline: "",
  });

  const handleChange = (e: any) => {
    e.stopPropagation();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setValues({ ...values, ids: selected });
  }, [selected]);

  useEffect(() => {
    const datas: any = {};
    if (batch) {
      if (batch.deadline) {
        datas.deadline = format(batch.deadline, "YYYY-MM-DD");
      }
      if (batch.labelerId) {
        datas.labelerId = batch.labelerId;
      }
    }
    setValues({ ...values, ...datas });
  }, [batch]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { labelerId, deadline, ids } = values;
    onSave({
      ids,
      deadline: deadline === "" ? null : parse(deadline).toISOString(),
    });
  };

  const handleDelete = () => {
    if (deleteDeadline) {
      deleteDeadline(batch);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="list-actif-title"
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <UiDialogTitle
          onCloseClick={onClose}
          title={`${
            batch && batch.deadline ? "Modifier la" : "Assigner une"
          } deadline`}
        />
        <DialogContent>
          <UiInput
            label="Deadline"
            name="deadline"
            type="date"
            defaultValue={values.deadline}
            onChange={handleChange}
          />
        </DialogContent>
        <UiDialogActions style={{ marginRight: 23 }}>
          {deleteDeadline && (
            <UiButton
              variant="contained"
              color="primary"
              onClick={handleDelete}
            >
              <DeleteIcon className={classes.deleteIcon}/>
              Supprimer
            </UiButton>
          )}
          <UiButton variant="contained" color="secondary" type="submit">
            Valider
          </UiButton>
        </UiDialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(withRouter(BatchFormDialog));
