import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import UiButton from "../../common/UiButton";
import UiProgress from "../../common/UiProgess";
import UiDialogTitle from "../../common/UiDialogTitle";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%"
    },
    titre: {
      minWidth: "550px"
    }
  });

const ImportJsonDialog = (props: any) => {
  const { open, onClose, saveImage, classes } = props;
  const [datas, setDatas] = useState<any>(null);
  const [saveLaunched, setSaveLaunched] = useState<boolean>(false);
  const [numberImages, setNumberImages] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const onReaderLoad = (e: any) => {
    try {
      var obj = JSON.parse(e.target.result);
      if (obj && obj.length && obj.length === 0) {
        throw new Error();
      }
      setDatas(obj);
      setNumberImages(obj.length);
    } catch (e) {
      setError("Fichier JSON non valide");
      setDatas(null);
      setNumberImages(null);
    }
  };

  const handleFileChange = (event: any) => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(event.target.files[0]);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    setError(null);
    if (saveLaunched && datas && datas.length) {
      saveImage({
        ...datas[0],
        labels: {
          ...datas[0].labels,
          simpleLabels: datas[0].labels.simpleLabels
        },
        lastLabeledBy: "model"
      }).then(() => {
        setDatas(datas.slice(1, datas.length));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, datas, saveLaunched]);

  const handleClick = () => setSaveLaunched(true);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <UiDialogTitle
          title="Appliquer une labellisation au lot"
          onCloseClick={onClose}
        />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography component="span">
              {!datas && "Veuillez sélectionner un fichier JSON"}
              {datas &&
                datas.length > 0 &&
                'Cliquez sur "Appliquer" lancer la sauvegarde'}
            </Typography>
          </DialogContentText>
          <FormControl className={classes.formControl} required>
            <p>
              {error ? error : ""}
              {datas && datas.length === 0 && "Terminé !"}
            </p>
            {!datas && (
              <input id="file" type="file" onChange={handleFileChange} />
            )}
            {datas && (
              <UiProgress
                variant="determinate"
                color="secondary"
                value={((numberImages - datas.length) * 100) / numberImages}
              />
            )}
            {datas && `${numberImages - datas.length} / ${numberImages}`}
          </FormControl>
        </DialogContent>
        <DialogActions>
          {(!datas || (datas && datas.length > 0)) && (
            <UiButton
              type="submit"
              color="secondary"
              variant="contained"
              disabled={datas === null}
              autoFocus
              onClick={handleClick}
            >
              Appliquer
            </UiButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(ImportJsonDialog);
