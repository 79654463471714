import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { InputProps } from "@material-ui/core/Input";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing.unit
      }
    },
    input: {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      border: "1px solid rgba(72,72,72,.2)",
      fontSize: 16,
      padding: "10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      }
    },
    label: {
      fontSize: 16,
      display: "block",
      fontWeight: 200
    },
    labelDisabled: {
      color: "silver"
    }
  });

interface PropTypes extends InputProps {
  classes: any;
  label?: any;
  required: boolean;
  disabled: boolean;
  fullWidth?: boolean;
  onChange?: any;
  value?: string;
  name?: string;
  tabIndex?: any;
  placeHolder?: string;
}

const Input = (props: PropTypes) => {
  const { classes, label, required, disabled, fullWidth, ...rest } = props;
  return (
    <FormControl fullWidth>
      {label && (
        <label
          className={classNames(classes.label, {
            [classes.labelDisabled]: disabled
          })}
        >
          {label}
          {required && (
            <span>
              {" "}
              <sup>*</sup>
            </span>
          )}
        </label>
      )}
      <InputBase
        {...rest}
        required={required}
        disabled={disabled}
        classes={{
          root: classes.root,
          input: classes.input
        }}
      />
    </FormControl>
  );
};

Input.defaultProps = {
  disabled: false,
  required: true
};

export default withStyles(styles)(Input);
