import React, { useState, useEffect } from "react";
import { format, parse } from "date-fns";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { RouteComponentProps } from "react-router";
import { stableSort, getSorting } from "../../../tools/sortingTools";
import UiDialogTitle from "../../common/UiDialogTitle";
import UiSelect from "../../common/UiSelect";
import UiButton from "../../common/UiButton";
import UiInput from "../../common/UiInput";
import UiDialogActions from "../../common/UiDialogActions";
import BatchType from "../../../types/Batch";
import UserType from "../../../types/User";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%",
    },
    titre: {
      minWidth: "550px",
    },
  });

interface PropsType extends RouteComponentProps<any>, React.Props<any> {
  batch?: BatchType;
  selected?: any[];
  onSave: any;
  onClose: any;
  open: boolean;
  classes: any;
  batches: BatchType[];
  users: UserType[];
}

const BatchAttribFormDialog = (props: PropsType) => {
  const {
    batch,
    onSave,
    open,
    onClose,
    batches,
    users,
    selected,
  } = props;
  const [values, setValues] = useState({
    ids: selected,
    labelerId: "NULL",
    deadline: "",
  });

  const handleChange = (e: any) => {
    e.stopPropagation();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setValues({ ...values, ids: selected });
  }, [selected]);

  useEffect(() => {
    const datas: any = {};
    if (batch) {
      if (batch.deadline) {
        datas.deadline = format(batch.deadline, "YYYY-MM-DD");
      }
      if (batch.labelerId) {
        datas.labelerId = batch.labelerId;
      }
    }
    setValues({ ...values, ...datas });
  }, [batch]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { labelerId, deadline, ids } = values;
    onSave({
      ids,
      labelerId: labelerId !== "NULL" ? labelerId : null,
      deadline: deadline === "" ? null : parse(deadline).toISOString(),
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="list-actif-title"
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <UiDialogTitle
          onCloseClick={onClose}
          title={`Modifier l'attribution ${
            batch || batches.length === 1 ? "d'un lot" : "de plusieurs lots"
          }`}
        />
        <DialogContent>
          <UiSelect
            name="labelerId"
            required
            fullWidth
            value={values.labelerId}
            label={`Affecter ${batches.length === 1 ? "le lot" : "les lots"} à`}
            onChange={handleChange}
          >
            <option value="NULL">-- non attribué --</option>
            {stableSort(users, getSorting("asc", "lastName")).map(
              (u: UserType) => (
                <option key={u.id} value={u.id}>
                  {u.lastName} {u.firstName}
                </option>
              )
            )}
          </UiSelect>
        </DialogContent>
        <UiDialogActions style={{ marginRight: 23 }}>
          <UiButton variant="contained" color="secondary" type="submit">
            Valider
          </UiButton>
        </UiDialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(withRouter(BatchAttribFormDialog));
