import { createMuiTheme } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      // Name of the component ⚛️ / style sheet
      text: {
        // Name of the rule
        color: "white" // Some CSS
      },
      contained: {
        boxShadow: "0px 4px 1px rgba(0,0,0, 0.02)"
      },
      containedSecondary: {
        background: `linear-gradient(360deg,#FF5E62 10%, #FF9966)`
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 5
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(120, 153, 174, 0.5)"
      }
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderRadius: 3
        }
      }
    },
    MuiPaper: {
      elevation2: {
        boxShadow: "0px 4px 1px rgba(0,0,0, 0.02)"
      },
      elevation8: {
        // boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
        boxShadow:
          "0px 5px 5px -3px rgba(0,0,0,0.04),0px 8px 10px 1px rgba(0,0,0,0.03),0px 3px 14px 2px rgba(0,0,0,0.04)"
      }
    },
    MuiToolbar: {
      regular: {
        "@media (min-width:600px) and (orientation: landscape)": {
          minHeight: 48
        }
      }
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: lighten("#396AFC", 0.8)
        }
      },
      dense: {
        paddingTop: 1,
        paddingBottom: 1
      }
    }
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: false // No more ripple, on the whole application
    }
  },
  palette: {
    primary: {
      light: "#396AFC",
      main: "#2948FF",
      dark: "#002884",
      contrastText: "#fff"
    },
    secondary: {
      light: "#FF9966",
      main: "#FF5E62",
      dark: "#ba000d",
      contrastText: "#FFF"
    },
    background: {
      default: "#F7F9FD"
    },
    divider: "rgba(0, 0, 0, 0.85)",
    titleColor: "rgb(98, 86, 86)"
  },
  typography: {
    useNextVariants: true
  },
  shape: {
    borderRadius: 3
  },
  custom: {
    bottomDrawerBackground: "rgb(233, 236, 251)"
  }
});

export default theme;
