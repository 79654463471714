
export type size = {
  width: number;
  height: number;
}

const fitInBox = (width: number, height: number, maxWidth: number, maxHeight: number, expandable: boolean = false) : size => {
  let aspect: number = width / height;
  const initWidth = width;
  const initHeight = height;

  if (width > maxWidth || height < maxHeight) {
    width = maxWidth;
    height = Math.floor(width / aspect);
  }

  if (height > maxHeight || width < maxWidth) {
    height = maxHeight;
    width = Math.floor(height * aspect);
  }

  if (expandable === false && (width >= initWidth || height >= initHeight)) {
    width = initWidth;
    height = initHeight;
  }

  return {
    width,
    height
  };
};

export default fitInBox;
