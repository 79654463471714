import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import AddIcon from "@material-ui/icons/Add";
import UserFormDialog from "./UserFormDialog";
import UserType from "../../../types/User";
import ProjectType from "../../../types/Project";
import UiButton from "../../common/UiButton";


const styles = (theme: Theme) =>
  createStyles({
    button: {
      padding: "9px 16px",
      minWidth: "250px",
    },
  });

interface PropsTypes {
  saveUser: Function;
  classes: any;
  projects: ProjectType[];
}

const UsersActionButton = (props: PropsTypes) => {
  const { classes, saveUser, projects } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => setIsDialogOpen(true);

  const closeDialog = () => 
    setIsDialogOpen(false);

  const handleSave = (user: UserType) => {
    saveUser(user).then(() => closeDialog());
  };

  return (
    <React.Fragment>
      <UserFormDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onSave={handleSave}
        projects={projects}
      />      
      <UiButton
        color="secondary"
        variant="contained"
        onClick={openDialog}
        className={classes.button}
      >
        <AddIcon />
        Nouvel utilisateur
      </UiButton>
    </React.Fragment>
  );
};

export default withStyles(styles)(UsersActionButton);
