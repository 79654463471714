import React from "react";
import createStyles from "@material-ui/core/styles/createStyles";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    label: {
      display: "inline",
      minWidth: 200,
      textAlign: "center",
      padding: 5,
      marginRight: 10,
    },
    labelBox: {
      display: "inline",
      minWidth: 200,
      textAlign: "center",
      padding: 5,
      marginRight: 10,
    },
  });

const PlateCodeOrMotif = (props: any) => {
  const { images, classes, selectedIndex } = props;
  return (
    <ul style={{ margin: 0, listStyleType: "none", paddingLeft: 0 }}>
      {images.map((img: any, index: number) =>
        img.labels.rectangleLabels.length > 0 ? (
          <li
            key={img.id}
            className={classes.label}
            style={{ fontWeight: selectedIndex === index ? "bold" : "normal" }}
          >
            {img.labels.rectangleLabels
              .filter((f: any) => f.labelValue !== null)
              .sort((l1: any, l2: any) => {
                if (l1.position > l2.position) return 1;
                if (l1.position < l2.position) return -1;
                return 0;
              })
              .reduce((memo: string, lab: any) => memo + lab.labelValue, "")}
          </li>
        ) : (
          <li
            className={classes.label}
            key={img.id}
            style={{
              color: "gray",
              fontWeight: selectedIndex === index ? "bold" : "normal",
            }}
          >
            {img.motif}
          </li>
        )
      )}
    </ul>
  );
};

export default withStyles(styles)(PlateCodeOrMotif);
