import React, { useState } from "react";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import { format, parse } from "date-fns";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { withStyles, withTheme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";
import FilterIcon from "@material-ui/icons/FilterList";
import DoubleCheckIcon from "@material-ui/icons/DoneAll";
import withContext from "../../tools/withContext";
import BatchActionButton from "./components/BatchActionButton";
import BatchesActionButton from "./components/BatchesActionButton";
import ProgressBar from "./components/ProgressBar";
import UiSearchInputField from "../common/UiSearchInputField";
import UiSelect from "../common/UiSelect";
import TableBody from "../common/TableBody";
import EnhancedTableHeader from "../common/EnhancedTableHeader";
import DimensionsContainer from "../common/DimensionsContainer";
import TableNavigation from "../common/TableNavigation";
import { stableSort, getSorting } from "../../tools/sortingTools";
import BatchType from "../../types/Batch";
import ContextType from "../../types/Context";
import UserType from "../../types/User";
import { getHistory, pushToHistory } from "../../tools/batchHistory";
import useRouter from "../../tools/useRouter";
import FilteringBatchesDialog from "./components/FilteringBatchesDialog";

const rows = [
  {
    id: "selection",
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: "",
    onlyRoles: ["ROLE_ADMIN", "ROLE_PROJECT_ADMIN"],
    size: 1,
    align: "left",
  },
  {
    id: "name",
    numeric: false,
    sortable: true,
    disablePadding: true,
    label: "Nom du lot",
    onlyRoles: null,
    size: 2,
    align: "center",
  },
  {
    id: "createdDate",
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: "Date de création",
    onlyRoles: ["ROLE_ADMIN", "ROLE_PROJECT_ADMIN"],
    size: 2,
    align: "center",
  },
  {
    id: "filterFields.projectName",
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: "Projet",
    onlyRoles: null,
    size: 1,
    align: "center",
  },
  {
    id: "filterFields.percentDone",
    numeric: true,
    sortable: true,
    disablePadding: false,
    label: "Etat d'avancement",
    onlyRoles: null,
    size: 2,
    align: "center",
  },
  {
    id: "completed",
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: "Statut",
    onlyRoles: null,
    size: 1,
    align: "left",
  },
  {
    id: "filterFields.labelerFirstName",
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: "Labeler",
    onlyRoles: ["ROLE_ADMIN", "ROLE_PROJECT_ADMIN"],
    size: 2,
    align: "center",
  },
  {
    id: "deadline",
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: "Deadline",
    onlyRoles: null,
    size: 2,
    align: "left",
  },  
  {
    id: "actions",
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: "Actions",
    onlyRoles: null,
    size: 1,
    align: "right",
  },
];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 50,
    },
    noData: {
      textAlign: "center",
      padding: theme.spacing.unit * 2,
    },
    toolbar: {
      textAlign: "right",
      padding: theme.spacing.unit * 2,
    },
    textField: {
      borderRadius: "50px",
    },
    scopeSelect: {
      minWidth: 250,
    },
    fullWidth: {
      width: "100%",
    },
  });

interface PropsType {
  classes: any;
  ctx: ContextType;
  batches: BatchType[];
  saveBatch: Function;
  massUpdate: Function;
  deleteBatch: Function;
  deleteDeadline: Function;
  users: UserType[];
  isLoading: boolean;
  theme: any;
  location: any;
  history: any;
  match: any;
  pagination: any;
  keyEvent: any;
}

const Batches = (props: PropsType) => {
  const {
    classes,
    ctx: { user },
    match: {
      params: { noPage },
    },
    batches,
    massUpdate,
    saveBatch,
    deleteBatch,
    deleteDeadline,
    users,
    isLoading,
    theme,
    pagination,
    keyEvent,
  } = props;

  const router = useRouter();
  const searchParsed = router.query;

  const s = searchParsed.searchTerm || "";
  const h = searchParsed.batchIds || "tous";

  const [order, setOrder] = useState(
    //@ts-ignore
    searchParsed.sort ? searchParsed.sort.split(",")[1] : "desc"
  );

  const [orderBy, setOrderBy] = useState(
    //@ts-ignore
    searchParsed.sort ? searchParsed.sort.split(",")[0] : "createdDate"
  );

  //@ts-ignore
  const [searchTerm, setSearchTerm] = useState<string>(s);
  const [selected, setSelected] = useState<number[]>([]);
  const [scope, setScope] = useState(h);

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const [modalMode, setModalMode] = useState<boolean>(false);

  const [childrenShiftKeyPressed, setChildrenShiftKeyPressed] = useState<boolean>(false);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const handleRequestSort = (event: any, property: string) => {
    let _orderBy = property;
    let _order = "desc";

    if (orderBy === property && order === "desc") {
      _order = "asc";
    }

    props.history.push(
      `/batches/${noPage}?${queryString.stringify(
        { ...searchParsed, sort: `${_orderBy},${_order}` },
        { strict: false }
      )}`
    );
    setOrderBy(_orderBy);
    setOrder(_order);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      setSelected(batches.map((n) => n.id));
      return;
    }
    setSelected([]);
  };

  const handleSelection = (event: any, batchSelectedId: any) => {
    if (modalMode) return;
    const shiftKey = keyEvent.shiftKey || childrenShiftKeyPressed;
    const selectedIndex = selected.indexOf(batchSelectedId);
    let newSelected: number[] = [];
    if (selectedIndex === -1 || shiftKey) {
      if (!shiftKey) {
        newSelected = newSelected.concat(selected, batchSelectedId);
      } else if (selected.length === 1) {
        const firstElIdx = batches.findIndex(
          (b: BatchType) => b.id === selected[0]
        );
        const newSelectedIdx = batches.findIndex(
          (b: BatchType) => b.id === batchSelectedId
        );
        newSelected =
          firstElIdx < newSelectedIdx
            ? batches
                .slice(firstElIdx, newSelectedIdx + 1)
                .map((b: BatchType) => b.id)
            : batches
                .slice(newSelectedIdx, firstElIdx + 1)
                .map((b: BatchType) => b.id);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handlePushToHistory = (id: number) => {
    //@ts-ignore
    pushToHistory(id, user.id);
  };

  const selectOne = (id: any) => {
    setSelected([id]);
  };

  const selectNone = (id: any) => {
    setSelected([]);
  };

  const isAdmin =
    user &&
    (user.authorities[0] === "ROLE_ADMIN" ||
      user.authorities[0] === "ROLE_PROJECT_ADMIN");

  const roleFilter = (_: any, idx: number) =>
    rows[idx].onlyRoles === null ||
    //@ts-ignore
    rows[idx].onlyRoles.includes(user.authorities[0]);

  const scopedBatches =
    scope === "tous" || scope === "historique"
      ? batches
      : batches.filter((b: any) => b.labelerId === user.id); // tous_les_miens

  const batchesOrdereFiltered =
    order !== "createdDate"
      ? stableSort(scopedBatches, getSorting(order, orderBy))
      : scopedBatches;

  const buildColumns = (b: BatchType, isSelected: boolean) => {
    const cols = [
      <div className={classes.cellL}>
        <Checkbox checked={isSelected} />
      </div>,
      <div>{b.name}</div>,
      <div style={{ textAlign: "center" }}>
        {format(parse(new Date(b.createdDate)), "DD/MM/YY HH:mm")}
      </div>,
      <div>{b.filterFields.projectName}</div>,
      <div>
        <ProgressBar
          totalLabelDocs={b.totalLabelDocs}
          totalEligibleDocs={b.totalEligibleDocs}
          totalLabelDocsByModel={b.totalLabelDocsByModel}
        />
      </div>,
      <div style={{ textAlign: "right" }}>
        {b.completed && !b.verified && <CheckIcon />}
        {b.verified && <DoubleCheckIcon />}
      </div>,
      <div>{b.filterFields.labelerFirstName}</div>,
      <div style={{ textAlign: "center" }}>
        {b.deadline
          ? format(parse(new Date(b.deadline)), "DD/MM/YY")
          : "Aucune"}
      </div>,      
      <div>
        <BatchActionButton
          batches={batches}
          users={users}
          batch={b}
          selected={selected}
          massUpdate={massUpdate}
          saveBatch={saveBatch}
          selectOne={() => selectOne(b.id)}
          deleteBatch={deleteBatch}
          deleteDeadline={deleteDeadline}
          isAdmin={isAdmin}
          pushToHistory={handlePushToHistory}
          setModalMode={setModalMode}
        />
      </div>,
    ];
    // @ts-ignore
    return cols.filter(roleFilter);
    // return isAdmin ? cols.slice(1) : cols.filter((c: any, idx: number) => idx !== 0 && idx !== 1);
  };

  const handleChangePage = (noP: any) => {
    props.history.push(
      `/batches/${noP}?${queryString.stringify(router.query)}`
    );
  };

  const handleSearch = (str: string) => {
    setSearchTerm(str);
    props.history.push(
      `/batches/${str.length > 0 ? "0" : noPage}?${queryString.stringify({
        ...router.query,
        searchTerm: str.length > 0 ? str : undefined,
      })}`
    );
  };

  const handleChangeScope = (e: any) => {
    if (e.target.value === "historique") {
      props.history.push(
        //@ts-ignore
        `/batches/0?batchIds=${getHistory(user.id).join(",")}`
      );
    }
    if (e.target.value === "tous") {
      props.history.push("/batches/0?sort=createdDate,desc");
    }
    setSearchTerm("");
    setScope(e.target.value);
  };

  const handleCloseFilteringDialog = () => setFilterDialogOpen(false);

  const handleOpenFilteringDialog = () => setFilterDialogOpen(true);

  const handleFilterSubmitted = (
    filter: "verified" | "completed" | "not_verified_not_completed" | null
  ) => {
    const { completed, verified, ...restRouting } = searchParsed;
    const statusFilter: any = {};

    if (filter === "completed") {
      statusFilter.verified = false;
      statusFilter.completed = true;
    } else if (filter === "verified") {
      statusFilter.verified = true;
    } else if (filter === "not_verified_not_completed") {
      statusFilter.completed = false;
      statusFilter.verified = false;
    }

    const newQuery = {
      ...restRouting,
      ...statusFilter,
    };

    props.history.push(`/batches/0?${queryString.stringify(newQuery)}`);
  };

  const handleChildrenKeyEvent = (state: boolean) => setChildrenShiftKeyPressed(state);

  return (
    <Grid container direction="column" className={classes.root}>
      <FilteringBatchesDialog
        open={filterDialogOpen}
        onClose={handleCloseFilteringDialog}
        onSubmit={handleFilterSubmitted}
        clearFilter={handleFilterSubmitted}
        verified={searchParsed.verified}
        completed={searchParsed.completed}
      />
      <Helmet>
        <title>Liste des lots</title>
      </Helmet>
      <Grid item className={classes.fullWidth}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.toolbar}
        >
          <Grid item>
            <Grid container direction="row" spacing={8} alignItems="center">
              <Grid item>
                <Typography
                  component="h2"
                  style={{ fontSize: matches ? "1.8em" : "2.5em" }}
                >
                  Liste des lots{" "}
                  {searchParsed.projectId && batchesOrdereFiltered.length > 0
                    ? ` du projet "${batchesOrdereFiltered[0].project.name}"`
                    : ""}
                </Typography>
              </Grid>
              <Grid item>
                {s === "" && !searchParsed.projectId && (
                  <UiSelect
                    name="scope"
                    value={scope}
                    onChange={handleChangeScope}
                    className={classes.scopeSelect}
                  >
                    <option value="tous">Tous les lots</option>
                    <option value="tous_les_miens">Tous mes lots</option>
                    {typeof user !== "undefined" &&
                      //@ts-ignore
                      getHistory(user.id).length > 0 && (
                        <option value="historique">
                          Derniers lots consultés
                        </option>
                      )}
                  </UiSelect>
                )}
              </Grid>
              <Grid item>
                <IconButton title="Filtrer par statut">
                  <FilterIcon
                    color={
                      searchParsed.completed === "true" ||
                      searchParsed.verfified === "true"
                        ? "secondary"
                        : "default"
                    }
                    onClick={handleOpenFilteringDialog}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{isLoading && <CircularProgress color="primary" />}</Grid>
          <Grid item>
            <Grid container direction="row" spacing={8}>
              <Grid item>
                {isAdmin && (
                  <BatchesActionButton
                    saveBatch={massUpdate}
                    deleteBatch={deleteBatch}
                    users={users}
                    batches={batches}
                    selected={selected}
                    selectNone={selectNone}
                    setModalMode={setModalMode}
                  />
                )}
              </Grid>
              <Grid item>
                <UiSearchInputField
                  searchString={searchTerm}
                  setSearchString={handleSearch}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {batchesOrdereFiltered.length === 0 && !isLoading && (
        <Grid item className={classes.fullWidth}>
          <Typography className={classes.noData} variant="h4">
            Pour l'instant, aucun lot{" "}
            {isAdmin && searchTerm !== "" && " ne correspond à votre recherche"}
            {!isAdmin && " ne vous a été attribué."}
          </Typography>
        </Grid>
      )}
      {batchesOrdereFiltered.length > 0 && (
        <>
          <Grid item className={classes.fullWidth}>
            <EnhancedTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              rowCount={batches.length}
              rows={rows.filter(roleFilter)}
              withCheckBox={isAdmin}
              numSelected={selected.length}
            />
          </Grid>
          <DimensionsContainer>
            <TableBody
              handleSelection={handleSelection}
              selected={selected}
              buildColumns={buildColumns}
              datas={batchesOrdereFiltered}
              noPage={noPage}
              rows={rows.filter(roleFilter)}
              onShiftStateChanged={handleChildrenKeyEvent}
            />
          </DimensionsContainer>
          {scope === "tous" && pagination && (
            <Grid item className={classes.fullWidth}>
              <TableNavigation
                pagination={pagination}
                onChangePage={handleChangePage}
                itemName="Lots"
                numberSelected={selected.length}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(
  withRouter(withContext(withTheme()(Batches)))
);
