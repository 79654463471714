import * as React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";
import BackIcon from "@material-ui/icons/ArrowBack";
import FwdIcon from "@material-ui/icons/ArrowForward";
import VisibleIcon from "@material-ui/icons/Visibility";
import HiddenIcon from "@material-ui/icons/VisibilityOff";
import UiButton from "./UiButton";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      // marginTop: theme.spacing.unit * 2,
      lineHeight: "27px",
      //minWidth: "240px"
    },
    hidden: {
      visibility: "hidden",
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    toolbar: {
      padding: theme.spacing.unit,
      textAlign: "right",
    },
    withMinHeight: {
      minHeight: `calc(100vh - 80px)`,
    },
    btnDisabled: {
      opacity: 0.4,
    },
  });

interface PropsTypes {
  navigateBackVisible: boolean;
  navigateFwdVisible: boolean;
  onNavigateBackClicked: any;
  onNavigateFwdClicked: any;
  navigationOnly: boolean;
  motif: boolean;
  classes: any;
  onSaveClicked?: any;
  onSkipClicked: any;
  onDeleteClicked?: any;
  someItemsHidden?: boolean;
  onToggleVisibility?: any;
  onAddClicked?: any;
  savingDisabled?: boolean;
  hiddenableShapeName?: string;
  width?: Breakpoint;
  selected?: boolean;
  nombreItems?: number;
  acceptLabel?: string;
}

const CanvasButtons = (props: PropsTypes) => {
  const {
    selected,
    classes,
    onSaveClicked,
    onSkipClicked,
    onDeleteClicked,
    onAddClicked,
    onNavigateBackClicked,
    onNavigateFwdClicked,
    navigateBackVisible,
    navigateFwdVisible,
    onToggleVisibility,
    someItemsHidden,
    nombreItems,
    motif,
    width,
    navigationOnly,
    savingDisabled,
    hiddenableShapeName,
  } = props;
  return (
    <Grid
      container
      direction="column"
      spacing={0}
      alignItems="stretch"
      className={classNames(classes.toolbar)}
    >
      <Grid item style={{ padding: 10, flexGrow: 1 }}>
        <Grid
          container
          direction="column"
          spacing={8}
          justify="space-between"
          alignItems="stretch"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={8}
              justify="center"
              alignItems="stretch"
            >
              <Grid item style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  onClick={onNavigateBackClicked}
                  title="Image précédente"
                  className={classNames(classes.button, {
                    [classes.btnDisabled]: !navigateBackVisible,
                  })}
                  disabled={!navigateBackVisible}
                  fullWidth
                >
                  <BackIcon />
                </Button>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  onClick={onNavigateFwdClicked}
                  title="Image suivante"
                  className={classNames(classes.button, {
                    [classes.btnDisabled]: !navigateFwdVisible,
                  })}
                  disabled={!navigateFwdVisible}
                  fullWidth
                >
                  <FwdIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {!navigationOnly && (onToggleVisibility || onAddClicked) && (
            <Grid item>
              <Grid
                container
                direction="row"
                spacing={8}
                justify="center"
                alignItems="stretch"
              >
                <Grid item style={{ flexGrow: 1 }}>
                  <Button
                    variant="contained"
                    onClick={onToggleVisibility}
                    title={`${
                      someItemsHidden ? "Montrer" : "Cacher"
                    } ${hiddenableShapeName}`}
                    className={classNames(classes.button, {
                      [classes.btnDisabled]: nombreItems === 0,
                    })}
                    disabled={nombreItems === 0}
                    fullWidth
                  >
                    {!someItemsHidden ? <VisibleIcon /> : <HiddenIcon />}
                  </Button>
                </Grid>
                {onAddClicked && (
                  <Grid item style={{ flexGrow: 1 }}>
                    <Button
                      variant="contained"
                      onClick={onAddClicked}
                      title="Ajouter un noeud"
                      className={classNames(classes.button, {
                        [classes.btnDisabled]: !selected,
                      })}
                      disabled={!selected}
                      fullWidth
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                )}
                <Grid item style={{ flexGrow: 1 }}>
                  <Button
                    variant="contained"
                    onClick={onDeleteClicked}
                    className={classNames(classes.button, {
                      [classes.btnDisabled]: !selected,
                    })}
                    disabled={!selected}
                    title="Supprimer la sélection"
                    fullWidth
                  >
                    <DeleteIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          padding: `${
            width && isWidthDown("lg", width) ? "0.5" : "3"
          }em 1em 1em`,
          flexGrow: 1,
        }}
      >
        <Grid container direction="row" spacing={8} justify="flex-end">
          <Grid item>
            <UiButton
              color="secondary"
              variant="outlined"
              onClick={onSkipClicked}
              className={classes.button}
              title="Passer cette image"
            >
              {motif ? "Modifier motif" : "Passer cette image"}
            </UiButton>
          </Grid>
          {!navigationOnly && (
            <Grid item>
              <UiButton
                color="secondary"
                variant="contained"
                onClick={onSaveClicked}
                className={classes.button}
                title="Sauvegarder"
                disabled={savingDisabled}
              >
                Sauvegarder
              </UiButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

CanvasButtons.defaultProps = {
  navigationOnly: false,
  onToggleVisibility: null,
  onAddClicked: null,
};

export default withStyles(styles)(withWidth()(CanvasButtons));
