import React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "0px 4px 1px rgba(0,0,0, 0.02)"
    },
    formControl: {
      width: "100%"
    },
    select: {
      borderRadius: 4,
      padding: "7px 7px",
      boxSizing: "border-box",
      fontSize: 16,
      position: "relative",
      border: "1px solid rgba(72,72,72,.2)",
      appearance: "none",
      color: "#444f59",
      background: "url(/assets/arrow-select.png) no-repeat 100%",
      boxShadow: "0px 4px 1px rgba(0,0,0, 0.02)",
      width: "100%",
      "label + &": {
        marginTop: theme.spacing.unit
      },
      "& svg": {
        display: "none"
      }
    },
    option: {
      backgroundColor: "red",
      fontSize: "3em"
    },
    label: {
      display: "block",
      fontSize: 16,
      fontWeight: 200
    }
  });

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const UISelectMultipleProjects = (props: any) => {
  const {
    classes,
    label,
    required,
    projects,
    datas,
    handleChange,
    disabled,
    allProjects
  } = props;

  return (
    <FormControl className={classes.formControl}>
      {label && (
        <label className={classes.label}>
          {label}
          {required && (
            <span>
              {" "}
              <sup>*</sup>
            </span>
          )}
        </label>
      )}
      <Select
        multiple
        name="projects"
        value={datas.projects}
        onChange={handleChange}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={(selected: any) =>
          selected
            .map((id: any) => {
              let p = projects.find((p: any) => p.id === id);
              if (!p && props.allProjects) {
                p = allProjects.find((p: any) => p.id === id);
              }
              return p ? p.name : null;
            })
            .filter((p: any) => p !== null)
            .join(", ")
        }
        MenuProps={MenuProps}
        disabled={disabled}
        className={classes.select}
      >
        {projects.map((u: any) => (
          <MenuItem key={u.id} value={u.id}>
            <Checkbox
              checked={datas.projects && datas.projects.indexOf(u.id) > -1}
            />
            <ListItemText
              primary={`${u.name}${u.client ? ` /${u.client.name}` : ""}`}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

UISelectMultipleProjects.defaultProps = {
  minWidth: "250px",
  disabled: false
};

export default withStyles(styles)(UISelectMultipleProjects);
