import * as React from "react";
import classNames from "classnames";
import { RouteComponentProps } from "react-router";
import { Link, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none"
    },
    selected: {
      borderBottom: "1px solid silver"
    }
  });

interface PropTypes extends RouteComponentProps<any>, React.Props<any> {
  classes: any;
  history: any;
  href: string;
  children: any;
}

const UiLinkButton = (props: PropTypes) => {
  const {
    classes,
    history: {
      location: { pathname }
    },
    href
  } = props;

  return (
    <Button
      color="inherit"
      to={props.href}
      //@ts-ignore
      component={Link}
      className={classNames(classes.root, {
        [classes.selected]: pathname === href.split("?")[0]
      })}
    >
      {props.children}
    </Button>
  );
};

export default withStyles(styles)(withRouter(UiLinkButton));
