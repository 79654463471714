import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import CustomAppBar from "./pages/CustomAppBar";
import GlobalContext from "./GlobalContext";
import constants from "./tools/constants";
import MainRouter from "./pages/MainRouter";
import * as authStorage from "./tools/authStorage";
import theme from "./theme";
import UserType from "./types/User";
import jwt_decode from "jwt-decode";

interface TranslationType {
  [key: string]: string;
}

const translations: TranslationType = {
  Unauthorized: "Connexion refusée",
};

const styles = (thm: Theme) => {
  return createStyles({
    content: {
      display: "flex",
      width: "100%",
      flexGrow: 1,
      // backgroundColor: "blue"
    },
    padded: {
      padding: `0 ${thm.spacing.unit * 2}px ${thm.spacing.unit * 2}px ${thm
        .spacing.unit * 2}px`,
    },
    fullPadded: {
      paddingTop: `${thm.spacing.unit * 2}px`,
    },
  });
};

const App = (props: any) => {
  const { classes } = props;
  const [user, setUser] = useState<any>(null);
  const [triedUserFromStorage, setTriedUserFromStorage] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    id: "",
  });

  const handleLogout = () => {
    authStorage.clearUser();
    setUser(null);
  };

  const appFetch = async (
    url: any,
    options?: any,
    respWithHeader?: boolean
  ) => {
    if (user && user.exp && user.exp < Date.now() / 1000) {
      handleLogout();
    }
    const auth = user ? { Authorization: "Bearer " + user.token } : {};
    let headers = {
      "Content-Type": "application/json",
      ...auth,
    };

    const res = await fetch(
      process.env.NODE_ENV === "production" && url.indexOf("http") === -1
        ? //@ts-ignore
          `${(window as any)["env"]["API_URL"]}${url}`
        : url,
      {
        ...options,
        headers: {
          ...headers,
          ...(options && options.headers ? options.headers : {}),
        },
      }
    );
    if (!res.ok) {
      throw new Error(translations[res.statusText] || res.statusText);
    }

    const contentType = res.headers.get("Content-Type") || "";
    if (res.status === 200 && contentType === "image/png") {
      return res;
    }
    if (res.status !== 204 && contentType.indexOf("application/json") !== -1) {
      if (respWithHeader) {
        return {
          headers: res.headers,
          response: res.json(),
        };
      }
      return res.json();
    }

    if (respWithHeader) {
      return {
        headers: res.headers,
        response: res.text(),
      };
    }
    return res.text();
  };

  const handleSubmitLogin = async (username: string, password: string) => {
    const res = await appFetch(
      constants.paths.AUTH_PATH,
      {
        method: "POST",
        body: JSON.stringify({ username, password }),
      },
      true
    );
    setCredentials({
      username: username,
      password: password,
      id: res.headers.get("userId"),
    });
    // on renvoie le lien de redirection en fonction de si l'user
    // a deja configuré le 2FA ou non
    return res.headers.get("link");
  };

  const handleSubmitVerify = async (code: string) => {
    if (credentials.username === "") {
      window.location.replace("/login");
    }
    try {
      const res: any = await appFetch(`${constants.paths.VERIFY_CODE_PATH}`, {
        method: "POST",
        body: JSON.stringify({
          id: credentials.id,
          username: credentials.username,
          password: credentials.password,
          code: code,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
      });

      const { auth, exp, sub } = jwt_decode(res.id_token);
      const userInfos: UserType = await appFetch(
        `${constants.paths.USERS_PATH}/${sub}`,
        {
          headers: {
            Authorization: "Bearer " + res.id_token,
            "Content-Type": "application/json",
          },
        }
      );

      setUser({
        ...user,
        ...userInfos,
        authorities: [auth],
        exp,
        token: res.id_token,
      });
      authStorage.storeUser({
        ...user,
        ...userInfos,
        authorities: [auth],
        exp,
        token: res.id_token,
      });

      // on renvoie les droits
      return auth;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getQrCode = () => {
    if (credentials.username === "") {
      window.location.replace("/login");
    }
    return appFetch(`${constants.paths.QRCODE_PATH}`, {
      method: "POST",
      body: JSON.stringify({
        username: credentials.username,
        password: credentials.password,
      }),
    })
      .then((resp) => {
        return resp.blob();
      })
      .then(async (blob) => {
        const dataImage = await blobToBase64(blob);
        const urlImage = await URL.createObjectURL(blob);
        return { data: dataImage, url: urlImage };
      });
  };

  const updateUserAuth = (userInfos: any) => {
    const usr = authStorage.getUser();
    setUser({
      ...usr,
      ...userInfos,
    });
    authStorage.storeUser({
      ...usr,
      ...userInfos,
    });
  };

  useEffect(() => {
    const usr = authStorage.getUser();

    // dans le router la redirecttion vers login intervenait
    // avant qu'on ait eu le temps d'essayer charger l'utilisateur depuis localStorage
    setTriedUserFromStorage(true);

    if (usr) {
      setUser(usr);
    }
  }, []);

  if (!triedUserFromStorage) {
    return null;
  }

  const { pathname } = window.location;
  const noPadding =
    pathname.search("login") !== -1 ||
    (pathname.search("password") !== -1 && !props.user) ||
    pathname.search("qrcode") !== -1 ||
    pathname.search("verifyCode") !== -1;

  const fullPadded = pathname.search("mosaic") !== -1;

  console.log(pathname);
  return (
    <>
      <CssBaseline />
      <Router>
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider
            value={{ user, appFetch, updateUserAuth, setUser }}
          >
            <CustomAppBar user={user} handleLogout={handleLogout} />
            <main
              className={classNames(classes.content, {
                [classes.padded]: !noPadding,
                [classes.fullPadded]: fullPadded,
              })}
            >
              <MainRouter
                handleSubmitLogin={handleSubmitLogin}
                onSubmitVerify={handleSubmitVerify}
                getQrCode={getQrCode}
                user={user}
                handleLogout={handleLogout}
              />
            </main>
          </GlobalContext.Provider>
        </MuiThemeProvider>
      </Router>
    </>
  );
};

export default withStyles(styles)(App);
