import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    container: {
        flexGrow: 1,
    }
});

class DimensionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      painted: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ painted: true }), 50);
  }

  render() {
    return (
      <div className={this.props.classes.container} ref={this.containerRef}>
        {this.state.painted &&
          this.containerRef !== null &&
          this.containerRef.current &&
          React.cloneElement(this.props.children, {
            height: this.containerRef.current.offsetHeight,
            width: this.containerRef.current.offsetWidth
          })}
      </div>
    );
  }
}

export default withStyles(styles)(DimensionsContainer);
