import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import UiTableSortLabel from "./UiTableSortLabel";
import withContext from "../../tools/withContext";
import ContextType from "../../types/Context";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: "grid",
      marginBottom: "8px",
      borderBottom: "solid 1px silver"
    },
    root: {
      textAlign: "center",
      borderBottom: "none",
      backgroundColor: "inherit",
      padding: 0,
      color: "gray",
      paddingTop: theme.spacing.unit,
      marginBottom: "8px"
    },
    firstRoot: {
      textAlign: "left",
      marginBottom: "8px"
    },
    lastRoot: {
      textAlign: "right",
      marginBottom: "8px"
    }
  });

interface PropsTypes {
  order: "asc" | "desc";
  orderBy: string;
  rows: any[];
  ctx: ContextType;
  onRequestSort: any;
  withCheckBox: boolean;
  numSelected?: number;
  rowCount?: number;
  onSelectAllClick?: any;
  classes: any;
  actionsCellSize: any;
}

const EnhancedTableHead = (props: PropsTypes) => {
  const {
    order,
    orderBy,
    rows,
    ctx: { user },
    onRequestSort,
    withCheckBox,
    onSelectAllClick,
    numSelected,
    rowCount,
    classes
  } = props;

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  const userRole = user ? user.authorities[0] : null;
  const gridTemplateColumns = rows
    .filter(
      (r: any) =>
        // r.label !== "" &&
        !r.onlyAdmin || r.onlyRoles === null || r.onlyRoles.includes(userRole)
    )
    .map((row: any) => `${row.size}fr`)
    .join(" ");

  return (
    <div className={classes.header} style={{ gridTemplateColumns }}>
      {withCheckBox && typeof numSelected !== "undefined" && rowCount && (
        <div className={classes.firstRoot}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            onClick={onSelectAllClick}
          />
        </div>
      )}
      {rows
        .filter(
          (r: any) =>
            r.label !== "" &&
            (!r.onlyAdmin ||
              r.onlyRoles === null ||
              r.onlyRoles.includes(userRole))
        )
        .map((row: any, index: number) => (
          <div className={classes.root} key={index}>
            {
              //@ts-ignore
            row.sortable ? ( <Tooltip title="Sort" placement={row.numeric ? "bottom-end" : "bottom-start"} enterDelay={300} direction={order} onClick={createSortHandler(row.id)}>
                <UiTableSortLabel active={orderBy === row.id}>
                  {row.label}
                </UiTableSortLabel>
              </Tooltip>
            ) : (
              <span>{row.label}</span>
            )}
          </div>
        ))}
    </div>
  );
};

EnhancedTableHead.defaultProps = {
  withCheckBox: false,
  actionsCellSize: 2
};

export default withContext(withStyles(styles)(EnhancedTableHead));
