import React from "react";
import { withTheme } from "@material-ui/core/styles";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import VisibleIcon from "@material-ui/icons/Visibility";
import HiddenIcon from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";

const ResponsiveSection = (props: any) => {
  const {
    image,
    immatriculation,
    theme,
    classes,
    toggelShowPrediction,
    showPrediction,
    clearZoom,
    editImmatriculation
  } = props;

  let matches = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Grid
      container
      direction={matches ? "column" : "row"}
      spacing={8}
      justify={matches ? "center" : "space-around"}
      className={classes.responsiveSection}
    >
      {image && (
        <Grid className={classes.centered}>
          <button className={classes.imageButton} onClick={clearZoom}>
            <img
              src={image.url}
              alt="Pb chargement"
              height={matches ? 150 : "auto"}
              width={matches ? "auto" : 200}
            />
          </button>
        </Grid>
      )}
      {(!image.prediction || image.prediction.lpPred === "") &&
        immatriculation !== "" && (
          <Grid item>
            <Slide direction="right" in timeout={100}>
              <Grid
                container
                direction={matches ? "row" : "column"}
                alignItems="center"
                justify="center"
                spacing={8}
                // className={classes.paddedLeft}
              >
                <Grid item>Plaque lue :</Grid>
                <Grid item>
                  <strong className={classes.bold}>{immatriculation}</strong>
                </Grid>
                <Grid item>
                  <IconButton onClick={editImmatriculation} title="Modifier">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Slide>
          </Grid>
        )}
      {image.prediction && image.prediction.lpPred && (
        <Grid item>
          <Slide direction="right" in timeout={100}>
            <Grid
              container
              direction={matches ? "row" : "column"}
              alignItems="center"
              justify="center"
              spacing={8}
              // className={classes.paddedLeft}
            >
              <Grid item>Prédiction cyclope :</Grid>
              <Grid item>
                <strong className={classes.bold}>
                  {image.prediction.lpPred}
                </strong>
                <IconButton
                  onClick={toggelShowPrediction}
                  title={`${
                    showPrediction ? "Cacher" : "Montrer"
                  } la prédiction Cyclope`}
                >
                  {!showPrediction ? <VisibleIcon /> : <HiddenIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Slide>
        </Grid>
      )}
    </Grid>
  );
};

export default withTheme()(ResponsiveSection);
