import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import { RouteComponentProps } from 'react-router';
import ProjectType from '../../../types/Project';
import UiButton from '../../common/UiButton';
import UiInput from '../../common/UiInput';
import UiDialogActions from '../../common/UiDialogActions';
import UiDialogTitle from '../../common/UiDialogTitle';
import UserType from '../../../types/User';
import withContext from '../../../tools/withContext';

const styles = (theme: Theme) =>
	createStyles({
		form: {
			padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 4}px`
		},
		formArea: {
			overflowY: 'hidden'
		},
		list: {
			height: 250,
			overflowY: 'scroll',
			minWidth: '345px'
		},
		input: {
			marginBottom: '1em',
			minWidth: '345px'
		},
		action: {
			paddingRight: '48px',
			paddingBottom: '24px'
		}
	});

interface PropsTypes extends RouteComponentProps<any>, React.Props<any> {
	user?: UserType;
	projects: ProjectType[];
	projectsSelected: string[];
	onClick: Function;
	clearFilter: Function;
	onFilteringSubmitted: Function;
	isAlreadyFiltred: boolean;
	open: boolean;
	onClose: any;
	onSave: any;
	classes: any;
	ctx: any;
}

const FilteringProjectDialog = (props: PropsTypes) => {
	const { projectsSelected, onClick, projects, open, onClose, classes, isAlreadyFiltred, clearFilter, onFilteringSubmitted } = props;

	const [ stringSearch, setStringSearch ] = useState<string>('');

	const handleChange = (e: any) => setStringSearch(e.target.value);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		onFilteringSubmitted();
		onClose();
  	};
  
	const handleClick = (e: any, projectId: string) => {
		e.preventDefault();
		e.stopPropagation();
		onClick(projectId);
	}

	const clearSelection = () => {
		clearFilter();
		onClose();
	}

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="list-actif-title">
			<form onSubmit={handleSubmit} className={classes.formArea}>
				<UiDialogTitle title={'Filtrer par projets'} onCloseClick={onClose} />
				<DialogContent>
					<Grid container spacing={0} direction="column" justify="space-between">
						<Grid item className={classes.input}>
							<UiInput onChange={handleChange} required={false} />
						</Grid>
						<Grid item>
							<List className={classes.list}>
								{projects
									.filter(
										(p: any) => stringSearch === '' || p.name.toLowerCase().includes(stringSearch)
									)
									.map((p: any) => {
										const checked = typeof projectsSelected.find((id: string) => id === p.id) !== "undefined";
										return (<ListItem key={p.id} role={undefined} dense button onClick={(e) => handleClick(e, p.id)}>
											<Checkbox
												checked={checked}
												tabIndex={-1}
												disableRipple
											/>
											<ListItemText
												primary={`${p.name}${p.client ? ` / ${p.client.name}` : ''}`}
											/>
										</ListItem>);
									})}
							</List>
						</Grid>
					</Grid>
				</DialogContent>
				<UiDialogActions className={classes.action}>
					{isAlreadyFiltred && <UiButton variant="outlined" color="primary" onClick={clearSelection}>
						Supprimer le filtre
					</UiButton>}					
					<UiButton variant="contained" color="secondary" type="submit">
						Filtrer
					</UiButton>
				</UiDialogActions>
			</form>
		</Dialog>
	);
};

export default withStyles(styles)(withRouter(withContext(FilteringProjectDialog)));
