import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import CustomParticles from "../common/CustomParticles";
import InputContainer from "./inputContainer";
import ContextType from "../../types/Context";
import withContext from "../../tools/withContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 10%, ${theme.palette.primary.light})`,
      height: "100%",
      width: "100%",
      paddingTop: "100px ",
    },
    errorLine: {
      marginTop: theme.spacing.unit * 2,
      height: 45,
    },
    errorMesg: {
      fontWeight: "bold",
      color: "#FFF",
    },
    labeler: {
      color: "#FFF",
      textAlign: "center",
    },
    info: {
      color: "#FFF",
      fontSize: "16px",
    },
  });

type Props = {
  classes: any;
  history: any;
  onSubmitVerify: (value: string) => Promise<any>;
  getQrCode: () => any;
  type: string;
};

const Page2FA = ({
  classes,
  history,
  onSubmitVerify,
  getQrCode,
  type,
}: Props) => {
  const [submitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [qrCodeImage, setQrCodeImage] = useState<{
    data: string;
    url: string;
  }>();
  const [redirect, setRedirection] = useState<any>({});

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      setError("");
      onSubmitVerify(codeValue).then((auth) => {
        // L'erreur n'est pas toujours catchée par le catch donc j'ai ajouté cette condition
        if (auth instanceof Error) {
          setIsSubmitting(false);
          setError(
            "Une erreure est survenue. Veuillez vérifier le code de vérification."
          );
          return;
        }
        if (Object.keys(redirect).length === 0 || auth === "ROLE_LABELER") {
          history.push("/batches/0?sort=createdDate,desc");
          return;
        }
        history.push(`${redirect.pathname}${redirect.search}`);
      });
    } catch (err) {
      setIsSubmitting(false);
      setError(
        "Une erreure est survenue. Veuillez vérifier le code de vérification."
      );
    }
  };

  const fetchImage = async () => {
    const res = await getQrCode();
    setQrCodeImage(res);
  };

  useEffect(() => {
    if (type === "qrcode") {
      fetchImage();
    }
  }, []);

  useEffect(() => {
    const red = window.localStorage.getItem("redirect");
    if (red) {
      setRedirection(JSON.parse(red));
    }
    return () => window.localStorage.removeItem("redirect");
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justify="center"
      className={classes.root}
    >
      <CustomParticles />
      <Grid item sm={3} xl={2} style={{ zIndex: 1 }}>
        <Grid
          container
          direction="column"
          spacing={8}
          component="form"
          onSubmit={handleSubmit}
          justify="center"
          alignItems="stretch"
        >
          <Grid item>
            <Typography
              variant="h4"
              color="inherit"
              className={classes.labeler}
            >
              Labeler
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="inherit" className={classes.info}>
              {type === "qrcode" && (
                <>
                  Veuillez scanner le QR code ci-dessous avec{" "}
                  <b>Google Authenticator</b> :
                </>
              )}
            </Typography>
          </Grid>
          {type === "qrcode" && (
            <Grid item>
              <img
                style={{ zIndex: 50 }}
                src={qrCodeImage?.data}
                width={200}
                height={200}
              />
              <Typography
                className={classes.info}
                style={{ fontWeight: "bold" }}
              >
                Si vous ne voyez pas le QRCode,{" "}
                <a
                  href={qrCodeImage?.url}
                  target="_blank"
                  style={{ fontWeight: "bold", color: "white" }}
                >
                  cliquez ici
                </a>
              </Typography>
            </Grid>
          )}
          <Grid item container justify="center">
            <InputContainer
              codeValue={codeValue}
              setCodeValue={setCodeValue}
              submitting={submitting}
            />
          </Grid>
          <Grid item className={classes.errorLine}>
            {error && (
              <Slide direction="right" in timeout={50}>
                <Typography className={classes.errorMesg}>{error}</Typography>
              </Slide>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(withContext(Page2FA)));
