import React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary.main,
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 4}px`,
      borderRadius: "5px 5px 0 0",
      color: "#FFF"
    },
    title: {
      "@media (min-width:600px) and (orientation: landscape)": {
        lineHeight: 1.2,
        fontSize: "1.2rem"
      }
    }
  });

interface PropsTypes {
  classes: any;
  children: any;
}

const UiFormTitle = (props: PropsTypes) => {
  const { classes, children } = props;
  return (
    <header className={classes.header}>
      <Typography variant="h5" color="inherit" className={classes.title}>
        {children}
      </Typography>
    </header>
  );
};

export default withStyles(styles)(UiFormTitle);
