import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import UiProgress from "./UiProgess";
import KeyboardHelperButton from "./KeyboardHelperButton";

const styles = (theme: Theme) =>
  createStyles({
    labels: {
      padding: `0 ${theme.spacing.unit * 2}px`
    },
    sectionTitle: {
      textAlign: "center"
    }
  });

interface PropTypes {
  total: number;
  nbrLabelisees: number;
  classes: any;
  onHelpClicked: any;
  showHelp: boolean;
}

const Progress = (props: PropTypes) => {
  const { total, nbrLabelisees, classes, onHelpClicked, showHelp } = props;
  return (
    <Grid
      container
      direction="row"
      spacing={0}
      alignItems="flex-end"
      justify={onHelpClicked ? "space-between" : "center"}
      className={classes.labels}
    >
      <Grid item xs={onHelpClicked ? 8 : 10}>
        <UiProgress
          variant="determinate"
          color="primary"
          value={(nbrLabelisees * 100) / total}
        />
        <small>
          Nombre d'images labellisées :{" "}
          <strong>
            {nbrLabelisees}/{total}
          </strong>
        </small>
      </Grid>
      {onHelpClicked && (
        <Grid item xs={1}>
          <KeyboardHelperButton onClick={onHelpClicked} showHelp={showHelp} />
        </Grid>
      )}
    </Grid>
  );
};

Progress.defaultProps = {
  onHelpClicked: null
};

export default withStyles(styles)(Progress);
