import React from "react";
import { withRouter } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import UserIcon from "@material-ui/icons/Person";
import DoneIcon from "@material-ui/icons/Done";
import EventIcon from "@material-ui/icons/Event";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DeleteIcon from "@material-ui/icons/Delete";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ClassIcon from "@material-ui/icons/Class";
import MenuTransition from "../../common/MenuTransition";
import constants from "../../../tools/constants";
import withContext from "../../../tools/withContext";
import generateType from "./generateType";
import requestFileDownload from "./requestDownloadFile";

//@ts-ignore
const backHost = window["env"]["API_URL"];

const BatchActionsMenu = (props: any) => {
  const {
    closeMenu,
    pushToHistory,
    history,
    anchorEl,
    batch,
    openDialog,
    openAlert,
    ctx: {
      user: {
        id,
        authorities: [userRole],
        token,
      },
    },
  } = props;

  const options: any[] = [
    {
      id: 1,
      label: "Exporter vers csv",
      component: "span",
      href: null,
      roles: ["ROLE_ADMIN"],
      isVisible: () => true,
      onClick: async () => {
        await requestFileDownload(
          "GET",
          `${backHost}${constants.paths.BATCHES_EXPORT_PATH}?type=CSV&batches=${batch.id}`,
          token,
          "",
          "application/json"
        );
        closeMenu();
      },
      icon: <GetAppIcon />,
    },
    {
      id: 2,
      label: "Exporter vers json",
      component: "span",
      href: null,
      roles: ["ROLE_ADMIN"],
      isVisible: () => true,
      onClick: async () => {
        await requestFileDownload(
          "GET",
          `${backHost}${constants.paths.BATCHES_EXPORT_PATH}?type=JSON&batches=${batch.id}`,
          token,
          "",
          "application/json"
        );

        closeMenu();
      },
      icon: <GetAppIcon />,
    },
    {
      id: 3,
      label: "Labelliser",
      href: null,
      component: "span",
      roles: ["ROLE_ADMIN", "ROLE_PROJECT_ADMIN"],
      isVisible: () =>
        userRole !== "ROLE_LABELER" || (!batch.verified && !batch.completed),
      onClick: (e: any) => {
        e.stopPropagation();
        const type = generateType(batch);
        window.open(
          `/labeler${type}/${batch.id}/${
            batch.totalLabelDocs >= batch.totalEligibleDocs
              ? 0
              : batch.totalLabelDocs
          }`,
          "_blank"
        );
        closeMenu();
      },
      icon: <ClassIcon />,
    },
    {
      id: 4,
      label: "Modifier l'attribution",
      href: null,
      component: "span",
      roles: ["ROLE_ADMIN", "ROLE_PROJECT_ADMIN"],
      isVisible: () => true,
      onClick: (e: any) => {
        e.stopPropagation();
        openDialog("attrib");
        closeMenu();
      },
      icon: <UserIcon />,
    },
    {
      id: 5,
      label: "Deadline",
      href: null,
      component: "span",
      roles: ["ROLE_ADMIN", "ROLE_PROJECT_ADMIN"],
      isVisible: () => true,
      onClick: (e: any) => {
        e.stopPropagation();
        openDialog("deadline");
        closeMenu();
      },
      icon: <EventIcon />,
    },
    {
      id: 6,
      label: "Mosaique",
      href: null,
      component: "span",
      roles: ["ROLE_ADMIN", "ROLE_PROJECT_ADMIN"],
      isVisible: () => false, //batch.filterFields.percentDone === 100,
      onClick: (e: any) => {
        e.stopPropagation();
        pushToHistory(batch.id);
        history.push(`/mosaic/${batch.id}`);
      },
      icon: <DashboardIcon />,
    },
    {
      id: 7,
      label: "Corriger le lot",
      href: null,
      component: "span",
      roles: ["ROLE_LABELER"],
      isVisible: () => true,
      onClick: (e: any) => {
        e.stopPropagation();
        const type = generateType(batch);
        pushToHistory(batch.id);
        history.push(`/labeler${type}/${batch.id}/0`);
      },
      icon: <SwapHorizIcon />,
    },
    {
      id: 8,
      label: "Vérifier le lot",
      href: null,
      component: "span",
      roles: ["ROLE_ADMIN", "ROLE_PROJECT_ADMIN"],
      isVisible: () => true,
      onClick: (e: any) => {
        e.stopPropagation();
        const type = generateType(batch);
        pushToHistory(batch.id);
        history.push(`/labeler${type}/${batch.id}/0`);
      },
      icon: <SwapHorizIcon />,
    },
    {
      id: 9,
      label: "Lot terminé",
      href: null,
      component: "span",
      roles: ["ROLE_LABELER", "ROLE_ADMIN", "ROLE_PROJECT_ADMIN"],
      isVisible: () =>
        batch.totalLabelDocs === batch.totalEligibleDocs &&
        !batch.completed &&
        (userRole !== "ROLE_PROJECT_ADMIN" ||
          batch.project.creatorId === null ||
          batch.project.id === id),
      onClick: (e: any) => {
        e.stopPropagation();
        openAlert({
          type: "CLOSE",
          title: "Lot terminé",
          text: `Lot ${batch.name} terminé ?`,
        });
        closeMenu();
      },
      icon: <DoneIcon />,
    },
    {
      id: 10,
      label: "Lot à corriger",
      href: null,
      component: "span",
      roles: ["ROLE_ADMIN"],
      isVisible: () =>
        batch.totalLabelDocs === batch.totalEligibleDocs &&
        batch.completed &&
        !batch.verified,
      onClick: (e: any) => {
        e.stopPropagation();
        openAlert({
          type: "REOPEN",
          title: "Lot à corriger",
          text: `Voulez-vous rouvrir ce lot pour effectuer des modifications ?`,
        });
        closeMenu();
      },
      icon: <RefreshIcon />,
    },
    {
      id: 11,
      label: "Lot vérifié",
      href: null,
      component: "span",
      roles: ["ROLE_ADMIN"],
      isVisible: () =>
        batch.totalLabelDocs === batch.totalEligibleDocs &&
        batch.completed &&
        !batch.verified,
      onClick: (e: any) => {
        e.stopPropagation();
        openAlert({
          type: "VERIFIED",
          title: "Lot vérifié",
          text: `Lot ${batch.name} vérifié ?`,
        });
        closeMenu();
      },
      icon: <DoneAllIcon />,
    },
    {
      id: 12,
      label: "Supprimer le lot",
      href: null,
      component: "span",
      roles: ["ROLE_ADMIN"],
      isVisible: () => true,
      onClick: (e: any) => {
        e.stopPropagation();
        openAlert({
          type: "DELETE",
          title: "Supprimer un lot",
          text: `Voulez-vous vraiment supprimer le lot ${batch.name} ?`,
        });
        closeMenu();
      },
      icon: <DeleteIcon />,
    },
  ];

  const getMenuItems = (role: string) =>
    options.filter(
      (option: any) => option.roles.includes(role) && option.isVisible()
    );

  return (
    <Menu
      id="lock-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      disableAutoFocusItem
      onClose={closeMenu}
      TransitionComponent={MenuTransition}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      {getMenuItems(userRole).map((option: any, index: any) => (
        <MenuItem
          disableGutters
          dense
          component={option.component}
          key={option.id}
          onClick={option.onClick}
          href={option.href}
        >
          <ListItemIcon style={{ marginRight: 4 }}>{option.icon}</ListItemIcon>
          <ListItemText style={{ paddingRight: 4 }}>
            {option.label}
          </ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default withRouter(withContext(BatchActionsMenu));
