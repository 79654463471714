import * as React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { fabric } from "fabric";
import { withRouter, Prompt } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { isEqual } from "lodash";
import createStyles from "@material-ui/core/styles/createStyles";
import { Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Labels from "../common/Labels";
import LabelsFromList from "../common/LabelsFromList";
import Progression from "../common/Progression";
import CanvasButtons from "../common/CanvasButtons";
import AlertDialog from "../common/AlertDialog";
import SkipDialog from "../common/SkipDialog";
import ImageCache from "../common/ImageCache";
import DrawerPhotoList from "../common/DrawerPhotoList.jsx";
import DrawerButton from "../common/DrawerButton";
import LabelisationAbstract from "../common/LabelisationAbstract";
import fitInBox, { size } from "../../tools/fitInBox";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      paddingTop: 50,
    },
    nbrImagesG: {
      padding: `${theme.spacing.unit}px 0`,
    },
    checkImage: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "auto",
    },
    hidden: {
      visibility: "hidden",
    },
    motifButton: {
      textAlign: "center",
      marginTop: theme.spacing.unit,
    },
    canvasContainer: {
      backgroundColor: "#edf2ff",
      margin: "0 auto",
      width: "100%",
      height: "100%",
    },
    centered: {
      textAlign: "center",
    },
    fullWidth: {
      width: "100%",
    },
    clearSkip: {
      padding: 5,
      border: "none",
      backgroundColor: "transparent",
    },
  });

const i18nMapping: any = {
  UNREADABLE: "Image floue",
  CROPPED: "Image tronquée",
  OCCLUSION: "Occlusion",
};

interface IState {
  currentImageIndex: string;
  infoDialogMessage: string;
  submitting: boolean;
  justSaved: boolean;
  dirty: boolean;
  skip: boolean;
  skipDialogOpen: boolean;
  listImagesDrawerOpen: boolean;
  label: string;
  imgHeight: number | null;
  imgWidth: number | null;
  zoomFactor: number;
}

const SAVED_FEEDBACK_DURATION = 1500;

class Labeler extends React.Component<any, IState> {
  private canvasRef = React.createRef<HTMLCanvasElement>();
  private canvasWrapperRef = React.createRef<HTMLDivElement>();
  private image: any;
  private canvas: any;
  private scaling: any;

  constructor(props: any) {
    super(props);
    const {
      match: {
        params: { noImage },
      },
      images,
    } = props;

    const label = images[noImage]
      ? images[noImage].labels.simpleLabels[0]
      : null;

    this.state = {
      currentImageIndex: noImage,
      submitting: false,
      justSaved: false,
      dirty: false,
      skip: false,
      skipDialogOpen: false,
      listImagesDrawerOpen: false,
      label,
      imgHeight: null,
      imgWidth: null,
      zoomFactor: 1,
      infoDialogMessage: "",
    };

    this.scaling = {
      y: 1,
      x: 1,
      newWidth: props.width,
      newHeight: props.height,
    };
  }

  componentDidMount() {
    if (this.canvasRef.current !== null) {
      this.canvas = new fabric.Canvas(this.canvasRef.current);
      // this.canvas.uniScaleTransform = true;
      this.canvas.selection = false;
      this.canvas.defaultCursor = "pointer";

      this.canvas.on("mouse:wheel", this.handleMouseWheel);

      // le container fournit la taille de la 1ère image
      const { width, height } = this.props;
      this.configureFabricJSCanvas({ width, height });

      if (this.props.match.params.noImage !== `${this.props.images.length}`) {
        const cfgSizeCanvas = false; // déjà fait
        this.loadImage(cfgSizeCanvas);
      }
    }
  }

  handleKey = (e: any) => {
    const { zoomFactor } = this.state;

    if (e.key === "ArrowDown" && e.shiftKey && zoomFactor > 1) {
      this.setState({
        zoomFactor: zoomFactor - 1,
      });
    }

    if (e.key === "ArrowUp" && e.shiftKey && zoomFactor < 7) {
      this.setState({
        zoomFactor: zoomFactor + 1,
      });
    }

    if (e.key === "ArrowLeft") {
      if (this.props.match.params.noImage !== "0") {
        this.handlePreviousImage();
      }
    }

    if (e.key === "ArrowRight" && this.isNavigationFwdPossible()) {
      this.handleNextImage();
    }

    if (e.key === "Escape") {
      this.clearZoom();
    }

    // sélection du motif par défaut on Enter
    // désactivée quand on choisit le tag depuis une liste
    if (e.key === "Enter" && !this.props.tagsFromList) {
      this.changeMotif("UNREADABLE");
    }
  };

  handleMouseWheel = (o: any) => {
    const delta = o.e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom = zoom + delta / 200;

    if (zoom <= 1) {
      zoom = 1;
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    } else {
      if (zoom > 20) zoom = 20;
      this.canvas.zoomToPoint({ x: o.e.offsetX, y: o.e.offsetY }, zoom);
    }

    o.e.preventDefault();
    o.e.stopPropagation();
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    const {
      images,
      match: {
        params: { noImage },
      },
    } = this.props;

    if (Number(prevProps.match.params.noImage) !== Number(noImage)) {
      const label = images[noImage]
        ? images[noImage].labels.simpleLabels[0]
        : null;
      this.setState({ label, dirty: false });
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      this.canvas.zoomToPoint({ x: 0, y: 0 }, 1);
      this.loadImage();
    }

    // if (
    //   (prevState.motifSkip === null && this.state.motifSkip !== "") ||
    //   (this.state.motifSkip !== null &&
    //     this.state.motifSkip !== prevState.motifSkip)
    // ) {
    //   if (this.state.dirty) {
    //     this.handleSave();
    //   }
    // }

    if (!prevState.justSaved && this.state.justSaved) {
      setTimeout(
        () => this.setState({ justSaved: false }),
        SAVED_FEEDBACK_DURATION
      );
    }
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (!isEqual(this.props.keyEvent, nextProps.keyEvent)) {
      if (nextProps.keyEvent.type === "keydown") {
        this.handleKey(nextProps.keyEvent);
      }
    }

    const thisPropsImg = this.props.images[
      Number(this.props.match.params.noImage)
    ];
    const nextPropsImg =
      nextProps.images[Number(nextProps.match.params.noImage)];
    if (
      thisPropsImg &&
      nextPropsImg &&
      thisPropsImg.id === nextPropsImg.id &&
      ((thisPropsImg.motif === null && nextPropsImg.motif !== null) ||
        // modification d'un motif existant
        (thisPropsImg.motif !== null &&
          nextPropsImg.motif !== null &&
          thisPropsImg.motif !== nextPropsImg.motif))
    ) {
      console.log("motif changed");
      setTimeout(() => {
        this.handleSave();
      }, 250);
    }
  };

  configureFabricJSCanvas = (tailleImage: size) => {
    const { width, height } = tailleImage;

    setTimeout(() => {
      //@ts-ignore
      const { offsetHeight, offsetWidth } = this.canvasWrapperRef.current;
      const taille: size = fitInBox(
        width,
        height,
        offsetWidth,
        offsetHeight,
        true
      );

      const { height: newHeight, width: newWidth } = taille;
      this.canvas.setWidth(newWidth);
      this.canvas.setHeight(newHeight);

      this.scaling = {
        x: newWidth / width,
        y: newHeight / height,
        newHeight,
        newWidth,
      };
    }, 10);
  };

  clearZoom = () => {
    this.canvas.setZoom(1);
    this.canvas.absolutePan({
      x: 0,
      y: 0,
    });
  };

  changeMotif = (motifSkip: string | null) => {
    const { storeMotif } = this.props;

    this.setState({ dirty: true, skipDialogOpen: false });

    storeMotif(motifSkip);
  };

  clearSkip = () => {
    const {
      match: {
        params: { noImage },
      },
      storeMotif,
      images,
    } = this.props;

    if (images[Number(noImage)]) {
      storeMotif(null, images[Number(noImage)].id);
    }

    this.setState({
      dirty: true,
      skipDialogOpen: false,
    });
  };

  handleOpenSkipDialog = (e: any) => {
    this.setState({ skipDialogOpen: true });
  };

  handleCloseSkipDialog = (e: any) => {
    this.setState({ skipDialogOpen: false });
  };

  handleSubmit = () => {
    const { dirty } = this.state;

    const {
      images,
      onSave,
      match: {
        params: { noImage: currentImageIndex },
      },
    } = this.props;

    let { label } = this.state;
    const image = images[currentImageIndex];

    this.setState({
      submitting: true,
      skipDialogOpen: false,
    });

    return new Promise((resolve: any, reject: any) => {
      if (
        !label &&
        image.motif === null &&
        image.labels.simpleLabels.length === 0
      )
        return reject();

      // s'il n'y a pas eu de modification d'une image
      // labélisée, on ne sauvegarde pas
      if (!dirty && (image.motif || image.label)) return resolve();

      const { motif } = image; //motifSkip !== "" ? motifSkip : null;

      onSave({
        ...image,
        labels: {
          ...image.labels,
          simpleLabels: label && !motif ? [label] : [],
        },
        motif,
        lastLabeledBy: null,
      })
        .then((image: any) => {
          this.setState({ justSaved: true });
          return resolve(image);
        })
        .then(() => {
          return resolve();
        });
    });
  };

  handleSave = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
      history,
    } = this.props;

    this.handleSubmit()
      .then((image: any) => {
        this.setState({
          dirty: false,
          skip: false,
          justSaved: true,
          skipDialogOpen: false,
        });
        setTimeout(
          () =>
            this.setState({
              ...this.state,
              justSaved: false,
            }),
          300
        );
        history.push(`/labelerClass/${noBatch}/${Number(noImage) + 1}`);
      })
      .catch((e: any) => {
        console.log(e);
        throw new Error(e);
      });
  };

  handleToggleListeImagesDrawer = () =>
    this.setState({
      listImagesDrawerOpen: !this.state.listImagesDrawerOpen,
    });

  isNavigationFwdPossible = () => {
    const {
      match: {
        params: { noImage },
      },
      images,
    } = this.props;
    const image = images[Number(noImage)];
    const nextImage = images[Number(noImage) + 1];

    return (
      (image &&
        ((image.labels && image.labels.simpleLabels.length > 0) ||
          image.motif) &&
        !this.state.dirty) ||
      (nextImage &&
        ((nextImage.labels && nextImage.labels.simpleLabels.length > 0) ||
          nextImage.motif))
    );
  };

  closeListImagesDrawer = () => this.setState({ listImagesDrawerOpen: false });

  navigateToLastLabelized = (noImage: Number) => {
    const {
      match: {
        params: { noBatch },
      },
      history,
      images,
    } = this.props;
    const premiereImgNonLabellisee = images.findIndex(
      (img: any) => img.labels.simpleLabels.length === 0 && img.motif === null
    );
    history.push(`/labelerClass/${noBatch}/${premiereImgNonLabellisee}`);
  };

  handlePreviousImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(`/labelerClass/${noBatch}/${Number(noImage) - 1}`);
  };

  handleNextImage = () => {
    const {
      match: {
        params: { noImage, noBatch },
      },
    } = this.props;
    this.props.history.push(`/labelerClass/${noBatch}/${Number(noImage) + 1}`);
  };

  goToBatchesList = () =>
    this.props.history.push("/batches/0?sort=createdDate,desc");

  setLabel = (index: any) => {
    this.changeMotif(null);
    this.setState({
      label: this.props.labels[index].legend,
      dirty: index !== this.state.label,
    });
    setTimeout(() => this.handleSave(), 50);
  };

  loadImage = (cfgCanvas = true) => {
    const {
      images,
      match: {
        params: { noImage: currentImageIndex },
      },
    } = this.props;

    let url = "/404.png";

    const image = images[currentImageIndex];

    if (image) url = image.url;

    fabric.util.loadImage(url, (img: any) => {
      if (img === null) {
        fabric.util.loadImage("/404.png", (img: any) => {
          this.addImageToCanvas(img);
        });
        return;
      }
      const { height, width } = img;

      // on modifie la taille tu canvas uniquement quand c'est utile
      if (
        cfgCanvas &&
        (this.image.width !== width || this.image.height !== height)
      ) {
        this.configureFabricJSCanvas({ height, width });
      }
      if (image) {
        this.addImageToCanvas(img, image.lastLabeledBy);
      }
    });
  };

  addImageToCanvas = (img: any, lastLabeledBy: String = "model") => {
    if (this.image) {
      this.canvas.remove(this.image);
    }
    const { width, height } = img;
    const { newWidth, newHeight } = this.scaling;

    this.scaling = {
      x: newWidth / width,
      y: newHeight / height,
      newHeight,
      newWidth,
    };

    this.image = new fabric.Image(img, {});

    this.image.set({
      left: 0,
      top: 0,
      selectable: false,
      lockMovementX: true,
      centeredScaling: true,
      lockMovementY: true,
      hoverCursor: "arrow",
      scaleX: newWidth / width,
      scaleY: newHeight / height,
      originX: "left",
      originY: "top",
      opacity: 1,
    });

    this.canvas.add(this.image);
    this.canvas.renderAll();

    this.setState({
      dirty: lastLabeledBy === "model",
    });
  };

  public render() {
    const {
      classes,
      match: {
        params: { noImage, noBatch },
      },
      images,
      motifs,
      nomBatch,
      labels,
      saveLabel,
      updateLabels,
      onSave,
      tagsFromList,
    } = this.props;

    const {
      skipDialogOpen,
      dirty,
      listImagesDrawerOpen,
      infoDialogMessage,
      label,
      justSaved,
    } = this.state;

    const imagesWithLabelOrMotif = images.filter(
      (img: any) => img.labels.simpleLabels.length > 0 || img.motif !== null
    );
    const nbrLabelisees = imagesWithLabelOrMotif.length;
    const image = images[Number(noImage)];
    const out = Number(noImage) >= images.length;
    const finished = nbrLabelisees === images.length;

    let textAlert = infoDialogMessage;
    let titreAlert = "Le saviez-vous ?";
    if (out) {
      if (!finished) {
        textAlert = "Une image n'a pas été labellisée";
        titreAlert = "Labellisation incomplète";
      } else {
        textAlert = "Labellisation de ce lot terminée !";
        titreAlert = "C'est fini !";
      }
    }

    let alertOnClickHandler;
    if (out)
      alertOnClickHandler = () =>
        this.navigateToLastLabelized(imagesWithLabelOrMotif.length);
    if (finished) alertOnClickHandler = () => this.goToBatchesList();

    const labelSelectedIndex = labels.findIndex((l: any) => l.legend === label);

    return (
      <Grid
        container
        spacing={16}
        direction="row"
        justify="flex-start"
        className={classes.root}
      >
        <Helmet>
          <title>
            Classif / {nomBatch.split("-")[0]} / {noImage}
          </title>
        </Helmet>
        {Number(noImage) >= images.length && (
          <ImageCache images={images} noImageToCache={Number(noImage) + 1} />
        )}
        {listImagesDrawerOpen && (
          <DrawerPhotoList
            open={listImagesDrawerOpen}
            images={imagesWithLabelOrMotif}
            noBatch={noBatch}
            noImage={noImage}
            labelingTypePathname="labelerClass"
          />
        )}
        {imagesWithLabelOrMotif.length > 1 && (
          <DrawerButton
            onClick={this.handleToggleListeImagesDrawer}
            drawerOpen={listImagesDrawerOpen}
          />
        )}
        <Prompt
          when={dirty}
          message="Des modifications n'ont pas été sauvegardées. Continuer ?"
        />
        <SkipDialog
          motifs={motifs}
          motifSelected={image && image.motif}
          open={skipDialogOpen}
          onAccept={this.changeMotif}
          onClose={this.handleCloseSkipDialog}
          i18nMapping={i18nMapping}
          defaultMotifIndex={0}
        />
        <AlertDialog
          open={out}
          onClose={alertOnClickHandler}
          text={textAlert}
          title={titreAlert}
          onAccept={alertOnClickHandler}
          acceptLabel={!finished && !out ? "Bien noté !" : "OK"}
        />

        <Grid item sm={8} style={{ flexGrow: 1 }}>
          <Grid
            container
            direction="column"
            spacing={8}
            component={Paper}
            justify="center"
            style={{ height: "100%", padding: "4px", paddingBottom: "2em" }}
          >
            <Grid item>
              <LabelisationAbstract
                noImage={noImage}
                nomBatch={nomBatch}
                images={images}
                dirty={dirty}
                justSaved={justSaved}
              />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <div
                className={classes.canvasContainer}
                ref={this.canvasWrapperRef}
              >
                <canvas ref={this.canvasRef} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4} style={{ flexGrow: 1 }}>
          <Grid
            container
            component={Paper}
            direction="column"
            justify="space-between"
            alignItems="stretch"
            style={{ height: "100%" }}
          >
            <Grid item className={classes.fullWidth}>
              <Grid container direction="column">
                {image && (
                  <Grid item>
                    <Progression
                      total={images.length}
                      nbrLabelisees={nbrLabelisees}
                      showHelp={false}
                    />
                  </Grid>
                )}
                <Grid item>
                  {!tagsFromList && (
                    <Labels
                      labels={labels}
                      onClick={this.setLabel}
                      selected={labels.findIndex(
                        (l: any) => l.legend === label
                      )}
                      hideVisibilityIcon
                    />
                  )}
                  {tagsFromList && (
                    <LabelsFromList
                      labels={labels}
                      images={images}
                      noImage={noImage}
                      saveLabel={saveLabel}
                      saveImage={onSave}
                      updateLabels={updateLabels}
                      onSelect={this.setLabel}
                      selected={
                        labelSelectedIndex !== -1 ? labelSelectedIndex : null
                      }
                      frequentLabels={images
                        .filter((i: any) => i.labels.simpleLabels.length > 0)
                        .reduce(
                          (memo: any, i: any) =>
                            memo.concat(i.labels.simpleLabels),
                          []
                        )
                        .reduce(
                          (memo: any, i: any) => ({
                            ...memo,
                            [i]: memo[i] ? memo[i] + 1 : 1,
                          }),
                          {}
                        )}
                      frequentLabelsNumber={8}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {image && image.motif && (
              <Grid
                item
                className={classNames(classes.centered, classes.fullWidth)}
              >
                <span>
                  Image passée : <strong>{i18nMapping[image.motif]}</strong>
                </span>
                <button className={classes.clearSkip} onClick={this.clearSkip}>
                  x
                </button>
              </Grid>
            )}
            <Grid item className={classes.fullWidth}>
              <Grid container direction="column">
                <Grid item style={{ paddingLeft: "1em" }}>
                  <Typography>Actions :</Typography>
                </Grid>
                {image && !(finished && out) && (
                  <Grid item style={{ flexGrow: 1 }}>
                    <CanvasButtons
                      onNavigateBackClicked={this.handlePreviousImage}
                      onNavigateFwdClicked={this.handleNextImage}
                      navigateBackVisible={Number(noImage) > 0}
                      navigateFwdVisible={this.isNavigationFwdPossible()}
                      onSkipClicked={this.handleOpenSkipDialog}
                      navigationOnly
                      motif={image.motif}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(Labeler));
