import * as React from "react";
import Particles from "react-particles-js";

const CustomParticles = () => (
  <Particles
    style={{
      backgroundColor: "transparent",
      position: "absolute",
      left: 0,
      top: 0
    }}
    params={{
      particles: {
        number: {
          value: 50
        },
        size: {
          value: 3
        }
      },
      interactivity: {
        events: {
          onhover: {
            enable: true,
            mode: "repulse"
          }
        }
      }
    }}
  />
);

export default CustomParticles;
