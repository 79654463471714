import * as React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "0 0 10px"
    },
    title: {
      padding: `${theme.spacing.unit * 3}px`,
      //@ts-ignore
      color: theme.palette.titleColor,
    }
  });

interface UiDialogTitlePropTypes {
  classes: any;
  title: string;
  onCloseClick: any;
}

const UiDialogTitle = (props: UiDialogTitlePropTypes) => (
  <DialogTitle id="alert-dialog-title" className={props.classes.root}>
    <Grid container direction="row" justify="space-between">
      <Grid item className={props.classes.title}>{props.title}</Grid>
      <Grid item>
        <IconButton onClick={props.onCloseClick}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  </DialogTitle>
);

export default withStyles(styles)(UiDialogTitle);
