import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      visibility: "hidden",
      width: 0,
      height: 0,
      overflow: "hidden"
    }
  });

interface PropTypes {
  images: any[];
  noImageToCache: number;
  classes: any;
}

const ImageCache = (props: PropTypes) => {
  const { images, noImageToCache, classes } = props;
  if (!images[noImageToCache]) return null;
  return (
    <div className={classes.root}>
      <img src={images[noImageToCache].url} alt="img to process" />
    </div>
  );
};

export default withStyles(styles)(ImageCache);
