import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import UiDialogTitle from "../common/UiDialogTitle";
import UiButton from "../common/UiButton";
import UiDialogActions from "../common/UiDialogActions";

interface PropsTypes {
  open: boolean;
  onClose: any;
  onAccept: any;
  title: string;
  text: any;
  acceptLabel: string;
}

const AlertDialog = (props: PropsTypes) => {
  const {
    open,
    onClose,
    onAccept,
    title,
    text,
    acceptLabel
  } = props;

  if (text === "") return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ elevation: 2}}
    >
      <UiDialogTitle title={title} onCloseClick={onClose} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <UiDialogActions>
        <UiButton
          onClick={onAccept}
          color="secondary"
          variant="contained"
          autoFocus
        >
          {acceptLabel}
        </UiButton>
      </UiDialogActions>
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  acceptLabel: "Valider"
};

export default AlertDialog;
