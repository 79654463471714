import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      position: "absolute",
      width: "30px",
      borderRadius: "60px 60px 0 0 ",
      //@ts-ignore
      backgroundColor: theme.custom.bottomDrawerBackground,
      zIndex: 10000,
      left: `calc(50vw - 15px)`,
      bottom: 0,
      borderTop: `solid 2px ${theme.palette.primary.main}`,
      borderRight: `solid 2px ${theme.palette.primary.main}`,
      borderLeft: `solid 2px ${theme.palette.primary.main}`
    },
    buttonHigh: {
      position: "absolute",
      width: "30px",
      left: `calc(50vw - 15px)`,
      borderRadius: "60px 60px 0 0 ",
      bottom: 158,
      zIndex: 10000,
      //@ts-ignore
      backgroundColor: theme.custom.bottomDrawerBackground,
      borderTop: `solid 2px ${theme.palette.primary.main}`,
      borderRight: `solid 2px ${theme.palette.primary.main}`,
      borderLeft: `solid 2px ${theme.palette.primary.main}`,
      "&:hover": {
        //@ts-ignore
        backgroundColor: darken(theme.custom.bottomDrawerBackground, 0.15)
      }
    }
  });

interface PropTypes {
  drawerOpen: boolean;
  classes: any;
  onClick: any;
}

const DrawerButton = (props: PropTypes) => {
  const { drawerOpen, classes, ...rest } = props;
  return (
    <Button
      className={drawerOpen ? classes.buttonHigh : classes.button}
      {...rest}
    >
      {props.drawerOpen ? (
        <KeyboardArrowDownIcon color="primary" />
      ) : (
        <KeyboardArrowUpIcon color="primary" />
      )}
    </Button>
  );
};

export default withStyles(styles)(DrawerButton);
