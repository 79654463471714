import * as React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import GlobalContext from "../GlobalContext";

const LoginButton = (props: any) => {
  const handleLogin = () => {
    props.history.push("/login");
  };

  const { onLogout, staticContext, ...rest } = props;
  return (
    <GlobalContext.Consumer>
      {(ctx: any) => {
        return (
          <Button
            color="inherit"
            {...rest}
            onClick={handleLogin}
          >
            Login
          </Button>
        );
      }}
    </GlobalContext.Consumer>
  );
};

export default withRouter(LoginButton);
