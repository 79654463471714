
const desc = (a: any, b: any, orderBy: any) => {
  const aVal = typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
  const bVal = typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
};

export const stableSort = (array: any, cmp: any) => {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
};

export const getSorting = (order: any, orderBy: any) => {
  return order === "desc"
    ? (a: any, b: any) => desc(a, b, orderBy)
    : (a: any, b: any) => -desc(a, b, orderBy);
};
