import React, { useState, useEffect } from "react";
import Batch from "./component";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import withContext from "../../tools/withContext";
import ContextType from "../../types/Context";
import UserType from "../../types/User";

interface BatchProps {
  ctx: ContextType;
  match: any,
}

const BatchContainer = (props: BatchProps) => {
  const {
    ctx: { appFetch },
    match: { params: { projectId, type } }
  } = props;
  const {
    PROJECTS_PATH,
    COUNT_ES,
    IMPORT_ES,
    USERS_PATH,
    GENERATE_BATCHES,
    LIST_STAGGING_PATH,
  } = constants.paths;
  const [project, setProject] = useState<any>({});
  const [users, setUsers] = useState<any>([]);
  const [folders, setFolders] = useState<string[]>([]);
  const [numberImages, setNumberImages] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");

  const fetchDatas = async () => {
    setIsLoading(true);
    try {
      setIsError(false);

      // on récupère les batches
      const datas1 = await appFetch(`${PROJECTS_PATH}/${projectId}`);
      setProject(datas1);
      const datas2 = await appFetch(`${LIST_STAGGING_PATH}?projectId=${projectId}`);
      setFolders(datas2);
      setIsLoading(false);
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const loadUsers = async () => {
    setIsLoading(true);
    try {
      setIsError(false);

      // on récupère les projects
      const datas = await appFetch(
        `${USERS_PATH}?size=1000&sort=filterFields.lowerLastName,asc`
      );
      setUsers(datas.content.filter((u: UserType) => u.projects.includes(projectId)));
      setIsLoading(false);
    } catch (e) {
      //, activated: true
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const createBatch = async (params: any) => {
    setIsLoading(true);
    try {
      setIsError(false);
      await appFetch(GENERATE_BATCHES, {
        method: "POST",
        body: JSON.stringify(params),
        headers: { "Content-Type": "application/json" }
      });

      setIsLoading(false);
      setMessage(`Génération des lots lancée !`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const importEs = async (params: any) => {
    setIsLoading(true);
    const { dateFrom, dateTo, destFolder, projectId } = params;
    try {
      setIsError(false);
      await appFetch(`${IMPORT_ES}?projectId=${projectId}&dateFrom=${dateFrom}&dateTo=${dateTo}&destFolder=${destFolder}`, {
        method: "Get",
        headers: { "Content-Type": "application/json" }
      });

      setIsLoading(false);
      setMessage(`Importation ES lancée !`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  }

  const countEs = async (params: any) => {
    setIsLoading(true);
    const { dateFrom, dateTo, projectId } = params;
    try {
      setIsError(false);
      const nbImages = await appFetch(`${COUNT_ES}?projectId=${projectId}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {
        method: "Get",
        headers: { "Content-Type": "application/json" }
      });

      setIsLoading(false);
      setNumberImages(Number(nbImages));
      setMessage(`Importation ES lancée !`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  }  

  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchDatas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (folders.length > 0) {
      loadUsers();
    }
  }, [folders]);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      <Batch
        createBatch={createBatch}
        project={project}
        type={type}
        folders={folders}
        users={users}
        importEs={importEs}
        countEs={countEs}
        numberImages={numberImages}
        isLoading={isLoading}
        appFetch={appFetch}
      />
    </React.Fragment>
  );
};

export default withContext(BatchContainer);
