import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { RouteComponentProps } from "react-router";
import AlertDialog from "../../common/AlertDialog";
import MenuTransition from "../../common/MenuTransition";
import UiButton from "../../common/UiButton";
import UiArrowIcon from "../../common/UiArrowIcon";
import UserType from "../../../types/User";
import ProjectType from "../../../types/Project";

const options = ["Modifier", "Réinitialiser le token 2FA", "Supprimer"];

interface PropsType extends RouteComponentProps<any>, React.Props<any> {
  saveUser: Function;
  deleteUser: Function;
  resetToken2FA: Function;
  openDialog: Function;
  closeDialog: Function;
  user: UserType;
  projects: ProjectType[];
}

const UserActionButton = (props: PropsType) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertReset2FATokenOpen, setAlertReset2FATokenOpen] = useState(false);

  const { user, deleteUser, resetToken2FA, openDialog } = props;

  const onMenuClick = (event: any, index: any) => {
    if (index === 0) {
      openDialog();
    } else if (index === 1) {
      openAlertReset2FA();
    } else if (index === 2) {
      openAlert();
    }
    closeMenu();
  };

  const actionButtonClicked = (e: any) => setAnchorEl(e.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const openAlert = () => setAlertOpen(true);

  const closeAlert = () => setAlertOpen(false);

  const openAlertReset2FA = () => setAlertReset2FATokenOpen(true);

  const closeAlertReset2FA = () => setAlertReset2FATokenOpen(false);

  const handleDelete = () => {
    deleteUser(user).then(() => closeAlert());
  };

  const handleReset2FA = () => {
    resetToken2FA(user).then(() => closeAlertReset2FA());
  };

  // const openDialog = () => setIsDialogOpen(true);

  // const closeDialog = () => setIsDialogOpen(false);

  // const handleSave = (user: UserType) => {
  //  saveUser(user).then(() => closeDialog());
  // };

  return (
    <React.Fragment>
      {/* {isDialogOpen && <UserFormDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onSave={handleSave}
        projects={projects}
        user={user}
      />} */}
      {alertOpen && (
        <AlertDialog
          title="Supprimer un utilisateur"
          text={`Voulez-vous vraiment supprimer l'utilisateur ${user.firstName} ${user.lastName}?`}
          open={alertOpen}
          onClose={closeAlert}
          onAccept={handleDelete}
        />
      )}
      {alertReset2FATokenOpen && (
        <AlertDialog
          title="Réinitialiser le token 2FA"
          text={`Voulez-vous vraiment réinitialiser le token Google Authenticator pour l'utilisateur ${user.firstName} ${user.lastName}?`}
          open={alertReset2FATokenOpen}
          onClose={closeAlertReset2FA}
          onAccept={handleReset2FA}
        />
      )}
      <UiButton
        variant="outlined"
        color="secondary"
        onClick={(e: any) => actionButtonClicked(e)}
      >
        Actions
        <UiArrowIcon />
      </UiButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        disableAutoFocusItem
        onClose={closeMenu}
        TransitionComponent={MenuTransition}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {options.map((option, index) => (
          <MenuItem key={option} onClick={(e: any) => onMenuClick(e, index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default withRouter(UserActionButton);
