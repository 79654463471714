import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%",
    },
    titre: {
      minWidth: "550px",
    },
  });

interface PropTypes {
  open: boolean;
  onClose: any;
  onAccept: any;
  classes: any;
  motifs: string[];
  i18nMapping: any;
  defaultMotifIndex: number;
  motifSelected: number;
}

const SkipDialog = (props: PropTypes) => {
  const {
    open,
    onClose,
    onAccept,
    classes,
    motifs,
    i18nMapping,
    defaultMotifIndex,
    motifSelected,
  } = props;

  const [motif, setMotif] = useState<any>(
    motifSelected || motifs[defaultMotifIndex]
  );

  const handleChange = (e: any) => setMotif(e.target.value);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onAccept(`${motif}`);
    setMotif(motifs[defaultMotifIndex]);
  };

  useEffect(() => {
    if (open) {
      setMotif(motifSelected);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">Passer cette image ?</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <FormLabel>Motif :</FormLabel>
            <RadioGroup
              aria-label="Motif"
              name="motif"
              className={classes.group}
              value={motif}
              onChange={handleChange}
              tabIndex={0}
            >
              {motifs.map((motif: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={motif}
                  control={<Radio autoFocus={index === defaultMotifIndex} />}
                  label={i18nMapping[motif]}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Annuler
          </Button>
          <Button type="submit" color="primary" variant="contained" autoFocus>
            Valider
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(SkipDialog);
