import React, { useState, useEffect } from "react";
import { darken, rgbToHex } from "@material-ui/core/styles/colorManipulator";
import Labeler from "./component";
import Spinner from "../common/Spinner";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import { useHotkeys } from "../../tools/react-hotkeys-hook";
import withContext from "../../tools/withContext";
// import generateImageUrl from "../../tools/generateImageUrl";

/** Labeler container manage datas fetching & saving for image labelization */
const LabelerContainerF = (props: any) => {
  const {
    match: {
      params: { noBatch, noImage },
    },
    ctx: { appFetch },
  } = props;
  const [motifs, setMotifs] = useState<string[]>([]);
  const [nomBatch, setNomBatch] = useState("");
  const [tagListId, setTagListId] = useState<any>(null);
  const [tags, setTags] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [dimensions, setDimensions] = useState({ height: 576, width: 720 });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [keyEvent, setKeyEvent] = useState<any>(null);

  useHotkeys("*", (e) => {
    const { key, type, shiftKey, altKey, ctrlKey } = e;
    setKeyEvent({ key, type, shiftKey, altKey, ctrlKey });
  });

  const clearKeypressedState = () => setKeyEvent(null);

  const sortLabelFn = (a1: any, a2: any) => {
    if (a1.legend.toLowerCase() > a2.legend.toLowerCase()) return 1;
    if (a1.legend.toLowerCase() < a2.legend.toLowerCase()) return -1;
    return -1;
  };

  /** Fetch image list for batch noBatch */
  const fetchImages = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const images = await appFetch(
        `/api/images/batches/${noBatch}/all?offset=0&size=1000`
      );
      setImages(images);
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
    }
  };

  /** Load batch tags*/
  const fetchBatch = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const batch = await appFetch(
        `${constants.paths.BATCHES_PATH}/${noBatch}`
      );
      const labelingTypes = batch.project.labelingTypes[0];

      let tagsWithColors: any[] = [];
      if (batch.project.tagListId) {
        setTagListId(batch.project.tagListId);
        const labelsFromList = await appFetch(
          `${constants.paths.TAG_LIST}/${batch.project.tagListId}`
        );
        let colorIdx = 0; // to loop through constants colors [0..10]
        let variation = 0.01; // to change color by one on each loop iteration
        tagsWithColors = labelsFromList.tags
          .map((t: any) => {
            const color = rgbToHex(
              darken(constants.colors[colorIdx].color, variation)
            );
            colorIdx++;
            if (colorIdx === 10) {
              colorIdx = 0;
              variation += 0.01;
            }
            return {
              color: color.split(".")[0],
              textColor: "white",
              legend: t,
              visible: true,
            };
          })
          .sort(sortLabelFn);
      } else {
        setTagListId(null);
        tagsWithColors = labelingTypes.tags.map((t: any, index: number) => ({
          ...constants.colors[index],
          legend: t,
          visible: true,
        }));
      }
      setTags(tagsWithColors);
      setMotifs(labelingTypes.reasons);
      setDimensions({ height: batch.maxHeight, width: batch.maxWidth });
      setNomBatch(batch.name);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
    }
  };

  /** Save image's labelization (labels or motif _reason to skip_) */
  const saveImage = async (image: any) => {
    const { id, labels, motif, lastLabeledBy } = image;
    try {
      setIsError(false);
      
      const newImageDatas = {
        id,
        labels: { ...labels },
        motif,
        lastLabeledBy, // null si l'on veut que le backend remplace par username
      };

      setImages(
        images.map((img: any) =>
          img.id === image.id ? { ...img, ...newImageDatas } : img
        )
      );

      await appFetch(constants.paths.IMAGES_PATH, {
        method: "PUT",
        body: JSON.stringify(newImageDatas),
      });

      setIsError(false);
    } catch (e) {
      // restore previous state just in case it was modified before the exception was thrown
      setImages(images);

      setIsError(true);
      setIsSnackbarOpen(true);
      setMessage(e.message);
    }
  };

  const saveLabel = async (label: any) => {
    try {
      setIsError(false);
      const body: any = JSON.stringify({
        id: tagListId,
        tags: [label],
      });

      await appFetch(constants.paths.TAG_LIST, {
        method: "PUT",
        body,
      });

      const labelWithColor = {
        color: rgbToHex(darken(tags[tags.length - 1].color, 0.01)).split(
          "."
        )[0],
        textColor: "white",
        legend: label,
        visible: true,
      };
      const newTagList = tags.concat([labelWithColor]).sort(sortLabelFn);
      setTags(newTagList);
      setIsError(false);
      return newTagList.findIndex((t) => t.legend === label);
    } catch (e) {
      setIsError(true);
      setIsSnackbarOpen(true);
      setMessage(e.message);
    }
  };

  const updateLabels = async (tagMap: any) => {
    try {
      setIsError(false);
      const body: any = JSON.stringify({
        id: tagListId,
        tagMap,
      });

      await appFetch(constants.paths.TAG_LIST_UPDATE, {
        method: "PUT",
        body,
      });

      const newTagList = tags
        .map((t: any) => ({ ...t, legend: tagMap[t.legend] || t.legend }))
        .sort(sortLabelFn);
      setTags(newTagList);
      setIsError(false);
    } catch (e) {
      console.log(e);
      setIsError(true);
      setIsSnackbarOpen(true);
      setMessage(e.message);
    }
  };

  const storeMotif = (motif: string | null, clearLabels: boolean = true) => {
    setImages(
      images.map((img: any, index: number) =>
        index === Number(noImage)
          ? {
              ...img,
              labels: !clearLabels
                ? img.labels
                : {
                    rectangleLabels: [],
                    polygonLabels: [],
                    simpleLabels: [],
                    country: null,
                  },
              motif,
              lastLabeledBy: null, // null si l'on veut que le backend remplace par username
            }
          : img
      )
    );
  };

  /** Close snackbar */
  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchImages();
    fetchBatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // sur les anciens lots maxWidth et maxHeight ne sont pas renseignés
    // du coup on utilise l'ancienne méthode : on prend pour dimensions du canvas
    // les dimensions de la première image
    if (images.length > 0 && dimensions.height === 0) {
      const image: any = new Image();
      image.src = images[0].url;
      image.onload = (img: any) => {
        const { height, width } = img.target;
        setDimensions({ height, width });
      };
    }
  }, [images, dimensions]);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      {isLoading && <Spinner message="Chargement des images" />}
      {!isLoading &&
        images.length > 0 &&
        tags.length > 0 &&
        dimensions.height > 0 &&
        motifs.length > 0 && (
          <Labeler
            onSave={saveImage}
            saveLabel={saveLabel}
            storeMotif={storeMotif}
            updateLabels={updateLabels}
            loading={isLoading}
            images={images}
            labels={tags}
            tagsFromList={tagListId !== null}
            motifs={motifs}
            height={dimensions.height}
            width={dimensions.width}
            nomBatch={nomBatch}
            keyEvent={keyEvent}
            clearKeyevent={clearKeypressedState}
          />
        )}
    </React.Fragment>
  );
};

export default withContext(LabelerContainerF);
