import React, { useState, useEffect } from "react";
import Labeler from "./component";
import Spinner from "../common/Spinner";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import withContext from "../../tools/withContext";
import { useHotkeys } from "../../tools/react-hotkeys-hook";
import BatchType from "../../types/Batch";
import { AnyCnameRecord } from "dns";

const PlateTextContainer = (props: any) => {
  const {
    match: {
      params: { noBatch, noImage },
    },
    location: { search },
    ctx: { appFetch, user },
  } = props;
  const [motifs, setMotifs] = useState<string[]>([]);
  const [nomBatch, setNomBatch] = useState("");
  const [projectName, setProjectName] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [dimensions, setDimensions] = useState({ height: 576, width: 720 });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [keyEvent, setKeyEvent] = useState<any>(null);

  useHotkeys("*", (e) => {
    const { key, type, shiftKey, altKey, ctrlKey } = e;
    if (key === "Enter") {
      e.stopPropagation();
    }
    setKeyEvent({ key, type, shiftKey, altKey, ctrlKey });
  });

  const clearKeypressedState = () => setKeyEvent(null);

  /** Fetch image list for batch noBatch */
  const fetchImages = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const images = await appFetch(
        `/api/images/batches/${noBatch}/all?offset=0&size=1000`
      );
      setImages(images);
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      //@ts-ignore
      setMessage(e.message);
    }
  };

  /** Load batch tags*/
  const fetchBatch = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const batch: BatchType = await appFetch(
        `${constants.paths.BATCHES_PATH}/${noBatch}`
      );
      const labelingTypes = batch.project.labelingTypes[0];
      setMotifs(labelingTypes.reasons || ["ERROR"]);
      setDimensions({ height: batch.maxHeight, width: batch.maxWidth });
      setNomBatch(batch.name);
      setProjectName(batch.project.name);
      //@ts-ignore
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      //@ts-ignore
      setMessage(e.message);
    }
  };

  /** Save image's labelization (labels or motif _reason to skip_) */
  const saveImage = async (image: any) => {
    // const { id, labels, motif, country, lastLabeledBy } = image;
    try {
      setIsError(false);

      setImages(
        images.map((img: any) =>
          img.id === image.id ? { ...img, ...image } : img
        )
      );

      await appFetch(constants.paths.IMAGES_PATH, {
        method: "PUT",
        body: JSON.stringify(image),
      });

      setIsError(false);
    } catch (e) {
      // restore previous state just in case it was modified before the exception was thrown
      setImages(images);

      setIsError(true);
      setIsSnackbarOpen(true);
      //@ts-ignore
      setMessage(e.message);
    }
  };

  const storeMotif = (motif: string | null, clearLabels: boolean = true) => {
    setImages(
      images.map((img: any, index: number) =>
        index === Number(noImage)
          ? {
              ...img,
              labels: !clearLabels
                ? img.labels
                : {
                    rectangleLabels: [],
                    polygonLabels: [],
                    simpleLabels: [],
                    country: null,
                  },
              motif,
              lastLabeledBy: null, // null si l'on veut que le backend remplace par username
            }
          : img
      )
    );
  };

  const clearImmatriculation = (imageId: string) => {
    setImages(
      images.map((img: any) =>
        img.id === imageId
          ? {
              ...img,
              labels: {
                rectangleLabels: [],
                polygonLabels: [],
                simpleLabels: [],
                country: "FR",
              },
              certaintyLevel: "high",
            }
          : img
      )
    );
  };

  /** Close snackbar */
  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchImages();
    fetchBatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // sur les anciens lots maxWidth et maxHeight ne sont pas renseignés
    // du coup on utilise l'ancienne méthode : on prend pour dimensions du canvas
    // les dimensions de la première image
    if (images.length > 0 && dimensions.height === 0) {
      const image = new Image();
      image.src = images[0].url;
      image.onload = (img: any) => {
        const { height, width } = img.target;
        setDimensions({ height, width });
      };
    }
  }, [images, dimensions]);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      {isLoading && <Spinner message="Chargement des images" />}
      {!isLoading &&
        images.length > 0 &&
        motifs.length > 0 &&
        dimensions.height > 0 && (
          <Labeler
            onSave={saveImage}
            loading={isLoading}
            images={images}
            motifs={motifs}
            height={dimensions.height}
            width={dimensions.width}
            nomBatch={nomBatch}
            pays={
              projectName !== "LPM_Morocco"
                ? { FR: "France", IT: "Italie", DE: "Allemagne", Au: "Autre" }
                : { FR: "France", MAR: "Maroc", GOV: "Gouv", AUTRE: "Autre" }
            }
            paysDefault={projectName !== "LPM_Morocco" ? "FR" : "MAR"}
            userRole={user ? user.authorities[0] : null}
            storeMotif={storeMotif}
            search={search}
            keyEvent={keyEvent}
            clearKeyevent={clearKeypressedState}
          />
        )}
    </React.Fragment>
  );
};

export default withContext(PlateTextContainer);
