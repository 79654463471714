import React from "react";
import classNames from "classnames";
import { withStyles, withTheme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
// import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";

const styles = (theme: Theme) =>
  createStyles({
    accurate: {
      border: "solid 1px silver"
    },
    notAccurate: {
      border: "solid 1px orange"
    },
    plateInputArea: {
      textAlign: "center"
      // padding: theme.spacing.unit
    },
    withMinHeight: {
      minHeight: "114px"
    },
    bordered: {
      padding: theme.spacing.unit * 2,
      border: "solid 1px #3562fd66"
    },
    plateInputAreaLabel: {
      textAlign: "center",
      marginTop: 3,
      paddingBottom: 3,
      borderBottom: "none",
      fontSize: "1.5em"
    },
    plateInputAreaInput: {
      borderTop: "none"
    },
    input: {
      width: 45,
      height: 45,
      marginRight: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "1.3em"
    },
    biggerInput: {
      height: 55,
      borderColor: "#a59797",
      boxShadow: "3px 3px 1px rgba(0,0,0, 0.04)",
      fontSize: "1.3em"
    }
  });

const LetterBoxes = (props: any) => {
  const {
    letterBoxes,
    onChange,
    onMouseOver,
    onFocus,
    nbrBox,
    immatriculation,
    predictionCyclope,
    selectedIndex,
    classes
  } = props;
  // const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const direction = "row";
  const justify = "center";

  const handleChange = (e: any, index: number) => {
    onChange(e.target.value.toUpperCase(), index);
    const input = document.getElementById(`input${index + 1}`);
    if (input) {
      input.focus();
    }
  };

  const boxesPred = letterBoxes
    .map((b: any, index: number) => b.get("labelValue"))
    .join();

  return (
    <Grid
      item
      className={classNames(
        {},
        {
          [classes.withMinHeight]: true
        }
      )}
      // style={{ flexGrow: 1}}
    >
      <Grid
        container
        direction={direction}
        justify={justify}
        alignItems={"center"}
        style={{ height: "100%" }}
      >
        <Grid
          item
          className={classNames(classes.plateInputArea, {
            [classes.plateInputAreaInput]: true
          })}
          sm={12}
        >
          {letterBoxes.length > 0 && (
            <label
              style={{
                display: "block",
                fontSize: "1.1em",
                marginBottom: "4px"
              }}
            >
              Labellisation{" "}
              {predictionCyclope !== boxesPred ? (
                <em> corrigée </em>
              ) : null}
              :{" "}
            </label>
          )}
          {letterBoxes.map((b: any, index: number) => (
            <input
              key={index}
              id={`input${index}`}
              value={b.get("labelValue")}
              disabled={nbrBox === 0 && !immatriculation}
              name={`input_${index}`}
              maxLength={1}
              minLength={1}
              className={classNames(classes.input, {
                [classes.accurate]: b.get("accuracy") > 0.7,
                [classes.notAccurate]: b.get("accuracy") <= 0.7,
                [classes.biggerInput]:
                  selectedIndex !== null && b.get("index") === selectedIndex
              })}
              onMouseOver={e => onMouseOver(e, index)}
              onFocus={e => onFocus(e, index)}
              onChange={e => handleChange(e, index)}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withTheme()(LetterBoxes));
