import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import IconButton from "@material-ui/core/IconButton";
import NextIcon from "@material-ui/icons/NavigateNext";
import PrevIcon from "@material-ui/icons/NavigateBefore";

const styles = (theme: Theme) =>
  createStyles({
    numberItems: {
      paddingLeft: theme.spacing.unit * 2
    }
  });

const TableNavigation = (props: any) => {
  const {
    pagination: { number, totalPages, first, last, totalElements },
    itemName,
    numberSelected,
    onChangePage,
    classes
  } = props;
  const inputRef = useRef(null);

  const [pageDirectNo, setPageDirectNo] = useState(number + 1);

  const goToPrevious = () => onChangePage(number - 1);

  const goToNext = () => onChangePage(number + 1);

  const handleChange = (e: any) =>
    setPageDirectNo(
      Number(e.target.value) < totalPages + 1
        ? Number(e.target.value)
        : Number(totalPages)
    );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onChangePage(pageDirectNo - 1);
  };

  useEffect(() => setPageDirectNo(number + 1), [number]);

  useEffect(() => {
    if (inputRef !== null) {
      //@ts-ignore
      inputRef.current.addEventListener("focus", () => {
        //@ts-ignore
        inputRef.current.select();
      });
    }
  }, [inputRef]);
  return (
    <Grid item style={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item className={classes.numberItems}>
          {totalElements} {itemName}
          {numberSelected > 0 &&
            `, ${numberSelected} sélectionné${numberSelected > 1 ? "s" : ""}`}
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={8}
          >
            <Grid item>
              Page {number + 1} sur {totalPages}
            </Grid>
            <Grid item>
              <IconButton disabled={first} onClick={goToPrevious} title="Page précédente">
                <PrevIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <form onSubmit={handleSubmit}>
                <input
                  onChange={handleChange}
                  value={pageDirectNo}
                  style={{ maxWidth: 35 }}
                  ref={inputRef}
                />
                <input type="submit" style={{ display: "none" }} />
              </form>
            </Grid>
            <Grid item>
              <IconButton disabled={last} onClick={goToNext} title="Page suivante">
                <NextIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

TableNavigation.defaultProps = {
  numberSelected: 0
};

export default withStyles(styles)(TableNavigation);
