import React from "react";
import { withRouter } from "react-router-dom";
import { fabric } from "fabric";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import fitInBox, { size } from "../../../tools/fitInBox";

const styles = (theme: Theme) =>
  createStyles({
    canvasContainer: {
      backgroundColor: "#edf2ff",
      margin: "0 auto",
      width: "100%",
      height: "100%",
    },
  });

class ClassifLabellisation extends React.Component<any, any> {
  private validationStateChangeKeys: string[] = ["a", "z", "e", "q", "s", "d"];
  private canvasRef = React.createRef<HTMLCanvasElement>();
  private canvasWrapperRef = React.createRef<HTMLDivElement>();
  private image: any;
  private canvas: any;
  private scaling: any;
  private textBox: any;

  constructor(props: any) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  componentDidMount = () => {
    if (this.canvasRef.current !== null) {
      //@ts-ignore
      this.configureFabricJSCanvas(this.canvasRef.current).then(
        (canvas: any) => {
          this.canvas = canvas;
          this.bindEvents();
          this.loadImage(this.props);
        }
      );
    }
  };

  componentWillReceiveProps = (nextProps: any) => {
    const { image, keyJustPressed, index } = this.props;
    if (image.id !== nextProps.image.id) {
      this.loadImage(nextProps);
    }

    if (
      keyJustPressed === null &&
      nextProps.keyJustPressed !== null &&
      this.validationStateChangeKeys.indexOf(nextProps.keyJustPressed) === index
    ) {
      this.toggleValidation();
    }
  };

  bindEvents = () => {
    this.canvas.on("mouse:up", this.toggleValidation);
    this.canvas.on("mouse:dblclick", this.handleDblClick);
    this.canvas.on("mouse:wheel", this.handleMouseWheel);
  };

  handleMouseWheel = (o: any) => {
    const delta = o.e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom = zoom + delta / 100;

    if (zoom <= 1) {
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    } else {
      if (zoom > 20) zoom = 20;
      this.canvas.zoomToPoint({ x: o.e.offsetX, y: o.e.offsetY }, zoom);
    }

    o.e.preventDefault();
    o.e.stopPropagation();
  };

  handleDblClick = () => {
    const { history, index, noBatch } = this.props;
    history.push(`/labeler/${noBatch}/${index}`);
  };

  toggleValidation = (e: any = null) => {
    const selected = !this.state.selected;
    this.setState({ selected });
    if (!selected) {
      this.removeByType("line");
    } else {
      this.addSelection();
    }
  };

  addSelection = () => {
    const { newWidth, newHeight } = this.scaling;
    const fill = "red";
    const ligne1 = new fabric.Line([-5, -5, newWidth, newHeight], {
      fill,
      stroke: fill,
      strokeWidth: 8,
      hoverCursor: "pointer",
      selectable: false,
      evented: false,
    });
    const ligne2 = new fabric.Line([newWidth, 0, 0, newHeight], {
      fill,
      stroke: fill,
      strokeWidth: 8,
      hoverCursor: "pointer",
      selectable: false,
      evented: false,
    });
    this.canvas.add(ligne1);
    this.canvas.add(ligne2);
  };

  removeByType = (type: string) =>
    this.canvas
      .getObjects()
      .filter((o: any) => o.type === type)
      .forEach((o: any) => this.canvas.remove(o));

  configureFabricJSCanvas = (ref: HTMLCanvasElement) =>
    new Promise((resolve: any) => {
      const canvas = new fabric.Canvas(ref);
      const { width, height } = this.props;
      setTimeout(() => {
        //@ts-ignore
        const { offsetHeight, offsetWidth } = this.canvasWrapperRef.current;
        const taille: size = fitInBox(
          width,
          height,
          offsetWidth,
          offsetHeight,
          false
        );

        const { height: newHeight, width: newWidth } = taille;
        canvas.setWidth(newWidth);
        canvas.setHeight(newHeight);
        // canvas.uniScaleTransform = true;
        canvas.selection = false;
        canvas.defaultCursor = "pointer";

        this.scaling = {
          x: newWidth / width,
          y: newHeight / height,
          newHeight,
          newWidth,
        };
        resolve(canvas);
      }, 50);
    });

  loadImage = (props: any) => {
    const { image } = props;

    // nettoyer
    if (this.image) {
      this.removeByType("image");
      this.image = null;
    }
    if (this.textBox) {
      this.removeByType("group");
      this.textBox = null;
    }

    //valeurs par défaut
    let label: any = null;
    let url = "/404.png";
    if (image) {
      url = image.url;
      label = image.labels.simpleLabels[0] || null;
    }

    fabric.util.loadImage(url, (img: any) => {
      if (img === null) {
        fabric.util.loadImage("/404.png", (img: any) => {
          this.addImageToCanvas(img);
        });
        return;
      }
      this.addImageToCanvas(img, label, image.motif);
    });
  };

  addImageToCanvas = (
    img: any,
    label: any = null,
    motif: null | string = null
  ) => {
    const { newWidth, newHeight } = this.scaling;
    this.image = new fabric.Image(img, {});
    this.canvas.add(this.image);

    this.image.set({
      left: newWidth / 2,
      top: newHeight / 2,
      selectable: false,
      lockMovementX: true,
      centeredScaling: true,
      lockMovementY: true,
      hoverCursor: "arrow",
      scaleX: this.scaling.x,
      scaleY: this.scaling.y,
      originX: "center",
      originY: "center",
      opacity: 1,
    });

    this.hydrate(label, motif);
    this.canvas.renderAll();
  };

  hydrate = (label: any, motif: null | string) => {
    if (this.textBox) {
      this.removeByType("group");
      this.textBox = null;
    }

    if (motif) {
      this.addTextBox(motif, "#000", "#FFF");
    } else if (label) {
      const format = this.props.labels.find((l: any) => l.legend === label);
      if (format) {
        this.addTextBox(label, format.color, format.textColor);
      }
    }
  };

  addTextBox = (texte: string, bgColor: string, textColor: string) => {
    const { newWidth, newHeight } = this.scaling;
    const txt = new fabric.IText(texte, {
      left: newWidth / 2 - texte.length / 2,
      top: newHeight - 19,
      height: 50,
      width: newWidth / 4,
      fontSize: 20,
      fill: textColor,
      fontFamily: "Roboto",
      strokeWidth: 0.5,
      stroke: textColor,
      originY: "center",
      originX: "center",
    });

    const box = new fabric.Rect({
      left: 0,
      top: newHeight - 40,
      width: newWidth,
      height: 40,
      hasRotatingPoint: false,
      strokeWidth: 2,
      fill: bgColor,
      selectable: false,
      originX: "left",
      originY: "top",
    });

    this.textBox = new fabric.Group([box, txt]);

    this.canvas.add(this.textBox);
  };

  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.canvasContainer} ref={this.canvasWrapperRef}>
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(ClassifLabellisation));
