import React, { useState, useEffect } from "react";
import { getTime, parse } from "date-fns";
import queryString from "query-string";
import Users from "./component";
import LabelerSnackbar from "../common/SnackBar";
import constants from "../../tools/constants";
import withContext from "../../tools/withContext";
import ContextType from "../../types/Context";
import UserType from "../../types/User";
import ProjectType from "../../types/Project";
import useRouter from "../../tools/useRouter";
import lowerMapper from "../../tools/lowerMapper";

const lowerMap: any = {
  firstName: "filterFields.lowerFirstName",
  lastName: "filterFields.lowerLastName",
  role: "filterFields.lowerAuhtorityName",
  clientStr: "filterFields.lowerClientName",
};

interface PropsTypes {
  ctx: ContextType;
  match: any;
  location: any;
}

const UserContainer = (props: PropsTypes) => {
  const router = useRouter();
  const {
    ctx: { appFetch },
    match: {
      params: { noPage },
    },
    location: { search },
  } = props;

  const { USER_PROJECTS_PATH, USER_USERS_PATH } = constants.paths;

  const [users, setUsers] = useState<UserType[]>([]);
  const [pagination, setPagination] = useState<any>(null);
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");

  const fetchDatas = async () => {
    setIsLoading(true);
    try {
      setIsError(false);
      const datas: any = await appFetch(
        `${USER_USERS_PATH}?${queryString.stringify({
          ...lowerMapper(router.query, lowerMap),
          page: noPage,
        })}`
      );
      const { content, number, totalPages, first, last, totalElements } = datas;
      setUsers(
        content.map((user: UserType) => ({
          ...user,
          createdDateInt: getTime(parse(user.createdDate)),
        }))
      );
      setPagination({ number, totalPages, first, last, totalElements });

      const projs = await appFetch(`${USER_PROJECTS_PATH}?size=1000`);
      setProjects(projs.content);

      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const saveUser = async (u: UserType) => {
    setIsLoading(true);
    try {
      setIsError(false);
      const body = JSON.stringify({ ...u, login: u.email });
      const usrS = await appFetch(constants.paths.USERS_PATH, {
        method: u.id ? "PUT" : "POST",
        body,
      });
      setUsers(
        u.id
          ? users.map((usr: any) => (usr.id === u.id ? u : usr))
          : users.concat([
              {
                ...usrS,
                authorities: u.authorities,
                createdDateInt: getTime(parse(usrS.createdDate)),
              },
            ])
      );
      setPagination({
        ...pagination,
        totalElements: pagination.totalElements + 1,
      });
      setIsLoading(false);
      setMessage(`${u.firstName} ${u.lastName} ${u.id ? "modifié" : "créé"}`);
      setIsError(false);
      setIsSnackbarOpen(true);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const deleteUser = async (u: UserType) => {
    setIsLoading(true);
    try {
      setIsError(false);
      await appFetch(`${constants.paths.USERS_PATH}/${u.login}`, {
        method: "DELETE",
        body: JSON.stringify(u),
      });
      setUsers(users.filter((usr: UserType) => usr.id !== u.id));
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const resetToken2FA = async (u: UserType) => {
    setIsLoading(true);
    try {
      setIsError(false);
      await appFetch(`${constants.paths.USERS_PATH}/${u.id}/reset-qrcode`, {
        method: "PUT",
      });
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setIsLoading(false);
      setMessage(e.message);
      setIsSnackbarOpen(true);
    }
  };

  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noPage, search]);

  return (
    <React.Fragment>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={isError ? "error" : "success"}
        message={message || "Ok"}
      />
      <Users
        saveUser={saveUser}
        deleteUser={deleteUser}
        resetToken2FA={resetToken2FA}
        isLoading={isLoading}
        users={users}
        projects={projects}
        pagination={pagination}
      />
    </React.Fragment>
  );
};

export default withContext(UserContainer);
