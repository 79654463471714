import React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import DialogActions from "@material-ui/core/DialogActions";

const styles = (theme: Theme) =>
  createStyles({
    actions: {
      padding: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px 0`
    }
  });

interface PropTypes {
  className?: string;
  classes: any;
  children: any;
  style?: any;
}

const UiDialogActions = (props: PropTypes) => {
  const { className, classes } = props;
  return (
    <DialogActions {...props} className={className || classes.actions}>
      {props.children}
    </DialogActions>
  );
};

export default withStyles(styles)(UiDialogActions);
