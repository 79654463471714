import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import UiInput from "./UiInput";
import UiProgress from "./UiProgess";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%"
    },
    titre: {
      minWidth: "550px"
    },
    list: {
      height: 250,
      overflowY: "scroll"
    }
  });

interface PropTypes {
  classes: any;
  open: boolean;
  onClose: any;
  labels: string[];
  images: any[];
  saveImage: any;
  updateTags: any;
}

const TagListUpdateDialog = (props: PropTypes) => {
  const {
    classes,
    open,
    onClose,
    labels,
    images,
    saveImage,
    updateTags
  } = props;

  const [tagSelectedIdx, setTagSelectedIdx] = useState<any>(null);
  const [tags, setTags] = useState<any>(labels);
  const [nbrImageSaved, setImageSaved] = useState(0);
  const [viewMode, setViewMode] = useState<string>("tags");

  const handleChange = (e: any) =>
    setTags(
      tags.map((t: string, idx: number) =>
        idx === tagSelectedIdx ? e.target.value : t
      )
    );

  const handleSelect = (e: any, index: number) => setTagSelectedIdx(index);

  const listNewTags = useCallback(
    () =>
      tags
        .filter((t: string, idx: number) => t !== labels[idx])
        .reduce(
          (m: any, t: string, idx: any) => ({ ...m, [labels[idx]]: t }),
          {}
        ),
    [tags, labels]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (viewMode === "progress") {
      onClose();
      return;
    }
    const newTags = listNewTags();
    updateTags(newTags).then(() => {
      setViewMode("progress");
      incrementImgSaved();
    });
  };

  const incrementImgSaved = useCallback(
    () => setImageSaved(nbrImageSaved + 1),
    [nbrImageSaved]
  );

  useEffect(() => {
    if (nbrImageSaved > 0 && nbrImageSaved < images.length + 1) {
      const newTags = listNewTags();
      const simpleLabels = [
        newTags[images[nbrImageSaved - 1].labels.simpleLabels[0]]
      ];
      setTimeout(() => {
        saveImage({
          ...images[nbrImageSaved - 1],
          labels: { ...images[nbrImageSaved - 1].labels, simpleLabels }
        }).then(() => {
          incrementImgSaved();
        });
      }, 500);
    }
  }, [
    nbrImageSaved,
    images,
    saveImage,
    incrementImgSaved,
    listNewTags,
    onClose
  ]);

  useEffect(() => {
    if (!open) {
      setImageSaved(0);
    }
  }, [open]);

  useEffect(() => {
    setViewMode("tags");
    setTags(labels);
  }, [labels]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">
          {viewMode === "tags"
            ? "Tags modifiables"
            : nbrImageSaved < images.length + 1
            ? "Mise à jour des images labelisées..."
            : "Opération terminée !"}
        </DialogTitle>
        <DialogContent>
          {viewMode === "tags" && (
            <FormControl className={classes.formControl}>
              <Grid direction="row" container spacing={8}>
                <Grid item xs={6}>
                  <List className={classes.list}>
                    {tags.map((t: any, index: number) => {
                      return (
                        <ListItem
                          key={index}
                          role={undefined}
                          dense
                          button
                          onClick={e => handleSelect(e, index)}
                        >
                          <ListItemText primary={t} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
                <Grid item xs={6} style={{ flexGrow: 1 }}>
                  {tagSelectedIdx !== null && (
                    <UiInput
                      onChange={handleChange}
                      required={false}
                      value={tags[tagSelectedIdx]}
                      label="Tag à modifier"
                    />
                  )}
                  {tagSelectedIdx === null && (
                    <Typography
                      style={{ paddingTop: "2em", textAlign: "center" }}
                    >
                      Veuillez sélectionner le tag que vous souhaitez modifier
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </FormControl>
          )}
          {viewMode === "progress" && (
            <UiProgress
              variant="determinate"
              color="secondary"
              value={(nbrImageSaved * 100) / images.length}
            />
          )}
        </DialogContent>
        <DialogActions>
          {viewMode === "tags" && (
            <Button onClick={onClose} color="primary">
              Annuler
            </Button>
          )}
          <Button type="submit" color="primary" variant="contained" autoFocus>
            {viewMode === "tags" ? "Valider" : "OK"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(TagListUpdateDialog);
