import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import VisibleIcon from "@material-ui/icons/Visibility";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import LabelType from "../../types/Label";
import { Typography } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    labels: {
      padding: `0 ${theme.spacing.unit * 2}px`
    },
    padded: {
      padding: 5
    }
  });

interface LabelsWithNumType extends LabelType {
  occurences?: number;
}

interface PropTypes {
  labels: LabelsWithNumType[];
  selected: number | null;
  classes: any;
  onClick: any;
  onVisibilityClick?: any;
  hideVisibilityIcon: boolean;
}

const Labels = (props: PropTypes) => {
  const {
    labels,
    selected,
    classes,
    onClick,
    onVisibilityClick,
    hideVisibilityIcon
  } = props;

  const handleChangeVisibility = (e: any, index: Number) => {
    e.preventDefault();
    e.stopPropagation();
    onVisibilityClick(index);
  };

  return (
    <Grid
      container
      direction="row"
      spacing={8}
      justify="space-between"
      alignItems="center"
      className={classes.labels}
    >
      {labels.map((c: any, index: number) => {
        const color = lighten(c.color, index === selected ? 0 : 0.5);
        return (
          <Grid
            item
            key={index}
            xs={6}
            className={classes.padded}
            onClick={() => onClick(index)}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{
                border: `solid 3px ${color}`,
                borderLeft: `solid ${
                  index === selected ? "10px" : "3px"
                } ${color}`,
                borderRadius: "3px"
              }}
            >
              <Grid
                item
                xs={
                  hideVisibilityIcon
                    ? 11
                    : typeof c.occurences !== "undefined"
                    ? 8
                    : 9
                }
                style={{
                  paddingLeft: index === selected ? 5 : 9,
                  fontSize: hideVisibilityIcon ? 14 : 16,
                  padding: "0 5px"
                }}
              >
                {c.legend}
              </Grid>
              <Grid
                item
                xs={
                  hideVisibilityIcon
                    ? 1
                    : typeof c.occurences !== "undefined"
                    ? 4
                    : 3
                }
              >
                <IconButton
                  key={c.legend}
                  onClick={(e: any) => handleChangeVisibility(e, index)}
                  disabled={hideVisibilityIcon}
                  title={`${c.visible ? "Cacher" : "Afficher"} les${
                    c.occurences ? ` ${c.occurences}` : ""
                  } labellisations`}
                >
                  <VisibleIcon
                    style={{
                      color: c.visible ? color : "silver",
                      visibility: hideVisibilityIcon ? "hidden" : "visible"
                    }}
                  />
                  {typeof c.occurences !== "undefined" ? (
                    <Typography
                      style={{
                        fontSize: "0.5em",
                        fontWeight: 500,
                        color: c.visible ? color : "silver",
                        paddingBottom: 1,
                        paddingLeft: 2
                      }}
                    >
                      {c.occurences}
                    </Typography>
                  ) : null}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

Labels.defaultProps = {
  hideVisibilityIcon: false
};

export default withStyles(styles)(Labels);
