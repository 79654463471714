import React from "react";
import { createStyles, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import UiButton from "../common/UiButton";
import UiInput from "../common/UiInput";

const styles = () =>
  createStyles({
    info: {
      color: "#FFF",
      fontSize: "16px",
    },
  });

type Props = {
  classes: any;
  codeValue: string;
  setCodeValue: (value: string) => void;
  submitting: boolean;
};

const InputContainer = ({
  classes,
  codeValue,
  setCodeValue,
  submitting,
}: Props) => {
  return (
    <Grid container direction="column" alignItems="center" spacing={16}>
      <Grid item>
        <Typography color="inherit" className={classes.info}>
          Entrez le code à 6 chiffres généré par <b>Google Authenticator</b> :
        </Typography>
      </Grid>
      <Grid item style={{ width: "200px" }}>
        <UiInput
          name="code"
          type="code"
          placeholder="Code de vérification"
          value={codeValue}
          onChange={(e: any) => setCodeValue(e.target.value)}
        />
      </Grid>
      <Grid item>
        <UiButton
          variant="contained"
          color="secondary"
          type="submit"
          disabled={submitting}
        >
          Valider
        </UiButton>
      </Grid>
      <Grid item>
        <Typography color="inherit" className={classes.info}>
          En cas de problème, veuillez contacter votre administrateur Labeler
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(InputContainer);
