import * as React from "react";
import GlobalContext from "../GlobalContext";

const Authorization = (WrappedComponent: any, allowedRoles: any) => (
  props: any
) => {
  return (
    <GlobalContext.Consumer>
      {(ctx: any) => {
        if (ctx.user && allowedRoles.includes(ctx.user.authorities[0])) {
          return <WrappedComponent {...props} />;
        }
        return <h1>Accès non autorisé</h1>;
      }}
    </GlobalContext.Consumer>
  );
};

export default Authorization;
