import React from "react";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import LabelIcon from "@material-ui/icons/Label";

interface PropTypes {
  onClick: any;
  showHelp: boolean;
}

const KeyboardHelperButton = (props: PropTypes) => {
  return (
    <IconButton
      onClick={props.onClick}
      title={`Afficher les ${props.showHelp ? "Tags" : "raccourcis claviers"}`}
    >
      {props.showHelp ? (
        <LabelIcon color="primary" />
      ) : (
        <HelpIcon color="primary" />
      )}
    </IconButton>
  );
};

export default KeyboardHelperButton;
