import * as React from "react";
import GlobalContext from "../GlobalContext";

const withContext = (Component: any) => (props: any) => {
  return (
    <GlobalContext.Consumer>
      {(ctx: any) => <Component ctx={ctx} {...props} />}
    </GlobalContext.Consumer>
  );
};

export default withContext;
