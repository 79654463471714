import React, { useState } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withContext from "../../tools/withContext";
import constants from "../../tools/constants";
import AlertDialog from "../common/AlertDialog";
import UiInput from "../common/UiInput";
import UiButton from "../common/UiButton";
import CustomParticles from "../common/CustomParticles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      textAlign: "center",
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 10%, ${theme.palette.primary.light})`,
      flexGrow: 1,
      position: 'relative'
    },
    input: {
      width: "100%",
      borderRadius: ".35em",
      height: "2.5em",
      padding: "0.5em",
      lineHeight: "2.5em"
    },
    errorLine: {
      color: "white",
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
      fontSize: "1em"
    },
    infoLine: {
      color: "white",
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
      fontSize: "1.1em"
    },    
    explication: {
      marginBottom: theme.spacing.unit * 2
    },
    button: {
      marginTop: theme.spacing.unit,
      minWidth: 180
    }
  });

const PwdForgotten = (props: any) => {
  const {
    classes,
    ctx: { appFetch },
    history
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<any>("");
  const [mail, setEmail] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await appFetch(constants.paths.INIT_RESET_PASSWORD, {
        method: "POST",
        body: mail
      });
      setIsDialogOpen(true);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      setError(<>{"Erreur lors de la réinitialisation du mot de passe :"}<br /><strong>{"données invalides"}</strong></>);
    }
  };

  const handleChange = (e: any) => setEmail(e.target.value);

  const closeAlert = () => setIsDialogOpen(false);

  const handeDone = () => history.push("/login");

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <CustomParticles />
      <AlertDialog
        title="Réinitialisation de votre mot de passe"
        text={`Un email avec un lien de réinitialisation vous a été envoyé.`}
        open={isDialogOpen}
        onClose={closeAlert}
        onAccept={handeDone}
        acceptLabel="OK"
      />
      <Grid item sm={12}>
        <Grid
          container
          direction="column"
          spacing={8}
          component="form"
          alignItems="center"
          onSubmit={handleSubmit}
        >
          <Grid
            item
            className={classNames({
              [classes.errorLine]: error,
              [classes.explication]: !error
            })}
          >
            {!error && <Typography component="div" className={classes.infoLine}>{[
                "Saisissez votre email pour",
                <br />,
                "réinitialiser votre mot de passe"
              ]}
            </Typography>}
            {error && <Typography component="p" className={classes.errorLine}>
              {error}
            </Typography>}        
          </Grid>
          <Grid item style={{minWidth: 300}}>
            <UiInput
              name="email"
              autoFocus
              fullWidth
              placeholder="Adresse email"
              className={classes.input}
              onChange={handleChange}
              type="email"
            />
          </Grid>
          <Grid item className={classes.button}>
            <UiButton
              variant="contained"
              type="submit"
              fullWidth
              color="secondary"
            >
              {isSubmitting ? "Envoi..." : "Envoyer"}
            </UiButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(withContext(PwdForgotten)));
