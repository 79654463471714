import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withContext from "../../tools/withContext";
import constants from "../../tools/constants";
import UiButton from "../common/UiButton";
import UiInput from "../common/UiInput";
import AlertDialog from "../common/AlertDialog";
import CustomParticles from "../common/CustomParticles";

const styles = (theme: Theme) =>
  createStyles({
    rootNotLogged: {
      height: "100vh",
      textAlign: "left",
      flexGrow: 1,
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 10%, ${
        theme.palette.primary.light
      })`,
      "& $label": {
        color: "#FFF",
        fontSize: "1.2em"
      },
      position: "relative"
    },
    input: {
      width: "100%",
      borderRadius: ".35em",
      height: "2.5em",
      padding: "0.5em",
      lineHeight: "2.5em"
    },
    errorLine: {
      height: "25px",
      color: "red"
    },
    button: {
        color: "#FFF",
      },
    mT: {
      marginTop: theme.spacing.unit * 2
    },
    mdpTitre: {
      textAlign: "left",
      marginBottom: "1.3em",
      color: theme.palette.primary.dark
    }
  });

const ResetPassword = (props: any) => {
  const {
    history,
    ctx: { appFetch },
    classes,
    location: { search }
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const key = search !== "" ? search.split("=")[1] : null;

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (password !== passwordConfirm) {
        setError("Les mots de passe sont différents");
        setIsSubmitting(false);
        return;
      }
      const { FINISH_RESET_PASSWORD } = constants.paths;

      await appFetch(FINISH_RESET_PASSWORD, {
        method: "POST",
        body: JSON.stringify({ key, newPassword: password })
      });

      setIsDialogOpen(true);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      setError("Le lien n'est plus valide");
    }
  };

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleChangePasswordConfirm = (e: any) => {
    setPasswordConfirm(e.target.value);
  };

  const closeAlert = () => setIsDialogOpen(false);

  const handeDone = () => history.push("/login");

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.rootNotLogged}
    >
      <CustomParticles />
      <AlertDialog
        title="Réinitialisation de votre mot de passe"
        text={`Votre mot de passe a été réinitialisé. Vous pouvez vous connecter`}
        open={isDialogOpen}
        onClose={closeAlert}
        onAccept={handeDone}
        acceptLabel="OK"
      />
      <Grid item xs={3}>
        <Paper elevation={2} style={{ padding: "2em" }}>
          <Grid
            container
            direction="column"
            spacing={8}
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid item className={classes.mdpTitre}>
              Réinitialisation de votre mot de passe
            </Grid>
            <Grid item>
              <UiInput
                name="password"
                placeholder="Mot de passe"
                label="Nouveau mot de passe"
                type="password"
                autoFocus
                onChange={handleChangePassword}
              />
            </Grid>
            <Grid item>
              <UiInput
                name="passwordConfirm"
                placeholder="Mot de passe (confirmation)"
                label="Nouveau mot de passe (confirmation)"
                type="password"
                onChange={handleChangePasswordConfirm}
              />
            </Grid>

            <Grid item className={classes.errorLine}>
              <Typography>{error || ""}</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.mT}
              >
                <Grid sm={6}>
                  <UiButton
                    variant="contained"
                    fullWidth
                    color="secondary"
                    type="submit"
                    className={classes.button}
                  >
                    {isSubmitting ? "Modification..." : "Modifier"}
                  </UiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(withContext(ResetPassword)));
