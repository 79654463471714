import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { intersection, uniq } from "lodash";
import { RouteComponentProps } from "react-router";
import withContext from "../../../tools/withContext";
import BatchDeadlineFormDialog from "./BatchDeadlineFormDialog";
import BatchAttribFormDialog from "./BatchAttribFormDialog";
import AlertDialog from "../../common/AlertDialog";
import BatchType from "../../../types/Batch";
import UiButton from "../../common/UiButton";
import UiArrowIcon from "../../common/UiArrowIcon";
import ContextType from "../../../types/Context";
import UserType from "../../../types/User";
import BatchActionsMenu from "./BatchActionsMenu";
import generateType from "./generateType";

interface PropsType extends RouteComponentProps<any>, React.Props<any> {
  batch: BatchType;
  users: UserType[];
  batches: BatchType[];
  selected: any[];
  history: any;
  saveBatch: Function;
  massUpdate: Function;
  selectOne: Function; // Quand le bouton Action est cliqué on ne sélectionne que le batch lié
  deleteBatch: Function;
  deleteDeadline: Function;
  pushToHistory: Function;
  setModalMode: Function;
  isAdmin: boolean;
  ctx: ContextType;
  theme: any;
}

const BatchActionButton = (props: PropsType) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [alertMessage, setAlertMessage] = useState<any>(null);
  const [batchDialogOpen, setBatchDialogOpen] = useState(false);
  const [formView, setFormView] = useState<"attrib" | "deadline">("attrib");

  const {
    batch,
    users,
    batches,
    selected,
    history,
    saveBatch,
    massUpdate,
    deleteBatch,
    deleteDeadline,
    pushToHistory,
    isAdmin,
    selectOne,
    setModalMode,
    ctx: {
      user: {
        authorities: [userRole],
      },
    },
  } = props;

  useEffect(
    () => setModalMode(batchDialogOpen || alertMessage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [batchDialogOpen, alertMessage]
  );

  const handleLabeler = (batchId: number, noImage: number) => {
    const type = generateType(batch);
    history.push(`/labeler${type}/${batchId}/${noImage}`);
  };

  const actionButtonClicked = (e: any) => {
    e.stopPropagation();
    selectOne(batch.id);
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  const openDialog = (view: "attrib" | "deadline") => {
    setFormView(view);
    setBatchDialogOpen(true);
  };

  const closeDialog = () => setBatchDialogOpen(false);

  const openAlert = (message: any) => setAlertMessage(message);

  const closeAlert = () => setAlertMessage(null);

  const handleSave = (datas: any) => {
    if (datas.labelerId || datas.deadline) {
      saveBatch({ ...batch, ...datas });
    } else if (datas.ids) {
      massUpdate(datas).then(() => closeDialog());
    }
    closeDialog();
  };

  const handleDeleteDeadling = (batch: any) => {
    deleteDeadline(batch);
    closeDialog();
  };


  const handleAlertActionAccepted = () => {
    if (alertMessage && alertMessage.type === "DELETE") {
      deleteBatch(batch).then(() => closeAlert());
    }

    if (alertMessage && alertMessage.type === "CLOSE") {
      saveBatch({ ...batch, completed: true, verified: false }).then(() =>
        closeAlert()
      );
    }

    if (alertMessage && alertMessage.type === "VERIFIED") {
      saveBatch({ ...batch, completed: true, verified: true }).then(() =>
        closeAlert()
      );
    }

    if (alertMessage && alertMessage.type === "REOPEN") {
      saveBatch({ ...batch, completed: false, verified: false }).then(() =>
        closeAlert()
      );
    }
  };

  const batchesSelected = batches.filter(
    (b: BatchType) => selected.indexOf(b.id) !== -1
  );

  const batchesSelectedProjectsIds = uniq(
    batchesSelected.reduce((m: any, b: any) => m.concat(b.project.id), [])
  );

  // récupérer les users rattachés à tous les projets batchesSelectedProjectsIds
  const usersForSelection = users.filter((u: any) => {
    const i = intersection(u.projects, batchesSelectedProjectsIds);
    return (
      (i.length > 0 && i.length === batchesSelectedProjectsIds.length) ||
      u.authorities.find((a: string) => a === "ROLE_ADMIN")
    );
  });

  return isAdmin || batch.totalLabelDocs === batch.totalEligibleDocs ? (
    <React.Fragment>
      {batchDialogOpen && formView === "deadline" && (
        <BatchDeadlineFormDialog
          open={batchDialogOpen}
          onClose={closeDialog}
          onSave={handleSave}
          batch={batch}
          users={usersForSelection}
          selected={selected}
          deleteDeadline={handleDeleteDeadling}
        />
      )}
      {batchDialogOpen && formView === "attrib" && (
        <BatchAttribFormDialog
          open={batchDialogOpen}
          onClose={closeDialog}
          onSave={handleSave}
          batch={batch}
          users={usersForSelection}
          selected={selected}
          batches={batches.filter((l: BatchType) => !l.labelerId)}
        />
      )}      
      {alertMessage !== null && (
        <AlertDialog
          title={alertMessage.title}
          text={alertMessage.text}
          open={alertMessage !== null}
          onClose={closeAlert}
          onAccept={handleAlertActionAccepted}
        />
      )}
      <UiButton
        variant="outlined"
        color="secondary"
        onClick={(e: any) => actionButtonClicked(e)}
        disabled={selected.length > 1}
      >
        Actions
        <UiArrowIcon disabled={selected.length > 1} />
      </UiButton>
      {anchorEl && (
        <BatchActionsMenu
          anchorEl={anchorEl}
          userRole={userRole}
          closeMenu={closeMenu}
          pushToHistory={pushToHistory}
          openDialog={openDialog}
          openAlert={openAlert}
          batch={batch}
        />
      )}
    </React.Fragment>
  ) : (
    <UiButton
      variant="outlined"
      color="secondary"
      onClick={() => handleLabeler(batch.id, batch.totalLabelDocs)}
      disabled={selected.length > 1}
    >
      Labelliser
    </UiButton>
  );
};

export default withRouter(withContext(BatchActionButton));
