import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { isInteger } from "lodash";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import Paper from "@material-ui/core/Paper";
import { parse } from "date-fns";
import UiButton from "../common/UiButton";
import UiInput from "../common/UiInput";
import UiSelect from "../common/UiSelect";
import UiFormTitle from "../common/UiFormTitle";
import UsersFormDialog from "./components/UsersFormDialog";
import constants from "../../tools/constants";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: `calc(100vh - 64px - ${theme.spacing.unit * 5}px)`,
    },
    paper: {
      minWidth: "100%",
      borderRadius: "5px",
    },
    comment: {
      margin: "0px 16px",
      minHeight: 19,
      fontSize: 12,
    },
    marginTopNeg: {
      marginTop: "-16px",
    },
    title: {
      color: "white",
    },
    form: {
      padding: `${theme.spacing.unit * 4}px`,
    },
    formError: {
      color: "red",
    },
    input: {
      backgroundColor: "#FFF",
    },
    margin: {
      margin: theme.spacing.unit,
    },
    bootstrapRoot: {
      "label + &": {
        marginTop: theme.spacing.unit,
      },
    },
    bootstrapInput: {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      border: "1px solid rgba(72,72,72,.2)",
      fontSize: 16,
      padding: "10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
    bootstrapFormLabel: {
      fontSize: 18,
    },
    select: {
      width: "250px",
      borderRadius: 4,
      padding: "7px 7px",
      boxSizing: "border-box",
      // margin: "5px 0 0",
      fontSize: 16,
      position: "relative",
      border: "1px solid rgba(72,72,72,.2)",
      appearance: "none",
      color: "#444f59",
      background: "url(/assets/arrow-select.png) no-repeat 100%",
      "label + &": {
        marginTop: theme.spacing.unit,
      },
    },
    deadline: {
      lineHeight: "17px",
      minHeight: "17px",
      paddingTop: "5px",
    },
    button: {
      padding: "9px 16px",
    },
    icon: {
      marginRight: theme.spacing.unit,
    },
  });

const { BATCH_PREFIX_EXISTS } = constants.paths;

const Batch = (props: any) => {
  const {
    classes,
    history,
    folders,
    createBatch,
    project,
    appFetch,
    users,
  } = props;
  const [datas, setDatas] = useState<any>({});
  const [error, setError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(
    () =>
      setDatas((d: any) => ({
        ...d,
        projectId: project.id,
        deadline: "",
        userIds: [],
        nbLots: "",
        nbImage: "",
      })),
    [project]
  );

  const folder = folders.find((f: any) => f.url === datas.stagingFolder);

  const handleChange = (e: any) => {
    setError(false);
    setDatas({
      ...datas,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDeadline = (e: any) => {
    setError(false);
    setDatas({
      ...datas,
      deadline: e.target.value,
    });
  };

  const handleChangeParams = (e: any) => {
    const valueInt = parseInt(e.target.value, 10);
    const isInt = isInteger(valueInt);

    if (!isInt) return;
    let nbImage;
    if (e.target.name === "nbImage") {
      const rest = folder.nbFiles % valueInt;
      setDatas({
        ...datas,
        rest,
        nbImage: `${valueInt}`,
        nbLots: `${parseInt(`${folder.nbFiles / valueInt}`, 10) +
          (rest > 0 ? 1 : 0)}`,
      });
    }

    if (e.target.name === "nbLots") {
      nbImage = Math.floor(parseInt(folder.nbFiles, 10) / valueInt);
      const rest = folder.nbFiles - valueInt * nbImage;
      setDatas({
        ...datas,
        rest,
        nbLots: `${valueInt}`,
        nbImage: `${parseInt(`${folder.nbFiles / valueInt}`, 10) +
          (rest > 0 ? 1 : 0)}`,
      });
    }
  };

  const submit = (e: any) => {
    e.preventDefault();
    setError(false);
    isPrefixFreeOfUse()
      .then(() => {
        const { nbLots, rest, deadline, ...d } = datas;
        createBatch({
          ...d,
          deadline: deadline !== "" ? parse(deadline).toISOString() : null,
          nbImage: parseInt(datas.nbImage, 10),
        }).then(() =>
          setTimeout(() => history.push("/projectsOperations", 2000))
        );
      })
      .catch((e: any) => {
        setError(true);
      });
  };

  const isPrefixFreeOfUse = async () => {
    const test = await appFetch(`${BATCH_PREFIX_EXISTS}?name=${datas.prefix}`, {
      method: "Get",
      headers: { "Content-Type": "application/json" },
    });
    if (test) throw new Error();
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleUserListSave = (userIds: string[]) =>
    setDatas({ ...datas, userIds });

  const attrib = datas.userIds && datas.userIds.length > 0;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <UsersFormDialog
        open={open}
        users={users}
        onClose={handleClose}
        onSave={handleUserListSave}
        selectionMax={datas.nbLots !== "" ? parseInt(datas.nbLots, 10) : 0}
      />
      <Helmet>
        <title>Générer des lots</title>
      </Helmet>
      <Grid item lg={6}>
        <Paper className={classes.paper}>
          <UiFormTitle>{`Générer les lots ${
            project.name ? `pour le projet ${project.name}` : ""
          }`}</UiFormTitle>
          <form onSubmit={submit}>
            <Grid
              container
              spacing={32}
              direction="row"
              justify="space-between"
              className={classes.form}
            >
              <Grid item lg={6}>
                <UiSelect
                  name="stagingFolder"
                  autoFocus
                  required
                  minWidth={300}
                  label="Sélectionnez le dossier source"
                  onChange={handleChange}
                  tabIndex={1}
                >
                  <option value="" />
                  {folders.map((f: any, index: number) => (
                    <option key={index} value={f.url}>
                      {f.url}
                    </option>
                  ))}
                </UiSelect>
                <p className={classes.comment}>
                  {folder &&
                    `${folder.nbFiles} image${folder.nbFiles > 1 ? "s" : ""}`}
                </p>
              </Grid>
              <Grid item lg={6}>
                <UiInput
                  label={
                    !error ? (
                      "Préfixe des lots"
                    ) : (
                      <span style={{ color: "red" }}>Préfixe déjà utilisé</span>
                    )
                  }
                  required
                  name="prefix"
                  onChange={handleChange}
                  disabled={false}
                  tabIndex={0}
                />
              </Grid>
              <Grid item lg={4}>
                <UiInput
                  label="Images par lot cible"
                  name="nbImage"
                  placeHolder="Nombre d'images"
                  required
                  disabled={!datas.stagingFolder}
                  onChange={handleChangeParams}
                  value={datas.nbImage}
                  tabIndex={2}
                />
              </Grid>
              <Grid item lg={4}>
                <UiInput
                  label="Nbre lots cibles"
                  name="nbLots"
                  required
                  disabled={!datas.stagingFolder}
                  value={datas.nbLots}
                  onChange={handleChangeParams}
                />
                <p className={classes.comment}>
                  {
                    datas.rest > 0 &&
                    `Le dernier lot comporte ${datas.rest} image${
                      datas.rest > 1 ? "s" : ""
                    }`}
                </p>
              </Grid>
              <Grid item lg={4}>
                <UiInput
                  label="Deadline"
                  name="deadline"
                  type="date"
                  required={false}
                  disabled={!datas.stagingFolder}
                  value={datas.deadline}
                  onChange={handleChangeDeadline}
                  className={classes.deadline}
                />
              </Grid>
              <Grid item lg={12}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  spacing={0}
                >
                  <Grid item lg={4}>
                    <UiButton
                      className={classes.button}
                      fullWidth
                      color="primary"
                      variant="contained"
                      type="button"
                      tabIndex={3}
                      onClick={handleOpen}
                      disabled={datas.nbLots === ""}
                    >
                      <PersonIcon className={classes.icon} />
                      {attrib ? `${datas.userIds.length} ` : ""}
                      Attribution{attrib ? "s" : ""}
                    </UiButton>
                    {datas &&
                      datas.userIds &&
                      datas.userIds.length > 0 &&
                      datas.userIds.length !== parseInt(datas.nbLots, 10) && (
                        <div className={classes.formError}>
                          Erreur attribution : {datas.nbLots} attendu
                        </div>
                      )}
                  </Grid>
                  <Grid item lg={4}>
                    <UiButton
                      className={classes.button}
                      fullWidth
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={
                        error ||
                        (datas &&
                          datas.userIds &&
                          datas.userIds.length > 0 &&
                          datas.userIds.length !== parseInt(datas.nbLots, 10))
                      }
                      tabIndex={3}
                    >
                      Valider
                    </UiButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(Batch));
