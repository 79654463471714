import * as React from "react";
import { isEqual } from "lodash";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import BoxingLabellisation from "./components/BoxingLabellisation";
import ClassifLabellisation from "./components/ClassifLabellisation";
import SegmentationLabellisation from "./components/SegmentationLabellisation";
import PlateCodeOrMotif from "./components/PlateCodeOrMotif";
import PlateLabellisation from "./components/PlateLabellisation";
import paginate from "../../tools/paginate";

const styles = (theme: Theme) =>
  createStyles({
    label: {
      display: "inline",
      minWidth: 200,
      textAlign: "center",
      padding: 5,
      marginRight: 10,
    },
    labelBox: {
      display: "inline",
      minWidth: 200,
      textAlign: "center",
      padding: 5,
      marginRight: 10,
    },
  });

interface IState {
  justPressed: string | null;
  selectedIndex: number | null;
}

class Mosaic extends React.Component<any, IState> {
  private validationStateChangeKeys: string[] = ["a", "z", "e", "q", "s", "d"];

  state = {
    justPressed: null,
    selectedIndex: null,
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (!isEqual(this.props.keyEvent, nextProps.keyEvent)) {
      if (nextProps.keyEvent.type === "keyup") {
        this.handleKeyUp(nextProps.keyEvent);
      } else {
        this.handleKeyDown(nextProps.keyEvent);
      }
    }
  };

  handleKeyUp = (e: any) => {
    const { noPage } = this.props;
    if (e.key === "PageUp" && noPage !== "1") {
      this.navigatePrev();
    }
    if (e.key === "PageDown") {
      if (Number(noPage) < this.getNumberPages()) this.navigateNext();
    }

    if (this.validationStateChangeKeys.includes(e.key) || e.key === "c") {
      this.setState({ justPressed: e.key });
      setTimeout(() => this.setState({ justPressed: null }), 250);
    }
  };

  handleKeyDown = (e: any) => {
    console.log(e.key);
  };

  navigateNext = () => {
    const { noBatch, noPage, history } = this.props;
    history.push(`/mosaic/${noBatch}/${Number(noPage) + 1}`);
  };

  navigatePrev = () => {
    const { noBatch, noPage, history } = this.props;
    history.push(`/mosaic/${noBatch}/${Number(noPage) - 1}`);
  };

  getNumberPages = () => {
    const { images } = this.props;
    let nbrePage = Math.floor(images.length / 6);
    if (images.length % 6 > 0) {
      nbrePage++;
    }
    return nbrePage;
  };

  setSelectedIndex = (selectedIndex: number) =>
    this.setState({ selectedIndex });

  clearSelectedIndex = (selectedIndex: number) =>
    this.setState({ selectedIndex: null });

  public render() {
    const {
      typeLabellisation,
      images,
      labels,
      width,
      height,
      noBatch,
      nbrePages,
      noPage,
      classes,
    } = this.props;

    return (
      <Grid container direction="column" spacing={8}>
        <Grid item>
          {true && (
            <Grid container spacing={8} direction="row" justify="flex-start">
              {paginate(images, 6, Number(noPage)).map(
                (image: any, index: number) => (
                  <Grid
                    item
                    key={index}
                    xs={4}
                    style={{
                      minHeight: `calc((100vh / 2) - ${
                        typeLabellisation !== "CLASSIF" ||
                        typeLabellisation !== "PLATE"
                          ? 28
                          : 0
                      }px)`,
                    }}
                  >
                    {typeLabellisation === "RECTANGLE_BOXING" && (
                      <BoxingLabellisation
                        noBatch={noBatch}
                        index={index}
                        image={image}
                        labels={labels}
                        width={width}
                        height={height}
                        keyJustPressed={this.state.justPressed}
                      />
                    )}
                    {typeLabellisation === "CLASSIF" && (
                      <ClassifLabellisation
                        noBatch={noBatch}
                        index={index}
                        image={image}
                        labels={labels}
                        width={width}
                        height={height}
                        keyJustPressed={this.state.justPressed}
                      />
                    )}
                    {typeLabellisation === "SEGMENTATION" && (
                      <SegmentationLabellisation
                        noBatch={noBatch}
                        index={index}
                        image={image}
                        labels={labels}
                        width={width}
                        height={height}
                        keyJustPressed={this.state.justPressed}
                      />
                    )}
                    {typeLabellisation === "PLATE" && (
                      <PlateLabellisation
                        noBatch={noBatch}
                        index={index}
                        setSelectedIndex={this.setSelectedIndex}
                        clearSelectedIndex={this.clearSelectedIndex}
                        image={image}
                        labels={labels}
                        width={width}
                        height={height}
                        keyJustPressed={this.state.justPressed}
                      />
                    )}
                  </Grid>
                )
              )}
            </Grid>
          )}
        </Grid>

        {(typeLabellisation === "RECTANGLE_BOXING" ||
          typeLabellisation === "SEGMENTATION") && (
          <Grid item>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <ul
                  style={{ margin: 0, listStyleType: "none", paddingLeft: 0 }}
                >
                  <li className={classes.labelBox}>Légende : </li>
                  {labels.map((l: any) => {
                    return (
                      <li
                        className={classes.label}
                        style={{
                          backgroundColor: l.color,
                          color: l.textColor,
                        }}
                      >
                        {l.legend}
                      </li>
                    );
                  })}
                </ul>
              </Grid>
              <Grid item>
                Page {noPage}/{nbrePages}
              </Grid>
            </Grid>
          </Grid>
        )}
        {typeLabellisation === "PLATE" && (
          <Grid item>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <PlateCodeOrMotif
                  selectedIndex={this.state.selectedIndex}
                  images={paginate(images, 6, Number(noPage))}
                />
              </Grid>
              <Grid item>
                Page {noPage}/{nbrePages}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(Mosaic));
