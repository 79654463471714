import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { RouteComponentProps } from "react-router";
import UiDialogTitle from "../../common/UiDialogTitle";
import UiButton from "../../common/UiButton";
import UiDialogActions from "../../common/UiDialogActions";
import useInterval from "../../../tools/useInterval";
import BatchType from "../../../types/Batch";
import UserType from "../../../types/User";
import ProgressDelete from "./ProgressDelete";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      minWidth: "100%"
    },
    titre: {
      minWidth: "550px"
    }
  });

interface PropsType extends RouteComponentProps<any>, React.Props<any> {
  batch?: BatchType;
  selected?: any[];
  deleteBatch: any;
  onClose: any;
  open: boolean;
  classes: any;
  batches: BatchType[];
  users: UserType[];
}

const BatchesDeleteDialog = (props: PropsType) => {
  const { deleteBatch, open, onClose, batches, selected } = props;
  const [batchListIds, setBatchListIds] = useState(selected);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  useEffect(() => setBatchListIds(selected), [selected]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setDeleteConfirmed(true);
  };

  const deleteOneBatch = () => {
    if (batchListIds) {
      const [bId, ...rest] = batchListIds;
      const batchToDelete = batches.find((b: any) => b.id === bId);
      if (batchToDelete) {
        deleteBatch(batchToDelete).then(() => setBatchListIds(rest));
      }
    }
  };

  useInterval(() => {
    if (deleteConfirmed && batchListIds && batchListIds.length > 0) {
      deleteOneBatch();
    }
    if (batchListIds && batchListIds.length === 0 && open) {
      onClose();
    }
  }, 1500);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="list-actif-title"
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <UiDialogTitle
          onCloseClick={onClose}
          title={`Supprimer ${batches.length} lots ?`}
        />
        <DialogContent>
          {selected && batchListIds && (
            <ProgressDelete
              total={selected.length}
              nbreBatchesSupprimes={selected.length - batchListIds.length}
            />
          )}
        </DialogContent>
        <UiDialogActions style={{ marginRight: 23 }}>
          <UiButton variant="contained" color="secondary" type="submit">
            Supprimer ces lots
          </UiButton>
        </UiDialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(withRouter(BatchesDeleteDialog));
