import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    full: {
      width: "100%",
    },
  });

interface PropsTypes {
  message?: string;
  classes: any;
}

/** Display spinner in the center of the screen with an optional message */
const spinner = (props: any) => {
  const { message, classes } = props;
  return (
    <Grid container spacing={16} justify="center" alignItems="center">
      {message && (
        <Grid item style={{ textAlign: "center" }}>
          <div className={classes.full}>
            <Typography variant="h4">{message}</Typography>
          </div>
        </Grid>
      )}
      <Grid item>
        <div className={classes.full}>
          <CircularProgress color="primary" />
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(spinner);
