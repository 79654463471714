import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Notifications from "./component";
import withContext from "../../tools/withContext";
import constants from "../../tools/constants";
import ProjecType from "../../types/Project";
import * as authStorage from "../../tools/authStorage";
import LabelerSnackbar from "../common/SnackBar";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: `calc(100vh - 64px - ${theme.spacing.unit * 5}px)`,
    },
  });

const NotificationsContainer = (props: any) => {
  const {
    ctx: { appFetch, user, setUser },
    classes,
  } = props;
  const [projects, setProjects] = useState<ProjecType[]>([]);
  const [notifSettings, setNotifSettings] = useState(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [requestSent, setRequestSent] = useState(false);
  const history = useHistory();

  const loadProjects = async () => {
    try {
      const datas = await appFetch(
        `${constants.paths.USER_PROJECTS_PATH}?size=1000&sort=lowerName,asc`
      );
      setProjects(datas.content);
      setNotifSettings(
        user.mailReportingConf || {
          projects: [],
          batchCompletedReport: false,
          mondayReport: false,
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const saveReportingConf = async (mailReportingConf: any) => {
    setMessage("");
    try {
      const user = authStorage.getUser();
      const nUser = { ...user, mailReportingConf };
      setRequestSent(true);
      await appFetch(constants.paths.USERS_PATH, {
        method: "PUT",
        body: JSON.stringify(nUser),
      });
      setUser(nUser);
      authStorage.storeUser(nUser);
      setMessage("Configuration modifiée");
    } catch (e) {
      setMessage("Erreur, configuration non sauvegardée");
    }
    setIsSnackbarOpen(true);
    setTimeout(() => history.push("/batches/0?sort=createdDate,desc"), 3000);
  };

  const sendReport = async () => {
    setMessage("");
    try {
      setRequestSent(true);
      await appFetch(`${constants.paths.USER_REPORT_PATH}/${user.login}`);
      setMessage("Rapport(s) envoyé(s)");
    } catch (e) {
      console.log(e);
      setMessage("Erreur lors de l'envoi de(s) rapport(s)");
    }
    setIsSnackbarOpen(true);
    setTimeout(() => history.push("/batches/0?sort=createdDate,desc"), 3000);
  };

  const closeSnackbar = () => setIsSnackbarOpen(false);

  useEffect(() => {
    loadProjects();
  }, []);

  if (!notifSettings)
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );

  return (
    <>
      <LabelerSnackbar
        open={isSnackbarOpen}
        onClose={closeSnackbar}
        variant={message.indexOf("Erreur") !== -1 ? "error" : "success"}
        message={message}
      />
      <Notifications
        projects={projects}
        notifSettings={notifSettings}
        saveReportingConf={saveReportingConf}
        sendReport={sendReport}
        requestSent={requestSent}
      />
    </>
  );
};

export default withContext(withStyles(styles)(NotificationsContainer));
