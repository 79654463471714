
import React from 'react';

const GUTTER_SIZE = 5;

const DrawerPhotoListItemRenderer = ({ noBatch, noImage, classes, history, labelingTypePathname }) => ({ index, style, data }) => {
  const navigate = () => history.push(`/${labelingTypePathname}/${noBatch}/${index}`)
  return (
    <button
        style={{
          ...style,
          padding: 0,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE,
          height: style.height - GUTTER_SIZE          
        }}
        key={index}
        onClick={navigate}
        className={classes[Number(noImage) === index ? 'selected' : 'notSelected']}
      >
      <img src={data[index].url} alt="..." style={{ width: 'inherit', height: 'auto'}}/>
    </button>
  );
}

export default DrawerPhotoListItemRenderer;
