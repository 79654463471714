import React, { useState, useRef, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import withContext from "../../tools/withContext";
import TagListUpdateDialog from "./TagListUpdateDialog";

const styles = theme =>
  createStyles({
    labels: {
      padding: `0 ${theme.spacing.unit * 2}px`
    },
    padded: {
      padding: 5
    }
  });

const LabelsFromList = props => {
  const {
    labels,
    images,
    saveLabel,
    saveImage,
    updateLabels,
    selected,
    noImage,
    classes,
    onSelect,
    frequentLabels,
    frequentLabelsNumber,
    ctx: { user }
  } = props;

  const selectRef = useRef(null);
  const [newTags, setNewTags] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      saveLabel(newValue.label).then(indexNewTag => {
        onSelect(indexNewTag);
        setNewTags(newTags.concat([newValue.label]));
      });
    }
    if (actionMeta.action === "select-option") {
      onSelect(labels.findIndex(l => l.legend === newValue.label));
    }
  };

  const handleUpdateTags = tagMap => updateLabels(tagMap);

  const selectedValue =
    selected === null || typeof selected === "undefined" || !labels[selected]
      ? null
      : labels[selected].legend;

  useEffect(() => {
    if (selectRef && selected === null) {
      selectRef.current.focus();
    }
  }, [noImage, selected]);

  const handleClose = () => setDialogOpen(false);
  const handleOpen = () => setDialogOpen(true);

  return (
    <Grid
      container
      direction="row"
      spacing={8}
      justify="space-between"
      alignItems="center"
      className={classes.labels}
    >
      <TagListUpdateDialog
        open={dialogOpen}
        onClose={handleClose}
        labels={newTags}
        images={images.filter(image =>
          newTags.includes(image.labels.simpleLabels[0])
        )}
        updateTags={handleUpdateTags}
        saveImage={saveImage}
      />
      <Grid item key="select" xs={12} className={classes.padded}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={11}>
            <CreatableSelect
              isClearable={false}
              onChange={handleChange}
              options={labels.map(l => ({ value: l.legend, label: l.legend }))}
              formatCreateLabel={inputValue => `Créer le label ${inputValue}`}
              value={{ label: selectedValue, value: selectedValue }}
              autoFocus
              ref={selectRef}
              isValidNewOption={() => true} //() => user.authorities[0] === "ROLE_ADMIN"
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={handleOpen}
              disabled={newTags.length === 0}
              title={
                newTags.length === 0
                  ? null
                  : "Les nouveaux tags peuvent encore être modifiés"
              }
              fontSize="small"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {Object.keys(frequentLabels)
        .map(k => ({ legend: k, nombre: frequentLabels[k] }))
        .filter(l => l.nombre > 0)
        .sort((l1, l2) => {
          if (l2.nombre > l1.nombre) return 1;
          if (l2.nombre < l1.nombre) return -1;
          return 0;
        })
        .slice(0, frequentLabelsNumber - 1)
        .map(label => {
          const index = labels.findIndex(l => l.legend === label.legend);
          if (!labels[index]) return null;
          const color = lighten(
            labels[index].color,
            index === selected ? 0 : 0.5
          );
          return (
            <Grid
              item
              key={index}
              xs={6}
              className={classes.padded}
              onClick={() => onSelect(index)}
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{
                  border: `solid 3px ${color}`,
                  borderLeft: `solid ${
                    index === selected ? "10px" : "3px"
                  } ${color}`,
                  borderRadius: "3px"
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingLeft: index === selected ? 5 : 9,
                    fontSize: 16,
                    padding: "5px"
                  }}
                >
                  {label.legend}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

LabelsFromList.defaultProps = {
  hideVisibilityIcon: false
};

export default withStyles(styles)(withContext(LabelsFromList));
