import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import UiButton from "../common/UiButton";
import UiInput from "../common/UiInput";
import UiSelect from "../common/UiSelect";
import UiSInputButton from "../common/UiInputButton";
import UiFormTitle from "../common/UiFormTitle";
import TagList from "./components/TagList";
import ProjectUsersManager from "./components/ProjectUsersManager";
import Tags from "./components/Tags";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: `calc(100vh - 64px - ${theme.spacing.unit * 5}px)`,
    },
    paper: {
      minWidth: "100%",
      borderRadius: "5px",
    },
    header: {
      backgroundColor: theme.palette.primary.main,
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
      borderRadius: "5px 5px 0 0",
    },
    progress: {
      width: 250,
      minHeight: 40,
      margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
    },
    title: {
      color: "white",
    },
    form: {
      padding: `${theme.spacing.unit * 4}px`,
    },
    margin: {
      margin: theme.spacing.unit,
      fontWeight: 200,
    },
    label: {
      fontWeight: 200,
      display: "block",
      fontSize: 16,
    },
    sourceDonnees: {
      marginTop: theme.spacing.unit,
    },
    btnLink: {
      padding: "5px 0",
      backgroundColor: "transparent",
      textDecoration: "none",
      border: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      cursor: "pointer",
      marginRight: "-10px",
    },
    error: {
      color: "red",
      paddingTop: theme.spacing.unit,
    },
  });

const pDefault = {
  name: "",
  labelingTypes: [
    {
      name: "RECTANGLE_BOXING",
      tags: [],
    },
  ],
  dataSource: "S3",
  environment: "",
  indice: "",
  admins: [],
  tagListId: "",
  clientId: "",
};

const defaultLabellings: any = {
  PLATE: [{ name: "PLATE", tags: [] }],
  PLATE_TEXT: [{ name: "PLATE_TEXT", tags: [] }],
  RECTANGLE_BOXING: [{ name: "RECTANGLE_BOXING", tags: [] }],
  CLASSIF: [{ name: "CLASSIF", tags: [] }],
  SEGMENTATION: [{ name: "SEGMENTATION", tags: [] }],
};

const Project = (props: any) => {
  const {
    classes,
    clients,
    saveProject,
    checkProjectName,
    saveUser,
    history,
    indices,
    tagLists,
    project,
    projectId,
    users,
    location: { search },
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [viewMode, setViewMode] = useState<"form" | "users">(
    search.split("=")[1]
  );
  const [datas, setDatas] = useState<any>(pDefault);
  const [error, setError] = useState<string>("");
  const [tagDuplicated, setTagDuplicated] = useState(null);
  const [tagListMode, setTagListMode] = useState<boolean>(false);

  const indice = indices.find((i: any) => i.name === datas.indice);
  const envs = indice
    ? indice.types[0].envs
        .filter((e: any) => e.name !== "@timestamp")
        .map((e: any) => e.name)
    : [];

  const handleChange = (e: any) => {
    const value =
      e.target.name === "labelisationType"
        ? { labelingTypes: defaultLabellings[e.target.value] }
        : { [e.target.name]: e.target.value };
    setDatas({ ...datas, ...value });
  };

  const handleBlur = (e: any) => {
    if (datas.name !== "" && typeof project.id === "undefined") {
      checkProjectName(datas.name)
        .then(() => setError(""))
        .catch((e: any) => {
          setError("Ce nom de projet existe déjà");
        });
    } else {
      setError("");
    }
  };

  const addTag = (e: any) => {
    e.preventDefault();
    if (!datas.labelingTypes[0].tags.includes(datas.currentTag)) {
      setDatas({
        ...datas,
        currentTag: "",
        //@ts-ignore
        labelingTypes: [
          {
            ...datas.labelingTypes[0],
            tags: datas.labelingTypes[0].tags.concat([datas.currentTag]),
          },
        ],
      });
    } else {
      setDatas({ ...datas, currentTag: "" });
      setTagDuplicated(datas.currentTag);
    }
  };

  const setTags = (tags: any) => {
    setDatas({
      ...datas,
      currentTag: "",
      //@ts-ignore
      labelingTypes: [
        {
          ...datas.labelingTypes[0],
          tags: tags.map((t: any) => t.id),
        },
      ],
    });
  };

  const removeTag = (tag: string) => {
    setDatas({
      ...datas,
      labelingTypes: [
        {
          name: "RECTANGLE_BOXING",
          tags: datas.labelingTypes[0].tags.filter((t: string) => t !== tag),
        },
      ],
    });
  };

  const submit = (e: any) => {
    e.preventDefault();
    const { currentTag, ...project } = datas;
    saveProject(project).then(() =>
      setTimeout(() => history.push("/projects/0?sort=creationDate,desc"), 2000)
    );
  };

  const isValidated = () => {
    const {
      name,
      labelingTypes,
      dataSource,
      indice,
      environment,
      tagListId,
      clientId,
    } = datas;

    return (
      name !== "" &&
      error === "" &&
      clientId !== "" &&
      labelingTypes &&
      labelingTypes[0] &&
      (labelingTypes[0].name.indexOf("PLATE") !== -1 ||
        labelingTypes[0].tags.length > 0 ||
        tagListId !== "") &&
      (dataSource === "S3" ||
        (dataSource === "ES" && indice !== "" && environment !== ""))
    );
  };

  const toggleTagListMode = (e: any) => {
    try {
      e.preventDefault();
      if (tagListMode) {
        setDatas({ ...datas, tagListId: "" });
      } else {
        setDatas({
          ...datas,
          labelingTypes: [
            {
              name: "RECTANGLE_BOXING",
              tags: [],
            },
          ],
          tagListId: "",
        });
      }
      setTagListMode(!tagListMode);
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if (project.id) {
      const {
        name,
        labelingTypes,
        dataSource,
        esSourceConf,
        tagListId,
        client: { id: clientId },
      } = project;
      let environment = esSourceConf ? esSourceConf.esEnvironment : "";
      let indice = esSourceConf ? esSourceConf.esIndice : "";
      setDatas({
        name,
        labelingTypes,
        dataSource,
        environment,
        indice,
        tagListId,
        clientId,
      });
    }
  }, [project]);

  useEffect(() => {
    if (tagDuplicated !== null) {
      setTimeout(() => setTagDuplicated(null), 1000);
    }
  }, [tagDuplicated]);

  const labelisationTypeValue = datas.labelingTypes
    ? datas.labelingTypes[0].name
    : "";

  if (projectId && !project.id) return null;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Helmet>
        {viewMode !== "users" && <title>{project.id ? "Modifier un projet" : "Nouveau projet" }</title>}
        {viewMode === "users" && <title>Attribuer les utilisateurs à un projet</title> }
      </Helmet>
      <Grid item lg={6} md={8}>
        <Paper elevation={2} className={classes.paper}>
          <UiFormTitle>
            {project.id
              ? `${viewMode === "users" ? "Utilisateurs du p" : "P"}rojet ${
                  project.name
                }`
              : "NOUVEAU PROJET"}
          </UiFormTitle>
          {viewMode === "users" && (
            <ProjectUsersManager
              history={history}
              projectId={project.id}
              users={users}
              saveUser={saveUser}
            />
          )}
          {viewMode !== "users" && (
            <form>
              <Grid
                container
                spacing={40}
                direction="row"
                justify="space-between"
                alignItems="stretch"
                className={classes.form}
              >
                <Grid item sm={6}>
                  <Grid container direction="column" spacing={16}>
                    <Grid item>
                      <UiInput
                        label="Nom du projet"
                        name="name"
                        autoFocus
                        required
                        onChange={handleChange}
                        value={datas.name}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item>
                      <UiSelect
                        name="labelisationType"
                        required
                        disabled={typeof project.id !== "undefined"}
                        value={labelisationTypeValue}
                        label="Sélectionnez le type de labellisation"
                        onChange={handleChange}
                      >
                        <option value="" />
                        {[
                          { id: "RECTANGLE_BOXING", text: "RECTANGLE BOXING" },
                          { id: "PLATE", text: "PLATE" },
                          { id: "PLATE_TEXT", text: "PLATE_TEXT" },
                          { id: "CLASSIF", text: "CLASSIFICATION" },
                          { id: "SEGMENTATION", text: "SEGMENTATION" },
                        ].map((f: any, index: number) => (
                          <option key={index} value={f.id}>
                            {f.text}
                          </option>
                        ))}
                      </UiSelect>
                    </Grid>
                    <Grid item>
                      <UiSelect
                        name="clientId"
                        required
                        disabled={typeof project.id !== "undefined"}
                        value={datas.clientId}
                        label="Client"
                        onChange={handleChange}
                      >
                        <option value="" />
                        {clients.map((c: any) => (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </UiSelect>
                    </Grid>
                    <Grid item />
                  </Grid>
                </Grid>
                <Grid item sm={6}>
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                    style={{ height: "100%" }}
                    component="form"
                    onSubmit={addTag}
                  >
                    {(labelisationTypeValue === "RECTANGLE_BOXING" ||
                      labelisationTypeValue === "SEGMENTATION" ||
                      labelisationTypeValue === "CLASSIF") && (
                      <Grid item>
                        {typeof project.id !== "undefined" && (
                          <Typography className={classes.label}>
                            Liste de tags :
                          </Typography>
                        )}
                        {typeof project.id === "undefined" && !tagListMode && (
                          <UiSInputButton
                            ref={inputRef}
                            name="currentTag"
                            type="text"
                            placeHolder="Rentrez la liste des labels"
                            value={datas.currentTag}
                            onChange={handleChange}
                            buttonLabel="+"
                            buttonDisabled={
                              datas.currentTag === "" ||
                              typeof project.id !== "undefined"
                            }
                            label="Labels"
                            required
                            typeButton="submit"
                          />
                        )}
                        {tagListMode && (
                          <label className={classes.label}>
                            Choisissez une liste de tags :
                          </label>
                        )}
                        {tagListMode && (
                          <TagList
                            value={datas.tagListId}
                            onChange={handleChange}
                            disabled={typeof project.id !== "undefined"}
                            tagLists={tagLists}
                            tagListId={datas.tagListId}
                          />
                        )}
                        {!tagListMode &&
                          datas.labelingTypes &&
                          datas.labelingTypes[0].tags.length > 0 && (
                              <Tags
                                datas={datas.labelingTypes[0].tags.map(
                                  (t: any) => ({ id: t, selected: false })
                                )}
                                removeTag={removeTag}
                                projectId={project.id}
                                setList={setTags}
                                currentTag={tagDuplicated}
                              />
                          )}
                        {typeof project.id === "undefined" &&
                          (labelisationTypeValue === "RECTANGLE_BOXING" ||
                            labelisationTypeValue === "CLASSIF") && (
                            <div style={{ textAlign: "right" }}>
                              <button
                                className={classes.btnLink}
                                onClick={toggleTagListMode}
                              >
                                {tagListMode
                                  ? "Tags manuels"
                                  : "Utiliser une liste de tags"}
                              </button>
                            </div>
                          )}
                      </Grid>
                    )}
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <FormControl className={classes.formControl}>
                            <label className={classes.label}>
                              Choisissez votre source de données :
                            </label>
                            <RadioGroup
                              aria-label="dataSource"
                              name="dataSource"
                              className={classes.sourceDonnees}
                              value={datas.dataSource}
                              onChange={handleChange}
                              row
                            >
                              <FormControlLabel
                                value="S3"
                                control={<Radio />}
                                disabled={typeof project.id !== "undefined"}
                                label="Dossier local"
                              />
                              <FormControlLabel
                                value="ES"
                                control={<Radio />}
                                disabled={typeof project.id !== "undefined"}
                                label="Elastic Search"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {datas.dataSource === "ES" && (
                          <Grid item>
                            <Grid container direction="column" spacing={8}>
                              <Grid item>
                                <UiSelect
                                  name="indice"
                                  required
                                  value={datas.indice}
                                  disabled={
                                    datas.dataSource !== "ES" ||
                                    typeof project.id !== "undefined"
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="">
                                    Sélectionnez l'index ES
                                  </option>
                                  {indices.map((indice: any) => (
                                    <option
                                      key={indice.name}
                                      value={indice.name}
                                    >
                                      {indice.name}
                                    </option>
                                  ))}
                                </UiSelect>
                              </Grid>
                              {envs && (
                                <Grid item>
                                  <UiSelect
                                    name="environment"
                                    required
                                    value={datas.environment}
                                    disabled={
                                      datas.dataSource !== "ES" ||
                                      typeof project.id !== "undefined"
                                    }
                                    onChange={handleChange}
                                  >
                                    <option value="">
                                      {datas.indice !== ""
                                        ? "Sélectionnez l'environment"
                                        : ""}
                                    </option>
                                    {envs.map((env: any) => (
                                      <option key={env} value={env}>
                                        {env}
                                      </option>
                                    ))}
                                  </UiSelect>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <Grid
                    container
                    direction="row"
                    justify={error !== "" ? "space-between" : "flex-end"}
                    spacing={8}
                  >
                    <Grid item sm={8}>
                      {error !== "" && (
                        <Slide direction="up" in>
                          <Typography className={classes.error}>
                            {error}
                          </Typography>
                        </Slide>
                      )}
                    </Grid>
                    <Grid item sm={4}>
                      <UiButton
                        style={{ marginTop: 8 }}
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disabled={!isValidated()}
                        onClick={submit}
                      >
                        Valider
                      </UiButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(Project));
