import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import UiButton from "../common/UiButton";
import UiInput from "../common/UiInput";
import AlertDialog from "../common/AlertDialog";
import withContext from "../../tools/withContext";
import constants from "../../tools/constants";

const styles = (theme: Theme) =>
  createStyles({
    rootLogged: {
      height: "100%"
    },
    input: {
      width: "100%",
      borderRadius: ".35em",
      height: "2.5em",
      padding: "0.5em",
      lineHeight: "2.5em"
    },
    errorLine: {
      height: "25px"
    },
    button: {
      color: "#FFF"
    },
    mT: {
      marginTop: theme.spacing.unit * 2
    },
    mdpTitre: {
      textAlign: "left",
      marginBottom: "1.3em",
      color: theme.palette.primary.main
    }
  });

const ChangePassword = (props: any) => {
  const {
    history,
    ctx: { appFetch },
    classes
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (password !== passwordConfirm) {
        setError("Les mots de passe sont différents");
        setIsSubmitting(false);
        return;
      }
      const { CHANGE_PASSWORD } = constants.paths;

      await appFetch(CHANGE_PASSWORD, {
        method: "POST",
        body: JSON.stringify({ currentPassword, newPassword: password })
      });

      setIsDialogOpen(true);
      setIsSubmitting(false);

    } catch (e) {
      setIsSubmitting(false);
      setError(e.message);
    }
  };

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleChangeCurrentPassword = (e: any) => {
    setCurrentPassword(e.target.value);
  };

  const handleChangePasswordConfirm = (e: any) => {
    setPasswordConfirm(e.target.value);
  };

  const closeAlert = () => setIsDialogOpen(false);

  const handeDone = () =>  {
    const { search } = props.location;
    const { redirectPathname, redirectQuery } = queryString.parse(search);

    history.push(`${redirectPathname}${redirectQuery}`);
  }

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.rootLogged}
    >
      <AlertDialog
        title="Réinitialisation de votre mot de passe"
        text={`Votre mot de passe a bien été modifié.`}
        open={isDialogOpen}
        onClose={closeAlert}
        onAccept={handeDone}
        acceptLabel="OK"
      />
      <Grid item xs={3}>
        <Paper elevation={2} style={{ padding: "2em" }}>
          <Grid
            container
            direction="column"
            spacing={24}
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid item className={classes.mdpTitre}>
              Modification de votre mot de passe
            </Grid>
            <Grid item>
              <UiInput
                label="Mot de passe actuel"
                name="currentPassord"
                placeholder="Mot de passe actuel"
                type="password"
                autoFocus
                onChange={handleChangeCurrentPassword}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item>
              <UiInput
                name="password"
                placeholder="Mot de passe"
                label="Nouveau mot de passe"
                type="password"
                autoFocus
                onChange={handleChangePassword}
              />
            </Grid>
            <Grid item>
              <UiInput
                name="passwordConfirm"
                placeholder="Mot de passe (confirmation)"
                label="Nouveau mot de passe (confirmation)"
                type="password"
                onChange={handleChangePasswordConfirm}
              />
            </Grid>
            <Grid item className={classes.errorLine}>
              <Typography>{error || ""}</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.mT}
              >
                <Grid sm={6}>
                  <UiButton
                    variant="contained"
                    fullWidth
                    color="secondary"
                    type="submit"
                    className={classes.button}
                  >
                    {isSubmitting ? "Modification..." : "Modifier"}
                  </UiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withRouter(withContext(ChangePassword)));
