import React from "react";
import IconButton from "@material-ui/core/IconButton";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = (theme: Theme) =>
  createStyles({
    title: {
      padding: `0 ${theme.spacing.unit * 3}px`,
      marginTop: theme.spacing.unit * 2,
      fontWeight: 600
    },
    listBoxes: {
      listStyleType: "none",
      marginTop: theme.spacing.unit * 1,
      paddingLeft: theme.spacing.unit * 3
    },
    listItem: {
      display: "inline-block",
      marginRight: theme.spacing.unit * 2
    },
    listItemText: {
      display: "inherit",
      cursor: "pointer",
      transition: "all .1s ease-out",
      "&:hover": {
         transform: "scale(1.1)"
      }
    },
    icon: {
      padding: 0
    }
  });

const Superpositions = (props: any) => {
  const { classes, superpositions, higlighting, onClear } = props;
  const superpositionsOnly = superpositions.filter(
    (arrBox: any) => arrBox.length > 1
  );
  return (
    <Grid container direction="column">
      <Grid item className={classes.title}>
        <Grid container direction="row">
          <Grid item>
            <WarningIcon />
          </Grid>
          <Grid item>
            <Typography
              style={{ fontWeight: 600, padding: "4px 2px" }}            
            >
              Superposition{superpositionsOnly.length > 0 && "s"} détectée
              {superpositionsOnly.length > 0 && "s"} :
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ul className={classes.listBoxes}>
          {superpositionsOnly.map((arrSup: any[]) => (
            <li key={arrSup[0].get("id")} className={classes.listItem}>
              <Typography
                component="span" className={classes.listItemText}
                onMouseEnter={() => higlighting(arrSup[0].get("id"), true)}
                onMouseLeave={() => higlighting(arrSup[0].get("id"), false)}                
              >
                {arrSup.length} boxes{" "}
                <IconButton
                  onClick={() => onClear(arrSup[0].get("id"))}
                  className={classes.icon}
                  title="Conserver une seule box"
                >
                  <LayersClearIcon fontSize="small" />
                </IconButton>
              </Typography>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Superpositions);
