import * as React from 'react';
import {
    format,
    getYear,
    getMonth,
    isSameMonth,
    isSameDay,
    getDaysInMonth,
    startOfMonth,
    subMonths,
    addMonths,
    getDay,
    setDate,
    setMonth,
    setYear,
    getHours,
    getMinutes,
    getSeconds,
    getDate,
    setMinutes,
    setHours,
    setSeconds,
    isBefore,
    isEqual,
    isAfter,
} from 'date-fns';
import * as frLocal from 'date-fns/locale/fr';

import styled, { css } from 'styled-components';

import CalendarIcon from '../../icons/calendar.svg';
import ArrowLeft from '../../icons/arrow_left.svg';
import ArrowRight from '../../icons/arrow_right.svg';
import DbArrowLeft from '../../icons/db_arrow_left.svg';
import DbArrowRight from '../../icons/db_arrow_right.svg';

enum CSS {
    BLACK = '#000000',
    WHITE = '#ffffff',
    GREY = '#C6C6C6',
    DARKGREY = '#979797',
    COLOR = '#4DA1FF',
    BORDERCOLOR = '#E9EFF4',
    BORDER_RADIUS = '6px',
    PADDING = '20px',
}

const Heading = ({
    date,
    changeMonth,
    changeYear,
    resetDate,
    isSmall,
}: any) => (
    <CalenderNav isSmall={isSmall} className="calendar--nav">
        <CalendarNavLinkYear
            isSmall={isSmall}
            onClick={() => changeYear(getYear(date) - 1)}>
            <IconContainer src={DbArrowRight} />
        </CalendarNavLinkYear>

        <CalendarNavLink
            isSmall={isSmall}
            onClick={() => changeMonth(getMonth(date) - 1)}>
            <IconContainer src={ArrowRight} />
        </CalendarNavLink>
        <CalendarNavHeader onClick={() => resetDate()}>
            {format(date, 'MMMM', { locale: frLocal })
                .charAt(0)
                .toUpperCase() +
                format(date, 'MMMM', { locale: frLocal }).slice(1)}{' '}
            {format(date, 'YYYY', { locale: frLocal })}
        </CalendarNavHeader>

        <CalendarNavLink
            isSmall={isSmall}
            isRight={true}
            onClick={() => changeMonth(getMonth(date) + 1)}>
            <IconContainer src={ArrowLeft} />
        </CalendarNavLink>
        <CalendarNavLinkYear
            isSmall={isSmall}
            isRight={true}
            onClick={() => changeYear(getYear(date) + 1)}>
            <IconContainer src={DbArrowLeft} />
        </CalendarNavLinkYear>
    </CalenderNav>
);

const Day = ({
    currentDate,
    date,
    startDate,
    endDate,
    onClick,
    onDragStart,
    onDragLeave,
    isSmall,
}: any) => {
    const handleOnClick = () => onClick(date);
    const handleOnDragStart = (e: any) => {
        onDragStart(date);
    };

    const handleOnDragLeave = (e: any) => {
        onDragLeave(date);
    };

    return (
        <DaySpan
            isSmall={isSmall}
            draggable={true}
            current={isSameMonth(new Date(), date)}
            active={isSameDay(new Date(), date)}
            muted={!isSameMonth(currentDate, date)}
            start={isSameDay(startDate, date)}
            end={isSameDay(endDate, date)}
            between={isAfter(date, startDate) && isBefore(date, endDate)}
            onClick={handleOnClick}
            onDragLeave={handleOnDragLeave}
            onDragStart={handleOnDragStart}>
            {getDate(date)}
        </DaySpan>
    );
};

const Days = ({
    date,
    startDate,
    endDate,
    onClick,
    onDragStart,
    onDragLeave,
    isSmall,
}: any) => {
    const thisDate = date;
    const daysInMonth = getDaysInMonth(date);
    const firstDayDate = startOfMonth(date);
    const previousMonth = subMonths(date, 1);
    const previousMonthDays = getDaysInMonth(previousMonth);
    const nextsMonth = addMonths(date, 1);

    const days = [];
    const labelsl = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM'];
    const labels = [];

    for (let i = 0; i < 7; i++) {
        labels.push(
            <DayLabel isSmall={isSmall} key={'key:' + i}>
                {labelsl[i]}
            </DayLabel>,
        );
    }

    for (let i = getDay(firstDayDate); i > 1; i--) {
        setDate(previousMonth, previousMonthDays - i + 2);

        days.push(
            <Empty key={format(previousMonth, 'DD MM YYYY') + 'i' + i} />,
        );
    }

    for (let i = 1; i <= daysInMonth; i++) {
        setDate(thisDate, i);
        const newDate = setDate(thisDate, i);

        days.push(
            <Day
                isSmall={isSmall}
                key={format(newDate, 'DD MM YYYY') + 'i' + i}
                onClick={onClick}
                onDragStart={onDragStart}
                onDragLeave={onDragLeave}
                currentDate={date}
                date={newDate}
                startDate={startDate}
                endDate={endDate}
            />,
        );
    }

    const daysCount = days.length;

    for (let i = 1; i <= 42 - daysCount; i++) {
        setDate(nextsMonth, i);
        days.push(<Empty key={format(nextsMonth, 'DD MM YYYY') + 'i' + i} />);
    }

    return (
        <CalendarDays isSmall={isSmall}>
            {labels.concat()}
            {days.concat()}
        </CalendarDays>
    );
};

export const HoursC = () => {
    return <></>;
};

export const Calendar = ({
    istartDate,
    iendDate,
    handleChangeStartDate,
    handleChangeEndDate,
    error = false,
    isAm = true,
    isSeconds = true,
    isSmall = true,
}: any) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const [date, setLDate] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState(istartDate);
    const [endDate, setEndDate] = React.useState(iendDate);

    const [startDateAM, setStartDateAM] = React.useState(true);
    const [endDateAM, setEndDateAM] = React.useState(true);

    React.useEffect(() => {
        setStartDate(istartDate);
        setEndDate(iendDate);
    }, [istartDate, iendDate]);

    React.useEffect(() => {
        setLDate(date);
    }, [date]);

    const handleResetDate = () => {
        setLDate(new Date());
    };

    const handleChangeMonth = (month: any) => {
        const newDate = setMonth(date, month);
        setLDate(newDate);
    };

    const handleChangeYear = (year: any) => {
        const newDate = setYear(date, year);
        setLDate(newDate);
    };

    const noop = () => {
        return;
    };

    const changeStartDate = (newDate: any, time: boolean = true) => {
        let d = newDate;

        if (time) {
            d = setHours(d, getHours(startDate));
            d = setMinutes(d, getMinutes(startDate));
            d = setSeconds(d, getSeconds(startDate));
        }

        handleChangeStartDate(d);
    };

    const changeEndDate = (newDate: any, time: boolean = true) => {
        let d = newDate;

        if (time) {
            d = setHours(d, getHours(endDate));
            d = setMinutes(d, getMinutes(endDate));
            d = setSeconds(d, getSeconds(endDate));
        }

        handleChangeEndDate(d);
    };

    const handleChangeDate = (newDate: any) => {
        if (
            startDate === null ||
            isBefore(newDate, startDate) ||
            !isSameDay(startDate, endDate)
        ) {
            changeEndDate(newDate);
            changeStartDate(newDate);
        } else if (isAfter(newDate, startDate)) {
            changeEndDate(newDate);
        } else if (isEqual(newDate, startDate) && isEqual(newDate, endDate)) {
            changeEndDate(startDate);
            changeStartDate(startDate);
        }
    };

    const handleDragStart = (newDate: any) => {
        changeStartDate(newDate);
    };

    const handleDragEnd = (newDate: any) => {
        if (isBefore(newDate, startDate)) {
            if (isBefore(endDate, newDate)) {
                changeEndDate(newDate);
            }
            changeStartDate(newDate);
        } else {
            changeEndDate(newDate);
        }
    };

    const handleChangeStartDateTime = (e: any, target: string) => {
        let newDate = startDate;
        const value = e.target.value;

        if (target === 'h') {
            if (isAm) {
                if (startDateAM) {
                    newDate = setHours(newDate, Number(value));
                } else {
                    newDate = setHours(newDate, Number(value) + 12);
                }
            } else {
                newDate = setHours(newDate, Number(value));
            }
        } else if (target === 'm') {
            newDate = setMinutes(newDate, Number(value));
        } else if (target === 's') {
            newDate = setSeconds(newDate, Number(value));
        }

        changeStartDate(newDate, false);
        // setStartDate(newDate);
    };

    const handleChangeeEndDateTime = (e: any, target: string) => {
        let newDate = endDate;
        const value = e.target.value;

        if (target === 'h') {
            if (isAm) {
                if (startDateAM) {
                    newDate = setHours(newDate, Number(value));
                } else {
                    newDate = setHours(newDate, Number(value) + 12);
                }
            } else {
                newDate = setHours(newDate, Number(value));
            }
        } else if (target === 'm') {
            newDate = setMinutes(newDate, Number(value));
        } else if (target === 's') {
            newDate = setSeconds(newDate, Number(value));
        }

        changeEndDate(newDate, false);
        // setEndDate(newDate);
    };

    return (
        <FormItemContainer>
            <DropDowContainerHeader
                error={error}
                onClick={() => setIsVisible(!isVisible)}>
                <Input
                    isSmall={isSmall}
                    type="text"
                    value={`${format(startDate, 'Do MMMM YYYY', {
                        locale: frLocal,
                    })} - ${format(endDate, 'Do MMMM YYYY', {
                        locale: frLocal,
                    })}`}
                    placeholder="Entrez une date"
                    onChange={noop}
                />
                <Icon src={CalendarIcon} />
            </DropDowContainerHeader>
            <CalendarContainer
                isSmall={isSmall}
                onMouseLeave={() => setIsVisible(false)}
                isVisible={isVisible}>
                <Heading
                    isSmall={isSmall}
                    date={date}
                    changeMonth={handleChangeMonth}
                    changeYear={handleChangeYear}
                    resetDate={handleResetDate}
                />
                <Days
                    isSmall={isSmall}
                    onClick={handleChangeDate}
                    onDragStart={handleDragStart}
                    onDragLeave={handleDragEnd}
                    date={date}
                    startDate={startDate}
                    endDate={endDate}
                />
                <Hours isSmall={isSmall}>
                    <HoursContainer>
                        <Form>
                            <Label isSmall={isSmall} width={'60px'}>
                                Début :
                            </Label>
                            <TimeInput
                                isSmall={isSmall}
                                type="number"
                                min={0}
                                max={isAm ? (startDateAM ? 12 : 11) : 23}
                                value={
                                    isAm && getHours(startDate) > 12
                                        ? getHours(startDate) - 12
                                        : getHours(startDate)
                                }
                                onChange={e =>
                                    handleChangeStartDateTime(e, 'h')
                                }
                            />

                            <Label isSmall={isSmall} width={'3px'}>
                                :
                            </Label>
                            <TimeInput
                                isSmall={isSmall}
                                type="number"
                                min={0}
                                max={59}
                                value={getMinutes(startDate)}
                                onChange={e =>
                                    handleChangeStartDateTime(e, 'm')
                                }
                            />

                            {isSeconds && (
                                <>
                                    <Label isSmall={isSmall} width={'3px'}>
                                        :
                                    </Label>
                                    <TimeInput
                                        isSmall={isSmall}
                                        type="number"
                                        min={0}
                                        max={59}
                                        value={getSeconds(startDate)}
                                        onChange={e =>
                                            handleChangeStartDateTime(e, 's')
                                        }
                                    />
                                </>
                            )}

                            {isAm && (
                                <>
                                    <Sep width={'30px'} height={'1px'} />
                                    <RadioButton
                                        isSmall={isSmall}
                                        onClick={() => setStartDateAM(true)}
                                        active={startDateAM}>
                                        AM
                                    </RadioButton>
                                    <RadioButton
                                        isSmall={isSmall}
                                        onClick={() => setStartDateAM(false)}
                                        active={!startDateAM}>
                                        PM
                                    </RadioButton>
                                </>
                            )}
                        </Form>
                        <Form>
                            <Label isSmall={isSmall} width={'60px'}>
                                Fin :
                            </Label>
                            <TimeInput
                                isSmall={isSmall}
                                type="number"
                                min={0}
                                max={isAm ? (endDateAM ? 12 : 11) : 23}
                                value={
                                    isAm && getHours(endDate) > 12
                                        ? getHours(endDate) - 12
                                        : getHours(endDate)
                                }
                                onChange={e => handleChangeeEndDateTime(e, 'h')}
                            />

                            <Label isSmall={isSmall} width={'3px'}>
                                :
                            </Label>
                            <TimeInput
                                isSmall={isSmall}
                                type="number"
                                min={0}
                                max={59}
                                value={getMinutes(endDate)}
                                onChange={e => handleChangeeEndDateTime(e, 'm')}
                            />

                            {isSeconds && (
                                <>
                                    <Label isSmall={isSmall} width={'3px'}>
                                        :
                                    </Label>
                                    <TimeInput
                                        isSmall={isSmall}
                                        type="number"
                                        min={0}
                                        max={59}
                                        value={getSeconds(endDate)}
                                        onChange={e =>
                                            handleChangeeEndDateTime(e, 's')
                                        }
                                    />
                                </>
                            )}

                            {isAm && (
                                <>
                                    <Sep width={'30px'} height={'1px'} />
                                    <RadioButton
                                        isSmall={isSmall}
                                        onClick={() => setEndDateAM(true)}
                                        active={endDateAM}>
                                        AM
                                    </RadioButton>
                                    <RadioButton
                                        isSmall={isSmall}
                                        onClick={() => setEndDateAM(false)}
                                        active={!endDateAM}>
                                        PM
                                    </RadioButton>
                                </>
                            )}
                        </Form>
                    </HoursContainer>
                </Hours>
            </CalendarContainer>
        </FormItemContainer>
    );
};

interface SmallProps {
    isSmall?: boolean;
}

interface CalendarContainerProps {
    isVisible?: boolean;
    isSmall?: boolean;
}
//@ts-ignore
const CalendarContainer = styled.div`
    width: ${(props: CalendarContainerProps) =>
        props.isSmall ? '320px' : '360px'};
    padding: 0;
    margin-bottom: ${(props: CalendarContainerProps) =>
        props.isSmall ? '5px': '10px'};
    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: ${CSS.BORDER_RADIUS};
    overflow: hidden;
    background: ${CSS.WHITE};
    position: absolute;
    z-index: 10000;
    top: 60px;
    top: ${(props: CalendarContainerProps) =>
        props.isSmall ? '30px': '60px'};
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
    transform-origin: top center;
    transition: transform 0.3s ease, opacity 0.2s ease, visibility 0s ease 0.3s;
    display: flex;
    flex-direction: column;

    ${(props: CalendarContainerProps) =>
        props.isVisible &&
        css`
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            transition: transform 0.3s ease, opacity 0.2s ease,
                visibility 0s ease;
        `}
`;

const FormItemContainer = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
`;

const Input = styled.input`
    top: 0;
    left: 0;
    border: none;
    overflow: hidden !important;
    padding: 0;
    height: 40px;
    min-width: ${(props: SmallProps) => (props.isSmall ? '420px' : '420px')};
    padding-left: 20px;
    font-size: 1.1em;
    border-radius: ${CSS.BORDER_RADIUS};
    border: 1px solid ${CSS.COLOR + '20'};
    transition: box-shadow 0.3s ease;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &:focus {
        outline: none;
    }

    &:disabled {
        background: ${CSS.GREY};
    }

    &:hover ${CalendarContainer} {
        transform: scaleY(1) scaleX(1);
    }
`;

const CalenderNav = styled.nav`
    margin: 0;
    margin-top: ${(props: SmallProps) => (props.isSmall ? '10px' : '30px')};
    padding: 0;
    background-color: ${CSS.WHITE};
    height: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

interface CalendarNavLinkProps {
    isRight?: boolean;
    isSmall?: boolean;
}

const CalendarNavLink = styled.a`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: ${(props: CalendarNavLinkProps) =>
        props.isRight ? 'auto' : props.isSmall ? '50px' : '70px'};
    right: ${(props: CalendarNavLinkProps) =>
        props.isRight ? (props.isSmall ? '50px' : '70px') : 'auto'};
    width: 30px;
    height: 50px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
`;

const CalendarNavLinkYear = styled.a`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: ${(props: CalendarNavLinkProps) =>
        props.isRight ? 'auto' : props.isSmall ? '20px' : '40px'};
    right: ${(props: CalendarNavLinkProps) =>
        props.isRight ? (props.isSmall ? '20px' : '40px') : 'auto'};
    width: 30px;
    height: 50px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
`;

const CalendarNavHeader = styled.h1`
    margin: 0;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.4em;
    color: ${CSS.BLACK};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

interface DropDowContainerHeaderProps {
    error?: boolean;
}

const DropDowContainerHeader = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 420px;
    height: 40px;
    z-index: 100;
    display: flex;
    align-items: center;
    align-content: center;
    background-color: ${CSS.WHITE};
    padding-left: 0px;
    border-radius: ${CSS.BORDER_RADIUS};
    overflow: hidden;
    border: 1px solid ${CSS.BORDERCOLOR};
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    ::before {
        content: '';
        position: absolute;
        height: 40px;
        width: 50px;
        right: -2px;
        top: 0;
        background-color: ${CSS.COLOR + '20'};
        border-left: 1px solid ${CSS.BORDERCOLOR};
        border-radius: 0 ${CSS.BORDER_RADIUS} ${CSS.BORDER_RADIUS} 0;
        cursor: pointer;
    }

    ${(props: DropDowContainerHeaderProps) =>
        props.error &&
        css`
            border: 1px solid red;
        `}
`;

const Icon = styled.img`
    position: absolute;
    right: 15px;
    width: 20px;
    object-fit: contain;
    transition: transform 0.33s ease;
    cursor: pointer;
`;

const Hours = styled.div`
    margin-top: ${(props: SmallProps) => (props.isSmall ? '5px' : '30px')};
    width: 100%;
    border-top: 1px solid ${CSS.BORDERCOLOR};
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
`;

const HoursContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
`;

const Form = styled.form`
    margin-bottom: ${(props: SmallProps) => (props.isSmall ? '5px' : '10px')};
    margin-top: ${(props: SmallProps) => (props.isSmall ? '5px' : '10px')};
    display: flex;
    align-content: center;
    align-items: center;
`;

interface LabelProps {
    width: string;
    isSmall?: boolean;
}
const Label = styled.label`
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
    color: ${CSS.DARKGREY};
    font-size: ${(props: LabelProps) => (props.isSmall ? '11px' : '14px')};
    width: ${props => props.width};
    letter-spacing: 1px;
    font-weight: 400;
`;

const TimeInput = styled.input`
    border: 1px solid ${CSS.COLOR + '00'};
    border-radius: ${CSS.BORDER_RADIUS};
    overflow: hidden !important;
    padding: 0;
    font-size: ${(props: SmallProps) => (props.isSmall ? '11px' : '1em')};
    padding-left: 5px;
    padding-right: 5px;
    height: ${(props: SmallProps) => (props.isSmall ? '20px' : '35px')};
    background: ${CSS.COLOR + '20'};
    color: ${CSS.DARKGREY};
    font-weight: 400;

    &:focus {
        outline: none;
        border: 1px solid ${CSS.COLOR};
    }

    &:disabled {
        background: ${CSS.GREY};
    }

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
`;

interface RadioButtonProps {
    active?: boolean;
    isSmall?: boolean;
}

const RadioButton = styled.div`
    width: ${(props: RadioButtonProps) => (props.isSmall ? '28px' : '35px')};
    height: ${(props: RadioButtonProps) => (props.isSmall ? '28px' : '35px')};
    font-size: ${(props: RadioButtonProps) => (props.isSmall ? '11px' : '1em')};
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: ${CSS.DARKGREY};
    font-weight: 400;
    border-radius: ${CSS.BORDER_RADIUS};
    background-color: ${CSS.COLOR + '20'};
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid transparent;

    ${(props: RadioButtonProps) =>
        props.active &&
        css`
            border: 1px solid ${CSS.COLOR};
        `};
`;

interface SepProps {
    width: string;
    height: string;
    isSmall?: boolean;
}

const Sep = styled.div`
    width: ${(props: SepProps) => props.width};
    height: ${props => props.height};
`;

const DayLabel = styled.div`
    text-transform: uppercase;
    font-weight: 400;
    color: #808080;
    font-size: ${(props: SmallProps) => (props.isSmall ? '11px' : '14px')};
    color: darkgrey;
    margin-bottom: ${(props: SmallProps) => (props.isSmall ? '10px' : '18px')};
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
`;

const IconContainer = styled.img`
    width: 12px;
    height: 12px;
    object-fit: contain;
    transition: transform 0.33s ease;
    cursor: pointer;
`;

interface DaySpanProps {
    current: boolean;
    active: boolean;
    muted: boolean;
    start: boolean;
    end: boolean;
    between: boolean;
    isSmall?: boolean;
}

const DaySpan = styled.span`
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 13px;
    height: ${(props: DaySpanProps) => (props.isSmall ? '32px' : '35px')};
    border-radius: ${CSS.BORDER_RADIUS};
    color: ${(props: DaySpanProps) =>
        props.start || props.end
            ? CSS.WHITE
            : props.muted
            ? CSS.GREY
            : CSS.DARKGREY};
    background-color: ${props =>
        props.start || props.end
            ? CSS.COLOR
            : props.between
            ? CSS.COLOR + '20'
            : props.active
            ? CSS.COLOR + '40'
            : CSS.WHITE};
    font-weight: ${props =>
        props.active ? '700' : props.muted ? '300' : '400'};
`;

const Empty = styled.div`
    width: 0;
    height: 0;
`;

const CalendarDays = styled.nav`
    font-size: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: ${(props: SmallProps) => (props.isSmall ? '7px' : '15px')} 5px;
    width: 275px;
    align-self: center;
    justify-self: center;
`;
