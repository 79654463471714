import React from "react";
import { withRouter } from "react-router-dom";
import { fabric } from "fabric";
import { withStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import fitInBox, { size } from "../../../tools/fitInBox";

const styles = (theme: Theme) =>
  createStyles({
    canvasContainer: {
      backgroundColor: "#edf2ff",
      margin: "0 auto",
      width: "100%",
      height: "100%",
    },
  });

const SEPARATOR = "||";

class ClassifLabellisation extends React.Component<any, any> {
  private validationStateChangeKeys: string[] = ["a", "z", "e", "q", "s", "d"];
  private canvasRef = React.createRef<HTMLCanvasElement>();
  private canvasWrapperRef = React.createRef<HTMLDivElement>();
  private image: any;
  private canvas: any;
  private scaling: any;
  private textBox: any;

  constructor(props: any) {
    super(props);
    this.state = {
      selected: false,
      polygons: [],
    };
  }

  componentDidMount = () => {
    if (this.canvasRef.current !== null) {
      //@ts-ignore
      this.configureFabricJSCanvas(this.canvasRef.current).then(
        (canvas: any) => {
          this.canvas = canvas;
          this.bindEvents();
          this.loadImage();
        }
      );
    }
  };

  componentWillReceiveProps = (nextProps: any) => {
    const { image, keyJustPressed, index } = this.props;
    if (image.id !== nextProps.image.id) {
      this.loadImage();
    }

    if (
      keyJustPressed === null &&
      nextProps.keyJustPressed !== null &&
      this.validationStateChangeKeys.indexOf(nextProps.keyJustPressed) === index
    ) {
      this.toggleValidation();
    }
  };

  bindEvents = () => {
    this.canvas.on("mouse:up", this.toggleValidation);
    this.canvas.on("mouse:dblclick", this.handleDblClick);
    this.canvas.on("mouse:wheel", this.handleMouseWheel);
  };

  handleMouseWheel = (o: any) => {
    const delta = o.e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom = zoom + delta / 100;

    if (zoom <= 1) {
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    } else {
      if (zoom > 20) zoom = 20;
      this.canvas.zoomToPoint({ x: o.e.offsetX, y: o.e.offsetY }, zoom);
    }

    o.e.preventDefault();
    o.e.stopPropagation();
  };

  handleDblClick = () => {
    const { history, index, noBatch } = this.props;
    history.push(`/labeler/${noBatch}/${index}`);
  };

  toggleValidation = (e: any = null) => {
    const selected = !this.state.selected;
    this.setState({ selected });
    if (!selected) {
      this.removeByType("line");
    } else {
      this.addSelection();
    }
  };

  addSelection = () => {
    const { newWidth, newHeight } = this.scaling;
    const fill = "red";
    const ligne1 = new fabric.Line([-5, -5, newWidth, newHeight], {
      fill,
      stroke: fill,
      strokeWidth: 8,
      hoverCursor: "pointer",
      selectable: false,
      evented: false,
    });
    const ligne2 = new fabric.Line([newWidth, 0, 0, newHeight], {
      fill,
      stroke: fill,
      strokeWidth: 8,
      hoverCursor: "pointer",
      selectable: false,
      evented: false,
    });
    this.canvas.add(ligne1);
    this.canvas.add(ligne2);
  };

  removeByType = (type: string) =>
    this.canvas
      .getObjects()
      .filter((o: any) => o.type === type)
      .forEach((o: any) => this.canvas.remove(o));

  configureFabricJSCanvas = (ref: HTMLCanvasElement) =>
    new Promise((resolve: any) => {
      const canvas = new fabric.Canvas(ref);
      const { width, height } = this.props;
      setTimeout(() => {
        //@ts-ignore
        const { offsetHeight, offsetWidth } = this.canvasWrapperRef.current;
        const taille: size = fitInBox(
          width,
          height,
          offsetWidth,
          offsetHeight,
          false
        );

        const { height: newHeight, width: newWidth } = taille;
        canvas.setWidth(newWidth);
        canvas.setHeight(newHeight);
        // canvas.uniScaleTransform = true;
        canvas.selection = false;
        canvas.defaultCursor = "pointer";

        this.scaling = {
          x: newWidth / width,
          y: newHeight / height,
          newHeight,
          newWidth,
        };
        resolve(canvas);
      }, 50);
    });

  loadImage = () => {
    const { image } = this.props;

    if (this.canvas) {
      this.canvas.getObjects().forEach((o: any) => this.canvas.remove(o));
    }

    //valeurs par défaut
    //eslint-disable-next-line
    let labels: any[] = [];
    let url = "/404.png";

    if (image) {
      url = image.url;
      labels = image.labels.polygonLabels;
    }

    fabric.util.loadImage(url, (img: any) => {
      if (img === null) {
        fabric.util.loadImage("/404.png", (img: any) => {
          setTimeout(() => this.addImageToCanvas(img), 50);
        });
        return;
      }
      if (image) {
        this.loadPolygons(labels);
      }
      // si des coordonnées ont été sauvegarder en base
      // on utilisera directement les données depuis la base dans drawPlateBox
      setTimeout(() => this.addImageToCanvas(img, image.lastLabeledBy), 50);
    });
  };

  addImageToCanvas = (
    img: any,
    labels: any = null,
    motif: null | string = null
  ) => {
    const { newWidth, newHeight } = this.scaling;
    this.image = new fabric.Image(img, {});
    this.canvas.add(this.image);

    this.image.set({
      left: newWidth / 2,
      top: newHeight / 2,
      selectable: false,
      lockMovementX: true,
      centeredScaling: true,
      lockMovementY: true,
      hoverCursor: "arrow",
      scaleX: this.scaling.x,
      scaleY: this.scaling.y,
      originX: "center",
      originY: "center",
      opacity: 1,
    });

    this.hydrate(labels, motif);
    this.canvas.renderAll();
  };

  loadPolygons = (labels: any) => {
    const polys = labels.reduce(
      (memo: any, l: any) => ({
        ...memo,
        [l.labelValue]: memo[l.labelValue] ? memo[l.labelValue].concat(l) : [l],
      }),
      {}
    );
    const polygons = Object.keys(polys).reduce(
      (memo: any, key: string) =>
        memo.concat(
          polys[key].reduce(
            (m: any, l: any, idx: number) =>
              m.concat({
                labelValue: `${key}${SEPARATOR}${idx}`,
                done: true,
                deleted: false,
                points: l.allX.map((x: any, index: number) => ({
                  x: x * this.scaling.x,
                  y: l.allY[index] * this.scaling.y,
                })),
              }),
            []
          )
        ),
      []
    );
    this.setState({ polygons });
  };

  drawPolygon = (poly: any, pointIdxToActivate: number | null = null) => {
    const pointRectSize = 8;
    const demiSize = pointRectSize / 2;
    const labelForPoly = this.props.labels.find(
      (l: any) => l.legend === poly.labelValue.split(SEPARATOR)[0]
    );

    const fill = labelForPoly
      ? labelForPoly.color
      : this.props.labels[this.state.labelSelectedIndex].color;

    const polygon =
      poly.points.length !== 2
        ? new fabric.Polygon(poly.points, {
            fill,
            opacity: 0.4,
            selectable: false,
            objectCaching: false,
            stroke: darken(fill, 0.5),
            strokeWidth: 3,
          })
        : new fabric.Line(
            [
              poly.points[0].x,
              poly.points[0].y,
              poly.points[1].x,
              poly.points[1].y,
            ],
            {
              fill,
              stroke: fill,
              strokeWidth: 2,
              opacity: 0.4,
              selectable: false,
              evented: false,
            }
          );

    //@ts-ignore
    polygon.set("name", `${poly.labelValue}`);

    this.clearAllByName(`${poly.labelValue}`);

    this.canvas.add(polygon);

    // création des points d'accroche
    poly.points.forEach((p: any, index: number) => {
      const rect = new fabric.Rect({
        left: p.x - demiSize,
        top: p.y - demiSize,
        width: pointRectSize,
        height: pointRectSize, //: Math.floor(width / ratio),
        hasRotatingPoint: false,
        strokeWidth: 1,
        stroke: "#FFF",
        selectable: true,
        lockUniScaling: true,
        originX: "left",
        originY: "top",
        fill: index === poly.points.length - 1 ? darken(fill, 1) : "#FFF",
      });
      rect.set("name", `${poly.labelValue}${SEPARATOR}${index}`);
      //@ts-ignore
      rect.set("mask", false);

      rect.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
        mtr: false,
      });
    });
  };

  hydrate = (labels: any, motif: null | string) => {
    if (motif) {
      this.addTextBox(motif, "#000", "#FFF");
    } else {
      this.state.polygons.forEach((p: any) => this.drawPolygon(p));
    }
  };

  clearAllByName = (name: string) =>
    this.canvas
      .getObjects()
      .filter((o: any) => o.get("name") && o.get("name").indexOf(name) !== -1)
      .map((o: any) => this.canvas.remove(o));

  addTextBox = (texte: string, bgColor: string, textColor: string) => {
    const { newWidth, newHeight } = this.scaling;
    const txt = new fabric.IText(texte, {
      left: newWidth / 2 - texte.length / 2,
      top: newHeight - 19,
      height: 50,
      width: newWidth / 4,
      fontSize: 20,
      fill: textColor,
      fontFamily: "Roboto",
      strokeWidth: 0.5,
      stroke: textColor,
      originY: "center",
      originX: "center",
    });

    const box = new fabric.Rect({
      left: 0,
      top: newHeight - 40,
      width: newWidth,
      height: 40,
      hasRotatingPoint: false,
      strokeWidth: 2,
      fill: bgColor,
      selectable: false,
      originX: "left",
      originY: "top",
    });

    this.textBox = new fabric.Group([box, txt]);

    this.canvas.add(this.textBox);
  };

  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.canvasContainer} ref={this.canvasWrapperRef}>
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(ClassifLabellisation));
