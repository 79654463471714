import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import FilterIcon from "@material-ui/icons/FilterList";
import TableBody from "../common/TableBody";
import EnhancedTableHeader from "../common/EnhancedTableHeader";
import DimensionsContainer from "../common/DimensionsContainer";
import withContext from "../../tools/withContext";
import UserActionButton from "./components/UserActionButton";
import UsersActionButton from "./components/UsersActionButton";
import TableNavigation from "../common/TableNavigation";
import UiSearchInputField from "../common/UiSearchInputField";
import FilteringProjectDialog from "./components/FilteringProjectDialog";
import UserFormDialog from "./components/UserFormDialog";
import ContextType from "../../types/Context";
import UserType from "../../types/User";
import ProjectType from "../../types/Project";
import useRouter from "../../tools/useRouter";

const rows = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "Prénom",
    onlyRoles: null,
    size: 1,
    align: "center",
    sortable: true,
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: "Nom",
    onlyRoles: null,
    size: 2,
    align: "center",
    sortable: true,
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
    onlyRoles: null,
    size: 2,
    align: "center",
    sortable: true,
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Rôle",
    onlyRoles: null,
    size: 2,
    align: "center",
    sortable: true,
  },
  {
    id: "projects",
    numeric: false,
    disablePadding: false,
    label: "Projets",
    onlyRoles: null,
    size: 2,
    align: "center",
    sortable: false,
  },
  {
    id: "actions",
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: "Actions",
    onlyRoles: null,
    size: 1,
    align: "right",
  },
];

export const mappingUserRoles: any = {
  ROLE_ADMIN: "Admin",
  ROLE_LABELER: "Labeler",
  ROLE_PROJECT_ADMIN: "Project Admin",
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 50,
    },
    toolbar: {
      textAlign: "right",
      padding: theme.spacing.unit * 2,
    },
    noUser: {
      textAlign: "center",
      padding: theme.spacing.unit * 2,
    },
    fullWidth: {
      width: "100%",
    },
  });

interface PropsType {
  classes: any;
  ctx: ContextType;
  theme: any;
  saveUser: Function;
  deleteUser: Function;
  resetToken2FA: Function;
  users: UserType[];
  isLoading: boolean;
  projects: ProjectType[];
  pagination: any;
  location: any;
  history: any;
  match: any;
}

const Users = (props: PropsType) => {
  const {
    classes,
    users,
    projects,
    saveUser,
    deleteUser,
    resetToken2FA,
    isLoading,
    pagination,
    match: {
      params: { noPage },
    },
    theme,
  } = props;

  const router = useRouter();
  const searchParsed = router.query;
  const s = searchParsed.searchTerm || "";

  const [order, setOrder] = useState(
    //@ts-ignore
    searchParsed.sort ? searchParsed.sort.split(",")[1] : "desc"
  );

  const [orderBy, setOrderBy] = useState(
    //@ts-ignore
    searchParsed.sort ? searchParsed.sort.split(",")[0] : "createdDateInt"
  );

  //@ts-ignore
  const [searchTerm, setSearchTerm] = useState<string>(s);

  const [projectsSelected, setProjectSelected] = useState<any[]>([]);

  const [
    filteredByProjectDialogOpen,
    setFilteredByProjectDialogOpen,
  ] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [userSelected, setUserSelected] = useState<any>(null);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const findUrlProjectsSelected = useCallback(() => {
    const urlP =
      typeof searchParsed.projectIds === "string"
        ? searchParsed.projectIds.split(",")
        : searchParsed.projectIds;
    if (!urlP) return undefined;
    return urlP
      .map((pId: string) => projects.find((p: ProjectType) => p.id === pId))
      .filter((p: any) => !!p)
      .map((p: any) => p.id);
  }, [searchParsed, projects]);

  const handleRequestSort = (event: any, property: string) => {
    let _orderBy = property;
    let _order = "desc";

    if (orderBy === property && order === "desc") {
      _order = "asc";
    }

    props.history.push(
      `/userlist/${noPage}?${queryString.stringify(
        { ...searchParsed, sort: `${_orderBy},${_order}` },
        { strict: false }
      )}`
    );
    setOrderBy(_orderBy);
    setOrder(_order);
  };

  const handleChangePage = (noP: any) => {
    props.history.push(
      `/userlist/${noP}?${queryString.stringify(router.query)}`
    );
  };

  const handleSearch = (str: string) => {
    setSearchTerm(str);
    props.history.push(
      `/userlist/${str.length > 0 ? "0" : noPage}?${queryString.stringify({
        ...router.query,
        searchTerm: str.length > 0 ? str : undefined,
      })}`
    );
  };

  useEffect(() => {
    if (projects.length > 0) {
      const pr = findUrlProjectsSelected();

      if (pr) {
        setProjectSelected(pr);
      }
    }
  }, [projects]);

  const buildColumns = (usr: UserType) => [
    <div>{usr.firstName}</div>,
    <div>{usr.lastName}</div>,
    <div>{usr.email}</div>,
    <div>{mappingUserRoles[usr.authorities[0]]}</div>,
    <div>
      {usr.projects
        .map((id: any) => {
          const proj = projects.find((pro: any) => pro.id === id);
          if (!proj) return "";
          return `${proj.name} (${proj.client.name})`;
        })
        .filter((p: any) => (p.length > 1 && p[0] === "" ? p[0] : p))
        .join(", ")}
    </div>,
    // <div>{usr.createdDateInt}</Grid>,
    <div>
      <UserActionButton
        user={usr}
        saveUser={saveUser}
        deleteUser={deleteUser}
        resetToken2FA={resetToken2FA}
        projects={projects}
        openDialog={openDialog}
        closeDialog={closeDialog}
      />
    </div>,
  ];

  // const usersOrderedFiltered = stableSort(users, getSorting(order, orderBy));
  const usersOrderedFiltered = users;

  const openFilterByProjectDialog = () => setFilteredByProjectDialogOpen(true);
  const closeFilterByProjectDialog = () =>
    setFilteredByProjectDialogOpen(false);

  const onProjectClicked = (projectId: string) =>
    setProjectSelected(
      projectsSelected.includes(projectId)
        ? projectsSelected.filter((pId: string) => pId !== projectId)
        : projectsSelected.concat([projectId])
    );

  const clearFilter = () => {
    setProjectSelected([]);
    props.history.push(
      `/userlist/${noPage}?${queryString.stringify({
        ...router.query,
        projectIds: undefined,
      })}`
    );
  };

  const onFilteringSubmitted = () => {
    const projectIds =
      projectsSelected.length > 0 ? projectsSelected.join(",") : undefined;
    props.history.push(
      `/userlist/${noPage}?${queryString.stringify({
        ...router.query,
        projectIds,
      })}`
    );
  };

  const handleSelection = (e: any, id: string) => {
    setUserSelected(users.find((u: any) => u.id === id));
  };

  const closeDialog = () => setIsDialogOpen(false);

  const openDialog = () => setIsDialogOpen(true);

  const handleSave = (u: UserType) => {
    saveUser(u).then(() => closeDialog());
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Helmet>
        <title>Liste des utilisateurs</title>
      </Helmet>
      <UserFormDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onSave={handleSave}
        projects={projects}
        user={userSelected}
      />
      <Grid item className={classes.fullWidth}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.toolbar}
        >
          <Grid item>
            <Grid container direction="row" justify="flex-start" spacing={8}>
              <Grid item>
                <Typography
                  component="h2"
                  style={{ fontSize: matches ? "1.8em" : "2.5em" }}
                >
                  Liste des utilisateurs
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={openFilterByProjectDialog}
                  title="Filtrer par projet"
                  style={{ marginTop: "6px" }}
                >
                  <FilterIcon
                    color={
                      projectsSelected.length > 0 ? "secondary" : "default"
                    }
                  />
                </IconButton>
                <FilteringProjectDialog
                  projects={projects}
                  projectsSelected={projectsSelected}
                  open={filteredByProjectDialogOpen}
                  onClose={closeFilterByProjectDialog}
                  onClick={onProjectClicked}
                  clearFilter={clearFilter}
                  onFilteringSubmitted={onFilteringSubmitted}
                  isAlreadyFiltred={!!searchParsed.projectIds}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{isLoading && <CircularProgress color="primary" />}</Grid>
          <Grid item>
            <Grid container direction="row" spacing={8}>
              <Grid item style={{ minHeight: 70 }}>
                <UsersActionButton saveUser={saveUser} projects={projects} />
              </Grid>
              <Grid item>
                <UiSearchInputField
                  searchString={searchTerm}
                  setSearchString={handleSearch}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {users.length === 0 && !isLoading && (
        <Grid item>
          <Typography className={classes.noUser} variant="h4">
            Aucun utilisateur
          </Typography>
        </Grid>
      )}
      {users.length > 0 && (
        <>
          <Grid item className={classes.fullWidth}>
            <EnhancedTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
              rows={rows}
              withCheckBox={false}
            />
          </Grid>
          <DimensionsContainer>
            <TableBody
              handleSelection={handleSelection}
              buildColumns={buildColumns}
              selected={[]}
              datas={usersOrderedFiltered}
              rows={rows}
              noPage={noPage}
            />
          </DimensionsContainer>
          {pagination && (
            <Grid item className={classes.fullWidth}>
              <TableNavigation
                pagination={pagination}
                onChangePage={handleChangePage}
                itemName="Utilisateurs"
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(withRouter(withContext(withTheme()(Users))));
