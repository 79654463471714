import React, { useRef } from "react";
import { withRouter } from "react-router-dom";
import { withStyles, withTheme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import Drawer from "@material-ui/core/Drawer";
import useComponentSize from "@rehooks/component-size";
import { FixedSizeList as List } from "react-window";
import DrawerPhotoListItemRenderer from "./DrawerPhotoListItemRender";

const styles = theme =>
  createStyles({
    root: {
      //maxHeight: "240px",
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    selected: {
      border: "none",
      borderBottom: `solid 3px ${theme.palette.primary.main}`
    },
    notSelected: {
      border: "none"
    }
  });

const DrawerPhotoList = props => {
  const divRef = useRef(null);
  const size = useComponentSize(divRef);
  const { open, classes, images, theme } = props;

  return (
    <Drawer
      open={open}
      className={classes.root}
      variant="persistent"
      transitionDuration={3000}
      anchor="bottom"
      PaperProps={{
        style: {
          height: 160,
          padding: "10px",
          overflowY: "auto",
          borderTop: `solid 2px ${theme.palette.primary.main}`,
          backgroundColor: theme.custom.bottomDrawerBackground
        }
      }}
    >
      <div ref={divRef}>
        {size.width > 0 && (
          <List
            direction="horizontal"
            height={145}
            itemData={images}
            itemCount={images.length}
            itemSize={130}
            width={size.width}
          >
            {DrawerPhotoListItemRenderer(props)}
          </List>
        )}
      </div>
    </Drawer>
  );
};

export default withStyles(styles)(withTheme()(withRouter(DrawerPhotoList)));
